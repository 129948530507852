import React, { useEffect, useMemo, useState } from 'react';

import { EmployeeRole } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, PodConfig, PodRule } from '@vestahealthcare/common/models';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';

import { Button, CollapsableSidebar, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

interface Props {
  'data-cy'?: string;
  employees?: Employee[];
  open?: boolean;
  podConfig?: PodConfig;
  taskTypes: PodRule[];
  review?: boolean;

  onClose: () => void;
  onSubmit: (taskType: PodRule, employee: Employee) => void;
}

export const PodTaskOverrideModal = (props: Props) => {
  const {
    employees: allEmployees,
    open,
    onClose,
    onSubmit,
    podConfig,
    taskTypes,
    review,
  } = props;

  const [submitted, setSubmitted] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [employee, setEmployee] = useState<Employee>();
  const [taskType, setTaskType] = useState<PodRule>();

  const mapEmployeeSlot = (role: EmployeeRole) => (s: PodConfigEmployeeSlot) =>
    new Employee({ ...s.employee, role: role.value });

  const podEmployees = useMemo(() => {
    if (!podConfig) return [];
    return (taskType?.roles || []).reduce((acc, role) => {
      let employees = [] as Employee[];
      if (role === EmployeeRole.MA) {
        employees = podConfig.assignedCareCoordinators.map(
          mapEmployeeSlot(EmployeeRole.MA),
        );
      } else if (role === EmployeeRole.ENGAGEMENT_COORDINATOR) {
        employees = podConfig.assignedEngagementOwners.map(
          mapEmployeeSlot(EmployeeRole.ENGAGEMENT_COORDINATOR),
        );
      } else if (role === EmployeeRole.HEALTH_COACH) {
        employees = podConfig.assignedHealthCoaches.map(
          mapEmployeeSlot(EmployeeRole.HEALTH_COACH),
        );
      } else if (role === EmployeeRole.NP) {
        employees = podConfig.assignedNPs.map(mapEmployeeSlot(EmployeeRole.NP));
      } else if (role === EmployeeRole.RN) {
        employees = podConfig.assignedRNs.map(mapEmployeeSlot(EmployeeRole.RN));
      }
      return [...acc, ...employees];
    }, [] as Employee[]) as Employee[];
  }, [allEmployees, podConfig, taskType]);

  const podEmployeeIds = useMemo(() => podEmployees.map(({ id }) => id), [
    podEmployees,
  ]);

  const otherEmployees = useMemo(() => {
    return (taskType?.roles || [])
      .reduce((acc, role) => {
        const employees =
          allEmployees?.filter(
            ({ id, role: employeeRole }) =>
              employeeRole === role && !podEmployeeIds.includes(id),
          ) || [];
        return [...acc, ...employees];
      }, [] as Employee[])
      .filter(Boolean);
  }, [allEmployees, podEmployeeIds, taskType]);

  const reset = () => {
    setEmployee(undefined);
    setTaskType(undefined);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (!taskType || !employee) return;
    setLoadingSubmit(true);
    await onSubmit(taskType, employee);
    setLoadingSubmit(false);
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={<h2>{translate('pods.metrics.overrideModal.title')}</h2>}
      size={450}
    >
      <CollapsableSidebar.Body data-cy="upload-referral-file-body">
        <Select
          data-cy="pod-task-override-employee-type"
          error={submitted && !taskType}
          getItemLabel={({ name }: PodRule) => name}
          items={taskTypes || []}
          label={translate('pods.metrics.overrideModal.task')}
          onChange={setTaskType}
          required
          value={taskType}
        />
        <Select
          data-cy="pod-task-override-employee-assignee"
          disabled={!taskType}
          error={submitted && !employee}
          filterOptions={FilterEmployeeOptions}
          getItemLabel={({ fullName }: Employee) => fullName}
          grouped={({ id, role }) =>
            podEmployeeIds.includes(id) ? 'POD' : role.toString()
          }
          items={[...podEmployees, ...otherEmployees]}
          label={translate('pods.metrics.overrideModal.employee')}
          onChange={setEmployee}
          required
          value={taskType ? employee : undefined}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button data-cy="close-button" color="tertiary" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          data-cy="review-button"
          color={review ? 'primary' : 'secondary'}
          loading={loadingSubmit}
          onClick={doSubmit}
        >
          {translate(
            review ? 'pods.metrics.reassignModal.review' : 'global.save',
          )}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
