import { EmployeeRole, PodRuleConfirmationType } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { make, makeArray, makeEnums } from './Model';
import { PodRuleTrigger } from './PodRuleTrigger';
import { ProgramExtension } from './ProgramExtension';

export class PodRule {
  id = 0;

  active = true;

  additional = false;

  default = false;

  description = '';

  flexible = false;

  maxAttempts = 0;

  name = '';

  overriddenBy?: PodRule[];

  programExtension: ProgramExtension;

  requireConfirmation = false;

  confirmationType?: PodRuleConfirmationType;

  confirmationTypeReason = '';

  roles: EmployeeRole[];

  rolloverAfterPeriod = false;

  spacing = 0;

  triggers?: PodRuleTrigger[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.overriddenBy = makeArray(obj.overriddenBy, PodRule);
    this.programExtension = make(obj.programExtension, ProgramExtension);
    this.confirmationType = PodRuleConfirmationType.byKey[obj.confirmationType];
    this.roles = makeEnums(obj.roles, EmployeeRole);
    this.triggers = makeArray(obj.triggers, PodRuleTrigger);
  }

  static toSelectable(employee: PodRule[]): SelectableInfo<PodRule>[] {
    return employee.map((e) => ({
      value: e.id,
      label: e.name,
      info: e,
    }));
  }

  static INITIAL_CARE_PLAN_ID = 1;

  static WELLNESS_CHECK_IN_ID = 6;
}

export default PodRule;
