import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import ProcessRosterIcon from '@mui/icons-material/TaskOutlined';
import { makeStyles } from '@mui/styles';

import { RosterFileHistoryStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  PaginationType,
  RosterFileHistory,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Colors,
  Fonts,
  IconButton,
  StatusIndicator,
  Table,
  Tooltip,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';

type Props = {
  disableProcessRoster?: boolean;
  files: RosterFileHistory[];
  onChangePage: (pageSize: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onDowndload: (fileId: number, fileName: string) => void;
  onProcess: (file: RosterFileHistory) => void;
  pagination?: PaginationType;
};

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const getFileName = (name: string, suffix: string) => {
  const extensionDot = name?.lastIndexOf('.');
  const fileName =
    name?.substring(0, extensionDot > 0 ? extensionDot : undefined) || '';
  return `${fileName || 'file'}_${suffix}.csv`;
};

const ROSTER_FILE_HISTORY_COLUMNS = (
  { disableProcessRoster, onDowndload, onProcess }: Props,
  organizations: Organization[],
) => [
  {
    headerName: translate('rosterFileHistory.table.name'),
    field: 'name',
  },
  {
    headerName: translate('rosterFileHistory.table.referral'),
    component: ({ organizationId }: RosterFileHistory) =>
      organizations?.find(({ id }) => id === organizationId)?.name || EMPTY,
  },
  {
    headerName: translate('rosterFileHistory.table.uploadDate'),
    component: ({ createdAt }: RosterFileHistory) =>
      createdAt ? createdAt.format(DATE_FORMAT_SHORT) : EMPTY,
    width: 150,
  },
  {
    headerName: translate('rosterFileHistory.table.status'),
    component: ({ errorMessage, status }: RosterFileHistory) => (
      <StatusIndicator
        color={RosterFileHistoryStatus.getColor(status)}
        status={status?.toString()}
        subtitle={status === RosterFileHistoryStatus.ERROR && errorMessage}
      />
    ),
    width: 150,
  },
  {
    headerName: translate('rosterFileHistory.table.formatted'),
    component: ({
      numErrorRows,
      numErrorRowsPerc,
      numFormattedRows,
      numFormattedRowsPerc,
      rows,
    }: RosterFileHistory) => (
      <div className="flex min-gap">
        (
        <Tooltip text={`${numFormattedRows || 0} / ${rows}`}>
          <span className="green semi-bold">{numFormattedRowsPerc}%</span>
        </Tooltip>
        /
        <Tooltip text={`${numErrorRows || 0} / ${rows}`}>
          <span className="red semi-bold">{numErrorRowsPerc}%</span>
        </Tooltip>
        )
      </div>
    ),
    width: 200,
  },
  {
    headerName: translate('rosterFileHistory.table.source'),
    component: ({ source }: RosterFileHistory) => source?.toString() || EMPTY,
    width: 100,
  },
  {
    headerName: translate('rosterFileHistory.table.links'),
    component: ({
      errorFileId,
      name,
      rawRosterFileId,
      readyToUploadFileId,
      status,
    }: RosterFileHistory) => (
      <div className="flex ">
        {rawRosterFileId ? (
          <IconButton
            onClick={() =>
              onDowndload(rawRosterFileId, getFileName(name, 'raw'))
            }
            size="small"
            tooltip={`${translate('global.download')} ${translate(
              'rosterFileHistory.table.rawFileLink',
            )}`}
          >
            <DownloadIcon htmlColor={Colors.blue} />
          </IconButton>
        ) : (
          <div style={{ width: '3.3rem' }} />
        )}
        {readyToUploadFileId ? (
          <IconButton
            onClick={() =>
              onDowndload(
                readyToUploadFileId,
                getFileName(name, 'ready_to_upload'),
              )
            }
            disabled={status === RosterFileHistoryStatus.IN_PROGRESS}
            size="small"
            tooltip={`${translate('global.download')} ${translate(
              'rosterFileHistory.table.readyToUploadFileLink',
            )}`}
          >
            <DownloadIcon
              htmlColor={
                status === RosterFileHistoryStatus.IN_PROGRESS
                  ? Colors.iconGray
                  : undefined
              }
            />
          </IconButton>
        ) : (
          <div style={{ width: '3.3rem' }} />
        )}
        {errorFileId ? (
          <IconButton
            onClick={() => onDowndload(errorFileId, getFileName(name, 'error'))}
            disabled={status === RosterFileHistoryStatus.IN_PROGRESS}
            size="small"
            tooltip={`${translate('global.download')} ${translate(
              'rosterFileHistory.table.errorFileLink',
            )}`}
          >
            <DownloadIcon
              htmlColor={
                status === RosterFileHistoryStatus.IN_PROGRESS
                  ? Colors.iconGray
                  : Colors.error
              }
            />
          </IconButton>
        ) : (
          <div style={{ width: '3.3rem' }} />
        )}
      </div>
    ),
    width: 125,
  },
  {
    align: 'right',
    component: (rosterFileHistory: RosterFileHistory) => {
      if (!rosterFileHistory.canBeProcessed) return <></>;
      if (disableProcessRoster)
        return (
          <Tooltip
            text={translate(
              'rosterFileHistory.table.processFileDisabledTooltip',
            )}
          >
            <div>
              <IconButton disabled size="small">
                <ProcessRosterIcon htmlColor={Colors.iconGray} />
              </IconButton>
            </div>
          </Tooltip>
        );

      return (
        <IconButton
          onClick={() => onProcess(rosterFileHistory)}
          size="small"
          tooltip={translate('rosterFileHistory.table.processFile')}
        >
          <ProcessRosterIcon htmlColor={Colors.iconGreen} />
        </IconButton>
      );
    },
    width: 50,
  },
];

export const RosterFileHistoryTable = (props: Props) => {
  const styles = useStyles();
  const { files, onChangePage, onChangePageSize, pagination } = props;
  const [organizations, setOrganization] = useState<Organization[]>([]);

  const fetchInitialData = async () => {
    const organizations = await CacheServices.getOrganizations();
    setOrganization(organizations);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Table
      className={styles.table}
      config={{
        columns: ROSTER_FILE_HISTORY_COLUMNS(props, organizations),
        compact: true,
        data: files,
        noItemsLabel: translate('rosterFileHistory.noFiles'),
        pagination,
        paginationOptions: [25, 50, 100],
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
    />
  );
};

export default RosterFileHistoryTable;
