import React from 'react';

import { makeStyles } from '@mui/styles';

import { ProgramTaskStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PodReportingTask } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Fonts, Table, Tooltip } from 'styleguide-v2';

interface Props {
  data: PodReportingTask[];
  onClickTaskStatus?: (
    item: PodReportingTask,
    status?: ProgramTaskStatus[],
  ) => void;
}

const renderLink = (
  field: keyof PodReportingTask,
  handler?: Props['onClickTaskStatus'],
  statuses?: ProgramTaskStatus[],
) => (data: PodReportingTask) =>
  data[field] && handler ? (
    <a
      className="underline"
      onClick={(evt) => {
        evt.preventDefault();
        if (handler) handler(data, statuses);
      }}
    >
      {data[field]}
    </a>
  ) : (
    <span>{data[field] || EMPTY}</span>
  );

const POD_REPORTING_TASK_COLUMNS = ({ onClickTaskStatus }: Props) => [
  {
    headerName: translate('podReporting.table.taskName'),
    component: ({ name }: PodReportingTask) => <span>{name || EMPTY}</span>,
  },
  {
    headerName: translate('podReporting.table.total'),
    component: renderLink('total', onClickTaskStatus),
  },
  {
    headerName: translate('podReporting.table.completed'),
    component: renderLink('completed', onClickTaskStatus, [
      ProgramTaskStatus.COMPLETED,
    ]),
  },
  {
    headerName: translate('podReporting.table.awaitingConfirmation'),
    component: renderLink('pendingConfirmation', onClickTaskStatus, [
      ProgramTaskStatus.AWAITING_CONFIRMATION,
    ]),
  },
  {
    headerName: translate('podReporting.table.attempted'),
    component: (item: PodReportingTask) => (
      <Tooltip
        text={
          <>
            <p className="white no-margin flex spaced gap">
              <span>{translate('podReporting.table.oneAttempt')}:</span>
              <span>{item.oneAttempt}</span>
            </p>
            <p className="white no-margin flex spaced gap">
              <span>{translate('podReporting.table.twoAttempts')}:</span>
              <span>{item.twoAttempts}</span>
            </p>
            <p className="white no-margin flex spaced gap">
              <span>{translate('podReporting.table.moreAttempts')}:</span>
              <span>{item.threePlusAttempts}</span>
            </p>
          </>
        }
      >
        {renderLink('attempted', onClickTaskStatus, [
          ProgramTaskStatus.IN_PROGRESS,
        ])(item)}
      </Tooltip>
    ),
  },
  {
    headerName: translate('podReporting.table.notStarted'),
    component: renderLink('notStarted', onClickTaskStatus, [
      ProgramTaskStatus.NOT_STARTED,
    ]),
  },
  {
    headerName: translate('podReporting.table.transferred'),
    component: renderLink('transferred', onClickTaskStatus, [
      ProgramTaskStatus.TRANSFERRED,
    ]),
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

export const PodReportingTaskTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: POD_REPORTING_TASK_COLUMNS(props),
        compact: true,
        data: props.data,
        defaultPageSize: 25,
        paginationOptions: props.data?.length > 25 ? [25, 50, 100] : [-1],
      }}
      whiteBackground
    />
  );
};

export default PodReportingTaskTable;
