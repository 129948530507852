import React from 'react';

import { States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { FilterItem, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import { GetTOCParams } from 'dash/src/services/TOCServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetTOCParams = keyof GetTOCParams;

type Props = {
  data: {
    languages: Selectable[];
    employees: Selectable[];
    referralSources: Selectable[];
  };
  filters?: {
    [x in KeyGetTOCParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetTOCParams]?: FilterItem;
    },
  ) => void;
};

export const SkilledFilterBar = ({
  data,
  filters,
  loading,
  onChange,
}: Props) => (
  <>
    <Select
      allowAddNewValue
      data-cy="toc-filter-bar-member-id"
      items={[]}
      inputType="number"
      multiple
      noOptionsText={translate('members.typeToSearch')}
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          memberId: getFilterItem(
            translate('toc.common.memberId'),
            values.map(({ label }) => ({
              value: label,
              label,
            })),
          ),
        })
      }
      placeholder={translate('toc.common.memberId')}
      size="xs"
      value={filters?.memberId?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-state"
      items={Enum.toSelectable(States.asArray)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          state: getFilterItem(translate('toc.common.state'), values),
        })
      }
      placeholder={translate('toc.common.state')}
      placeholderV2
      size="xs"
      value={filters?.state?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-language"
      items={data.languages.filter(({ disabled }) => !disabled)}
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(translate('toc.common.language'), values),
        })
      }
      placeholder={translate('toc.common.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <ReferralDropdown
      data-cy="toc-filter-bar-referrals"
      items={data.referralSources}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          referralSourceId: getFilterItem(
            translate('toc.common.referralSources'),
            values,
          ),
        })
      }
      placeholder={translate('toc.common.referralSources')}
      placeholderV2
      size="xs"
      value={filters?.referralSourceId?.value || []}
    />
    <Select
      data-cy="toc-filter-bar-assignee"
      filterOptions={FilterEmployeeOptions}
      items={data.employees}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          assigneeId: getFilterItem(translate('toc.common.assignee'), values),
        })
      }
      placeholder={translate('toc.common.assignee')}
      placeholderV2
      size="xs"
      value={filters?.assigneeId?.value || []}
    />
  </>
);
