import { PatientBase } from './PatientBase';

export class PatientSearch extends PatientBase {
  medicareId = '';

  medicaidId = '';

  mltcId = '';

  constructor(obj: any) {
    super(obj);
    this.medicareId = obj.medicareId;
    this.medicaidId = obj.medicaidId;
    this.mltcId = obj.mltcId;
  }
}

export default PatientSearch;
