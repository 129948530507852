import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Fonts } from 'styleguide-v2/src/styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  extraInfo?: string[] | ReactNode[];
  key: string;
  info: string | ReactNode;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  type: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

export const SelectDetailedOption = ({
  className,
  'data-cy': dataCy,
  extraInfo,
  info,
  key,
}: Props) => {
  const styles = useStyles();
  const classes = classnames(className, styles.container);

  return (
    <div className={classes} data-cy={dataCy} key={key}>
      <span className={styles.type}>{info}</span>
      {extraInfo?.map((ei, idx) => (
        <span className={styles.extraInfo} key={`${key}-item-${idx}`}>
          {ei}
        </span>
      ))}
    </div>
  );
};

export default SelectDetailedOption;
