import { translate } from '@vestahealthcare/common/i18n';

export const POD_REPORTING_TAB_ALL = 'all';
export const POD_REPORTING_TAB_COMPLEX_CCM = 'complex-ccm';
export const POD_REPORTING_TAB_WORKLIST_TASKS = 'worklist-tasks';
export const POD_REPORTING_TAB_WORKLIST_GROUPS = 'worklist-groups';

export const POD_REPORTING_TAB_ITEMS_COMPLEX = [
  {
    label: translate('podReporting.tabs.all'),
    value: POD_REPORTING_TAB_ALL,
  },
  {
    label: translate('podReporting.tabs.complexCCM'),
    value: POD_REPORTING_TAB_COMPLEX_CCM,
  },
];

export const POD_REPORTING_TAB_ITEMS_WORKLIST = [
  {
    label: translate('podReporting.tabs.tasks'),
    value: POD_REPORTING_TAB_WORKLIST_TASKS,
  },
  {
    label: translate('podReporting.tabs.wordklistGroups'),
    value: POD_REPORTING_TAB_WORKLIST_GROUPS,
  },
];
