import React, { Fragment } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

import { Enum } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PodConfig } from '@vestahealthcare/common/models';
import { PodConfigEmployeeSlot } from '@vestahealthcare/common/models/PodConfigEmployeeSlot';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { IconButton, Table, Tooltip, UpdatedBy } from 'styleguide-v2';

interface Props {
  configs: PodConfig[];
  onAdd: () => void;
  onEdit: (group: PodConfig) => void;
}

const renderItem = (podId: number, key: string) => (item: Enum) => (
  <p className="no-margin" key={`pod-config-${podId}-${key}-${item.value}`}>
    {item.toString()}
  </p>
);

const renderData = (podId: number, key: string, maxItems = 4) => (
  items: Enum[],
) =>
  items && items.length > maxItems ? (
    <>
      {[...(items || [])].splice(0, maxItems - 1).map(renderItem(podId, key))}
      <Tooltip
        text={
          <>
            {[...items]
              .slice(maxItems - 1)
              .map((item) => item?.toString())
              .join(', ')}
          </>
        }
      >
        <a onClick={(evt) => evt.preventDefault()}>
          {items.length - maxItems + 1} more
        </a>
      </Tooltip>
    </>
  ) : (
    (items || []).map(renderItem(podId, key))
  );

const renderVestaTeam = (podId: number) => (
  slots?: PodConfigEmployeeSlot[],
  maxItems = 4,
) => {
  if (!slots?.length)
    return <span className="red">{translate('global.unassigned')}</span>;
  if (slots.length > maxItems)
    return (
      <>
        {[...slots].slice(0, maxItems - 1).map(({ id, employee }) => (
          <span
            className={employee?.id ? '' : 'red'}
            key={`pod-id-${id}-separator`}
          >
            {employee?.fullName || translate('global.unassigned')}
            {', '}
          </span>
        ))}
        <Tooltip
          text={
            <>
              {[...slots].slice(maxItems - 1).map(({ id, employee }) => (
                <p
                  className="no-margin white"
                  key={`pod-${podId}-employee-${id}`}
                >
                  {employee?.fullName || translate('global.unassigned')}
                </p>
              ))}
            </>
          }
        >
          <a onClick={(evt) => evt.preventDefault()}>
            {slots.length - maxItems + 1} more
          </a>
        </Tooltip>
      </>
    );
  return slots.map(({ employee, id }, idx) => (
    <span className={employee?.id ? '' : 'red'} key={`pod-id-${id}-separator`}>
      {employee?.fullName || translate('global.unassigned')}
      {(slots?.length || 0) - 1 !== idx && ', '}
    </span>
  ));
};

const POD_CONFIG_COLUMNS = ({ onEdit }: Props) => [
  {
    headerName: translate('pods.config.common.name'),
    field: 'name',
    width: 100,
  },
  {
    headerName: translate('pods.config.common.states'),
    component: ({ id, states }: PodConfig) =>
      renderData(id, 'state')([...states].sort(Enum.sortEnum)),
    width: 125,
  },
  {
    headerName: translate('pods.config.common.languages'),
    component: ({ id, languages }: PodConfig) =>
      renderData(id, 'lang')([...languages].sort(Enum.sortEnum)),
    width: 150,
  },
  {
    headerName: translate('pods.config.common.vestaTeam'),
    component: ({
      id,
      npOwners,
      rnOwners,
      careCoordinators,
      healthCoaches,
      engagementOwners,
      podManager,
    }: PodConfig) => (
      <div key={`pod-config-${id}-vesta-team`} className="grid-wrapper fit">
        <div className="grid-span-6">
          <p>
            <span className="bold">
              {translate('pods.config.common.podManager')}:&nbsp;
            </span>
            <span>{podManager?.fullName || EMPTY}</span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.nps')}:&nbsp;
            </span>
            <span>{renderVestaTeam(id)([...npOwners])}</span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.rns')}:&nbsp;
            </span>
            <span>{renderVestaTeam(id)([...rnOwners])}</span>
          </p>
        </div>
        <div className="grid-span-6">
          <p>
            <span className="bold">
              {translate('pods.config.common.careCoordinators')}:&nbsp;
            </span>
            <span>{renderVestaTeam(id)([...careCoordinators], 3)}</span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.healthCoaches')}:&nbsp;
            </span>
            <span>{renderVestaTeam(id)([...healthCoaches], 3)}</span>
          </p>
          <p>
            <span className="bold">
              {translate('pods.config.common.engagementOwners')}:&nbsp;
            </span>
            <span>{renderVestaTeam(id)([...engagementOwners])}</span>
          </p>
        </div>
      </div>
    ),
  },
  {
    headerName: translate('pods.config.common.active'),
    component: ({ active }: PodConfig) => (
      <span className="flex">
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </span>
    ),
  },
  {
    headerName: translate('pods.config.common.updated'),
    component: ({ updatedAt, updatedBy }: PodConfig) =>
      updatedAt ? (
        <UpdatedBy user={updatedBy?.fullName} date={updatedAt} />
      ) : (
        EMPTY
      ),
    width: 150,
  },
  {
    headerName: '',
    component: (config: PodConfig) => (
      <IconButton size="small" onClick={() => onEdit(config)}>
        <EditIcon />
      </IconButton>
    ),
    width: 20,
  },
];

export const PodConfigTable = (props: Props) => (
  <Table
    config={{
      columns: POD_CONFIG_COLUMNS(props),
      compact: true,
      data: props.configs,
      paginationOptions: [-1],
    }}
    empty={translate('pods.config.table.noResults')}
    emptyLink={translate('pods.config.table.add')}
    fontSize="small"
    onClickEmptyLink={props.onAdd}
  />
);

export default PodConfigTable;
