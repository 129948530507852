import React, { Fragment } from 'react';

import { Employee } from '@vestahealthcare/common/models';

import { SelectDetailedOption } from 'styleguide-v2';

export const SelectEmployeeOwner = ({
  key,
  employee,
}: {
  key: string;
  employee: Employee;
}) => (
  <SelectDetailedOption
    key={`${key}-${employee.id}`}
    info={employee.fullName}
    extraInfo={[
      [
        <Fragment key={`${key}-${employee.id}-states`}>
          {employee.nlc && <b>NLC </b>}
          {employee.licences?.map((st) => st.value).join(' ')}
        </Fragment>,
        employee.licences?.length && employee.languages?.length ? ' - ' : '',
        employee.languages?.map((lang) => lang.toString()).join(' '),
      ],
    ]}
  />
);
