import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { ReferralDeletionReason } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberReferral, Patient } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Button,
  CollapseIcon,
  Colors,
  Fonts,
  IconButton,
  Modal,
  Select,
  Spinner,
  Table,
  TextInput,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPatientById } from 'dash/src/redux/slices/MemberInfoSlice';
import {
  createMemberReferral,
  getMemberReferrals,
  removeMemberReferral,
  updateMemberReferral,
} from 'dash/src/services/PatientServices';

import Session from '../../../../services/SessionServices';
import ReferralEditModal, { ReferralData } from './ReferralEditModal';

const useStyles = makeStyles({
  title: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.5)`,
      fontWeight: 500,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface Props {
  member: Patient;
  onLoading: (loading: boolean) => void;
}

interface TableProps {
  deleteReferral?: (item: MemberReferral) => void;
  editReferral?: (item: MemberReferral) => void;
  emptyState?: string;
  isInactive?: boolean;
  referrals: MemberReferral[];
  showReferral?: (item: MemberReferral) => void;
}

const MEMBER_REFERRAL_COLUMNS = ({
  isInactive,
  editReferral,
  deleteReferral,
  showReferral,
}: TableProps) => {
  const columns: Column[] = [
    {
      headerName: translate('memberReferrals.source'),
      component: ({ organization }: MemberReferral) => (
        <span className="size-xs">{organization.name || EMPTY}</span>
      ),
      width: 225,
    },
    {
      headerName: translate('memberReferrals.affiliation'),
      component: ({ organization }: MemberReferral) => (
        <span className="size-xs">{organization.parent?.name || EMPTY}</span>
      ),
      width: 140,
    },
    {
      headerName: translate('memberReferrals.externalId'),
      component: (item: MemberReferral) => (
        <span className="size-xs" style={{ overflowWrap: 'anywhere' }}>
          {item.externalId || EMPTY}
        </span>
      ),
    },
    {
      headerName: translate('memberReferrals.type'),
      field: 'organization.type.name',
      component: ({ organization }: MemberReferral) => (
        <span className="size-xs">{organization.type?.name || EMPTY}</span>
      ),
      width: 150,
    },
    {
      headerName: translate('memberReferrals.enrollmentStart'),
      component: (item: MemberReferral) =>
        item.enrollmentStart
          ? moment(item.enrollmentStart).format(DATE_FORMAT_SHORT)
          : EMPTY,

      width: 140,
    },
    {
      headerName: translate('memberReferrals.enrollmentEnd'),
      component: (item: MemberReferral) =>
        item.enrollmentEnd
          ? moment(item.enrollmentEnd).format(DATE_FORMAT_SHORT)
          : EMPTY,

      width: 140,
    },
    {
      headerName: translate('memberReferrals.created'),
      component: ({ createdAt, createdBy }: MemberReferral) => (
        <UpdatedBy
          user={createdBy?.fullName}
          date={createdAt}
          contrast={isInactive}
          size="s"
        />
      ),
      width: 150,
    },
    {
      headerName: translate('memberReferrals.updated'),
      component: ({ updatedAt, updatedBy }: MemberReferral) => (
        <UpdatedBy
          user={updatedBy?.fullName}
          date={updatedAt}
          contrast={isInactive}
          size="s"
        />
      ),
      width: 150,
    },
  ];

  if (!isInactive) {
    columns.push({
      headerName: '',
      component: (item: MemberReferral) => (
        <div className="flex gap">
          {showReferral && (
            <IconButton
              className="no-padding"
              data-cy="referral-show-button"
              onClick={() => showReferral(item)}
              size="small"
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          )}
          {editReferral && (
            <IconButton
              className="no-padding"
              data-cy="referral-edit-button"
              onClick={() => editReferral(item)}
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          {deleteReferral && (
            <IconButton
              className="no-padding"
              data-cy="referral-delete-button"
              onClick={() => deleteReferral(item)}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      ),
      width: 50,
    });
  }

  return columns;
};

const tableStyles = makeStyles({
  inactive: {
    '&&': {
      backgroundColor: `${BackgroundColors.lightGray}!important`,
    },
  },
  table: {
    marginBottom: '2rem',
  },
});

const MemberReferralsTable = (props: TableProps) => {
  const styles = tableStyles();
  return (
    <Table
      className={styles.table}
      config={{
        columns: MEMBER_REFERRAL_COLUMNS(props),
        compact: true,
        data: props.referrals,
        getRowClass: () => {
          return props.isInactive ? styles.inactive : '';
        },
        paginationOptions: [-1],
        size: 'm',
      }}
      data-cy="referral-list"
      empty={props.emptyState}
    />
  );
};

const MemberReferrals = ({ member, onLoading }: Props) => {
  const { actingUser } = Session;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [referrals, setReferrals] = useState<MemberReferral[]>();
  const [pastReferrals, setPastReferrals] = useState<MemberReferral[]>();
  const [refresh, setRefresh] = useState(0);
  const [editing, setEditing] = useState<MemberReferral>();
  const [readonly, setReadonly] = useState(false);
  const [showDisabledRows, setShowDisabledRows] = useState(false);
  const [deleteReferralModal, setDeleteReferralModal] = useState<
    MemberReferral
  >();
  const [deleteReason, setDeleteReason] = useState<ReferralDeletionReason>();
  const [deleteReasonOther, setDeleteReasonOther] = useState<string>('');

  const addReferral = () => {
    setEditing(new MemberReferral({}));
  };

  const editReferral = (referral: MemberReferral) => {
    setEditing(referral);
  };

  const removeReferral = async (referral: MemberReferral) => {
    referral.discontinuedAt = moment().unix();
    referral.enrollmentEnd = moment().format(DATE_FORMAT);

    await removeMemberReferral(member.id, referral.id, {
      deletedReason: deleteReason?.value as string,
      deletedReasonOther:
        deleteReason === ReferralDeletionReason.OTHER ? deleteReasonOther : '',
    });

    return new Promise<void>((res) => {
      setTimeout(() => {
        doRefresh();
        res();
      }, 250);
    });
  };

  const showReferral = (referral: MemberReferral) => {
    setEditing(referral);
  };

  const doEdit = async (data: ReferralData) => {
    let result = true;

    try {
      if (!data.id) {
        await createMemberReferral(member.id, data);
      } else {
        await updateMemberReferral(member.id, data.id, data);
      }
      setEditing(undefined);
    } catch (e) {
      showGlobalError(e as string);
      result = false;
    }

    if (result) {
      return new Promise<boolean>((res) => {
        setTimeout(() => {
          doRefresh();
          res(true);
        }, 250);
      });
    }

    return false;
  };

  const doRefresh = () => {
    dispatch(fetchPatientById(member.id));
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    setReadonly(!member.isEditable() || !actingUser.canManageMemberReferrals);
    onLoading(true);
    getMemberReferrals(member.id, true).then((referrals) => {
      setReferrals(
        referrals.sort((a: MemberReferral, b: MemberReferral) =>
          a.enrollmentStart.localeCompare(b.enrollmentStart),
        ),
      );
      onLoading(false);
    });
  }, [member, refresh]);

  useEffect(() => {
    if (showDisabledRows) {
      getMemberReferrals(member.id, false).then((referrals) => {
        setPastReferrals(
          referrals.sort((a: MemberReferral, b: MemberReferral) =>
            a.enrollmentStart.localeCompare(b.enrollmentStart),
          ),
        );
      });
    }
  }, [refresh, showDisabledRows]);

  return (
    <>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{translate('memberReferrals.title')}</h2>
        <div>
          {actingUser.canManageMemberReferrals && member.isEditable() && (
            <Button
              className="btn-add profile-add"
              color="info"
              data-cy="add-referral-button"
              icon={<AddIcon />}
              onClick={addReferral}
              size="s"
              type="outlined"
            >
              {translate('memberReferrals.add')}
            </Button>
          )}
        </div>
      </div>
      <MemberReferralsTable
        referrals={referrals || []}
        editReferral={
          actingUser.canManageMemberReferrals && member.isEditable()
            ? editReferral
            : undefined
        }
        emptyState={translate('memberReferrals.empty')}
        deleteReferral={
          actingUser.canManageMemberReferrals && member.isEditable()
            ? (referral) => {
                setDeleteReason(undefined);
                setDeleteReasonOther('');
                setDeleteReferralModal(referral);
              }
            : undefined
        }
        showReferral={
          actingUser.canManageMemberReferrals && member.isEditable()
            ? undefined
            : showReferral
        }
      />

      <footer>
        <div style={{ float: 'right' }}>
          <button
            className={`h3 null ${
              showDisabledRows && !pastReferrals ? 'flex gap' : ''
            }`}
            onClick={() => setShowDisabledRows(!showDisabledRows)}
            data-cy="toggle-disabled-referrals"
          >
            {showDisabledRows && !pastReferrals ? (
              <Spinner color="inherit" width={16} />
            ) : (
              <CollapseIcon htmlColor={Colors.gray} open={showDisabledRows} />
            )}
            {translate('memberReferrals.history')}
          </button>
        </div>
      </footer>

      {showDisabledRows && pastReferrals && (
        <MemberReferralsTable
          data-cy="disabled-referrals"
          emptyState={translate('memberReferrals.emptyHistory')}
          referrals={pastReferrals || []}
          isInactive
        />
      )}

      {editing && (
        <ReferralEditModal
          referral={editing}
          onClose={() => setEditing(undefined)}
          onSubmit={doEdit}
          readonly={readonly}
        />
      )}

      <Modal
        title={translate('memberReferrals.deleteTitle')}
        submitText={translate('memberReferrals.deleteConfirmButton')}
        onSubmit={async () => {
          if (deleteReferralModal) {
            await removeReferral(deleteReferralModal);
            return true;
          }
        }}
        onClose={() => setDeleteReferralModal(undefined)}
        open={!!deleteReferralModal}
        submitDisabled={
          !deleteReason ||
          (deleteReason === ReferralDeletionReason.OTHER && !deleteReasonOther)
        }
      >
        <p>{translate('memberReferrals.deleteBody')}:</p>
        <div className="grid-wrapper">
          <Select
            className={`grid-span-${
              deleteReason === ReferralDeletionReason.OTHER ? 6 : 12
            }`}
            getItemLabel={(item: ReferralDeletionReason) => item.toString()}
            items={ReferralDeletionReason.asArray}
            label={translate('memberReferrals.deleteReason')}
            onChange={setDeleteReason}
            required
            value={deleteReason}
          />
          {deleteReason === ReferralDeletionReason.OTHER && (
            <TextInput
              className="grid-span-6"
              label={translate('memberReferrals.deleteReasonOther')}
              onChange={(value) => setDeleteReasonOther(value || '')}
              required
              value={deleteReasonOther}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default MemberReferrals;
