import {
  EmployeeGroupType,
  EmployeeRole,
  Language,
  States,
  TaskDefinitionStatus,
} from '@vestahealthcare/common/enums';
import {
  BaseEnum,
  Brand,
  CommunityResource,
  CommunityResourceSpeciality,
  DBEnum,
  Employee,
  EmployeeGroup,
  EngagementAudience,
  EngagementCampaignAudience,
  EngagementCampaignCondition,
  EngagementCampaignTimingOption,
  EngagementFileType,
  EventInterventionType,
  EventType,
  EventsConfiguration,
  IncidentAction,
  IncidentInvolvedActor,
  IncidentStatus,
  InsuranceMLTC,
  InsurancePlan,
  InsurancePriority,
  IssueDetailFieldGroup,
  IssueType,
  Organization,
  OrganizationStatuses,
  OrganizationType,
  PodConfig,
  PodRule,
  ProgramConfiguration,
  ProgramExtension,
  ProgramExtensionStatus,
  RPMVendorModel,
  RosterFileHistory,
  SourceChannel,
  TOCStatus,
} from '@vestahealthcare/common/models';
import ConsentRequest from '@vestahealthcare/common/models/ConsentRequest';
import DashPermission from '@vestahealthcare/common/models/DashPermission';
import EventLinkReason from '@vestahealthcare/common/models/EventLinkReason';
import EventStatus from '@vestahealthcare/common/models/EventStatus';
import TaskDefinition from '@vestahealthcare/common/models/TaskDefinition';

import {
  fecthCareTeamMemberAideTypes,
  fecthCareTeamMemberFamilyRelationShips,
  fecthCareTeamMemberOtherRelationships,
  fecthCareTeamMemberProviderSpecialities,
  fecthCareTeamMemberTypes,
  fecthCareTeamPersonStatuses,
  fetchInactiveReasons,
} from 'dash/src/services/CareTeamServices';
import {
  fetchActiveByGroup,
  fetchActiveByRole,
  fetchEmployeeGroups,
  fetchEmployeeNLCStates,
  fetchEmployees,
} from 'dash/src/services/EmployeeServices';
import {
  getEngagementCampaignAudiences,
  getEngagementCampaignConditions,
  getEngagementCampaignTimingOptions,
  getEngagementContentAudiences,
  getEngagementContentTypes,
  getEngagementDeliveryMethods,
  getEngagementFileTypes,
  getEngagementLanguages,
} from 'dash/src/services/EngagementServices';
import {
  fetchOrganizationBrands,
  fetchOrganizationStatuses,
  fetchOrganizationTypes,
  fetchOrganizations,
} from 'dash/src/services/OrganizationServices';
import { fetchClients, fetchPrograms } from 'dash/src/services/ProgramServices';
import {
  fetchSourceChannelDirections,
  fetchSourceChannels,
} from 'dash/src/services/SourceServices';
import { fetchTaskDefinitions } from 'dash/src/services/TaskServices';

import { PaginatedResponse } from './Api';
import { fecthCCMTouchpointReasons, fetchCCMPods } from './CCMServices';
import {
  fetchCommunityResourceTypes,
  fetchCommunityResources,
} from './CommunityResourcesServices';
import { fetchConsentRequests } from './ConsentServices';
import {
  fecthEventDivertedOptions,
  fecthEventInterventionCategories,
  fecthEventInterventionTypes,
  fecthEventLinkReasons,
  fecthEventResolutionOptions,
  fecthIssueDetailFields,
  fetchEventStatuses,
  fetchEventTypes,
  fetchEventsConfig,
  fetchIssueTypes,
  getInvalidateReasons,
} from './EventServices';
import {
  fetchAllRosterFileHistory,
  fetchRosterRequiredColumns,
} from './FileHistoryServices';
import { fetchHouseHoldRelationships } from './HouseHoldServices';
import {
  fetchIncidentActions,
  fetchIncidentAnticoagulants,
  fetchIncidentDMETypes,
  fetchIncidentEntities,
  fetchIncidentFollowUp,
  fetchIncidentHelpOptions,
  fetchIncidentInvolvedActors,
  fetchIncidentLocations,
  fetchIncidentStatuses,
  fetchIncidentTypes,
} from './IncidentServices';
import {
  fetchInsuranceMLTCs,
  fetchInsurancePlans,
  fetchInsuranceTypes,
} from './InsurancePlansServices';
import { fetchInsurancePriorities } from './InsurancePriorityServices';
import { fetchSupportedLanguages } from './LanguageServices';
import {
  fetchAllProgramExtensions,
  fetchProgramExtensionStatus,
  getCarePlanGroups,
} from './PatientServices';
import { fetchDashPermissions } from './PermissionServices';
import { fetchAllPodConfigs, fetchAllPodRules } from './PodServices';
import {
  fetchSmartMeterModels,
  fetchSmartMeterShippingMethods,
} from './RPMVendorServices';
import {
  fecthTOCActuityLevels,
  fecthTOCEligibilityStatus,
  fecthTOCHandoffDispositions,
  fecthTOCMedicalReqStatus,
  fecthTOCStatus,
} from './TOCServices';

class CacheServices {
  cachedClients?: Promise<Organization[]>;

  cachedConsentRequests?: Promise<ConsentRequest[]>;

  cachedCGConsentRequests?: Promise<ConsentRequest[]>;

  cachedCTPConsentRequests?: Promise<ConsentRequest[]>;

  cachedEmployees?: Promise<Employee[]>;

  cachedEmployeeGroups?: EmployeeGroup[];

  cachedRNs?: Promise<Employee[]>;

  cachedNPs?: Promise<Employee[]>;

  cachedClinicalEmployees?: Promise<Employee[]>;

  cachedAllRNOwners?: Promise<Employee[]>;

  cachedAllNPOwners?: Promise<Employee[]>;

  cachedNPOwners?: Promise<Employee[]>;

  cachedCareCoordinators?: Promise<Employee[]>;

  cachedEngagementOwners?: Promise<Employee[]>;

  cachedHealthCoaches?: Promise<Employee[]>;

  cachedIncidentOwners?: Promise<Employee[]>;

  cachedClinicalAssistants?: Promise<Employee[]>;

  cachedPrograms?: Promise<ProgramConfiguration[]>;

  cachedOrganizations?: Promise<Organization[]>;

  cachedOrganizationBrands?: Promise<Brand[]>;

  cachedOrganizationTypes?: Promise<OrganizationType[]>;

  cachedOrganizationStatuses?: Promise<OrganizationStatuses[]>;

  cachedInsuranceMLTCs?: Promise<InsuranceMLTC[]>;

  cachedInsurancePlans?: Promise<InsurancePlan[]>;

  cachedInsurancePriorities?: Promise<InsurancePriority[]>;

  cachedInsuranceTypes?: Promise<BaseEnum[]>;

  cachedDashPermissions?: Promise<DashPermission[]>;

  cachedAllProgramExtensions?: Promise<ProgramExtension[]>;

  cachedProgramExtensionStatus?: Promise<ProgramExtensionStatus[]>;

  cachedEventTypes?: Promise<EventType[]>;

  cachedEventConfig?: Promise<EventsConfiguration>;

  cachedIssueTypes?: Promise<IssueType[]>;

  cachedSourceChannels?: Promise<SourceChannel[]>;

  cachedSourceChannelDirections?: Promise<BaseEnum[]>;

  cachedEventStatuses?: Promise<EventStatus[]>;

  cachedEventInterventionTypes?: Promise<EventInterventionType[]>;

  cachedEventInterventionCategories?: Promise<BaseEnum[]>;

  cachedTaskDefinitions?: Promise<TaskDefinition[]>;

  cachedEventDivertedFromOptions?: Promise<BaseEnum[]>;

  cachedEventResolutionOptions?: Promise<BaseEnum[]>;

  cachedEventLinkReasons?: Promise<EventLinkReason[]>;

  cachedIssueDetailFields?: Promise<IssueDetailFieldGroup[]>;

  cachedEngagementContentAudiences?: Promise<EngagementAudience[]>;

  cachedEngagementCampaignAudiences?: Promise<EngagementCampaignAudience[]>;

  cachedEngagementFileTypes?: Promise<EngagementFileType[]>;

  cachedEngagementContentTypes?: Promise<BaseEnum[]>;

  cachedEngagementLanguages?: Promise<Language[]>;

  cachedEngagementDeliveryMethods?: Promise<BaseEnum[]>;

  cachedEngagementCampaignConditions?: Promise<EngagementCampaignCondition[]>;

  cachedEngagementCampaignTimingOptions?: Promise<
    EngagementCampaignTimingOption[]
  >;

  cachedCarePlanGroups?: Promise<DBEnum[]>;

  cachedInvalidateEventReasons?: Promise<BaseEnum[]>;

  cachedCareTeamMemberTypes?: Promise<BaseEnum[]>;

  cachedCareTeamMemberAideTypes?: Promise<BaseEnum[]>;

  cachedCareTeamMemberFamilyRelationships?: Promise<BaseEnum[]>;

  cachedCareTeamMemberOtherRelationships?: Promise<BaseEnum[]>;

  cachedCareTeamMemberProviderSpecialities?: Promise<BaseEnum[]>;

  cachedCareTeamPersonStatuses?: Promise<BaseEnum[]>;

  cachedCareTeamMemberInactiveReasons?: Promise<BaseEnum[]>;

  cachedCommunityResourceTypes?: Promise<BaseEnum[]>;

  cachedCCMTouchpointReasons?: Promise<string[]>;

  cachedPods?: Promise<BaseEnum[]>;

  cachedPodConfigs?: Promise<PodConfig[]>;

  cachedPodRules?: Promise<PodRule[]>;

  cachedIncidentTypes?: Promise<BaseEnum[]>;

  cachedIncidentInvolved?: Promise<BaseEnum[]>;

  cachedIncidentLocations?: Promise<BaseEnum[]>;

  cachedIncidentHelpOptions?: Promise<BaseEnum[]>;

  cachedIncidentStatuses?: Promise<IncidentStatus[]>;

  cachedIncidentEntities?: Promise<BaseEnum[]>;

  cachedIncidentActions?: Promise<IncidentAction[]>;

  cachedIncidentInvolvedActors?: Promise<IncidentInvolvedActor[]>;

  cachedIncidentDMETypes?: Promise<BaseEnum[]>;

  cachedIncidentAnticoagulants?: Promise<BaseEnum[]>;

  cachedIncidentFollowUp?: Promise<BaseEnum[]>;

  cachedNLCStates?: Promise<States[]>;

  cachedHouseHoldRelationships?: Promise<BaseEnum[]>;

  cachedHomeHealthCRs?: Promise<PaginatedResponse<CommunityResource>>;

  cachedLanguages?: Promise<Language[]>;

  cachedTOCAcuityLevels?: Promise<BaseEnum[]>;

  cachedTOCVisitStatus?: Promise<BaseEnum[]>;

  cachedTOCStatus?: Promise<TOCStatus[]>;

  cachedTOCMedicalReqStatus?: Promise<BaseEnum[]>;

  cachedTOCEligibilityStatus?: Promise<BaseEnum[]>;

  cachedTOCHandoffDispositions?: Promise<BaseEnum[]>;

  cachedSmartMeterModels?: Promise<RPMVendorModel[]>;

  cachedSmartMeterShippingMethods?: Promise<BaseEnum[]>;

  cachedRosterFileHistory?: Promise<RosterFileHistory[]>;

  cachedRosterRequiredColumns?: Promise<string[]>;

  getClients() {
    return (this.cachedClients = this.cachedClients || fetchClients());
  }

  getPrograms() {
    return (this.cachedPrograms = this.cachedPrograms || fetchPrograms());
  }

  getOrganizations() {
    return (this.cachedOrganizations =
      this.cachedOrganizations || fetchOrganizations());
  }

  getOrganizationBrands() {
    return (this.cachedOrganizationBrands =
      this.cachedOrganizationBrands || fetchOrganizationBrands());
  }

  getOrganizationTypes() {
    return (this.cachedOrganizationTypes =
      this.cachedOrganizationTypes || fetchOrganizationTypes());
  }

  getOrganizationStatuses() {
    return (this.cachedOrganizationStatuses =
      this.cachedOrganizationStatuses || fetchOrganizationStatuses());
  }

  getInsuranceMLTCs() {
    return (this.cachedInsuranceMLTCs =
      this.cachedInsuranceMLTCs || fetchInsuranceMLTCs());
  }

  getInsurancePlans() {
    return (this.cachedInsurancePlans =
      this.cachedInsurancePlans || fetchInsurancePlans({ minimal: true }));
  }

  invalidateInsurancePlansCache() {
    this.cachedInsurancePlans = undefined;
  }

  getInsurancePriorities() {
    return (this.cachedInsurancePriorities =
      this.cachedInsurancePriorities || fetchInsurancePriorities());
  }

  getInsuranceTypes() {
    return (this.cachedInsuranceTypes =
      this.cachedInsuranceTypes || fetchInsuranceTypes());
  }

  getMemberConsentRequests() {
    return (this.cachedConsentRequests =
      this.cachedConsentRequests || fetchConsentRequests('MEMBER'));
  }

  getCaregiverConsentRequests() {
    return (this.cachedCGConsentRequests =
      this.cachedCGConsentRequests || fetchConsentRequests('CAREGIVER'));
  }

  getCTPConsentRequests() {
    return (this.cachedCTPConsentRequests =
      this.cachedCTPConsentRequests || fetchConsentRequests('CAREGIVER'));
  }

  invalidateEmployees() {
    delete this.cachedEmployees;
    delete this.cachedAllNPOwners;
    delete this.cachedAllRNOwners;
    delete this.cachedEngagementOwners;
    delete this.cachedHealthCoaches;
    delete this.cachedClinicalAssistants;
    delete this.cachedClinicalEmployees;
    delete this.cachedIncidentOwners;
  }

  getEmployees() {
    return (this.cachedEmployees =
      this.cachedEmployees || fetchEmployees({ enabled: true }));
  }

  invalidateEmployeeGroupsCache() {
    delete this.cachedEmployeeGroups;
  }

  async getEmployeeGroupsAssignee() {
    if (!this.cachedEmployeeGroups) {
      this.cachedEmployeeGroups = await fetchEmployeeGroups();
    }
    return Promise.resolve(
      this.cachedEmployeeGroups?.filter(
        ({ type }) => type === EmployeeGroupType.ASSIGNEE,
      ),
    );
  }

  async getEmployeeGroupsWorklist() {
    if (!this.cachedEmployeeGroups) {
      this.cachedEmployeeGroups = await fetchEmployeeGroups();
    }
    return Promise.resolve(
      this.cachedEmployeeGroups?.filter(
        ({ type }) => type === EmployeeGroupType.WORKLIST,
      ),
    );
  }

  getRNs() {
    return (this.cachedRNs =
      this.cachedRNs || fetchActiveByRole(EmployeeRole.RN));
  }

  getNPs() {
    return (this.cachedNPs =
      this.cachedNPs || fetchActiveByRole(EmployeeRole.NP));
  }

  getCareCoordinators() {
    return (this.cachedCareCoordinators =
      this.cachedCareCoordinators ||
      fetchActiveByGroup(EmployeeGroup.CLINICAL_ASSISTANTS));
  }

  getHealthCoaches() {
    return (this.cachedHealthCoaches =
      this.cachedHealthCoaches ||
      fetchActiveByGroup([EmployeeGroup.HEALTH_COACH]));
  }

  getClinicalAssistants() {
    return (this.cachedClinicalAssistants =
      this.cachedClinicalAssistants ||
      fetchActiveByGroup([EmployeeGroup.CLINICAL_ASSISTANTS]));
  }

  getEngagementOwners() {
    return (this.cachedEngagementOwners =
      this.cachedEngagementOwners ||
      fetchActiveByGroup(EmployeeGroup.ENGAGEMENT));
  }

  getIncidentOwners() {
    return (this.cachedIncidentOwners =
      this.cachedIncidentOwners ||
      fetchActiveByGroup(EmployeeGroup.INCIDENTS_MANAGEMENT));
  }

  getDashPermissions() {
    return (this.cachedDashPermissions =
      this.cachedDashPermissions || fetchDashPermissions());
  }

  getAllProgramExtensions() {
    return (this.cachedAllProgramExtensions =
      this.cachedAllProgramExtensions || fetchAllProgramExtensions());
  }

  getProgramExtensionStatus() {
    return (this.cachedProgramExtensionStatus =
      this.cachedProgramExtensionStatus || fetchProgramExtensionStatus());
  }

  invalidateEventTypes() {
    delete this.cachedEventTypes;
  }

  getEventTypes() {
    return (this.cachedEventTypes = this.cachedEventTypes || fetchEventTypes());
  }

  invalidateEventsConfig() {
    delete this.cachedEventConfig;
  }

  getEventsConfig() {
    return (this.cachedEventConfig =
      this.cachedEventConfig || fetchEventsConfig());
  }

  invalidateIssueTypes() {
    delete this.cachedIssueTypes;
  }

  getIssueTypes() {
    return (this.cachedIssueTypes = this.cachedIssueTypes || fetchIssueTypes());
  }

  getSourceChannels() {
    return (this.cachedSourceChannels =
      this.cachedSourceChannels || fetchSourceChannels());
  }

  getSourceChannelDirections() {
    return (this.cachedSourceChannelDirections =
      this.cachedSourceChannelDirections || fetchSourceChannelDirections());
  }

  getEventStatuses() {
    return (this.cachedEventStatuses =
      this.cachedEventStatuses || fetchEventStatuses());
  }

  invalidateEventInterventionTypes() {
    delete this.cachedEventInterventionTypes;
  }

  getEventInterventionTypes() {
    return (this.cachedEventInterventionTypes =
      this.cachedEventInterventionTypes ||
      fecthEventInterventionTypes({ active: true }));
  }

  getEventInterventionCategories() {
    return (this.cachedEventInterventionCategories =
      this.cachedEventInterventionCategories ||
      fecthEventInterventionCategories());
  }

  getEventDivertedFromOptions() {
    return (this.cachedEventDivertedFromOptions =
      this.cachedEventDivertedFromOptions || fecthEventDivertedOptions());
  }

  getEventResolutionOptions() {
    return (this.cachedEventResolutionOptions =
      this.cachedEventResolutionOptions || fecthEventResolutionOptions());
  }

  getEventLinkReasons() {
    return (this.cachedEventLinkReasons =
      this.cachedEventLinkReasons || fecthEventLinkReasons());
  }

  getIssueDetailFields() {
    return (this.cachedIssueDetailFields =
      this.cachedIssueDetailFields || fecthIssueDetailFields());
  }

  invalidateTaskDefinitions() {
    delete this.cachedTaskDefinitions;
  }

  getTaskDefinitions() {
    return (this.cachedTaskDefinitions =
      this.cachedTaskDefinitions ||
      fetchTaskDefinitions({
        topLevelOnly: true,
        status: TaskDefinitionStatus.PUBLISHED.value,
      }));
  }

  getEngagementContentAudiences() {
    return (this.cachedEngagementContentAudiences =
      this.cachedEngagementContentAudiences || getEngagementContentAudiences());
  }

  getEngagementCampaignAudiences() {
    return (this.cachedEngagementCampaignAudiences =
      this.cachedEngagementCampaignAudiences ||
      getEngagementCampaignAudiences());
  }

  getEngagementContentTypes() {
    return (this.cachedEngagementContentTypes =
      this.cachedEngagementContentTypes || getEngagementContentTypes());
  }

  getEngagementFileTypes() {
    return (this.cachedEngagementFileTypes =
      this.cachedEngagementFileTypes || getEngagementFileTypes());
  }

  getEngagementLanguages() {
    return (this.cachedEngagementLanguages =
      this.cachedEngagementLanguages || getEngagementLanguages());
  }

  getEngagementDeliveryMethods() {
    return (this.cachedEngagementDeliveryMethods =
      this.cachedEngagementDeliveryMethods || getEngagementDeliveryMethods());
  }

  getEngagementCampaignConditions() {
    return (this.cachedEngagementCampaignConditions =
      this.cachedEngagementCampaignConditions ||
      getEngagementCampaignConditions());
  }

  getEngagementCampaignTimingOptions() {
    return (this.cachedEngagementCampaignTimingOptions =
      this.cachedEngagementCampaignTimingOptions ||
      getEngagementCampaignTimingOptions());
  }

  getCarePlanGroups() {
    return (this.cachedCarePlanGroups =
      this.cachedCarePlanGroups || getCarePlanGroups());
  }

  getEventInvalidateReaons() {
    return (this.cachedInvalidateEventReasons =
      this.cachedInvalidateEventReasons || getInvalidateReasons());
  }

  /* Care Team */

  getCareTeamMemberTypes() {
    return (this.cachedCareTeamMemberTypes =
      this.cachedCareTeamMemberTypes || fecthCareTeamMemberTypes());
  }

  getCareTeamMemberAideTypes() {
    return (this.cachedCareTeamMemberAideTypes =
      this.cachedCareTeamMemberAideTypes || fecthCareTeamMemberAideTypes());
  }

  getCareTeamMemberFamilyRelationShips() {
    return (this.cachedCareTeamMemberFamilyRelationships =
      this.cachedCareTeamMemberFamilyRelationships ||
      fecthCareTeamMemberFamilyRelationShips());
  }

  getCareTeamMemberOtherRelationships() {
    return (this.cachedCareTeamMemberOtherRelationships =
      this.cachedCareTeamMemberOtherRelationships ||
      fecthCareTeamMemberOtherRelationships());
  }

  getCareTeamMemberProviderSpecialities() {
    return (this.cachedCareTeamMemberProviderSpecialities =
      this.cachedCareTeamMemberProviderSpecialities ||
      fecthCareTeamMemberProviderSpecialities());
  }

  getCareTeamPersonStatuses() {
    return (this.cachedCareTeamPersonStatuses =
      this.cachedCareTeamPersonStatuses || fecthCareTeamPersonStatuses());
  }

  getCareTeamMemberInactiveReasons() {
    return (this.cachedCareTeamMemberInactiveReasons =
      this.cachedCareTeamMemberInactiveReasons || fetchInactiveReasons());
  }

  getCommunityResourceTypes() {
    return (this.cachedCommunityResourceTypes =
      this.cachedCommunityResourceTypes || fetchCommunityResourceTypes());
  }

  getCCMTouchpointReasons() {
    return (this.cachedCCMTouchpointReasons =
      this.cachedCCMTouchpointReasons || fecthCCMTouchpointReasons());
  }

  getPods() {
    return (this.cachedPods = this.cachedPods || fetchCCMPods());
  }

  getPodConfigs() {
    return (this.cachedPodConfigs =
      this.cachedPodConfigs || fetchAllPodConfigs());
  }

  getPodRules() {
    return (this.cachedPodRules = this.cachedPodRules || fetchAllPodRules());
  }

  getIncidentTypes() {
    return (this.cachedIncidentTypes =
      this.cachedIncidentTypes || fetchIncidentTypes());
  }

  getIncidentLocations() {
    return (this.cachedIncidentLocations =
      this.cachedIncidentLocations || fetchIncidentLocations());
  }

  getIncidentActions() {
    return (this.cachedIncidentActions =
      this.cachedIncidentActions || fetchIncidentActions());
  }

  getIncidentEntities() {
    return (this.cachedIncidentEntities =
      this.cachedIncidentEntities || fetchIncidentEntities());
  }

  getIncidentHelpOptions() {
    return (this.cachedIncidentHelpOptions =
      this.cachedIncidentHelpOptions || fetchIncidentHelpOptions());
  }

  getIncidentStatuses() {
    return (this.cachedIncidentStatuses =
      this.cachedIncidentStatuses || fetchIncidentStatuses());
  }

  getIncidentInvolvedActors() {
    return (this.cachedIncidentInvolvedActors =
      this.cachedIncidentInvolvedActors || fetchIncidentInvolvedActors());
  }

  getIncidentDMETypes() {
    return (this.cachedIncidentDMETypes =
      this.cachedIncidentDMETypes || fetchIncidentDMETypes());
  }

  getIncidentAnticoagulants() {
    return (this.cachedIncidentAnticoagulants =
      this.cachedIncidentAnticoagulants || fetchIncidentAnticoagulants());
  }

  getIncidentFollowUp() {
    return (this.cachedIncidentFollowUp =
      this.cachedIncidentFollowUp || fetchIncidentFollowUp());
  }

  getEmployeeNLCStates() {
    return (this.cachedNLCStates =
      this.cachedNLCStates || fetchEmployeeNLCStates());
  }

  getHouseHoldRelationships() {
    return (this.cachedHouseHoldRelationships =
      this.cachedHouseHoldRelationships || fetchHouseHoldRelationships());
  }

  getHomeHealthCRs() {
    return (this.cachedHomeHealthCRs =
      this.cachedHomeHealthCRs ||
      fetchCommunityResources({
        specialities: [CommunityResourceSpeciality.HOME_HEALTH],
        status: 'ACTIVE',
        limit: -1,
      }));
  }

  getLanguages() {
    return (this.cachedLanguages =
      this.cachedLanguages || fetchSupportedLanguages());
  }

  getAcuityLevels() {
    return (this.cachedTOCAcuityLevels =
      this.cachedTOCAcuityLevels || fecthTOCActuityLevels());
  }

  getTOCStatus() {
    return (this.cachedTOCStatus = this.cachedTOCStatus || fecthTOCStatus());
  }

  getTOCMedicalReqStatus() {
    return (this.cachedTOCMedicalReqStatus =
      this.cachedTOCMedicalReqStatus || fecthTOCMedicalReqStatus());
  }

  getTOCEligibilityStatus() {
    return (this.cachedTOCEligibilityStatus =
      this.cachedTOCEligibilityStatus || fecthTOCEligibilityStatus());
  }

  getTOCHandoffDispositions() {
    return (this.cachedTOCHandoffDispositions =
      this.cachedTOCHandoffDispositions || fecthTOCHandoffDispositions());
  }

  getSmartMeterModels() {
    return (this.cachedSmartMeterModels =
      this.cachedSmartMeterModels || fetchSmartMeterModels());
  }

  getSmartMeterShippingMethods() {
    return (this.cachedSmartMeterShippingMethods =
      this.cachedSmartMeterShippingMethods || fetchSmartMeterShippingMethods());
  }

  invalidateRosterFileHistory() {
    delete this.cachedRosterFileHistory;
  }

  getRosterFileHistory() {
    return (this.cachedRosterFileHistory =
      this.cachedRosterFileHistory || fetchAllRosterFileHistory());
  }

  getRosterRequiredColumns() {
    return (this.cachedRosterRequiredColumns =
      this.cachedRosterRequiredColumns || fetchRosterRequiredColumns());
  }
}

export default new CacheServices();
