import classNames from 'classnames';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import {
  Language,
  PatientGender,
  ReferralMappingOperationType,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  ReferralIngestionMapperColumn,
  ReferralMapping,
  ReferralMappingColumn,
  ReferralMappingOperation,
} from '@vestahealthcare/common/models';
import { ReferralMappingMappedValue } from '@vestahealthcare/common/models/ReferralMappingMappedValue';

import {
  Arrow,
  BackgroundColors,
  Button,
  CheckboxWithLabel,
  Colors,
  IconButton,
  Modal,
  PanelInfo,
  Select,
  TextInput,
  Tooltip,
} from 'styleguide-v2';

import {
  CreateRosterFileHistoryMapper,
  UpdateRosterFileHistoryMapper,
  readRosterFileHistoryHeaderValues,
  readRosterFileHistoryHeaders,
} from 'dash/src/services/FileHistoryServices';
import { excludeOtherValues } from 'dash/src/utils/dataUtils';

const SPLIT_OPTIONS = [
  { value: '1', label: translate('referrals.ingestionMapper.modal.first') },
  { value: '2', label: translate('referrals.ingestionMapper.modal.second') },
  { value: '-1', label: translate('referrals.ingestionMapper.modal.last') },
];

type Rule = Partial<ReferralMappingColumn> & {
  default: boolean;
  required: boolean;
  sort: number;
};

type Props = {
  data: {
    destinationColumns: SelectableInfo<ReferralIngestionMapperColumn>[];
    files: Selectable[];
    organizations: SelectableInfo<Organization>[];
  };
  loadingData: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreateRosterFileHistoryMapper | UpdateRosterFileHistoryMapper,
    id?: number,
  ) => Promise<void>;
  rule?: ReferralMapping;
};

const useStyles = makeStyles({
  activeSwitch: {
    marginLeft: '0.5rem',
  },
  addItemLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
  addItemIcon: {
    marginTop: '-0.25rem',
  },
  addTransformationLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
  button: {
    width: '30rem',
  },
  fileInputLarge: {
    flex: '1',
    minWidth: '25rem',
  },
  fileInputSmall: {
    flex: '0',
    minWidth: '10rem',
  },
  firtsItem: {
    '&&': {
      marginTop: '2.1rem',
    },
  },
  footerCheckbox: {
    '&& .MuiCheckbox-root': {
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
    },
  },
  row: {
    gap: '1rem',
    padding: '0 0 0.5rem 1rem',
  },
  rowMappingContainer: {
    margin: '0 3.5rem 2rem',
    padding: '0 1.5rem',
    position: 'relative',
  },
  rowMappingContainerBorder: {
    '&::before': {
      borderLeft: `2px solid ${BackgroundColors.grayLine}`,
      content: '""',
      top: '3rem',
      height: 'calc(100% - 4.5rem)',
      left: 0,
      position: 'absolute',
    },
  },
  select: {
    width: '22.5rem',
  },
  selectLarge: {
    width: '25rem',
  },
  selectExtraLarge: {
    width: '30rem',
  },
  selectExtraExtraMin: {
    width: '5rem',
  },
  selectExtraMin: {
    width: '11rem',
    '&& .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
      paddingRight: '1.5rem',
    },
  },
  selectMin: {
    width: '15rem',

    '&& .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
      paddingRight: '1.5rem',
    },
  },
});

export const ReferralIngestionMapperEditModal = ({
  data,
  loadingData,
  open,
  onClose,
  onSubmit,
  rule,
}: Props) => {
  const styles = useStyles();

  const [loadingFileHeaders, setLoadingFileHeaders] = useState<boolean>(false);
  const [loadingFileBranchValues, setLoadingFileBranchValues] = useState<
    boolean
  >(false);
  const [loadingFileGenderValues, setLoadingFileGenderValues] = useState<
    boolean
  >(false);
  const [loadingFileLanguageValues, setLoadingFileLanguageValues] = useState<
    boolean
  >(false);

  const [errorBranchNotFound, setErrorBrandNotFound] = useState<boolean>(false);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(false);

  const [separator, setSeparator] = useState<string>(',');
  const [file, setFile] = useState<Selectable>();
  const [mappingRules, setMappingRules] = useState<Rule[]>([]);
  const [mixedFile, setMixedFile] = useState<boolean>();
  const [name, setName] = useState<string>();
  const [referral, setReferral] = useState<SelectableInfo<Organization>[]>([]);
  const [sourceFileHeaders, setSourceFileHeaders] = useState<string[]>([]);
  const [sourceFileBranches, setSourceFileBranches] = useState<string[]>([]);
  const [sourceFileGenders, setSourceFileGenders] = useState<string[]>([]);
  const [sourceFileLanguages, setSourceFileLanguages] = useState<string[]>([]);

  const branchColumn = useMemo(
    () =>
      mappingRules?.find(
        ({ operation }) =>
          operation?.action === ReferralMappingOperationType.BRANCH,
      ),
    [mappingRules],
  );

  const genderColumn = useMemo(
    () =>
      mappingRules?.find(
        ({ operation }) =>
          operation?.action === ReferralMappingOperationType.GENDER,
      ),
    [mappingRules],
  );

  const languageColumn = useMemo(
    () =>
      mappingRules?.find(
        ({ operation }) =>
          operation?.action === ReferralMappingOperationType.LANGUAGE,
      ),
    [mappingRules],
  );

  const showMixedFileCheckbox = useMemo(
    () =>
      file &&
      referral?.some((item) =>
        item.info?.children?.some(({ hasValidDate }) => hasValidDate),
      ),
    [referral, file],
  );

  const mixedReferrals = useMemo(
    () =>
      showMixedFileCheckbox
        ? Organization.toSelectable(
            referral.reduce(
              (acc, item) =>
                [
                  ...acc,
                  ...(item.info?.children?.filter(
                    ({ hasValidDate }) => hasValidDate,
                  ) || []),
                ].filter(Boolean),
              [] as Organization[],
            ),
          )
        : [],
    [referral, showMixedFileCheckbox],
  );

  useEffect(() => {
    if (!file || !separator) {
      setSourceFileHeaders([]);
    } else {
      setLoadingFileHeaders(true);
      readRosterFileHistoryHeaders(file.value as number, separator)
        .then((headers) => {
          setSourceFileHeaders(headers);
        })
        .finally(() => setLoadingFileHeaders(false));
    }
  }, [file, separator]);

  const updateFileValues = async (
    ruleAccessor: 'branchColumn' | 'genderColumn' | 'languageColumn',
    setterValues: React.Dispatch<React.SetStateAction<string[]>>,
    setterLoading: (value: React.SetStateAction<boolean>) => void,
    file?: Selectable,
    modelColumn?: Rule,
  ) => {
    if (file && modelColumn?.sourceColumn) {
      setterLoading(true);
      await readRosterFileHistoryHeaderValues(
        file.value as number,
        modelColumn.sourceColumn,
        separator,
      )
        .then((items) => {
          const itemsNotIncluded =
            (rule &&
              rule[ruleAccessor]?.operation?.dataDictionary
                ?.map((item) => item.sourceValue)
                ?.filter((key) => !items.includes(key))) ||
            [];
          setterValues([...itemsNotIncluded, ...items]);
          return items;
        })
        .then((items) => {
          const column = rule ? rule[ruleAccessor] : undefined;
          if (modelColumn?.operation) {
            if (
              items?.length &&
              column?.sourceColumn === modelColumn.sourceColumn
            ) {
              modelColumn.operation.dataDictionary = column?.operation
                ?.dataDictionary || [new ReferralMappingMappedValue({})];
            } else {
              modelColumn.operation.dataDictionary = [
                new ReferralMappingMappedValue({}),
              ];
            }
          }
        })
        .then(() => setterLoading(false));
    } else {
      setterValues([]);
    }
  };

  useEffect(() => {
    if (mixedFile && open && separator) {
      updateFileValues(
        'branchColumn',
        setSourceFileBranches,
        setLoadingFileBranchValues,
        file,
        branchColumn,
      );
    } else {
      setSourceFileBranches([]);
    }
  }, [file, mixedFile, branchColumn, separator, open]);

  useEffect(() => {
    if (branchColumn && open) {
      setErrorBrandNotFound(false);
    }
  }, [branchColumn, open]);

  useEffect(() => {
    if (open && separator) {
      updateFileValues(
        'genderColumn',
        setSourceFileGenders,
        setLoadingFileGenderValues,
        file,
        genderColumn,
      );
    }
  }, [file, genderColumn, separator, open]);

  useEffect(() => {
    if (open && separator) {
      updateFileValues(
        'languageColumn',
        setSourceFileLanguages,
        setLoadingFileLanguageValues,
        file,
        languageColumn,
      );
    }
  }, [file, languageColumn, separator, open]);

  useEffect(() => {
    if (!showMixedFileCheckbox) {
      setMixedFile(false);
      if (branchColumn) {
        delete branchColumn.operation;
        setMappingRules([...mappingRules]);
      }
    }
  }, [showMixedFileCheckbox]);

  useEffect(() => {
    if (rule?.columns?.length) {
      setMappingRules(
        rule.columns
          .filter(({ sourceColumn }) =>
            sourceFileHeaders.includes(sourceColumn),
          )
          .map(
            (column) =>
              ({
                ...column,
                default:
                  column?.operation?.action ===
                  ReferralMappingOperationType.DEFAULT,
                defaultValue:
                  column?.operation?.action ===
                  ReferralMappingOperationType.DEFAULT
                    ? column.operation.param1
                    : undefined,
                operation:
                  column?.operation?.action ===
                  ReferralMappingOperationType.DEFAULT
                    ? undefined
                    : column.operation,
                required: !!data.destinationColumns.find(
                  ({ value }) => value === column.targetColumn,
                )?.info?.required,
              } as Rule),
          )
          .sort(({ required: a }, { required: b }) => {
            if (a && !b) return -1;
            if (!a && b) return 1;
            return 0;
          }) || [],
      );
    } else {
      setMappingRules(
        data.destinationColumns?.length
          ? data.destinationColumns
              .filter(({ info }) => info?.required)
              .map(
                ({ info }) =>
                  ({
                    targetColumn: info?.name || '',
                    required: !!info?.required,
                    operation: info?.transformation,
                  } as Rule),
              )
          : [],
      );
    }
  }, [sourceFileHeaders, data.destinationColumns]);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
    }
  }, [open]);

  useEffect(() => {
    setActive(!!rule?.active);
    setName(rule?.name || '');
    setSeparator(rule?.separator || ',');
    const orgIds = rule?.organizations?.map(({ id }) => id);
    setReferral(
      orgIds
        ? data.organizations?.filter(({ value }) =>
            orgIds?.includes(value as number),
          )
        : [],
    );
    setFile(
      rule?.file?.id
        ? data.files?.find(({ value }) => value === rule?.file?.id)
        : undefined,
    );
    if (rule?.branchColumn) {
      setMixedFile(true);
    } else {
      setMixedFile(false);
    }
  }, [rule, data.files, data.organizations]);

  const checkRules = () =>
    mappingRules?.every(
      ({ sourceColumn, targetColumn, operation, defaultValue }) =>
        (sourceColumn || defaultValue) &&
        targetColumn &&
        (!operation || operation.action),
    );

  const checkMixedRules = () =>
    branchColumn?.sourceColumn &&
    branchColumn?.operation?.dataDictionary?.every(
      ({ sourceValue, destinationValue }) => destinationValue && sourceValue,
    );

  const checkCustomRules = (
    column?: string,
    mixedRules?: ReferralMappingMappedValue[],
  ) =>
    !column ||
    (mixedRules?.length &&
      mixedRules?.every(
        ({ sourceValue, destinationValue }) => destinationValue && sourceValue,
      ));

  const validate = () =>
    name &&
    referral?.length &&
    file?.value &&
    separator &&
    checkRules() &&
    (!mixedFile || checkMixedRules()) &&
    checkCustomRules(
      genderColumn?.sourceColumn,
      genderColumn?.operation?.dataDictionary,
    ) &&
    checkCustomRules(
      languageColumn?.sourceColumn,
      languageColumn?.operation?.dataDictionary,
    );

  const getServerOperation = (uiRule: Rule) => {
    if (uiRule?.default) {
      return {
        action: ReferralMappingOperationType.DEFAULT.value,
        param1: uiRule?.defaultValue,
      };
    }
    if (uiRule?.operation) {
      return {
        action: uiRule?.operation?.action?.value || '',
        param1: uiRule?.operation?.param1,
        param2: uiRule?.operation?.param2,
        dataDictionary: uiRule.operation.dataDictionary?.reduce(
          (acc, uiRule) => ({
            ...acc,
            [uiRule.sourceValue || '']: uiRule.destinationValue || '',
          }),
          {},
        ),
      };
    }
    return undefined;
  };

  const doSubmit = async () => {
    setSubmitted(true);
    if (!validate()) {
      if (mixedFile && !branchColumn) setErrorBrandNotFound(true);
      return false;
    }

    try {
      await onSubmit(
        {
          active,
          name: name || '',
          organizationIds: referral?.map(({ value }) => value as number),
          fileId: file?.value as number,
          columns: mappingRules?.map((rule) => ({
            id: rule?.id,
            sourceColumn: rule?.default ? undefined : rule.sourceColumn,
            targetColumn: rule?.targetColumn,
            operation: getServerOperation(rule),
          })),
          separator,
        },
        rule?.id,
      );
      setSubmitted(false);
    } catch (e) {
      return false;
    }
    return true;
  };

  const renderMappingOptions = (
    operation: ReferralMappingOperation,
    sourceItems: string[],
    destinationItemLabel: string,
    destinationItems: SelectableInfo<any>[],
    destinationItemsGrouped?: any,
    keySuffix?: string,
  ) => (
    <div
      className={classNames(
        'grid-span-12',
        styles.rowMappingContainer,
        sourceItems?.length && styles.rowMappingContainerBorder,
      )}
    >
      {!!sourceItems?.length &&
        !!operation &&
        operation?.dataDictionary?.map((data, idx) => (
          <div
            className={classNames('flex middle', styles.row)}
            key={`referral-mixed-ingestion-rule-${idx}-${keySuffix || 'items'}`}
          >
            <Select
              className={styles.selectLarge}
              disableClearable
              error={submitted && !data.sourceValue}
              getItemLabel={(item: { value: string }) => item.value}
              items={excludeOtherValues<{ value: string }>(
                [...sourceItems]
                  .sort((a, b) => a.localeCompare(b))
                  .map((value) => ({ value, label: value })),
                operation?.dataDictionary.map(({ sourceValue }) => ({
                  value: sourceValue || '',
                })) || [],
                { value: data.sourceValue || '' },
              )}
              label={
                idx === 0 ? (
                  <span className="flex middle" style={{ height: '17.14px' }}>
                    {translate('referrals.ingestionMapper.modal.source')}
                    {' * '}
                    {(operation?.dataDictionary?.length || 0) <
                      sourceItems?.length && (
                      <a
                        className={classNames(
                          'flex middle',
                          styles.addItemLink,
                        )}
                        onClick={(evt) => {
                          evt.preventDefault();
                          if (operation) {
                            operation.dataDictionary = [
                              ...operation.dataDictionary,
                              new ReferralMappingMappedValue({}),
                            ];
                            setMappingRules([...mappingRules]);
                          }
                        }}
                      >
                        <AddIcon
                          className={styles.addItemIcon}
                          fontSize="small"
                        />
                        {translate('referrals.ingestionMapper.modal.addSource')}
                      </a>
                    )}
                  </span>
                ) : undefined
              }
              placeholder={translate('referrals.ingestionMapper.modal.source')}
              onChange={(sourceValue: { value: string }) => {
                if (operation) {
                  operation.dataDictionary[idx].sourceValue = sourceValue.value;
                  setMappingRules([...mappingRules]);
                }
              }}
              value={data.sourceValue ? { value: data.sourceValue } : undefined}
              size="small"
            />
            <Arrow className={classNames(idx === 0 && styles.firtsItem)} />
            <Select
              className={styles.selectExtraLarge}
              disableClearable
              error={submitted && !data.destinationValue}
              items={destinationItems}
              grouped={destinationItemsGrouped}
              label={idx === 0 ? destinationItemLabel : undefined}
              placeholder={destinationItemLabel}
              onChange={(destinationValue: Selectable) => {
                if (operation) {
                  operation.dataDictionary[idx].destinationValue =
                    destinationValue?.value?.toString() || '';
                  setMappingRules([...mappingRules]);
                }
              }}
              value={destinationItems?.find(
                ({ value }) => value.toString() === data.destinationValue,
              )}
              size="small"
            />
            <IconButton
              className={classNames(idx === 0 && styles.firtsItem)}
              disabled={
                operation?.dataDictionary &&
                operation.dataDictionary.length < 2 &&
                !operation.dataDictionary[0]?.sourceValue &&
                !operation.dataDictionary[0]?.destinationValue
              }
              size="small"
              onClick={() => {
                if (operation?.dataDictionary) {
                  if (operation.dataDictionary.length > 1) {
                    operation.dataDictionary?.splice(idx, 1);
                  } else {
                    operation.dataDictionary = [
                      new ReferralMappingMappedValue({}),
                    ];
                  }
                  setMappingRules([...mappingRules]);
                }
              }}
            >
              <DeleteOutlineIcon fontSize="large" />
            </IconButton>
          </div>
        ))}
      {!sourceItems?.length && (
        <PanelInfo type="warning">
          {translate('referrals.ingestionMapper.modal.warningNoItems', {
            file: file?.label || 'File',
          })}
        </PanelInfo>
      )}
    </div>
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={translate(
        `referrals.ingestionMapper.modal.title${rule ? 'Edit' : 'Add'}`,
        {
          rule: rule?.name || 'Rule',
        },
      )}
      maxWidth="lg"
      onSubmit={doSubmit}
      preventCloseOnBackdropClick
      submitText={translate(`global.${rule ? 'save' : 'add'}`)}
    >
      <div className="grid-wrapper grid-span-12 fit">
        <div className="grid-span-12 flex gap wrap baseline">
          <TextInput
            className={styles.fileInputLarge}
            error={submitted && !name}
            footer={
              <CheckboxWithLabel
                checked={!!active}
                className={styles.footerCheckbox}
                label={translate('common.active')}
                onChange={setActive}
              />
            }
            label={translate('referrals.ingestionMapper.modal.name')}
            onChange={setName}
            value={name}
            required
          />
          <Select
            className={styles.fileInputLarge}
            error={submitted && !referral?.length}
            items={data.organizations}
            footer={
              showMixedFileCheckbox ? (
                <CheckboxWithLabel
                  checked={!!mixedFile}
                  className={styles.footerCheckbox}
                  label={translate('referrals.ingestionMapper.modal.mixedFile')}
                  onChange={setMixedFile}
                />
              ) : undefined
            }
            label={translate('referrals.ingestionMapper.modal.referral')}
            loading={loadingData}
            multiple
            onChange={setReferral}
            value={referral}
            required
          />
          <Select
            className={styles.fileInputLarge}
            error={submitted && !file}
            items={data.files}
            label={translate('referrals.ingestionMapper.modal.file')}
            loading={loadingData || loadingFileHeaders}
            onChange={setFile}
            value={file}
            required
          />
          <TextInput
            className={styles.fileInputSmall}
            error={submitted && !separator}
            label={translate('referrals.ingestionMapper.modal.separator')}
            maxLength={1}
            onChange={(separator) => setSeparator(separator || '')}
            value={separator}
            tooltip={translate(
              'referrals.ingestionMapper.modal.separatorTooltip',
            )}
            required
          />
        </div>
        {errorBranchNotFound && (
          <PanelInfo className="grid-span-12" type="warning">
            {translate('referrals.ingestionMapper.modal.errorBranchNotFound')}
          </PanelInfo>
        )}
        <div className="grid-span-1" />
        <hr className="separator grid-span-10" />
        <div className="grid-span-12">
          {mappingRules?.map((item, idx) => (
            <Fragment key={`referral-ingestion-rule-${idx}`}>
              <div className={classNames('flex middle', styles.row)}>
                <Select
                  className={styles.select}
                  disabled={!sourceFileHeaders?.length || !referral?.length}
                  disableClearable
                  error={submitted && !item.sourceColumn && !item.default}
                  items={[
                    translate('referrals.ingestionMapper.modal.defaultValue'),
                    ...sourceFileHeaders,
                  ]}
                  getItemLabel={(item) => item?.toString()}
                  grouped={(item: string) =>
                    item ===
                    translate('referrals.ingestionMapper.modal.defaultValue')
                      ? ''
                      : translate('common.column')
                  }
                  label={
                    idx === 0
                      ? translate('referrals.ingestionMapper.modal.source')
                      : undefined
                  }
                  placeholder={translate(
                    'referrals.ingestionMapper.modal.source',
                  )}
                  onChange={(sourceColumn: string) => {
                    if (
                      sourceColumn ===
                      translate('referrals.ingestionMapper.modal.defaultValue')
                    ) {
                      mappingRules[idx].default = true;
                      mappingRules[idx].sourceColumn = '';
                      delete mappingRules[idx].operation;
                    } else {
                      mappingRules[idx].default = false;
                      mappingRules[idx].defaultValue = '';
                      mappingRules[idx].sourceColumn = sourceColumn;
                    }
                    setMappingRules([...mappingRules]);
                  }}
                  renderOption={(item) =>
                    item ===
                    translate(
                      'referrals.ingestionMapper.modal.defaultValue',
                    ) ? (
                      <Tooltip
                        text={
                          <>
                            <p className="white">
                              {translate(
                                'referrals.ingestionMapper.modal.defaultValueTooltip1',
                              )}
                            </p>
                            <p className="white no-margin">
                              {translate(
                                'referrals.ingestionMapper.modal.defaultValueTooltip2',
                              )}
                            </p>
                            <p className="white">
                              {translate(
                                'referrals.ingestionMapper.modal.defaultValueTooltip3',
                              )}
                            </p>
                            <p className="white no-margin">
                              {translate(
                                'referrals.ingestionMapper.modal.defaultValueTooltip4',
                              )}
                            </p>
                          </>
                        }
                        followCursor
                        enterDelay={300}
                      >
                        <span className="flex min-gap">
                          {item} <InfoIcon color="info" />
                        </span>
                      </Tooltip>
                    ) : (
                      item
                    )
                  }
                  value={
                    item?.default
                      ? translate(
                          'referrals.ingestionMapper.modal.defaultValue',
                        )
                      : item.sourceColumn
                  }
                  size="small"
                />
                {item?.operation || item.default ? (
                  <>
                    {item.default ? (
                      <>
                        :
                        <TextInput
                          className={classNames(
                            styles.select,
                            idx === 0 && styles.firtsItem,
                          )}
                          error={submitted && !item.defaultValue}
                          onChange={(defaultValue?: string) => {
                            if (mappingRules[idx].default) {
                              mappingRules[idx].defaultValue = defaultValue;
                              setMappingRules([...mappingRules]);
                            }
                          }}
                          placeholder={translate(
                            'referrals.ingestionMapper.modal.default',
                          )}
                          value={item.defaultValue}
                          size="small"
                        />
                      </>
                    ) : (
                      <>
                        <Arrow
                          className={classNames(idx === 0 && styles.firtsItem)}
                        />
                        <Select
                          className={classNames(
                            styles.selectExtraMin,
                            idx === 0 && styles.firtsItem,
                          )}
                          disableClearable
                          error={submitted && !item.operation}
                          getItemDisabled={(operation: Selectable) =>
                            !item.sourceColumn &&
                            [
                              ReferralMappingOperationType.BRANCH.value,
                              ReferralMappingOperationType.GENDER.value,
                              ReferralMappingOperationType.LANGUAGE.value,
                            ].includes(operation.value as string)
                          }
                          items={[
                            { value: -1, label: translate('global.none') },
                            ...Enum.toSelectable(
                              ReferralMappingOperationType.asArray.filter(
                                (item) =>
                                  item !==
                                    ReferralMappingOperationType.DEFAULT &&
                                  ((!branchColumn && mixedFile) ||
                                    item !==
                                      ReferralMappingOperationType.BRANCH) &&
                                  (!genderColumn ||
                                    item !==
                                      ReferralMappingOperationType.GENDER) &&
                                  (!languageColumn ||
                                    item !==
                                      ReferralMappingOperationType.LANGUAGE),
                              ),
                            ),
                          ]}
                          loading={
                            item.operation?.action &&
                            ((ReferralMappingOperationType.BRANCH ===
                              item.operation?.action &&
                              loadingFileBranchValues) ||
                              (ReferralMappingOperationType.GENDER ===
                                item.operation?.action &&
                                loadingFileGenderValues) ||
                              (ReferralMappingOperationType.LANGUAGE ===
                                item.operation?.action &&
                                loadingFileLanguageValues))
                          }
                          placeholder={translate(
                            'referrals.ingestionMapper.modal.transformation',
                          )}
                          onChange={(type?: Selectable) => {
                            if (mappingRules[idx].operation && type) {
                              if (type.value === -1) {
                                delete mappingRules[idx].operation;
                              } else {
                                mappingRules[idx].operation.action =
                                  ReferralMappingOperationType.byKey[
                                    type.value
                                  ];
                                mappingRules[idx].operation.param1 = '';
                                mappingRules[idx].operation.param2 = '';
                              }
                              setMappingRules([...mappingRules]);
                            }
                          }}
                          value={
                            item.operation?.action
                              ? Enum.toSelectable([item.operation.action])[0]
                              : undefined
                          }
                          size="small"
                        />
                      </>
                    )}
                    {item.operation?.action ===
                      ReferralMappingOperationType.DATE && (
                      <>
                        <span
                          className={classNames(
                            idx === 0 && styles.firtsItem,
                            'semi-bold',
                          )}
                        >
                          {translate('referrals.ingestionMapper.modal.format')}:
                        </span>
                        <Select
                          allowAddNewValue
                          className={classNames(
                            styles.selectMin,
                            idx === 0 && styles.firtsItem,
                          )}
                          disableClearable
                          error={submitted && !item.operation?.param1}
                          items={[
                            { value: 'yyyy-MM-DD', label: 'yyyy-MM-DD' },
                            { value: 'yy-MM-DD', label: 'yy-MM-DD' },
                            {
                              value: 'M/D/yyyy',
                              label: 'M/D/yyyy',
                            },
                          ]}
                          placeholder={translate(
                            'referrals.ingestionMapper.modal.format',
                          )}
                          onChange={(format?: Selectable) => {
                            if (mappingRules[idx].operation && format) {
                              mappingRules[
                                idx
                              ].operation.param1 = format?.label as string;
                              setMappingRules([...mappingRules]);
                            }
                          }}
                          value={
                            item.operation?.param1
                              ? {
                                  label: item.operation?.param1,
                                  value: item.operation?.param1,
                                }
                              : undefined
                          }
                          size="small"
                        />
                      </>
                    )}
                    {item.operation?.action ===
                      ReferralMappingOperationType.SPLIT && (
                      <>
                        <span
                          className={classNames(
                            idx === 0 && styles.firtsItem,
                            'semi-bold',
                          )}
                        >
                          {translate('referrals.ingestionMapper.modal.by')}:
                        </span>
                        <TextInput
                          className={classNames(
                            styles.selectExtraExtraMin,
                            idx === 0 && styles.firtsItem,
                          )}
                          error={submitted && !item.operation?.param1}
                          onChange={(format?: string) => {
                            if (mappingRules[idx].operation && format) {
                              mappingRules[idx].operation.param1 = format;
                              setMappingRules([...mappingRules]);
                            }
                          }}
                          placeholder={translate(
                            'referrals.ingestionMapper.modal.by',
                          )}
                          value={item.operation?.param1}
                          size="small"
                        />
                        <span
                          className={classNames(
                            idx === 0 && styles.firtsItem,
                            'semi-bold',
                          )}
                        >
                          {translate('referrals.ingestionMapper.modal.get')}:
                        </span>
                        <Select
                          className={classNames(
                            styles.selectExtraMin,
                            idx === 0 && styles.firtsItem,
                          )}
                          error={submitted && !item.operation?.param2}
                          items={SPLIT_OPTIONS}
                          placeholder={translate(
                            'referrals.ingestionMapper.modal.get',
                          )}
                          onChange={(get?: Selectable) => {
                            if (mappingRules[idx].operation && get) {
                              mappingRules[
                                idx
                              ].operation.param2 = get?.value as string;
                              setMappingRules([...mappingRules]);
                            }
                          }}
                          value={SPLIT_OPTIONS?.find(
                            ({ value }) => value === item.operation?.param2,
                          )}
                          size="small"
                        />
                      </>
                    )}
                    <Arrow
                      className={classNames(idx === 0 && styles.firtsItem)}
                    />
                  </>
                ) : (
                  <Arrow className={classNames(idx === 0 && styles.firtsItem)}>
                    {!!sourceFileHeaders?.length && !!referral?.length && (
                      <a
                        className={classNames(
                          'flex middle',
                          styles.addTransformationLink,
                        )}
                        onClick={(evt) => {
                          evt.preventDefault();
                          mappingRules[
                            idx
                          ].operation = new ReferralMappingOperation({});
                          setMappingRules([...mappingRules]);
                        }}
                      >
                        <AddIcon fontSize="small" />
                        {translate(
                          'referrals.ingestionMapper.modal.addTransformation',
                        )}
                      </a>
                    )}
                  </Arrow>
                )}
                <Select
                  className={styles.select}
                  disableClearable
                  disabled={item.required}
                  error={submitted && !item.targetColumn}
                  items={excludeOtherValues<{ value: string }>(
                    [
                      ...data.destinationColumns,
                    ].sort(({ value: a }, { value: b }) =>
                      a.toString().localeCompare(b.toString()),
                    ) as { value: string }[],
                    mappingRules?.map(({ targetColumn }) => ({
                      value: targetColumn || '',
                    })),
                    { value: item.targetColumn || '' },
                  )}
                  label={
                    idx === 0
                      ? translate('referrals.ingestionMapper.modal.destination')
                      : undefined
                  }
                  placeholder={translate(
                    'referrals.ingestionMapper.modal.destination',
                  )}
                  loading={loadingData}
                  onChange={(destination: Selectable) => {
                    mappingRules[
                      idx
                    ].targetColumn = destination?.value as string;
                    setMappingRules([...mappingRules]);
                  }}
                  value={data.destinationColumns?.find(
                    ({ value }) => value === item.targetColumn,
                  )}
                  size="small"
                />
                <IconButton
                  className={classNames(idx === 0 && styles.firtsItem)}
                  disabled={
                    item.required || (mappingRules && mappingRules.length < 2)
                  }
                  size="small"
                  onClick={() => {
                    mappingRules?.splice(idx, 1);
                    setMappingRules([...mappingRules]);
                  }}
                >
                  <DeleteOutlineIcon fontSize="large" />
                </IconButton>
              </div>
              {item.operation?.action === ReferralMappingOperationType.BRANCH &&
                !!mixedReferrals?.length &&
                !loadingFileBranchValues &&
                renderMappingOptions(
                  item.operation,
                  sourceFileBranches,
                  translate('referrals.ingestionMapper.modal.referral'),
                  mixedReferrals,
                  (item: SelectableInfo<Organization>) =>
                    item?.info?.parent?.name || '',
                  'referral',
                )}
              {item.operation?.action === ReferralMappingOperationType.GENDER &&
                !loadingFileGenderValues &&
                renderMappingOptions(
                  item.operation,
                  sourceFileGenders,
                  translate('referrals.ingestionMapper.modal.gender'),
                  Enum.toSelectable(PatientGender.asArray),
                  undefined,
                  'language',
                )}
              {item.operation?.action ===
                ReferralMappingOperationType.LANGUAGE &&
                !loadingFileLanguageValues &&
                renderMappingOptions(
                  item.operation,
                  sourceFileLanguages,
                  translate('referrals.ingestionMapper.modal.language'),
                  Enum.toSelectable(Language.asArray),
                  undefined,
                  'language',
                )}
            </Fragment>
          ))}
          {mappingRules?.length < data.destinationColumns?.length && (
            <div className={classNames('flex center', styles.row)}>
              <Button
                className={styles.button}
                color="tertiary"
                icon={<AddIcon />}
                onClick={() => setMappingRules([...mappingRules, {} as Rule])}
              >
                {translate('referrals.ingestionMapper.modal.addRule')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReferralIngestionMapperEditModal;
