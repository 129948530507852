import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Patient, PodConfig } from '@vestahealthcare/common/models';

import {
  Button,
  CollapsableSidebar,
  Modal,
  Select,
  SelectDetailedOption,
  Tooltip,
} from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { SelectEmployeeOwner } from 'dash/src/components/SelectEmployeeOwnerOption.tsx';
import { CacheServices } from 'dash/src/services';
import { PatientParam } from 'dash/src/services/PatientServices';

const useStyles = makeStyles({
  disabledOption: {
    opacity: 0.38,
    width: '100%',
  },
  languageList: {
    '&&': {
      listStyleType: 'inherit',
      padding: 'inherit',
    },
  },
});

type Props = {
  member?: Patient;
  onClose: () => void;
  onSubmit: (params: PatientParam) => void;
  open: boolean;
};

export const EditVestaTeamModal = ({
  member,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const { showAdminPodsPage } = useFlags();
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const [careCoordinators, setCareCoordinators] = useState<Employee[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [engagementOwners, setEngagementOwners] = useState<Employee[]>([]);
  const [healthCoaches, setHealthCoaches] = useState<Employee[]>([]);
  const [podManagers, setPodManagers] = useState<Employee[]>([]);
  const [NPs, setNPs] = useState<Employee[]>([]);
  const [pods, setPods] = useState<PodConfig[]>([]);
  const [RNs, setRNs] = useState<Employee[]>([]);

  const [selectedCareCoordinators, setSelectedCareCoordinators] = useState<
    Employee[]
  >([]);
  const [selectedEngagementOwners, setSelectedEngagementOwners] = useState<
    Employee[]
  >([]);
  const [selectedHealthCoaches, setSelectedHealthCoaches] = useState<
    Employee[]
  >([]);
  const [selectedNP, setSelectedNP] = useState<Employee>();
  const [selectedRN, setSelectedRN] = useState<Employee>();
  const [selectedPod, setSelectedPod] = useState<PodConfig>();
  const [selectedPodManager, setSelectedPodManager] = useState<Employee>();
  const [nlcStates, setNLCStates] = useState<States[]>([]);

  const [openLanguageMismathModal, setOpenLanguageMismathModal] = useState<
    boolean
  >(false);

  const fetchInitialData = async () => {
    setLoading(true);
    const [pods, employees, states] = await Promise.all([
      CacheServices.getPodConfigs(),
      CacheServices.getEmployees(),
      CacheServices.getEmployeeNLCStates(),
    ]);

    setPods(pods);
    setEmployees(employees);
    if (!showAdminPodsPage || !member?.pod) {
      setCareCoordinators(employees.filter((e) => e.isInCareCoordinator));
      setEngagementOwners(employees.filter((e) => e.isInEngagement));
      setHealthCoaches(employees.filter((e) => e.isInHealthCoaches));
      setNPs(employees.filter((e) => e.isNP));
      setPodManagers(employees.filter((e) => e.isPodManager));
      setRNs(employees.filter((e) => e.isRN));
    }
    setNLCStates(states || []);

    setLoading(false);
  };

  const resetData = () => {
    if (showAdminPodsPage) {
      setSelectedPod(pods?.find(({ id }) => id === member?.pod?.id));
    } else {
      setSelectedCareCoordinators(member?.careCoordinators || []);
      setSelectedEngagementOwners(member?.engagementOwners || []);
      setSelectedHealthCoaches(member?.healthCoaches || []);
      setSelectedNP(member?.npOwner);
      setSelectedRN(member?.owner);
    }
    setSelectedPodManager(member?.podManager);

    setSelectedRN(member?.owner);
    setLoadingButton(false);
    setSubmit(false);
  };

  useEffect(() => {
    if (open) {
      fetchInitialData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      resetData();
    }
  }, [open, member, pods]);

  useEffect(() => {
    if (selectedPod) {
      const ccIds = selectedPod.assignedCareCoordinators?.map(
        ({ employee: { id } }) => id,
      );
      const eoIds = selectedPod.assignedEngagementOwners?.map(
        ({ employee: { id } }) => id,
      );
      const hcIds = selectedPod.assignedHealthCoaches?.map(
        ({ employee: { id } }) => id,
      );
      const npIds = selectedPod.assignedNPs?.map(({ employee: { id } }) => id);
      const rnIds = selectedPod.assignedRNs?.map(({ employee: { id } }) => id);
      setCareCoordinators(employees?.filter(({ id }) => ccIds.includes(id)));
      setEngagementOwners(employees?.filter(({ id }) => eoIds.includes(id)));
      setHealthCoaches(employees?.filter(({ id }) => hcIds.includes(id)));
      setNPs(employees?.filter(({ id }) => npIds.includes(id)));
      setRNs(employees?.filter(({ id }) => rnIds.includes(id)));

      setPodManagers([selectedPod.podManager]);
      setSelectedPodManager(selectedPod?.podManager);
    } else {
      setCareCoordinators(employees.filter((e) => e.isInCareCoordinator));
      setEngagementOwners(employees.filter((e) => e.isInEngagement));
      setHealthCoaches(employees.filter((e) => e.isInHealthCoaches));
      setNPs(employees.filter((e) => e.isNP));
      setPodManagers(employees.filter((e) => e.isPodManager));
      setRNs(employees.filter((e) => e.isRN));
    }
  }, [selectedPod]);

  useEffect(() => {
    if (selectedCareCoordinators) {
      setSelectedCareCoordinators(
        selectedCareCoordinators?.filter((e) =>
          careCoordinators?.find((cc) => cc.id === e.id),
        ),
      );
    }
  }, [careCoordinators]);

  useEffect(() => {
    if (selectedEngagementOwners) {
      setSelectedEngagementOwners(
        selectedEngagementOwners?.filter((e) =>
          engagementOwners?.find((eo) => eo.id === e.id),
        ),
      );
    }
  }, [engagementOwners]);

  useEffect(() => {
    if (selectedHealthCoaches) {
      setSelectedHealthCoaches(
        selectedHealthCoaches?.filter((e) =>
          healthCoaches?.find((hc) => hc.id === e.id),
        ),
      );
    }
  }, [healthCoaches]);

  useEffect(() => {
    if (selectedNP) {
      setSelectedNP(NPs?.find((e) => selectedNP.id === e.id));
    }
  }, [NPs]);

  useEffect(() => {
    if (selectedRN) {
      setSelectedRN(RNs?.find((e) => selectedRN.id === e.id));
    }
  }, [RNs]);

  const checkSameIds = (a?: Employee[], b?: Employee[]) => {
    if (!a?.length && !b?.length) return true;
    if (a?.length !== b?.length) return false;
    const aIds = a?.map(({ id }) => id);
    const bIds = b?.map(({ id }) => id);
    return aIds?.reduce((acc, item) => acc && !!bIds?.includes(item), true);
  };

  const hasChanges =
    !checkSameIds(selectedCareCoordinators, member?.careCoordinators) ||
    !checkSameIds(selectedEngagementOwners, member?.engagementOwners) ||
    !checkSameIds(selectedHealthCoaches, member?.healthCoaches) ||
    selectedNP?.id !== member?.npOwner?.id ||
    selectedRN?.id !== member?.owner?.id ||
    selectedPodManager?.id !== member?.podManager?.id ||
    (showAdminPodsPage && selectedPod?.id !== member?.pod?.id);

  const validate = () =>
    (!showAdminPodsPage || selectedPod?.id) &&
    selectedPodManager?.id &&
    selectedNP?.id &&
    selectedRN?.id &&
    (!!selectedCareCoordinators?.length || !member?.careCoordinators?.length);

  const doSubmit = async () => {
    try {
      setLoadingButton(true);
      await onSubmit({
        careCoordinatorIds: selectedCareCoordinators?.map(({ id }) => id),
        engagementOwnerIds: selectedEngagementOwners?.map(({ id }) => id),
        healthCoachIds: selectedHealthCoaches?.map(({ id }) => id),
        npOwner: selectedNP?.id,
        owner: selectedRN?.id,
        pod: showAdminPodsPage ? selectedPod?.id : undefined,
        podManager: selectedPodManager?.id,
      });
      onClose();
      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
    }
  };

  const findLanguageMatch = (member: Patient, employee: Employee) => {
    const memberLanguages = [member.language, ...(member.languages || [])]
      ?.map((l) => l?.value)
      .filter(Boolean);
    return employee.languages.some((lang) =>
      memberLanguages.includes(lang.value),
    );
  };

  const checkSubmit = () => {
    if (!hasChanges) return;
    setSubmit(true);
    if (!member || !selectedNP || !selectedRN || !validate()) return;

    if (
      !findLanguageMatch(member, selectedNP) ||
      !findLanguageMatch(member, selectedRN)
    ) {
      setOpenLanguageMismathModal(true);
    } else {
      doSubmit();
    }
  };

  const memberState = member?.address.state;

  return (
    <>
      <CollapsableSidebar
        onClose={onClose}
        open={open}
        title={translate('memberProfile.network.vestaTeamModalTitle')}
      >
        <CollapsableSidebar.Body>
          {showAdminPodsPage && (
            <Select
              data-cy="edit-vesta-team-pod"
              disableClearable
              error={submit && !selectedPod}
              items={pods}
              getItemLabel={({ name }: PodConfig) => name}
              label={translate('memberProfile.network.pod')}
              loading={loading}
              onChange={setSelectedPod}
              renderOption={(pod: PodConfig) => (
                <SelectDetailedOption
                  key={`member-network-pod-${pod.id}`}
                  info={pod.name}
                  extraInfo={[pod.podManager.fullName]}
                />
              )}
              required
              value={selectedPod}
            />
          )}
          <Select
            data-cy="edit-vesta-team-pod-manager"
            disableClearable
            disabled={!!selectedPod}
            error={submit && !selectedPodManager}
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={podManagers}
            label={translate('memberProfile.network.podManager')}
            loading={loading}
            onChange={setSelectedPodManager}
            required
            value={selectedPodManager}
          />
          <Select
            data-cy="edit-vesta-team-np-ownwer"
            disableClearable
            error={submit && !selectedNP}
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={NPs}
            label={translate('memberProfile.network.npOwner')}
            loading={loading}
            onChange={setSelectedNP}
            renderOption={(e: Employee) =>
              e.allowedInState(memberState, nlcStates) ? (
                <SelectEmployeeOwner key="member-network-np" employee={e} />
              ) : (
                <Tooltip
                  text={
                    <>
                      <span>
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip1',
                        )}
                      </span>
                      <span className="semi-bold">
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip2',
                          {
                            name: e.fullName,
                            state: memberState?.toString(),
                          },
                        )}
                      </span>
                      <span>
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip3',
                        )}
                      </span>
                    </>
                  }
                  enterDelay={200}
                  followCursor
                >
                  <span
                    className={styles.disabledOption}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    {' '}
                    <SelectEmployeeOwner key="member-network-np" employee={e} />
                  </span>
                </Tooltip>
              )
            }
            required={!!member?.npOwner}
            value={selectedNP}
          />
          <Select
            data-cy="edit-vesta-team-rn-ownwer"
            disableClearable
            error={submit && !selectedRN}
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={RNs}
            label={translate('memberProfile.network.rnOwner')}
            loading={loading}
            onChange={setSelectedRN}
            renderOption={(e: Employee) =>
              e.allowedInState(memberState, nlcStates) ? (
                <SelectEmployeeOwner key="member-network-rn" employee={e} />
              ) : (
                <Tooltip
                  text={
                    <>
                      <span>
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip1',
                        )}
                      </span>
                      <span className="semi-bold">
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip2',
                          {
                            name: e.fullName,
                            state: memberState?.toString(),
                          },
                        )}
                      </span>
                      <span>
                        {translate(
                          'memberProfile.network.warningEmployeeState.tooltip3',
                        )}
                      </span>
                    </>
                  }
                  enterDelay={200}
                  followCursor
                >
                  <span
                    className={styles.disabledOption}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    {' '}
                    <SelectEmployeeOwner key="member-network-rn" employee={e} />
                  </span>
                </Tooltip>
              )
            }
            required={!!member?.owner}
            value={selectedRN}
          />
          <Select
            data-cy="edit-vesta-team-care-coordinator"
            disableClearable
            error={
              submit &&
              !selectedCareCoordinators?.length &&
              !!member?.careCoordinators?.length
            }
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={careCoordinators}
            label={translate('memberProfile.network.careCoordinators')}
            loading={loading}
            multiple
            onChange={setSelectedCareCoordinators}
            required={!!member?.careCoordinators?.length}
            value={selectedCareCoordinators}
          />
          <Select
            data-cy="edit-vesta-team-engagement-owner"
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={engagementOwners}
            label={translate('memberProfile.network.engagementOwners')}
            loading={loading}
            multiple
            onChange={setSelectedEngagementOwners}
            value={selectedEngagementOwners}
          />
          <Select
            data-cy="edit-vesta-team-health-coach"
            filterOptions={FilterEmployeeOptions}
            getItemLabel={({ fullName }: Employee) => fullName}
            items={healthCoaches}
            label={translate('memberProfile.network.healthCoaches')}
            loading={loading}
            multiple
            onChange={setSelectedHealthCoaches}
            value={selectedHealthCoaches}
          />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button
            color="tertiary"
            data-cy="edit-vesta-team-close"
            onClick={onClose}
          >
            {translate('global.close')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-vesta-team-submit"
            disabled={!hasChanges}
            loading={loadingButton}
            onClick={checkSubmit}
          >
            {translate('global.save')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      {member && selectedNP && selectedRN && (
        <Modal
          title={translate('global.warning')}
          onClose={() => setOpenLanguageMismathModal(false)}
          onSubmit={async () => {
            await doSubmit();
          }}
          open={openLanguageMismathModal}
        >
          {translate('memberProfile.network.warningEmployewLanguage.line1')}:
          <ul className={styles.languageList}>
            <li>
              <span className="semi-bold">
                {translate('memberProfile.network.member')}
              </span>{' '}
              ({member?.fullName}):{' '}
              {(member?.languages || []).map((l) => l?.toString()).join(', ')}
            </li>
            {!findLanguageMatch(member, selectedNP) && (
              <li>
                <span className="semi-bold">
                  {translate('memberProfile.network.npOwner')}
                </span>{' '}
                ({selectedNP.fullName}):{' '}
                {selectedNP.languages?.length
                  ? selectedNP.languages.map((l) => l?.toString()).join(', ')
                  : translate('global.none')}
              </li>
            )}
            {!findLanguageMatch(member, selectedRN) && (
              <li>
                <span className="semi-bold">
                  {translate('memberProfile.network.rnOwner')}
                </span>{' '}
                ({selectedRN.fullName}):{' '}
                {selectedRN.languages?.length
                  ? selectedRN.languages
                      .filter(Boolean)
                      .map((l) => l.toString())
                      .join(', ')
                  : translate('global.none')}
              </li>
            )}
          </ul>
          {translate('memberProfile.network.warningEmployewLanguage.line2')}
        </Modal>
      )}
    </>
  );
};

export default EditVestaTeamModal;
