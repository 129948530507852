import classNames from 'classnames';
import React from 'react';

import PodManagerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';

import {
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
  PodMetricsSummaryForecast,
} from '@vestahealthcare/common/models';

import { Card, Colors } from 'styleguide-v2';

import { RecursiveTableData } from 'dash/src/components/RecursiveTable';

import { PodSummaryCardBiannual } from './PodSummaryCardBiannual';
import { PodSummaryCardMemberAllocation } from './PodSummaryCardMemberAllocation';
import { PodSummaryCardMembers } from './PodSummaryCardMembers';
import { PodSummaryCardProgram } from './PodSummaryCardProgram';
import { PodSummaryCardProgramForecast } from './PodSummaryCardProgramForecast';
import { PodSummaryCardTask } from './PodSummaryCardTask';
import { PodSummaryCardTaskForecast } from './PodSummaryCardTaskForecast';
import { PodSummaryCardUnreachable } from './PodSummaryCardUnreachable';
import { PodSummaryCardWarnings } from './PodSummaryCardWarnings';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary | PodMetricsSummaryForecast;
  employees: PodMetricsSummaryEmployee[];
  getForecastTaskRowOnClick?: (
    item: RecursiveTableData,
  ) => ((item: RecursiveTableData) => void) | undefined;
  getTaskRowOnClick?: (
    item: RecursiveTableData,
  ) => ((item: RecursiveTableData) => void) | undefined;
  onOpenEmployees: () => void;
};

export const PODSummaryCard = ({
  className,
  employees,
  getTaskRowOnClick,
  getForecastTaskRowOnClick,
  onOpenEmployees,
  pod,
}: Props) => {
  const styles = PODSummaryCardStyles();

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={classNames(styles.titleContainer, styles.borderBottom)}>
        <span className={styles.title}>{pod.name}</span>
        <div className={styles.titleInfo}>
          <PodManagerIcon htmlColor={Colors.iconGreen} fontSize="large" />
          <span className={styles.textWithIcon}>{pod.manager.fullName}</span>
        </div>
      </div>
      <PodSummaryCardMembers className={styles.borderBottom} pod={pod} />
      {pod instanceof PodMetricsSummary ? (
        <PodSummaryCardProgram className={styles.borderBottom} pod={pod} />
      ) : (
        <PodSummaryCardProgramForecast
          className={styles.borderBottom}
          pod={pod}
        />
      )}
      <PodSummaryCardUnreachable className={styles.borderBottom} pod={pod} />

      {pod instanceof PodMetricsSummary ? (
        <PodSummaryCardTask
          className={styles.borderBottom}
          pod={pod}
          getTaskRowOnClick={getTaskRowOnClick}
        />
      ) : (
        <PodSummaryCardTaskForecast
          className={styles.borderBottom}
          pod={pod}
          getForecastTaskRowOnClick={getForecastTaskRowOnClick}
        />
      )}
      <PodSummaryCardBiannual className={styles.borderBottom} pod={pod} />
      <PodSummaryCardMemberAllocation
        className={styles.borderBottom}
        pod={pod}
        employees={employees}
        onOpenEmployees={onOpenEmployees}
      />
      <PodSummaryCardWarnings pod={pod} />
    </Card>
  );
};
