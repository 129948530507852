import { CollectionMethod } from '@vestahealthcare/common/enums';
import {
  Address,
  BaseEnum,
  RPMVendorModel,
} from '@vestahealthcare/common/models';

import Api from './Api';

const SMART_METER_VENDOR_ID = 1;

export const fetchSmartMeterModels = async (): Promise<RPMVendorModel[]> => {
  const { rpmDeviceModels } = await Api.getv2(`rpm-devices/models`, {
    vendorId: SMART_METER_VENDOR_ID,
  });
  return rpmDeviceModels.items.map((x: any) => new RPMVendorModel(x));
};

export const fetchSmartMeterShippingMethods = async (): Promise<BaseEnum[]> => {
  const {
    shippingMethods: { items },
  } = await Api.getv2(
    `rpm-devices/orders/${SMART_METER_VENDOR_ID}/shipping-methods`,
  );
  return items.map((x: any) => new BaseEnum(x));
};

export type OrderDeviceParams = {
  address: Address;
  deviceId: number[];
  fullName: string;
  memberId: number;
  shippingMethod: string;
  taskId: number;
};

export const orderRPMDevices = async ({
  address,
  ...params
}: OrderDeviceParams) =>
  await Api.postv2JSON(
    `rpm-devices/orders`,
    {
      address: { ...address, state: address.state?.value },
      ...params,
    },
    { showToast: true },
  );

export type UpdateRPMOrderIdParams = {
  collectionMethod: CollectionMethod;
  newDeviceNumber: string;
  oldDeviceNumber: string;
};

export const updateRPMDeviceId = async (
  orderDeviceId: string,
  vendorDeviceId: string,
) =>
  await Api.patchv2JSON(
    `rpm-devices/order-devices/${orderDeviceId}`,
    {
      vendorDeviceId,
    },
    { showToast: true },
  );
