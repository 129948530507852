import {
  ReferralMapping,
  RosterFile,
  RosterFileHistory,
  RosterFileMetadata,
} from '@vestahealthcare/common/models';

import Api, { PaginationParams } from 'dash/src/services/Api';

import { PaginatedResponse } from '.';

export interface FetchParams {
  offset?: number;
  limit?: number;
  sort?: string;
  sourceOrgId?: number | number[];
  destinationOrgId?: number | number[];
  fileDateFrom?: string;
  fileDateTo?: string;
  transferDateFrom?: string;
  transferDateTo?: string;
  filename?: string;
}

interface RosterFileParams extends FetchParams {
  filename?: string;
  sort?: string;
}

export const fetchAllRosterFiles = async (
  params?: RosterFileParams,
): Promise<PaginatedResponse<RosterFile>> => {
  const {
    rosterFiles: { items, pagination },
  } = await Api.getv2(`roster-files`, params);
  return { items: items.map((i: any) => new RosterFile(i)), pagination };
};

export const downloadFileHistory = async (fileId: number, fileName: string) => {
  const blob: Blob = await Api.getv2Blob(`roster-files/${fileId}`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const fetchAllArchiveFiles = async (
  params?: RosterFileParams,
): Promise<PaginatedResponse<RosterFileMetadata>> => {
  const {
    archiveFiles: { items, pagination },
  } = await Api.getv2(`roster-files/archive`, params);
  return {
    items: items.map((i: any) => new RosterFileMetadata(i)),
    pagination,
  };
};

export const uploadReferralFile = async (
  referral: number,
  date: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('referralSourceId', referral.toString());
  formData.append('receivedAt', date);
  formData.append('file', file);
  await Api.postv2File('roster-files', formData);
};

export type RosterFileHistoryParams = PaginationParams & {
  organizationId?: number[];
  source?: string[];
  status?: string[];
  createdAtFrom?: string;
  createdAtTo?: string;
};

export const fetchRosterFileHistory = async (
  params?: RosterFileParams,
): Promise<PaginatedResponse<RosterFileHistory>> => {
  const {
    rawRosterFiles: { items, pagination },
  } = await Api.getv2(`raw-roster-files`, params);
  return {
    items: items.map((i: any) => new RosterFileHistory(i)),
    pagination,
  };
};

export const fetchAllRosterFileHistory = async (): Promise<
  RosterFileHistory[]
> => {
  return (await fetchRosterFileHistory({ limit: -1 }))?.items;
};

export const uploadRosterFileHistory = async (
  referralId: number,
  file: File,
) => {
  const formData = new FormData();
  formData.append('organizationId', referralId.toString());
  formData.append('file', file);
  await Api.postv2File('raw-roster-files', formData);
};

export const lookupRosterFileHistory = async (
  organizationId: number,
  fileId: number,
) => {
  await Api.postv2JSON('raw-roster-files/from-file-history', {
    organizationId,
    fileId,
  });
};
export const fetchRosterFileHistoryMappers = async (
  params: PaginationParams,
): Promise<PaginatedResponse<ReferralMapping>> => {
  const {
    referralMappings: { items, pagination },
  } = await Api.getv2(`raw-roster-files/referral-mappings`, params);
  return {
    items: items.map((x: any) => new ReferralMapping(x)),
    pagination,
  };
};

type RosterFileHistoryMapperTransformation = {
  action: string;
  param1?: string;
  param2?: string;
};

type RosterFileHistoryMapperRule = {
  sourceColumn?: string;
  defaultValue?: string;
  targetColumn?: string;
  operation?: RosterFileHistoryMapperTransformation;
};

export type CreateRosterFileHistoryMapper = {
  columns: RosterFileHistoryMapperRule[];
  fileId: number;
  name: string;
  organizationIds: number[];
  separator?: string;
};

export type UpdateRosterFileHistoryMapper = CreateRosterFileHistoryMapper & {
  active: boolean;
};

export const createRosterFileHistoryMapper = async (
  params: CreateRosterFileHistoryMapper,
) => {
  const { separator } = params;
  await Api.postv2JSON(`raw-roster-files/referral-mappings`, {
    ...params,
    separator: separator || ',',
  });
};

export const updateRosterFileHistoryMapper = async (
  id: number,
  params: CreateRosterFileHistoryMapper,
) => {
  const { separator } = params;
  await Api.putv2JSON(`raw-roster-files/referral-mappings/${id}`, {
    ...params,
    separator: separator || ',',
  });
};

export const fetchRosterRequiredColumns = async (): Promise<string[]> => {
  const { requiredColumns } = await Api.getv2(
    `raw-roster-files/required-columns`,
  );
  return requiredColumns;
};

export const readRosterFileHistoryHeaders = async (
  fileId: number,
  separator: string = ',',
): Promise<string[]> => {
  const { columns } = await Api.postv2JSON(`raw-roster-files/read-header`, {
    fileId,
    separator: separator || ',',
  });
  return columns;
};

export const readRosterFileHistoryHeaderValues = async (
  fileId: number,
  columnName: string,
  separator: string = ',',
): Promise<string[]> => {
  const { columnValues } = await Api.postv2JSON(
    `raw-roster-files/read-column-values`,
    {
      fileId,
      columnName,
      separator: separator || ',',
    },
  );
  return columnValues;
};

export const fetchReferralIngestionConfig = async (): Promise<{
  errorRate: number;
}> => {
  const {
    config: { errorRate },
  } = await Api.getv2(`raw-roster-files/referral-mappings/config`);
  return {
    errorRate,
  };
};

export type UpdateReferralIngestionConfigParams = {
  errorRate: number;
};

export const updateReferralIngestionConfig = async (
  params: UpdateReferralIngestionConfigParams,
) => {
  await Api.putv2JSON(`raw-roster-files/referral-mappings/config`, params);
};

export const processRawRosterReadyFile = async (
  rawArchiveId: number,
  applyFutureLead: boolean,
  applyTermByAbsence: boolean,
) => {
  const resp = await Api.postv2JSON(
    'raw-roster-files/process-dash-ready-file',
    {
      futureLeads: applyFutureLead,
      termByAbsence: applyTermByAbsence,
      rawArchiveId,
    },
  );
  return resp.asyncTask.uuid as string;
};
