import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SMS from 'dash/src/pages/Chats/SMS';
import Conversation from 'dash/src/pages/Chats/SMS/Conversation';
import ClinicalDashboardSidebar from 'dash/src/pages/Clinical/ClinicalDashboardSidebar';
import { EventsPage } from 'dash/src/pages/Clinical/Events';
import HealthReports from 'dash/src/pages/Clinical/HealthReports';
import Session from 'dash/src/services/SessionServices';

import { CCMDashboard } from './CCM';
import CCMTaskDashboard from './CCMTask';
import { ExternalDashboardSidebar } from './ExternalDashboardSidebar';
import { MembersDashboard } from './Members';
import { PodReportingDashboard } from './PodReporting';
import ProgramTaskManagement from './ProgramTaskManagement';
import { TOCDashboard } from './TOC';
import { TOCDetailPage } from './TOC/TOCDetail';
import { ClinicalTasks } from './Tasks';

export const ClinicalDashboard = () => {
  return (
    <div id="page-wrapper">
      {Session.actingUser.isExternal ? (
        <ExternalDashboardSidebar />
      ) : (
        <ClinicalDashboardSidebar />
      )}
      {!Session.actingUser.isExternal ? (
        <Switch>
          <Route path="/dashboard/tasks" component={ClinicalTasks} exact />
          <Route path="/dashboard/tasks/:taskId" component={ClinicalTasks} />
          <Route path="/dashboard/events" component={EventsPage} />
          <Route path="/dashboard/members" component={MembersDashboard} />
          <Route path="/dashboard/health-reports" component={HealthReports} />
          <Route path="/dashboard/chats/sms/:userPhone">
            <Conversation showHeader />
          </Route>
          <Route path="/dashboard/chats/sms">
            <SMS
              showHeader
              showFrom
              detailPath="/dashboard/chats/sms/:userPhone"
            />
          </Route>
          <Route path="/dashboard/ccm-tasks" component={CCMTaskDashboard} />
          <Route
            path="/dashboard/program-tasks-management"
            component={ProgramTaskManagement}
          />
          <Route path="/dashboard/ccm" component={CCMDashboard} />
          <Route
            path="/dashboard/pod-reporting"
            component={PodReportingDashboard}
          />
          <Route path="/dashboard/toc-list" component={TOCDashboard} />
          <Route path="/dashboard/skilled/:id">
            <TOCDetailPage type="SKILLED" />
          </Route>
          <Route path="/dashboard/toc/:id">
            <TOCDetailPage type="TOC" />
          </Route>
          <Redirect from="/dashboard/**" to="/dashboard/events" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/dashboard/ccm-tasks" component={CCMTaskDashboard} />
          <Route path="/dashboard/ccm" component={CCMDashboard} />
          <Redirect from="/dashboard/**" to="/dashboard/ccm" />
        </Switch>
      )}
    </div>
  );
};

export default ClinicalDashboard;
