import moment, { Moment } from 'moment';

import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

export const NUMBER_OF_MONTHS = 12;
export const NUMBER_OF_FUTURE_MONTHS = 4;
export const POD_METRICS_INIT_MONTH = moment('2025-04-01', DATE_FORMAT);

export const FUTURE_MONTHS = Array.from(
  Array(NUMBER_OF_FUTURE_MONTHS).keys(),
).map((i) =>
  moment()
    .add(i + 1, 'month')
    .endOf('month'),
);
export const PAST_MONTHS = Array.from(Array(NUMBER_OF_MONTHS).keys())
  .map((i) => {
    const monthToPush = moment().subtract(i, 'month').endOf('month');
    if (POD_METRICS_INIT_MONTH.isSameOrBefore(monthToPush)) return monthToPush;
    return false;
  })
  .filter(Boolean) as Moment[];
