import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  PaginationType,
  PodConfig,
  PodForecastTask,
  PodRule,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Colors,
  Fonts,
  Icon,
  IconButton,
  Panel,
  Select,
  Tooltip,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import {
  PodTaskBulkCommonParams,
  bulkOverridePodTasks,
  bulkReassignPodTasks,
  fetchPogramTasks,
} from 'dash/src/services/PodServices';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { FUTURE_MONTHS, PAST_MONTHS } from '../constants';
import { ForecastDetailTaskTable } from './ForecastDetailTaskTable';
import { PodTaskOverrideModal } from './PodTaskOverrideModal';
import { PodTaskReassignModal } from './PodTaskReassignModal';
import { PodTaskReviewModal } from './PodTaskReviewModal';

const uniq = (array: any[]) => [...new Set(array)];

const DEFAULT_PAGE_SIZE = 25;

const useStyles = makeStyles({
  actionItem: {
    fontSize: Fonts.fontSize,
    marginLeft: 0,
    width: '100%',
  },
  back: {
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  date: {
    width: '15rem',
  },
  disabledOption: {
    cursor: 'default',
    opacity: 0.38,
    width: '100%',
  },
  upperTitle: {
    alignSelf: 'flex-start',
    color: Colors.textGray,
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
    marginTop: '0.25rem',
    textTransform: 'uppercase',
  },
  title: {
    height: '2.9rem',
  },
  titleContainer: {
    alignItems: 'flex-start',
    display: 'grid',
    marginTop: '-1.5rem',

    '& > a': {
      height: '2rem',
    },
  },
  subtitleContainer: {},
});

type Props = {
  type: 'forecast' | 'summary';
};

type Params = {
  id: string;
  taskType: string;
};

export const PODsMetricsForecastDetail = ({ type }: Props) => {
  const { id: podId, taskType: taskDefinitionId } = useParams<Params>();
  const history = useHistory();
  const query = useQueryParams();
  const styles = useStyles();

  const monthValue = moment(query.get('month'), DATE_FORMAT);
  const podNameValue = query.get('podName');
  const taskNameValue = query.get('taskName');

  const [taskDefinitions, setTaskDefinitions] = useState<PodRule[]>([]);
  const taskDefinition = useMemo(
    () => taskDefinitions?.find(({ id }) => String(id) === taskDefinitionId),
    [taskDefinitionId, taskDefinitions],
  );

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [podConfigs, setPodConfigs] = useState<PodConfig[]>([]);
  const podConfig = useMemo(
    () => podConfigs?.find(({ id }) => String(id) === podId),
    [podId, podConfigs],
  );

  const [anchorActions, setAnchorActions] = useState<Element>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [month, setMonth] = useState<Moment>(
    (monthValue.isValid() ? monthValue : moment().add(1, 'month')).endOf(
      'month',
    ),
  );

  const [tasks, setTasks] = useState<PodForecastTask[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [openReassignModal, setOpenReassignModal] = useState<boolean>(false);
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>(false);
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);
  const [reassignEmloyee, setReassignEmployee] = useState<Employee>();
  const [overrideEmloyee, setOverrideEmployee] = useState<Employee>();
  const [overrideType, setOverrideType] = useState<PodRule>();

  const selectedTasks = selectAll
    ? (pagination?.total || 0) - selectedIds.length
    : selectedIds.length;

  const getInitialData = async () => {
    const [employees, items, pods] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getPodRules(),
      CacheServices.getPodConfigs(),
    ]);
    setEmployees(employees);
    setTaskDefinitions(items);
    setPodConfigs(pods);
  };

  const getTasks = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < tasks?.length) return;

    setLoading(true);
    try {
      const { items, pagination } = await fetchPogramTasks({
        month,
        taskDefinitionId: [taskDefinitionId].filter(Boolean),
        podId: [podId],
        offset,
        limit: pageSize,
      });

      if (page > 0) {
        setTasks([...tasks, ...items]);
      } else {
        setTasks(items);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const getPageTitle = () => {
    return (
      <div className={styles.titleContainer}>
        <div
          className={classNames('flex gap spaced', styles.subtitleContainer)}
        >
          <Button
            href="blank"
            className={styles.back}
            data-cy="sms-back-button"
            onClick={() => history.goBack()}
          >
            <Icon className="fa fa-arrow-left" color="green" size="sm" />
            &nbsp;{translate('global.back')}
          </Button>
          <span className={styles.upperTitle}>{taskNameValue}</span>
        </div>
        <div className={classNames('flex', styles.title)}>
          <span>
            {translate(
              `pods.metrics.forecastDetail.${
                type === 'summary' ? 'titleSummary' : 'title'
              }`,
              {
                pod: podNameValue || 'POD',
              },
            )}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    history.replace(
      `?podName=${podNameValue || ''}&taskName=${
        taskNameValue || ''
      }&month=${month.format(DATE_FORMAT)}`,
    );
  }, [month]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getTasks();
  }, [page, pageSize, month]);

  const showChecks = type === 'forecast' || month.isSame(moment(), 'month');

  return (
    <Panel>
      <Panel.Heading title={getPageTitle()} filtersV2>
        <Panel.Actions>
          <Select
            className={styles.date}
            data-cy="pod-reporting-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={type === 'forecast' ? FUTURE_MONTHS : PAST_MONTHS}
            onChange={(month?: Moment) => month && setMonth(month)}
            placeholder={translate('pods.metrics.forecastDetail.month')}
            value={month}
          />
          {showChecks && (
            <IconButton
              data-cy="pod-forecast-selection-actions"
              disabled={!selectedTasks}
              onClick={(evt) => {
                evt.stopPropagation();
                if (anchorActions) {
                  setAnchorActions(undefined);
                } else {
                  setAnchorActions(
                    evt.currentTarget?.parentElement?.parentElement ||
                      undefined,
                  );
                }
              }}
              tooltip={translate('pods.metrics.forecastDetail.actions')}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorActions}
            open={Boolean(anchorActions)}
            onClose={() => setAnchorActions(undefined)}
            PaperProps={{
              style: {
                marginTop: '0.5rem',
                minWidth: '17rem',
              },
            }}
          >
            <MenuItem
              key="pod-metrics-reassign"
              onClick={() => {
                setOverrideEmployee(undefined);
                setOverrideType(undefined);
                setReassignEmployee(undefined);
                setOpenReassignModal(true);
                setAnchorActions(undefined);
              }}
            >
              <span className={styles.actionItem}>
                {translate('pods.metrics.forecastDetail.actionReassign')}
              </span>
            </MenuItem>
            <MenuItem
              key="pod-metrics-reassign"
              disableTouchRipple={taskDefinition && !taskDefinition.flexible}
              disableRipple={taskDefinition && !taskDefinition.flexible}
              onClick={() => {
                setOverrideEmployee(undefined);
                setOverrideType(undefined);
                setReassignEmployee(undefined);
                setOpenOverrideModal(true);
                setAnchorActions(undefined);
              }}
            >
              {taskDefinition && !taskDefinition.flexible ? (
                <Tooltip
                  text={translate(
                    'pods.metrics.forecastDetail.actionOverrideTooltip',
                  )}
                  enterDelay={200}
                  followCursor
                >
                  <span
                    className={classNames(
                      styles.actionItem,
                      styles.disabledOption,
                    )}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    {translate('pods.metrics.forecastDetail.actionOverride')}
                  </span>
                </Tooltip>
              ) : (
                <span className={styles.actionItem}>
                  {translate('pods.metrics.forecastDetail.actionOverride')}
                </span>
              )}
            </MenuItem>
          </Menu>
        </Panel.Actions>
        {showChecks && (
          <Panel.Tabs className="flex spaced buttons">
            <span>
              {translate('pods.metrics.forecastDetail.selectedItems', {
                count: selectedTasks,
              })}
            </span>
            <div className="flex gap">
              <IconButton
                data-cy="pod-forecast-selection-button"
                disabled={selectAll}
                onClick={() => {
                  setSelectedIds([]);
                  setSelectAll(!selectAll);
                }}
                tooltip={translate('pods.metrics.forecastDetail.selection')}
              >
                <CustomSelectionIcon />
              </IconButton>
              <IconButton
                data-cy="pod-forecast-clear-button"
                disabled={!selectedIds?.length && !selectAll}
                onClick={() => {
                  setSelectedIds([]);
                  setSelectAll(false);
                }}
                tooltip={translate('global.clear')}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </Panel.Tabs>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <ForecastDetailTaskTable
          allChecked={
            !!(
              tasks?.length &&
              [...tasks]
                .slice(page * pageSize, (page + 1) * pageSize)
                .every((task) => selectAll !== selectedIds.includes(task.id))
            )
          }
          blacklist={selectAll}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          items={tasks}
          onChangePage={setPage}
          onChangePageSize={(ps) => {
            setPage(0);
            setPageSize(ps);
          }}
          onSelectAll={(checked) => {
            const ids =
              [...tasks]
                .slice(page * pageSize, (page + 1) * pageSize)
                .map((task) => task.id) || [];
            if (!ids.length) return;

            setSelectedIds(
              selectAll === checked
                ? uniq([...selectedIds, ...ids])
                : selectedIds.filter((id) => !ids.includes(id)),
            );
          }}
          onSelectTask={(task) => {
            const idx = selectedIds?.findIndex((id) => id === task.id);
            if (idx === -1) {
              selectedIds.push(task.id);
            } else {
              selectedIds.splice(idx, 1);
            }
            setSelectedIds([...selectedIds]);
          }}
          pagination={pagination}
          selectedIds={selectedIds}
          showChecks={showChecks}
        />
      </Panel.Body>
      {podConfig && taskDefinition && (
        <PodTaskReassignModal
          employees={employees}
          open={openReassignModal}
          podConfig={podConfig}
          taskType={taskDefinition}
          review
          onSubmit={(employee: Employee) => {
            setReassignEmployee(employee);
            setOpenReviewModal(true);
          }}
          onClose={() => setOpenReassignModal(false)}
        />
      )}
      {podConfig && taskDefinition?.flexible && (
        <PodTaskOverrideModal
          employees={employees}
          open={openOverrideModal}
          podConfig={podConfig}
          taskTypes={taskDefinitions?.filter(
            ({ id, flexible }) => id !== taskDefinition?.id && flexible,
          )}
          review
          onSubmit={(taskType: PodRule, employee: Employee) => {
            setOverrideEmployee(employee);
            setOverrideType(taskType);
            setOpenReviewModal(true);
          }}
          onClose={() => setOpenOverrideModal(false)}
        />
      )}
      {podConfig && taskDefinition && (
        <PodTaskReviewModal
          assignee={overrideType ? overrideEmloyee : reassignEmloyee}
          filters={{
            month,
            podConfig,
            taskDefinition,
          }}
          open={openReviewModal}
          selectAll={selectAll}
          selectedIds={selectedIds}
          taskType={overrideType}
          onSubmit={async () => {
            const commonFilters: PodTaskBulkCommonParams = {
              filter: selectAll
                ? {
                    excludedTaskIds: selectedIds,
                    month: month.format(DATE_FORMAT),
                    podIds: [podConfig.id],
                    taskDefinitionIds: [taskDefinition.id],
                  }
                : undefined,
              taskIds: selectAll ? undefined : selectedIds,
            };
            if (overrideType && overrideEmloyee) {
              await bulkOverridePodTasks({
                ...commonFilters,
                assigneeId: overrideEmloyee.id,
                taskDefinitionId: overrideType.id,
              });
            } else if (reassignEmloyee) {
              await bulkReassignPodTasks({
                ...commonFilters,
                assigneeId: reassignEmloyee.id,
              });
            }
            setOpenReassignModal(false);
            setOpenOverrideModal(false);
            setOpenReviewModal(false);
            setSelectedIds([]);
            setSelectAll(false);
            if (page !== 0) {
              setPage(0);
            } else {
              getTasks();
            }
          }}
          onClose={() => setOpenReviewModal(false)}
        />
      )}
    </Panel>
  );
};

export default PODsMetricsForecastDetail;
