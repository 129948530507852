import { ProgramTaskBase } from '@vestahealthcare/common/models';

import Api from './Api';

export const fetchProgramTask = async (
  id: number,
): Promise<ProgramTaskBase> => {
  const { task } = await Api.patchv2JSON(`program-tasks/${id}`);
  return new ProgramTaskBase(task);
};
