import React from 'react';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import { HouseHoldMember } from '@vestahealthcare/common/models';

import { Tooltip } from 'styleguide-v2';

export type Props = {
  householdMembers?: HouseHoldMember[];
};

export const HouseHoldMembersChip = ({ householdMembers }: Props) =>
  householdMembers?.length ? (
    <Tooltip
      text={
        <>
          {householdMembers.map(({ id, linkedMember: hhm, relationship }) => (
            <p
              className="no-margin white"
              key={`member-${hhm.id}-household-${id}`}
            >
              {relationship.description}: {hhm.fullName} (
              <a
                className="lighten"
                href={`#/patients/${hhm.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {hhm.id}
              </a>
              )
            </p>
          ))}
        </>
      }
    >
      <GroupOutlinedIcon
        color="primary"
        sx={{ height: '2rem', width: '2rem' }}
      />
    </Tooltip>
  ) : (
    <></>
  );
