import classNames from 'classnames';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';
import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  EncounterMethodOutcome,
  RPMAdherenceLevel,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PaginationType, RPMMember } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  BrandMemberIcon,
  CheckboxWithLabel,
  Chip,
  Colors,
  IconButton,
  StatusIndicator,
  Table,
  Tooltip,
} from 'styleguide-v2';

import { HouseHoldMembersChip } from 'dash/src/components/HouseHoldMembersChip';
import Session from 'dash/src/services/SessionServices';

interface Props {
  allChecked: boolean;
  blacklist: boolean;
  defaultPageSize?: number;
  defaultSort?: string;
  items: RPMMember[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickRow: (member: RPMMember) => void;
  onDefaultSort: () => void;
  onLogCall: (member: RPMMember) => void;
  onSelectMember: (member: RPMMember) => void;
  onSelectAll: (allChecked: boolean) => void;
  onSort: (field: string, direction: string) => void;
  selectedIds: number[];
}

const TABLE_HEADERS = {
  daysRemaining: translate('ccm.common.daysLeft'),
  language: translate('ccm.common.language'),
  lastAttempt: translate('ccm.common.lastAttempt'),
  lastReading: translate('ccm.common.lastReading'),
  member: translate('ccm.common.member'),
  minutes: translate('ccm.common.rpmMinutes'),
  rpmDaysWithReadings: translate('ccm.common.readings'),
  recentCycles: translate('ccm.common.recentCycles'),
  rpmStartDate: translate('ccm.common.rpmStartDate'),
  state: translate('ccm.common.state'),
};

const SORT_FIELDS = {
  [TABLE_HEADERS.daysRemaining]: 'currentCycleEndDate',
  [TABLE_HEADERS.language]: 'preferredLanguage',
  [TABLE_HEADERS.lastReading]: 'lastReadingDate',
  [TABLE_HEADERS.member]: 'memberId',
  [TABLE_HEADERS.minutes]: 'rpmMinutes',
  [TABLE_HEADERS.rpmStartDate]: 'rpmStartDate',
  [TABLE_HEADERS.rpmDaysWithReadings]: 'rpmDaysWithReadings',
};

const RPM_COLUMNS = (
  styles: ClassNameMap<
    | 'validMins'
    | 'minutes'
    | 'defaultMinutes'
    | 'successMinutes'
    | 'warningMinutes'
    | 'warning2Minutes'
    | 'errorMinutes'
    | 'small'
    | 'extraSmall'
    | 'rpmMinutes'
  >,
  {
    allChecked,
    blacklist,
    items,
    onLogCall,
    onSelectAll,
    onSelectMember,
    selectedIds,
  }: Props,
  sort?: string,
) => {
  const field = sort?.split(' ')[0];
  const direction = sort?.split(' ')[1] as 'asc' | 'desc';

  return [
    {
      headerName: (
        <CheckboxWithLabel
          checked={allChecked || blacklist}
          disabled={!items.length}
          onChange={() => onSelectAll(allChecked)}
        />
      ),
      component: (member: RPMMember) => (
        <CheckboxWithLabel
          checked={blacklist !== selectedIds.includes(member.memberId)}
          onChange={() => onSelectMember(member)}
        />
      ),
      width: 50,
    },
    {
      headerName: TABLE_HEADERS.member,
      component: (member: RPMMember) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            <HouseHoldMembersChip householdMembers={member?.householdMembers} />
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          {!Session.actingUser.showMemberLink ? (
            member?.memberId
          ) : (
            <a
              className={styles.small}
              href={`#/patients/${member?.memberId}`}
              rel="noreferrer"
              target="_blank"
              onClick={(evt) => evt.stopPropagation()}
            >
              {member?.memberId}
            </a>
          )}
          )
          {member?.language && (
            <>
              {' '}
              <Chip color="info" size="small" type="outlined">
                {member.language?.toString()}
              </Chip>
            </>
          )}
        </>
      ),
      width: 185,
    },
    {
      headerName: TABLE_HEADERS.state,
      component: ({ state }: RPMMember) => state?.value || EMPTY,
      width: 75,
    },
    {
      headerName: TABLE_HEADERS.rpmStartDate,
      component: ({ rpmStartDate }: RPMMember) => (
        <span className={styles.small}>
          {rpmStartDate?.format(DATE_FORMAT_SHORT) || EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.rpmStartDate]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.lastAttempt,
      component: ({ lastAttemptDate, lastAttemptOutcome }: RPMMember) => {
        const label = (
          <span className={styles.small}>
            {lastAttemptDate?.format(DATE_FORMAT_SHORT) || EMPTY}
          </span>
        );
        return lastAttemptOutcome ? (
          <StatusIndicator
            status={lastAttemptOutcome.toString()}
            date={lastAttemptDate}
            dateFormat={DATE_FORMAT_SHORT}
            color={EncounterMethodOutcome.getColor(lastAttemptOutcome)}
            size="small"
          />
        ) : (
          label
        );
      },
    },
    {
      headerName: TABLE_HEADERS.lastReading,
      component: ({ lastReadingDate }: RPMMember) => (
        <span className={styles.small}>
          {lastReadingDate?.format(DATE_FORMAT_SHORT) || EMPTY}
        </span>
      ),
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.lastReading]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.rpmDaysWithReadings,
      component: ({ rpmDaysWithReadings, rpmAdherence }: RPMMember) => {
        if (!rpmAdherence) return EMPTY;

        let className = styles.errorMinutes;
        if (rpmAdherence === RPMAdherenceLevel.MET) {
          className = styles.successMinutes;
        } else if (rpmAdherence === RPMAdherenceLevel.ON_TRACK) {
          className = styles.warningMinutes;
        } else if (rpmAdherence === RPMAdherenceLevel.MISSED) {
          className = styles.errorMinutes;
        } else if (rpmAdherence === RPMAdherenceLevel.AT_RISK) {
          className = styles.warning2Minutes;
        }
        return (
          <Tooltip text={rpmAdherence.toString()}>
            <span className={classNames(className, styles.minutes)}>
              {rpmDaysWithReadings}
            </span>
          </Tooltip>
        );
      },
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.rpmDaysWithReadings]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.daysRemaining,
      component: ({ daysRemaining }: RPMMember) =>
        daysRemaining === null ? EMPTY : daysRemaining,
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.daysRemaining]
          ? direction
          : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.minutes,
      component: ({ rpmMinutes }: RPMMember) => {
        if (rpmMinutes === null) return EMPTY;

        let className = styles.errorMinutes;
        if (rpmMinutes >= 20) {
          className = styles.successMinutes;
        } else if (rpmMinutes >= 20) {
          className = styles.warningMinutes;
        }
        return (
          <span className={classNames(className, styles.minutes)}>
            {rpmMinutes}
          </span>
        );
      },
      direction:
        field === SORT_FIELDS[TABLE_HEADERS.minutes] ? direction : undefined,
      sortable: true,
    },
    {
      headerName: TABLE_HEADERS.recentCycles,
      component: ({ pastCycles, recentMetCycles }: RPMMember) => {
        let className = styles.errorMinutes;
        const totalCycles = pastCycles > 3 ? 3 : pastCycles;
        if (totalCycles === 0) {
          className = styles.defaultMinutes;
        } else if (recentMetCycles === totalCycles) {
          className = styles.successMinutes;
        } else if (recentMetCycles === totalCycles - 1) {
          className = styles.warningMinutes;
        } else if (recentMetCycles === totalCycles - 2) {
          className = styles.warning2Minutes;
        }
        return (
          <span
            className={classNames(className, styles.minutes, styles.rpmMinutes)}
          >
            {`${recentMetCycles} / ${totalCycles}`}
          </span>
        );
      },
    },
    {
      headerName: ' ',
      component: (member: RPMMember) => (
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onLogCall(member);
          }}
          tooltip={translate('ccm.actions.logCall')}
        >
          <AddCallIcon />
        </IconButton>
      ),
      width: 20,
    },
  ];
};

const useStyles = makeStyles({
  validMins: {
    marginLeft: '0.5rem',
  },
  minutes: {
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontWeight: 500,
    minWidth: '3rem',
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
  },
  rpmMinutes: {
    '&&': {
      minWidth: '4.36rem',
    },
  },
  successMinutes: {
    color: Colors.textGreen,
    background: BackgroundColors.lightMint,
  },
  warningMinutes: {
    background: BackgroundColors.lightYellow,
  },
  warning2Minutes: {
    background: `${Colors.gold}45`,
    color: Colors.textRed2,
  },
  errorMinutes: {
    background: `${Colors.textRed}33`,
    color: Colors.textRed2,
  },
  defaultMinutes: {
    background: `${Colors.gray}33`,
    color: Colors.textBlack,
  },
  small: { fontSize: '0.875em', lineHeight: '16px' },
  extraSmall: { fontSize: '0.75em', lineHeight: '16px' },
});

export const RPMTable = (props: Props) => {
  const styles = useStyles();

  const columns = useMemo(() => RPM_COLUMNS(styles, props, props.defaultSort), [
    props.onSelectMember,
    props.defaultSort,
    styles,
  ]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.items,
        defaultPageSize: props.defaultPageSize,
        getRowBrand: ({ brand }: RPMMember) => brand,
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      onClickRow={props.onClickRow}
      onDefaultSort={props.onDefaultSort}
      onSort={({ headerName, direction }) => {
        if (SORT_FIELDS[headerName as string] && direction) {
          props.onSort(SORT_FIELDS[headerName as string], direction);
        }
      }}
    />
  );
};

export default RPMTable;
