import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  FilterSelectable,
  PodConfig,
  ProgramExtension,
  StoredFilter,
} from '@vestahealthcare/common/models';

import {
  Panel,
  Select,
  SelectProgramExtension,
  SelectProgramExtensionData,
} from 'styleguide-v2';
import { FilterGroup, FilterItem } from 'styleguide-v2/src/components/Panel';

import {
  FiltersVestaTeam,
  VestaTeamKey,
} from 'dash/src/components/filters/FiltersVestaTeam';
import { GetPodReportDataParams } from 'dash/src/services/CCMServices';
import { dataUtils } from 'dash/src/utils/dataUtils';
import { getFilterCount, getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetPodParams = keyof GetPodReportDataParams;

type Props = {
  data: {
    employees: SelectableInfo<Employee>[];
    languages: Selectable[];
    pods: SelectableInfo<PodConfig>[];
    programExtensions: SelectableInfo<ProgramExtension>[];
    status: Selectable[];
    worklistGroups: Selectable[];
  };
  disabledFilters?: KeyGetPodParams[];
  filters?: {
    [x in KeyGetPodParams]?: FilterItem;
  };
  loadingFilters?: boolean;
  open: boolean;
  onChange: (
    filters: {
      [x in KeyGetPodParams]?: FilterItem;
    },
  ) => void;
  onClose: () => void;
  onDeleteFilter: (filter: StoredFilter) => Promise<void>;
  onEditFilter: (filter: StoredFilter) => Promise<void>;
  onSaveFilter: (filter: StoredFilter) => Promise<void>;
  selectedPE?: number;
  storedFilters?: StoredFilter[];
};

const MEMBER_FILTERS: KeyGetPodParams[] = ['status', 'language'];

const PROGRAM_FILTERS: KeyGetPodParams[] = [
  'programExtensionFilter',
  'excludedProgramExtensionId',
  'worklistGroupId',
];

const VESTA_TEAM_FILTERS: VestaTeamKey[] = [
  'podId',
  'podManagerId',
  'npOwnerId',
  'rnOwnerId',
  'healthCoachId',
  'careCoordinatorId',
];

const OPS_FILTERS: KeyGetPodParams[] = ['isUnreachable'];

type FilterType = {
  [x in KeyGetPodParams]?: FilterItem;
};

const useStyles = makeStyles({
  container: {
    '&&': {
      marginTop: 0,
    },
  },
});

export const PodReportingFiltersModal = ({
  data,
  disabledFilters,
  filters,
  loadingFilters,
  open,
  onChange,
  onClose,
  onDeleteFilter,
  onEditFilter,
  onSaveFilter,
  selectedPE,
  storedFilters,
}: Props) => {
  const { showAdminPodsPage } = useFlags();
  const styles = useStyles();

  const [pendingFilters, setPendingFilters] = useState<FilterType>(
    filters || {},
  );

  useEffect(() => {
    setPendingFilters(filters || {});
  }, [filters]);

  return (
    <Panel.FilterModal
      className={styles.container}
      open={open}
      onClear={() => {
        onChange({
          isUnreachable: getFilterItem(
            translate('podReporting.filters.unreachable'),
            false,
          ),
        });
      }}
      onClose={onClose}
      onDeleteFilter={onDeleteFilter}
      onEditFilter={onEditFilter}
      onSaveFilter={onSaveFilter}
      onSubmit={() => onChange(pendingFilters)}
      size={400}
      storedFilters={storedFilters}
    >
      <FilterGroup
        title="Member Details"
        count={getFilterCount(pendingFilters, MEMBER_FILTERS)}
      >
        <Select
          data-cy="ccm-filter-modal-status"
          disabled={disabledFilters?.includes('status')}
          items={data.status}
          label={translate('podReporting.filters.memberStatus')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              status: getFilterItem(
                translate('podReporting.filters.memberStatus'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.status?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-language"
          disabled={disabledFilters?.includes('language')}
          grouped={({ preferred }) =>
            preferred
              ? translate('global.preferred')
              : translate('global.other')
          }
          items={data.languages.filter(({ disabled }) => !disabled)}
          label={translate('podReporting.filters.language')}
          limitTags={1}
          loading={loadingFilters}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              language: getFilterItem(
                translate('podReporting.filters.language'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.language?.value || []}
        />
      </FilterGroup>
      <FilterGroup
        title="Program Details"
        count={getFilterCount(pendingFilters, PROGRAM_FILTERS)}
      >
        <SelectProgramExtension
          items={data.programExtensions?.map(
            ({ info }) => info as ProgramExtension,
          )}
          itemsEnabled={selectedPE ? [selectedPE] : undefined}
          label={translate('podReporting.filters.programExtensions')}
          limitTags={1}
          onChange={(value) =>
            setPendingFilters({
              ...pendingFilters,
              programExtensionFilter: value?.length
                ? getFilterItem(
                    translate('podReporting.filters.programExtensions'),
                    value,
                    dataUtils.programExtensions.getFilterItem,
                  )
                : undefined,
            })
          }
          size="small"
          value={(pendingFilters?.programExtensionFilter
            ?.value as FilterSelectable<SelectProgramExtensionData>[])
            ?.map(({ value }) => value)
            ?.filter(
              (item) => !selectedPE || item.programExtension.id === selectedPE,
            )}
        />
        <Select
          data-cy="ccm-filter-modal-pe"
          disabled={disabledFilters?.includes('excludedProgramExtensionId')}
          getItemDisabled={({ value }: Selectable) => value === selectedPE}
          items={data.programExtensions}
          label={translate('podReporting.filters.excludedProgramExtensions')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              excludedProgramExtensionId: getFilterItem(
                translate('podReporting.filters.excludedProgramExtensions'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.excludedProgramExtensionId?.value || []}
        />
        <Select
          data-cy="ccm-filter-modal-care-plan-group"
          disabled={disabledFilters?.includes('worklistGroupId')}
          items={data.worklistGroups}
          label={translate('podReporting.filters.groups')}
          limitTags={1}
          multiple
          onChange={(values: Selectable[]) => {
            setPendingFilters({
              ...pendingFilters,
              worklistGroupId: getFilterItem(
                translate('podReporting.filters.groups'),
                values,
              ),
            });
          }}
          size="small"
          value={pendingFilters.worklistGroupId?.value || []}
        />
      </FilterGroup>
      <FiltersVestaTeam
        data={{ employees: data.employees, pods: data.pods }}
        disabledFilters={disabledFilters as VestaTeamKey[]}
        filters={pendingFilters}
        keys={VESTA_TEAM_FILTERS.filter(
          (item) => showAdminPodsPage || item !== 'podId',
        )}
        loading={loadingFilters}
        onChange={(updatedFilters) =>
          setPendingFilters({ ...pendingFilters, ...updatedFilters })
        }
      />
      <FilterGroup
        title="Operational Details"
        count={getFilterCount(pendingFilters, OPS_FILTERS)}
      >
        <Select.Choice
          data-cy="ccm-filter-modal-unreachable"
          disabled={disabledFilters?.includes('isUnreachable')}
          disableClearable
          label={translate('podReporting.filters.unreachable')}
          onChange={(value?: boolean) => {
            setPendingFilters({
              ...pendingFilters,
              isUnreachable: getFilterItem(
                translate('podReporting.filters.unreachable'),
                value,
              ),
            });
          }}
          size="small"
          value={
            (pendingFilters.isUnreachable?.value as FilterSelectable<any>)
              ?.value as boolean | undefined
          }
        />
      </FilterGroup>
    </Panel.FilterModal>
  );
};
