import moment from 'moment';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';

import { Enum, ProgramTaskStatus } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PaginationType,
  PodConfig,
  PodForecastTask,
  PodRule,
} from '@vestahealthcare/common/models';
import {
  EMPTY,
  NO_DAY_NO_TIME_DATE_FORMAT_DO,
} from '@vestahealthcare/common/utils/constants';

import {
  Actions,
  IconButton,
  StatusIndicator,
  Table,
  TableItem,
} from 'styleguide-v2';

import Session from 'dash/src/services/SessionServices';

import { MemberCCMTaskAction } from './types/MemberCCMTaskAction';

export interface TaskTableModel extends PodForecastTask, TableItem {
  actionLoading?: boolean;
}

interface Props {
  data: {
    pods: PodConfig[];
    taskDefinitions: PodRule[];
  };
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onLogCall: (task: PodForecastTask) => void;
  onTaskAction: (
    action: Selectable,
    event: PodForecastTask,
    index: number,
  ) => void;
  pagination?: PaginationType;
  tasks: PodForecastTask[];
}

const MEMBER_CCM_TASK_COLUMNS = ({ data, onLogCall, onTaskAction }: Props) => [
  {
    headerName: translate('memberCCMTasks.table.date'),
    component: ({ taskDate }: PodForecastTask) =>
      taskDate?.format(NO_DAY_NO_TIME_DATE_FORMAT_DO) || EMPTY,
    width: 150,
  },
  {
    headerName: translate('memberCCMTasks.table.type'),
    field: 'name',
  },
  {
    headerName: translate('memberCCMTasks.table.status'),
    component: ({ status }: PodForecastTask) =>
      status ? (
        <StatusIndicator
          status={status.toString()}
          color={ProgramTaskStatus.getColor(status)}
        />
      ) : (
        EMPTY
      ),
    width: 250,
  },
  {
    headerName: translate('memberCCMTasks.table.assignee'),
    field: 'assignee.fullName',
    width: 250,
  },
  {
    headerName: ' ',
    component: (task: PodForecastTask) =>
      task.taskDate?.isSameOrBefore(moment(), 'month') &&
      ProgramTaskStatus.asArrayOpened.includes(task.status) ? (
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            onLogCall(task);
          }}
          tooltip={translate('ccmTasks.table.logCall')}
        >
          <AddCallIcon />
        </IconButton>
      ) : (
        <div style={{ height: '3.3rem' }} />
      ),
    width: 50,
  },

  {
    headerName: ' ',
    component: (task: TaskTableModel, index: number) => {
      const actions = MemberCCMTaskAction.asActionsArray(
        task,
        data.taskDefinitions?.find(({ name }) => name === task.name),
        data.pods?.find(({ id }) => id === task.member?.pod?.id),
        Session.actingUser,
      );

      return actions?.length ? (
        <Actions
          actions={Enum.toSelectable(actions)}
          data-cy={`member-ccm-task-item-${index}-settings`}
          loading={task.actionLoading}
          onActionSelected={(action) => onTaskAction(action, task, index)}
        />
      ) : (
        <div style={{ height: '3.3rem' }} />
      );
    },
    width: 40,
  },
];

export const MemberCCMTasksTable = (props: Props) => {
  const columns = useMemo(() => MEMBER_CCM_TASK_COLUMNS(props), [
    props.onLogCall,
    props.data.taskDefinitions,
  ]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.tasks,
        defaultPageSize: 25,
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default MemberCCMTasksTable;
