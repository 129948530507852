import classnames from 'classnames';
import React, { ReactNode } from 'react';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { makeStyles } from '@mui/styles';

import { MemberRisk, MemberStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  EventMember,
  MemberView,
  Patient,
  PatientBase,
} from '@vestahealthcare/common/models';

import { BrandMemberIcon } from '../BrandMemberIcon';
import { Chip } from '../Chip';
import { MemberProgramExtensions } from '../MemberProgramExtensions';
import { Tooltip } from '../Tooltip';

type Props = {
  className?: string;
  'data-cy'?: string;
  icon?: ReactNode;
  link?: string;
  member: Patient | MemberView | PatientBase | EventMember;
  showBirthDate?: boolean;
  showHouseHoldMembers?: boolean;
  showLanguage?: boolean;
  showProgramExtensions?: boolean;
  showRiskLevel?: boolean;
  showState?: boolean;
  showStatus?: boolean;
  smallName?: boolean;
};

const useStyles = makeStyles({
  small: {
    fontSize: '0.875em',
  },
  bold: {
    fontWeight: 500,
  },
  icon: {
    '&&': {
      height: '2rem',
      width: '2rem',
    },
  },
  name: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.1rem 0.4rem',
  },
});

export const MemberInfo = ({
  className,
  'data-cy': dataCy,
  icon,
  link,
  member,
  showBirthDate,
  showHouseHoldMembers,
  showLanguage,
  showProgramExtensions,
  showRiskLevel,
  showState,
  showStatus,
  smallName,
}: Props) => {
  const styles = useStyles();

  return (
    <div className={className} data-ct={dataCy}>
      <p
        className={classnames(
          styles.name,
          smallName && styles.small,
          'no-margin',
        )}
      >
        <span className={styles.bold}>{member?.fullName}</span>{' '}
        {showHouseHoldMembers &&
          !!(member as Patient)?.householdMembers?.length && (
            <Tooltip
              text={
                <>
                  {(member as Patient)?.householdMembers?.map(
                    ({ id, linkedMember: hhm, relationship }) => (
                      <p
                        className="no-margin white"
                        key={`member-${hhm.id}-household-${id}`}
                      >
                        {relationship.description}: {hhm.fullName} (
                        <a
                          className="lighten"
                          href={`#/patients/${hhm.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {hhm.id}
                        </a>
                        )
                      </p>
                    ),
                  )}
                </>
              }
            >
              <GroupOutlinedIcon className={styles.icon} color="primary" />
            </Tooltip>
          )}
        {member.isBrandCareAtHome() && <BrandMemberIcon brand="careAtHome" />}
        {icon}
        {link ? (
          <a
            href={link}
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            ({member?.id})
          </a>
        ) : (
          <span>({member?.id})</span>
        )}
      </p>
      <div className={classnames('flex gap wrap')}>
        {showBirthDate && (member as any)?.birthDateFormatted && (
          <>{(member as any).birthDateFormatted} </>
        )}
        {showStatus && member?.status && (
          <Chip color={MemberStatus.COLOR_MAP.get(member.status)} size="small">
            {member.status?.toString()}
          </Chip>
        )}
        {showLanguage && (member as any)?.language && (
          <Chip color="info" size="small" type="outlined">
            {(member as any).language?.toString()}
          </Chip>
        )}
        {showState &&
          !showLanguage &&
          (showBirthDate || showStatus) &&
          (member as any)?.state &&
          '- '}
        {showState &&
          (member as any)?.state &&
          (member as any)?.state?.valueOf()}
        {showRiskLevel &&
          !(member instanceof PatientBase) &&
          !(member instanceof EventMember) &&
          member?.riskLevel && (
            <Chip
              color={MemberRisk.COLOR_MAP.get(member.riskLevel)}
              size="small"
              type="outlined"
            >
              {translate('common.riskLevel', {
                risk: member.riskLevel?.toString(),
              })}
            </Chip>
          )}
        {showProgramExtensions &&
          member instanceof EventMember &&
          member.programExtensions && (
            <MemberProgramExtensions
              hideTooltips
              memberId={member.id}
              programExtensions={member.programExtensions}
            />
          )}
      </div>
    </div>
  );
};

export default MemberInfo;
