export class PodMetricsSummaryTaskForecast {
  item = '';

  count = 0;

  employees?: PodMetricsSummaryTaskForecast[];

  constructor(name: string, data: number | PodMetricsSummaryTaskForecast[]) {
    this.item = name;
    if (!data || typeof data === 'number') {
      this.count = data || 0;
    } else {
      this.employees = data
        .map((item) => new PodMetricsSummaryTaskForecast(item.item, item.count))
        .sort(PodMetricsSummaryTaskForecast.sort);
      this.count =
        this.employees?.reduce((acc, { count }) => acc + (count || 0), 0) || 0;
    }
  }

  static sort(
    { item: a }: PodMetricsSummaryTaskForecast,
    { item: b }: PodMetricsSummaryTaskForecast,
  ) {
    return a.localeCompare(b);
  }
}

export default PodMetricsSummaryTaskForecast;
