import { make } from './Model';
import { ReferralMappingOperation } from './ReferralMappingOperation';

export class ReferralMappingColumn {
  id = 0;

  sourceColumn = '';

  targetColumn = '';

  defaultValue = '';

  operation: ReferralMappingOperation;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.operation = make(obj.operation, ReferralMappingOperation);
  }
}
