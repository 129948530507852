export const getTaskColor = (perc: number) => {
  if (perc < 50) return 'error';
  if (perc < 75) return 'warning';
  return 'success';
};

export const getProgramColor = (perc: number) => {
  if (perc < 50) return 'error';
  if (perc < 75) return 'warning';
  return 'success';
};

export const getWarningColor = (count: number) => {
  if (count === 0) return 'action';
  return 'error';
};
