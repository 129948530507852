export class PodReportingTask {
  id = 0;

  name = '';

  total = 0;

  completed = 0;

  notStarted = 0;

  oneAttempt = 0;

  pendingConfirmation = 0;

  twoAttempts = 0;

  threePlusAttempts = 0;

  transferred = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get attempted() {
    return this.oneAttempt + this.twoAttempts + this.threePlusAttempts;
  }
}

export default PodReportingTask;
