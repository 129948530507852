/* eslint-disable no-param-reassign */
import { Moment } from 'moment';

import { Employee } from '../Employee';
import { make, makeUnix } from '../Model';

export interface UpdateInfo {
  createdBy: Employee;

  updatedBy: Employee;

  createdAt: Moment;

  updatedAt: Moment;
}

export const initUpdateInfo = (o: UpdateInfo, obj: any) => {
  o.createdAt = makeUnix(obj.createdAt) as Moment;
  o.createdBy = make(obj.createdBy, Employee);
  o.updatedAt = makeUnix(obj.updatedAt) as Moment;
  o.updatedBy = make(obj.updatedBy, Employee);
};
