import moment from 'moment';

import { Enum, ProgramTaskStatus } from '@vestahealthcare/common/enums';
import {
  Employee,
  PodConfig,
  PodRule,
  ProgramTaskBase,
} from '@vestahealthcare/common/models';

export class MemberCCMTaskAction extends Enum {
  translationKey = 'memberCCMTaskAction';

  static asArray: MemberCCMTaskAction[] = [];

  static byKey: { [name: string]: MemberCCMTaskAction } = {};

  static readonly UPDATE_TASK_STATUS_ATTEMPTED = new MemberCCMTaskAction(
    'UPDATE_TASK_STATUS_ATTEMPTED',
  );

  static readonly CHANGE_ASSIGNEE = new MemberCCMTaskAction('CHANGE_ASSIGNEE');

  static readonly OVERRIDE_TASK_TYPE = new MemberCCMTaskAction(
    'OVERRIDE_TASK_TYPE',
  );

  static readonly FORCE_OVERRIDE = new MemberCCMTaskAction('FORCE_OVERRIDE');

  static asActionsArray(
    task: ProgramTaskBase,
    taskDefinition?: PodRule,
    pod?: PodConfig,
    actingUser?: Employee,
  ) {
    const actions = [] as MemberCCMTaskAction[];

    const isCurrentMonth = task?.taskDate?.isSameOrBefore(moment(), 'month');

    const isClosedStatus =
      task.status && !ProgramTaskStatus.asArrayOpened.includes(task.status);
    const isInProgress = ProgramTaskStatus.IN_PROGRESS === task.status;

    const isAssignedToUser =
      task?.assignee && actingUser?.id === task.assignee.id;
    const isSameRolInPod = pod?.isEmployeeInPod(
      actingUser,
      taskDefinition?.roles,
    );
    const canPerformAction = actingUser?.canPerformProgramTaskAction;

    if (isClosedStatus || !isCurrentMonth || actingUser?.isExternal)
      return actions;

    if (isAssignedToUser || isSameRolInPod || canPerformAction) {
      actions.push(MemberCCMTaskAction.CHANGE_ASSIGNEE);
    }

    if (!isInProgress && (isAssignedToUser || canPerformAction)) {
      actions.push(MemberCCMTaskAction.UPDATE_TASK_STATUS_ATTEMPTED);
    }

    if (taskDefinition?.flexible && canPerformAction) {
      actions.push(MemberCCMTaskAction.OVERRIDE_TASK_TYPE);
    }

    if (taskDefinition?.id !== PodRule.WELLNESS_CHECK_IN_ID) {
      actions.push(MemberCCMTaskAction.FORCE_OVERRIDE);
    }

    return actions;
  }

  constructor(public readonly value: string) {
    super();
    MemberCCMTaskAction.asArray.push(this);
    MemberCCMTaskAction.byKey[value] = this;
  }
}

export default MemberCCMTaskAction;
