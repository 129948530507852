import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  CareTeamMember,
  CareTeamMemberAide,
  CareTeamMemberFamily,
  CareTeamMemberOther,
  CareTeamMemberProvider,
  CareTeamMemberType,
  CareTeamPersonExtended,
  PatientBase,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';
import {
  Button,
  CareTeamPersonInfo,
  CollapsableSidebar,
  IconButton,
  Label,
  MemberInfo,
  PanelInfo,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import { SearchMember } from 'dash/src/components/SearchMember';
import { CacheServices } from 'dash/src/services';
import {
  CreateCareTeamMemberParams,
  UpdateCareTeamMemberParams,
} from 'dash/src/services/CareTeamServices';

import { AideFields } from './AideFields';
import { FamilyFields } from './FamilyFields';
import { OtherFields } from './OtherFields';
import { ProviderFields } from './ProviderFields';

type Props = {
  person?: CareTeamPersonExtended;
  member?: CareTeamMember;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    id: number,
    params: CreateCareTeamMemberParams | UpdateCareTeamMemberParams,
    changeRole: boolean,
  ) => Promise<void>;
  editMode: 'person' | 'member';
};

const useStyles = makeStyles({
  changeRoleButton: {
    alignSelf: 'end',
    marginBottom: '0.25rem',
  },
  deleteIcon: {
    '&&': {
      alignSelf: 'baseline',
      padding: '0.125rem',
    },
  },
  disabledSwitches: {
    alignSelf: 'end',
  },
  disabled: {
    opacity: 0.5,
  },
  marginBottom: {
    marginBottom: '1.95rem',
  },
  notes: {
    marginTop: '-1rem',
  },
  separator: {
    width: '100%',
    margin: '1rem 0 0',
  },
});

export const CareTeamMemberEditModal = ({
  editMode = 'person',
  member,
  onClose,
  onSubmit,
  open,
  person,
}: Props) => {
  const styles = useStyles();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const [types, setTypes] = useState<CareTeamMemberType[]>([]);
  const [subtypes, setSubtypes] = useState<BaseEnum[]>([]);

  const [currentMember, setMember] = useState<CareTeamMember>();
  const [type, setType] = useState<CareTeamMemberType>();
  const [subtype, setSubtype] = useState<BaseEnum>();
  const [subtypeOther, setSubtypeOther] = useState<string>();
  const [subtypeMultiple, setSubtypeMultiple] = useState<BaseEnum[]>([]);
  const [params, setParams] = useState<Partial<UpdateCareTeamMemberParams>>(
    {} as UpdateCareTeamMemberParams,
  );
  const [notes, setNotes] = useState<string | null>();
  const [emergencyContact, setEmergencyContact] = useState<boolean>(false);
  const [preferred, setPreferred] = useState<boolean>(false);
  const [phiAccess, setPhiAccess] = useState<boolean>(false);
  const [phiExceptions, setPhiExceptions] = useState<boolean>(false);
  const [healthCareProxy, setHealthCareProxy] = useState<boolean>(false);
  const [campaignEnabled, setCampaignEnabled] = useState<boolean>(false);
  const [hciNotifications, setHCINotifications] = useState<boolean>(false);
  const [
    healthCareProxyFileLocation,
    setHealthCareProxyFileLocation,
  ] = useState<string>();

  const [allowChangeRole, setAllowChangeRole] = useState<boolean>(false);

  const getInitialData = async () => {
    setLoading(true);
    const types = await CacheServices.getCareTeamMemberTypes();
    setTypes(types);
    setLoading(false);
  };

  const getSubTypes = async (type: CareTeamMemberType) => {
    let subtypes = [] as BaseEnum[];

    setLoading(true);

    switch (type?.id) {
      case CareTeamMemberType.AIDE:
        subtypes = await CacheServices.getCareTeamMemberAideTypes();
        break;
      case CareTeamMemberType.FAMILY_FRIEND:
        subtypes = await CacheServices.getCareTeamMemberFamilyRelationShips();
        break;
      case CareTeamMemberType.PROVIDER:
        subtypes = await CacheServices.getCareTeamMemberProviderSpecialities();
        break;
      case CareTeamMemberType.OTHER:
        subtypes = await CacheServices.getCareTeamMemberOtherRelationships();
        break;
      default:
        subtypes = [];
    }
    setSubtypes(subtypes);
    initSubtypeValues();
    setLoading(false);
  };

  const initSubtypeValues = () => {
    switch (type?.id) {
      case CareTeamMemberType.AIDE:
        setSubtype(member?.aideType);
        setSubtypeMultiple([]);
        break;
      case CareTeamMemberType.FAMILY_FRIEND:
        setSubtype(member?.relationship);
        setSubtypeMultiple([]);
        break;
      case CareTeamMemberType.OTHER:
        setSubtype(member?.otherRelationship);
        setSubtypeMultiple([]);
        setSubtypeOther(member?.relationshipOther);
        break;
      case CareTeamMemberType.PROVIDER:
        setSubtype(undefined);
        setSubtypeMultiple(
          member?.specialities?.map(({ specialityType }) => specialityType) ||
            [],
        );
        setSubtypeOther(
          member?.specialities?.find(
            ({ specialityType }) => specialityType.id === 'OTHER',
          )?.otherSpeciality || '',
        );
        break;
      default:
        setSubtype(undefined);
        setSubtypeMultiple([]);
    }
  };

  const reset = () => {
    setLoading(false);
    setSubmitted(false);
    setAllowChangeRole(false);

    setType(member?.type);

    setCampaignEnabled(!!member?.campaignEnabled);
    setEmergencyContact(!!member?.emergencyContact);
    setHCINotifications(!!member?.hciNotifications);
    setHealthCareProxy(!!member?.healthCareProxy);
    setHealthCareProxyFileLocation(member?.healthCareProxyFileLocation);
    setNotes(member?.notes);
    setParams({});
    setPhiAccess(!!member?.phiAccess);
    setPhiExceptions(!!member?.phiExceptions);
    setPreferred(!!member?.preferred);

    initSubtypeValues();
  };

  useEffect(() => {
    if (type) {
      getSubTypes(type);
      setSubtype(undefined);
      setSubtypeMultiple([]);
    }
  }, [type]);

  useEffect(() => {
    setMember(member);
  }, [member]);

  useEffect(() => {
    if (!currentMember) {
      setType(undefined);
      setSubtype(undefined);
      setSubtypeMultiple([]);
    }
  }, [currentMember]);

  useEffect(() => {
    if (open) {
      getInitialData();
      reset();
      if (!member) {
        setMember(undefined);
      }
    }
  }, [open]);

  const validate = () =>
    type &&
    (subtype || subtypeMultiple?.length) &&
    (!healthCareProxy || healthCareProxyFileLocation) &&
    (type?.id === CareTeamMemberType.AIDE ||
      subtype?.id !== 'OTHER' ||
      subtypeOther);

  const handleSave = async () => {
    setSubmitted(true);

    if (!currentMember?.member?.id) return;
    if (!validate()) {
      if (healthCareProxy && !healthCareProxyFileLocation) {
        new Toast({
          position: 'bottom-right',
          title: 'Validation error',
          body: translate('careTeam.errors.healthCareProxyFileLocation'),
          type: 'error',
        });
        return;
      }
      return;
    }

    setLoadingSave(true);

    const subtypeObj: any = {};

    switch (type?.id) {
      case CareTeamMemberType.AIDE:
        subtypeObj.aideTypeId = subtype;
        break;
      case CareTeamMemberType.FAMILY_FRIEND:
        subtypeObj.relationshipId = subtype;
        break;
      case CareTeamMemberType.PROVIDER:
        subtypeObj.specialityIds = subtypeMultiple;
        break;
      case CareTeamMemberType.OTHER:
        subtypeObj.otherRelationshipId = subtype;
        break;
      default:
    }

    await onSubmit(
      currentMember.member.id,
      {
        campaignEnabled,
        emergencyContact,
        hciNotifications,
        healthCareProxy,
        healthCareProxyFileLocation,
        notes,
        phiAccess,
        phiExceptions,
        preferred,
        type,
        ...subtypeObj,
        specialityOther:
          type?.id === CareTeamMemberType.PROVIDER ? subtypeOther : undefined,
        relationshipOther:
          type?.id === CareTeamMemberType.OTHER ? subtypeOther : undefined,
        ...params,
      },
      allowChangeRole && member?.type?.id !== type?.id,
    );
    setLoadingSave(false);
    onClose();
  };

  const updateParams = (newParams: Partial<any>) => {
    setParams({
      ...params,
      ...newParams,
    });
  };

  const ctpHasAlreadyMember =
    !member && person?.hasAlreadyLinkedMember(currentMember?.member);
  const disabledFields = !currentMember || ctpHasAlreadyMember;

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      size={650}
      title={
        <h2>
          {editMode === 'member' && !currentMember?.id
            ? translate('careTeam.modals.edit.titleAdd')
            : translate('careTeam.modals.edit.title', {
                name:
                  editMode === 'person'
                    ? person?.fullName
                    : member?.member?.fullName,
              })}
        </h2>
      }
    >
      <CollapsableSidebar.Body>
        {person && editMode === 'person' && (
          <>
            <Label>{translate(`careTeam.modals.edit.careTeamPerson`)}</Label>
            <CareTeamPersonInfo
              link={`#/care-team/${person.id}?firstName=${person.firstName}&lastName=${person.lastName}`}
              person={person}
            />
          </>
        )}

        {!member && editMode === 'member' && (
          <>
            <SearchMember
              disabled={!!currentMember}
              label={translate('careTeam.steps.linkAMemberBody')}
              onChange={(member: PatientBase) =>
                setMember({ member } as CareTeamMember)
              }
              placeholder={translate('careTeam.steps.searchMemberBy')}
            />
          </>
        )}

        {editMode === 'member' && (
          <>
            <Label>{translate(`careTeam.modals.edit.careTeamPerson`)}</Label>
            <div className="flex spaced">
              {currentMember ? (
                <MemberInfo
                  link={`#/patients/${member?.member?.id}`}
                  member={currentMember.member}
                />
              ) : (
                <p>{EMPTY}</p>
              )}
              {!member && currentMember && (
                <IconButton
                  className={styles.deleteIcon}
                  onClick={() => setMember(undefined)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </>
        )}

        {ctpHasAlreadyMember && (
          <PanelInfo
            type="warning"
            title={translate('careTeam.steps.memberAlreadyLinked')}
          />
        )}

        <div className="grid-wrapper fit">
          <Select
            className={classNames(
              'grid-span-6',
              disabledFields && styles.disabled,
            )}
            disabled={(!!member?.id && !allowChangeRole) || disabledFields}
            disableClearable
            getItemLabel={({ description }) => description}
            items={types}
            label={translate('careTeam.common.role')}
            onChange={setType}
            value={type}
            required={!member?.id || allowChangeRole}
          />

          {!disabledFields && !!member?.id && (
            <Button
              className={classNames('grid-span-6', styles.changeRoleButton)}
              color="secondary"
              disabled={allowChangeRole}
              onClick={() => setAllowChangeRole(true)}
            >
              {translate('careTeam.actions.changeRole')}
            </Button>
          )}

          {allowChangeRole && member?.type?.id !== type?.id && (
            <PanelInfo
              className="grid-span-12"
              type="warning"
              title={translate('careTeam.steps.warningChangeRole')}
            />
          )}

          {type?.id === CareTeamMemberType.PROVIDER ? (
            <Select
              className={classNames(
                'grid-span-6',
                disabledFields && styles.disabled,
              )}
              disabled={!type}
              error={submitted && !subtypeMultiple?.length}
              getItemLabel={({ description }) => description}
              items={subtypes}
              key="select-multiple"
              label={translate('careTeam.common.subrole')}
              limitTags={1}
              loading={loading}
              multiple
              onChange={setSubtypeMultiple}
              value={subtypeMultiple}
              required
            />
          ) : (
            <Select
              className={classNames(
                'grid-span-6',
                disabledFields && styles.disabled,
              )}
              disableClearable
              disabled={!type}
              error={submitted && !subtype}
              getItemLabel={({ description }) => description}
              key="select-single"
              items={subtypes}
              label={translate('careTeam.common.subrole')}
              loading={loading}
              onChange={setSubtype}
              value={subtype}
              required
            />
          )}

          {((type?.id === CareTeamMemberType.PROVIDER &&
            subtypeMultiple?.find(({ id }) => id === 'OTHER')) ||
            (type?.id === CareTeamMemberType.OTHER &&
              subtype?.id === 'OTHER')) && (
            <TextInput
              className="grid-span-12"
              error={submitted && !subtypeOther}
              label={translate(
                `careTeam.common.${
                  type?.id === CareTeamMemberType.PROVIDER
                    ? 'subroleOther'
                    : 'subtypeOther'
                }`,
              )}
              onChange={setSubtypeOther}
              value={subtypeOther}
              required
            />
          )}

          <br className="grid-span-12" />

          <TextArea
            className={classNames(
              'grid-span-12',
              disabledFields && styles.disabled,
              styles.notes,
            )}
            disabled={disabledFields}
            label={translate('careTeam.common.notes')}
            onChange={(notes) => setNotes(notes || null)}
            rows={3}
            value={notes || ''}
          />

          <SwitchGroup
            className={classNames(
              'grid-span-6',
              disabledFields && styles.disabled,
            )}
            disabled={disabledFields}
            items={[
              {
                label: translate('careTeam.permissions.emergencyContact'),
                checked: emergencyContact,
              },
              {
                label: translate('careTeam.permissions.hciNotifications'),
                checked: hciNotifications,
              },
              {
                label: translate('careTeam.permissions.preferredContact'),
                checked: preferred,
              },
              {
                label: translate('careTeam.permissions.healthCareProxy'),
                checked: healthCareProxy,
              },
              {
                label: translate('careTeam.permissions.phiAccess'),
                checked: phiAccess,
              },
            ]}
            onChange={(items) => {
              setEmergencyContact(!!items[0].checked);
              setHCINotifications(!!items[1].checked);
              setPreferred(!!items[2].checked);
              setHealthCareProxy(!!items[3].checked);
              if (!items[3].checked) {
                setHealthCareProxyFileLocation('');
              }
              setPhiAccess(!!items[4].checked);
              if (!items[4].checked) {
                setPhiExceptions(false);
              }
            }}
          />

          <div className={classNames('grid-span-6', styles.disabledSwitches)}>
            <TextInput
              className={classNames(
                (!healthCareProxy || disabledFields) && styles.disabled,
              )}
              disabled={!healthCareProxy || disabledFields}
              data-cy="care-team-steps-healthCareProxyFileLocation"
              error={
                submitted && healthCareProxy && !healthCareProxyFileLocation
              }
              label={translate(
                'careTeam.permissions.healthCareProxyFileLocation',
              )}
              onChange={setHealthCareProxyFileLocation}
              value={healthCareProxyFileLocation}
              size="small"
            />
            <SwitchGroup
              className={classNames(
                (!phiAccess || disabledFields) && styles.disabled,
              )}
              disabled={!phiAccess || disabledFields}
              items={[
                {
                  label: translate('careTeam.permissions.phiExceptions'),
                  checked: phiExceptions,
                  disabled: !phiAccess,
                },
              ]}
              onChange={(items) => {
                setPhiExceptions(!!items[0].checked);
              }}
            />
          </div>
          {type && member?.type?.id === type?.id && (
            <hr className={classNames('grid-span-12', styles.separator)} />
          )}
        </div>
        {member?.type?.id === type?.id && (
          <>
            {type?.id === CareTeamMemberType.AIDE && (
              <AideFields
                isAgency={subtype?.id === 'AGENCY'}
                isOther={subtype?.id === 'OTHER'}
                member={member as CareTeamMemberAide}
                onChange={updateParams}
              />
            )}
            {type?.id === CareTeamMemberType.FAMILY_FRIEND && (
              <FamilyFields
                member={member as CareTeamMemberFamily}
                onChange={updateParams}
              />
            )}

            {type?.id === CareTeamMemberType.PROVIDER && (
              <ProviderFields
                member={member as CareTeamMemberProvider}
                onChange={updateParams}
              />
            )}

            {type?.id === CareTeamMemberType.OTHER && (
              <OtherFields
                member={member as CareTeamMemberOther}
                onChange={updateParams}
              />
            )}
          </>
        )}
        <br />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="care-team-quick-add-back"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="care-team-quick-add-submit-and-open"
          disabled={disabledFields}
          loading={loadingSave}
          onClick={handleSave}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
