import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
  PodMetricsSummaryForecast,
  PodRule,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  LS_DASHBOARD_POD_METRICS_V2,
} from '@vestahealthcare/common/utils/constants';
import {
  LoadFilterOption,
  getFilterItem,
} from '@vestahealthcare/common/utils/filter';

import {
  FilterItem,
  FilterSelectable,
  Panel,
  Select,
  Tabs,
  ToggleButton,
} from 'styleguide-v2';

import {
  fetchPodMetricEmployees,
  fetchPodMetricsForecast,
  fetchPodMetricsSummary,
} from 'dash/src/services/PodServices';
import {
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';

import { CacheServices } from '../../../../services';
import { PODSummaryCard } from './components/PODSummaryCard';
import { PODSummaryTable } from './components/PODSummaryTable';
import { FUTURE_MONTHS, PAST_MONTHS } from './constants';

const POD_METRICS_SUMMARY = 'pod-metrics-summary';
const POD_METRICS_FORECAST = 'pod-metrics-forecast';
const POD_METRICS_TABS = [
  { value: POD_METRICS_SUMMARY, label: translate('pods.metrics.tabs.summary') },
  {
    value: POD_METRICS_FORECAST,
    label: translate('pods.metrics.tabs.forecast'),
  },
];

const POD_METRICS_CARD = 'pod-metrics-card';
const POD_METRICS_TABLE = 'pod-metrics-table';
const POD_METRICS_VIEW_OPTIONS = [
  {
    label: translate('pods.metrics.viewOptions.card'),
    value: POD_METRICS_CARD,
  },
  {
    label: translate('pods.metrics.viewOptions.table'),
    value: POD_METRICS_TABLE,
  },
];

const MIN_CARD_SIZE = 56;
const MAX_CARD_SIZE = Math.floor((MIN_CARD_SIZE * 3) / 2);

const useStyles = makeStyles({
  card: {
    flex: `1 1 ${MIN_CARD_SIZE}rem`,
    height: 'fit-content',
    minWidth: `${MIN_CARD_SIZE}rem`,
    maxWidth: `${MAX_CARD_SIZE}rem`,
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${MIN_CARD_SIZE}rem, 1fr))`,
    gridGap: '1rem',
  },
  date: {
    width: '15rem',
  },
});

export const PODsMetricsDashboard = () => {
  const history = useHistory();
  const styles = useStyles();

  const [taskDefinitions, setTaskDefinitions] = useState<PodRule[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [month, setMonth] = useState<Moment | undefined>(moment());
  const [selectedTab, setSelectedTab] = useState<string>();
  const [selectedViewOptions, setSelectedViewOptions] = useState<string>(
    POD_METRICS_CARD,
  );

  const [pods, setPods] = useState<
    PodMetricsSummary[] | PodMetricsSummaryForecast[]
  >([]);
  const [employees, setEmployees] = useState<{
    [x: string]: PodMetricsSummaryEmployee[];
  }>({});

  const months =
    selectedTab === POD_METRICS_SUMMARY ? PAST_MONTHS : FUTURE_MONTHS;

  const getInitialData = async () => {
    const items = await CacheServices.getPodRules();
    setTaskDefinitions(items);
  };

  const getSummaryData = async () => {
    if (!selectedTab || month?.isAfter(moment(), 'month')) return;

    setLoading(true);
    try {
      const items = await fetchPodMetricsSummary({ month });
      setPods(items);
    } finally {
      setLoading(false);
    }
  };

  const getForecastData = async () => {
    if (!selectedTab || month?.isSameOrBefore(moment(), 'month')) return;

    setLoading(true);
    try {
      const items = await fetchPodMetricsForecast({ month });
      setPods(items);
    } finally {
      setLoading(false);
    }
  };

  const getEmployees = async (id: number) => {
    const items = await fetchPodMetricEmployees(id);
    employees[id.toString()] = items;
    setEmployees({ ...employees });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    const { selectedTab } = loadNewFiltersFromStorage(
      LS_DASHBOARD_POD_METRICS_V2,
      {
        selectedTab: {},
      } as {
        [x in 'selectecTab']?: LoadFilterOption;
      },
    );
    const selectedTabValue = selectedTab?.value as FilterSelectable;
    setSelectedTab(selectedTabValue?.value || POD_METRICS_SUMMARY);
  }, []);

  useEffect(() => {
    saveNewFiltersToStorage(LS_DASHBOARD_POD_METRICS_V2, {
      selectedTab: getFilterItem('', selectedTab) as FilterItem,
    });
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === POD_METRICS_SUMMARY) {
      getSummaryData();
    } else {
      getForecastData();
    }
  }, [month]);

  useEffect(() => {
    if (!months?.find((m) => m.isSame(month, 'month'))) setMonth(months[0]);
  }, [months]);

  return (
    <Panel id="pods-config-section">
      <Panel.Heading title={translate('pods.metrics.title')}>
        <Panel.Actions>
          <Select
            className={styles.date}
            data-cy="pod-metrics-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={months}
            onChange={(month?: Moment) => {
              setMonth(month);
            }}
            placeholder={translate('pods.metrics.common.month')}
            value={month}
          />
        </Panel.Actions>
        <Panel.Tabs>
          <div className="flex space-between">
            <Tabs
              disabled={loading}
              items={POD_METRICS_TABS}
              onChange={setSelectedTab}
              value={selectedTab}
            />
            <ToggleButton
              items={POD_METRICS_VIEW_OPTIONS}
              onChange={setSelectedViewOptions}
              value={selectedViewOptions}
              size="small"
            />
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {selectedViewOptions === POD_METRICS_CARD && (
          <div className={styles.cardContainer}>
            {pods.map((pod) => (
              <PODSummaryCard
                className={styles.card}
                key={`pod-summary-${pod.id}`}
                pod={pod}
                employees={employees[pod.id.toString()] || []}
                onOpenEmployees={() => getEmployees(pod.id)}
                getTaskRowOnClick={(item) => {
                  const taskDefinition = taskDefinitions?.find(
                    ({ name }) => item.name === name,
                  );
                  if (taskDefinition)
                    return () =>
                      history.push(
                        `/admin/pods/metrics/summary/${pod.id}/${
                          taskDefinition.id
                        }?podName=${pod.name}&taskName=${
                          taskDefinition.name
                        }&month=${month?.format(DATE_FORMAT)}`,
                      );
                }}
                getForecastTaskRowOnClick={(item) => {
                  const taskDefinition = taskDefinitions?.find(
                    ({ name }) => item.name === name,
                  );
                  if (taskDefinition)
                    return () =>
                      history.push(
                        `/admin/pods/metrics/forecast/${pod.id}/${
                          taskDefinition.id
                        }?podName=${pod.name}&taskName=${
                          taskDefinition.name
                        }&month=${month?.format(DATE_FORMAT)}`,
                      );
                }}
              />
            ))}
          </div>
        )}
        {selectedViewOptions === POD_METRICS_TABLE && (
          <PODSummaryTable pods={pods} />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default PODsMetricsDashboard;
