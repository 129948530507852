import React, { useEffect, useState } from 'react';

import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberReferral, Organization } from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  Select,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';

export interface ReferralData {
  id?: number;
  organizationId: number;
  externalId: string;
  enrollmentStart: string;
  enrollmentEnd: string | null;
}

interface Props {
  referral: MemberReferral;
  onClose: () => void;
  onSubmit: (data: ReferralData) => Promise<boolean>;
  readonly: boolean;
}

const ReferralEditModal = ({
  referral,
  onClose,
  onSubmit,
  readonly,
}: Props) => {
  const [organizations, setOrganizations] = useState<Organization[]>([
    ...(referral.organization ? [referral.organization] : []),
  ]);

  const [organizationId, setOrganizationId] = useState(
    referral.organization?.id,
  );
  const [externalId, setExternalId] = useState(referral.externalId);
  const [enrollmentStart, setEnrollmentStart] = useState<string | undefined>(
    referral.enrollmentStart,
  );
  const [enrollmentEnd, setEnrollmentEnd] = useState<string | null>(
    referral.enrollmentEnd || null,
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const isEdit = !!referral.id;
  const isValid = () => !!externalId && !!organizationId && !!enrollmentStart;

  const doSubmit = async () => {
    setSubmitted(true);
    if (!isValid()) return;
    setLoadingSubmit(true);
    try {
      await onSubmit({
        id: referral.id,
        externalId,
        organizationId,
        enrollmentStart: enrollmentStart || referral.enrollmentStart,
        enrollmentEnd,
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const getTitle = ({
    referral,
    readonly,
  }: {
    referral: MemberReferral;
    readonly: boolean;
  }) => {
    let tag = 'memberReferrals.';
    if (readonly) {
      tag += 'showTitle';
    } else if (referral.id) {
      tag += 'editTitle';
    } else {
      tag += 'add';
    }
    return translate(tag);
  };

  useEffect(() => {
    CacheServices.getOrganizations().then((orgs) => {
      // we only want to pick the lowest level (with no children)
      setOrganizations(orgs.filter((item) => item.isActiveReferral));
    });
  }, []);

  return (
    <CollapsableSidebar
      onClose={onClose}
      open
      title={getTitle({ referral, readonly })}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper gap">
          {isEdit ? (
            <TextInput
              className="grid-span-12"
              data-cy="organization-input"
              label={translate('memberReferrals.source')}
              value={
                organizations.find(({ id }) => id === organizationId)?.name
              }
              readOnly
            />
          ) : (
            <Select
              data-cy="organization-input"
              className="grid-span-12"
              value={Organization.toSelectable(organizations).find(
                ({ value }) => value === organizationId,
              )}
              label={translate('memberReferrals.source')}
              onChange={(item: Selectable) =>
                setOrganizationId(item?.value as number)
              }
              items={Organization.toSelectable(organizations)}
              error={submitted && !organizationId}
              required
            />
          )}
          <TextInput
            data-cy="external-id-input"
            className="grid-span-12"
            value={externalId || ''}
            label={translate('memberReferrals.externalId')}
            onChange={(value) => setExternalId(value || '')}
            error={submitted && !externalId}
            disabled={readonly}
            required
          />
          <DateTimePicker
            className="grid-span-12"
            data-cy="enrollment-start-input"
            value={
              enrollmentStart ? moment(enrollmentStart).toDate() : undefined
            }
            label={translate('memberReferrals.enrollmentStart')}
            maxDate={enrollmentEnd ? moment(enrollmentEnd).toDate() : undefined}
            onChange={(value) =>
              setEnrollmentStart(
                value ? moment(value.getTime()).format(DATE_FORMAT) : undefined,
              )
            }
            error={submitted && !enrollmentStart}
            disabled={readonly}
            required
          />
          <DateTimePicker
            data-cy="enrollment-end-input"
            className="grid-span-12"
            value={enrollmentEnd ? moment(enrollmentEnd).toDate() : undefined}
            label={translate('memberReferrals.enrollmentEnd')}
            minDate={
              enrollmentStart ? moment(enrollmentStart).toDate() : undefined
            }
            onChange={(value) =>
              setEnrollmentEnd(
                value ? moment(value?.getTime()).format(DATE_FORMAT) : null,
              )
            }
            disabled={readonly}
          />
        </div>
      </CollapsableSidebar.Body>
      {doSubmit && (
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" onClick={onClose}>
            {translate('global.close')}
          </Button>
          {!readonly && (
            <Button
              color="secondary"
              loading={loadingSubmit}
              onClick={doSubmit}
            >
              {translate('global.save')}
            </Button>
          )}
        </CollapsableSidebar.Buttons>
      )}
    </CollapsableSidebar>
  );
};

export default ReferralEditModal;
