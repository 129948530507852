import { Moment } from 'moment';

import { makeMoment } from './Model';
import { ProgramTaskBase } from './ProgramTaskBase';

export class PodForecastTask extends ProgramTaskBase {
  lastAppointmentDate?: Moment;

  lastAppointmentType = '';

  lastCCMDate?: Moment;

  lastCCMReason = '';

  nextAppointmentDate?: Moment;

  nextAppointmentType = '';

  constructor(obj: any) {
    super({
      ...obj,
      id: obj.taskId,
      name: obj.taskName,
    });

    this.lastAppointmentDate = makeMoment(obj.lastAppointmentDate);
    this.lastAppointmentType = obj.lastAppointmentType;
    this.lastCCMDate = makeMoment(obj.lastCCMDate);
    this.lastCCMReason = obj.lastCCMReason;
    this.nextAppointmentDate = makeMoment(obj.nextAppointmentDate);
    this.nextAppointmentType = obj.nextAppointmentType;
  }
}

export default PodForecastTask;
