/* eslint-disable no-underscore-dangle */
import { Moment } from 'moment';

import { EmployeeRole, Language, States } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { Employee } from './Employee';
import { make, makeArray, makeEnums, makeUnix } from './Model';
import { Organization } from './Organization';
import { PodConfigEmployeeSlot } from './PodConfigEmployeeSlot';
import { PodConfigMinimal } from './PodConfigMinimal';
import { ProgramExtension } from './ProgramExtension';

type FilledEmployeeSlot = Omit<PodConfigEmployeeSlot, 'employee'> & {
  employee: Employee;
};
export class PodConfig extends PodConfigMinimal {
  active = true;

  careCoordinators: PodConfigEmployeeSlot[];

  engagementOwners: PodConfigEmployeeSlot[];

  healthCoaches: PodConfigEmployeeSlot[];

  languages: Language[];

  maxMembers = 0;

  npOwners: PodConfigEmployeeSlot[];

  podManager: Employee;

  programExtensions: ProgramExtension[];

  referralSources?: Organization[];

  rnOwners: PodConfigEmployeeSlot[];

  states: States[];

  updatedAt?: Moment;

  updatedBy?: Employee;

  _employeeSlots: PodConfigEmployeeSlot[];

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);

    obj.employeeSlots.sort(PodConfigEmployeeSlot.sort);
    this._employeeSlots =
      makeArray(obj.employeeSlots, PodConfigEmployeeSlot) || [];

    this.careCoordinators = this._employeeSlots?.filter(
      ({ role }: any) => role === EmployeeRole.MA.value,
    );
    this.engagementOwners = this._employeeSlots?.filter(
      ({ role }: any) => role === EmployeeRole.ENGAGEMENT_COORDINATOR.value,
    );
    this.healthCoaches = this._employeeSlots?.filter(
      ({ role }: any) => role === EmployeeRole.HEALTH_COACH.value,
    );
    this.languages = makeEnums(obj.languages, Language);
    this.npOwners = this._employeeSlots?.filter(
      ({ role }: any) => role === EmployeeRole.NP.value,
    );
    this.podManager = make(obj.manager, Employee);
    this.programExtensions = makeArray(obj.services, ProgramExtension);
    this.referralSources = makeArray(obj.referrals, Organization);
    this.rnOwners = this._employeeSlots?.filter(
      ({ role }: any) => role === EmployeeRole.RN.value,
    );
    this.states = makeEnums(obj.states, States);
    this.updatedAt = makeUnix(obj.updatedAt);
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  static toSelectable(items?: PodConfig[]) {
    return (
      items?.map(
        (pod) =>
          ({
            label: pod.name,
            value: pod.id,
            info: pod,
          } as SelectableInfo<PodConfig>),
      ) || []
    );
  }

  get assignedCareCoordinators(): FilledEmployeeSlot[] {
    return this.careCoordinators?.filter(
      (slot) => slot.employee?.id,
    ) as FilledEmployeeSlot[];
  }

  get assignedEngagementOwners(): FilledEmployeeSlot[] {
    return this.engagementOwners?.filter(
      (slot) => slot.employee?.id,
    ) as FilledEmployeeSlot[];
  }

  get assignedHealthCoaches(): FilledEmployeeSlot[] {
    return this.healthCoaches?.filter(
      (slot) => slot.employee?.id,
    ) as FilledEmployeeSlot[];
  }

  get assignedNPs(): FilledEmployeeSlot[] {
    return this.npOwners?.filter(
      (slot) => slot.employee?.id,
    ) as FilledEmployeeSlot[];
  }

  get assignedRNs(): FilledEmployeeSlot[] {
    return this.rnOwners?.filter(
      (slot) => slot.employee?.id,
    ) as FilledEmployeeSlot[];
  }

  isEmployeeInPod(user?: Employee, roles?: EmployeeRole[]): boolean {
    const roleIds = roles?.map(({ value }) => value);
    return !!this._employeeSlots?.find(
      ({ employee, role }) =>
        (!roleIds?.length || roleIds.includes(role || '')) &&
        employee &&
        user &&
        employee.id === user.id,
    );
  }
}

export default PodConfig;
