import { translate } from '@vestahealthcare/common/i18n';

export type Selectable = {
  label: string | React.ReactElement;
  value: string | number;
  disabled?: boolean;
};

export type SelectableInfo<T> = Selectable & {
  info?: T;
};

export class Enum {
  translationKey = '';

  selectable?: boolean;

  value = '';

  valueOf() {
    return this.value;
  }

  toString() {
    return this.translationKey
      ? translate(`enums.${this.translationKey}.${this.value}`)
      : this.valueOf();
  }

  get abbr() {
    return this.translationKey
      ? translate(`enums.${this.translationKey}.abbr.${this.value}`)
      : this.toString();
  }

  static toSelectable(arr: Enum[]): Selectable[] {
    return arr.map((enumValue) => ({
      value: enumValue.value,
      label: enumValue.toString(),
    }));
  }

  static sort({ label: a }: Selectable, { label: b }: Selectable) {
    return a.toString().localeCompare(b.toString());
  }

  static sortEnum(a: Enum, b: Enum) {
    return a.toString().localeCompare(b.toString());
  }
}

export default Enum;
