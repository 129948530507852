import { EmployeeRole } from '../enums/EmployeeRole';
import { SelectableInfo } from '../enums/Enum';
import { Employee } from './Employee';
import { make } from './Model';

export class PodConfigEmployeeSlot {
  id = 0;

  employee?: Employee;

  maxMembers = 0;

  role?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.employee = make(obj.employee, Employee);
  }

  static toSelectable(
    slots: PodConfigEmployeeSlot[],
    showRole?: boolean,
  ): SelectableInfo<PodConfigEmployeeSlot>[] {
    return slots.map((s) => ({
      value: s.employee?.id || -1,
      label: showRole
        ? `${s?.employee?.fullName} - ${
            EmployeeRole.byKey[s.role || '']?.toString() || s.role
          }`
        : s.employee?.fullName || '',
      info: s,
    }));
  }

  static sort(
    { employee: a }: PodConfigEmployeeSlot,
    { employee: b }: PodConfigEmployeeSlot,
  ) {
    if (!a) return 1;
    if (!b) return -1;
    return a.fullName.toString().localeCompare(b.fullName.toString());
  }
}

export default PodConfigEmployeeSlot;
