import classNames from 'classnames';
import React from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  PaginationType,
  PodForecastTask,
  PodRule,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { BrandMemberIcon, Chip, Colors, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { HouseHoldMembersChip } from 'dash/src/components/HouseHoldMembersChip';

interface Props {
  assignee: Employee;
  className?: string;
  items: PodForecastTask[];
  pagination?: PaginationType;
  taskType?: PodRule;
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
}

const Removed = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ textDecoration: 'line-through', color: Colors.textRed }}>
    {children}
  </span>
);

const Added = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ color: Colors.iconGreen }}>{children}</span>
);

const showTypeDiff = (taskId: number, currentType: string, newType: string) => {
  if (currentType === newType) return EMPTY;
  return (
    <>
      <Removed key={`task-review-${taskId}-employee-${currentType}`}>
        {currentType}
      </Removed>{' '}
      <Added key={`task-review-${taskId}-employee-${newType}`}>{newType}</Added>
    </>
  );
};

const showEmployeeDiff = (
  taskId: number,
  currentEmployee?: Employee,
  newEmployee?: Employee,
) => {
  if (!currentEmployee)
    return (
      <Added key={`task-review-${taskId}-employee-${newEmployee?.id}`}>
        {newEmployee?.fullName}
      </Added>
    );
  if (currentEmployee?.id === newEmployee?.id) return currentEmployee?.fullName;
  return (
    <>
      <Removed key={`task-review-${taskId}-employee-${currentEmployee?.id}`}>
        {currentEmployee?.fullName}
      </Removed>{' '}
      <Added key={`task-review-${taskId}-employee-${newEmployee?.id}`}>
        {newEmployee?.fullName}
      </Added>
    </>
  );
};

const BULK_TASK_REVIEW_COLUMNS = (
  { assignee, taskType }: Props,
  styles: ClassNameMap<'small'>,
) => {
  const columns: Column[] = [
    {
      headerName: translate('pods.metrics.forecastDetail.table.member'),
      component: ({ member }: PodForecastTask) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            <HouseHoldMembersChip householdMembers={member?.householdMembers} />
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          <a
            className={styles.small}
            href={`#/patients/${member?.id}`}
            rel="noreferrer"
            target="_blank"
            onClick={(evt) => evt.stopPropagation()}
          >
            {member?.id}
          </a>
          )
          {member?.language && (
            <>
              {' '}
              <Chip color="info" size="small" type="outlined">
                {member.language?.toString()}
              </Chip>
            </>
          )}
        </>
      ),
      width: 200,
    },
    {
      headerName: '. . .',
      component: () => '. . .',
      width: taskType ? 250 : 400,
    },
  ];

  if (taskType) {
    columns.push({
      headerName: translate('pods.metrics.forecastDetail.table.task'),
      component: ({ id, name }: PodForecastTask) =>
        showTypeDiff(id, name, taskType.name),
    });
  }

  columns.push({
    headerName: translate('pods.metrics.forecastDetail.table.assignee'),
    component: ({ id, assignee: oldAssignee }: PodForecastTask) =>
      showEmployeeDiff(id, oldAssignee, assignee),
  });

  return columns;
};

const useStyles = makeStyles({
  small: { fontSize: '0.875em', lineHeight: '16px' },
});

export const PodTaskReviewTable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      className={props.className}
      config={{
        columns: BULK_TASK_REVIEW_COLUMNS(props, styles),
        compact: true,
        data: props.items,
        defaultPageSize: props.pagination?.limit,
        pagination: props.pagination,
        paginationOptions: [10, 25, 50, 100],
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      whiteBackground
    />
  );
};

export default PodTaskReviewTable;
