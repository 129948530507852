import React from 'react';

import { BiometricsConfig } from '@vestahealthcare/common/models';

import { Colors } from 'styleguide-v2';

import { DotProps } from 'recharts';

export const BiometricsDot = (
  props: DotProps & { color: string; config: BiometricsConfig },
) => {
  const {
    cx = 0,
    cy = 0,
    color,
    config,
    // @ts-ignore: value prop it's not included in definition
    payload,
    // @ts-ignore: value prop it's not included in definition
    value,
  } = props;
  const { upperLimit, lowerLimit } = config;
  console.log(payload?.abnormal);
  if (
    (upperLimit && value > upperLimit) ||
    (lowerLimit && value < lowerLimit) ||
    payload?.abnormal
  ) {
    return (
      <svg
        x={cx}
        y={cy - 10}
        width={50}
        height={50}
        fill="white"
        viewBox="0 0 496 300"
      >
        <circle cx="0" cy="0" r="30" stroke={Colors.orange} strokeWidth="25" />
      </svg>
    );
  }

  return (
    <svg
      x={cx}
      y={cy - 10}
      width={50}
      height={50}
      fill="white"
      viewBox="0 0 496 300"
    >
      <circle cx="0" cy="0" r="30" stroke={color} strokeWidth="25" />
    </svg>
  );
};
