import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import {
  EmployeeRole,
  PodRuleConfirmationType,
  TimeUnit,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PodRule,
  PodRuleTrigger,
  ProgramExtension,
} from '@vestahealthcare/common/models';
import { getDiff } from '@vestahealthcare/common/utils/api';

import {
  BackgroundColors,
  Button,
  CollapsableSidebar,
  Colors,
  IconButton,
  Label,
  Select,
  SwitchGroup,
  TextArea,
  TextInput,
} from 'styleguide-v2';

import {
  CreatePodRuleParams,
  UpdatePodRuleParams,
} from 'dash/src/services/PodServices';

interface Props {
  data: {
    programExtensions: Selectable[];
    rules: PodRule[];
    triggers: PodRuleTrigger[];
  };
  loadingData: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    params: CreatePodRuleParams | UpdatePodRuleParams,
    id?: number,
  ) => Promise<void>;
  rule?: PodRule;
}

const useStyles = makeStyles({
  delayInput: {
    borderLeft: `2px solid ${BackgroundColors.grayLine}`,
    paddingLeft: '1rem',
  },
  taskTriggercontainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  taskTriggerIcon: {
    margin: 'auto',
  },
  taskTriggerAddIcon: {
    marginTop: '-0.25rem',
  },
  taskTriggerAddLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
});

const TRIGGER_STATUSES = [
  { label: translate('common.any'), value: null },
  { label: translate('common.completed'), value: true },
  { label: translate('common.incompleted'), value: false },
];

export const PodRuleEditModal = ({
  data,
  loadingData,
  open,
  onClose,
  onSubmit,
  rule,
}: Props) => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorDuplicateTriggers, setErrorDuplicateTriggers] = useState<
    string[]
  >([]);

  const [active, setActive] = useState<boolean>(false);
  const [additional, setAdditional] = useState<boolean>(false);
  const [requireConfirmation, setRequireConfirmation] = useState<boolean>(
    false,
  );
  const [rolloverAfterPeriod, setRolloverAfterPeriod] = useState<boolean>(
    false,
  );
  const [defaultValue, setDefault] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [flexible, setFlexible] = useState<boolean>(false);
  const [maxAttempts, setMaxAttemps] = useState<number>();
  const [name, setName] = useState<string>('');
  const [programExtension, setProgramExtension] = useState<Selectable>();
  const [roles, setRoles] = useState<Selectable[]>([]);
  const [spacingDays, setSpacingDays] = useState<number>();
  const [triggers, setTriggers] = useState<PodRuleTrigger[]>([]);
  const [rules, setRules] = useState<PodRule[]>([]);
  const [confirmationType, setConfirmationType] = useState<
    PodRuleConfirmationType
  >();
  const [confirmationTypeReason, setConfirmationTypeReason] = useState<
    string
  >();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSubmitted(false);

      setActive(!!rule?.active);
      setAdditional(!!rule?.additional);
      setRequireConfirmation(!!rule?.requireConfirmation);
      setConfirmationType(rule?.confirmationType);
      setConfirmationTypeReason(rule?.confirmationTypeReason);
      setRolloverAfterPeriod(!!rule?.rolloverAfterPeriod);
      setDefault(!!rule?.default);
      setDescription(rule?.description);
      setFlexible(!!rule?.flexible);
      setMaxAttemps(rule?.maxAttempts);
      setName(rule?.name || '');
      setProgramExtension(
        rule?.programExtension
          ? ProgramExtension.toSelectable([rule?.programExtension])[0]
          : undefined,
      );
      setRoles(Enum.toSelectable(rule?.roles || []));
      setRules(rule?.overriddenBy || []);
      setSpacingDays(rule?.spacing);
      setTriggers(
        rule?.triggers?.length
          ? rule.triggers.map(
              (i) => new PodRuleTrigger({ ...i, delayType: i.delayType.value }),
            )
          : [new PodRuleTrigger({})],
      );
    }
  }, [open]);

  const checkDuplicateTriggers = (triggers?: PodRuleTrigger[]) => {
    let result = true;
    const duplicates = [] as string[];
    triggers?.forEach((trigger, idx) => {
      const duplicate = triggers.find(
        ({ id, delayType, delayValue, triggerSuccess }, checkIdx) =>
          id === trigger.id &&
          delayType === trigger.delayType &&
          delayValue === trigger.delayValue &&
          triggerSuccess === trigger.triggerSuccess &&
          idx !== checkIdx,
      );
      if (duplicate) {
        duplicates.push(duplicate.description);
        result = false;
      }
    });
    setErrorDuplicateTriggers(duplicates);
    return result;
  };

  const validate = () =>
    name &&
    roles?.length &&
    maxAttempts !== undefined &&
    !triggers?.find((item) => !item?.id) &&
    checkDuplicateTriggers(triggers) &&
    (!requireConfirmation || (confirmationType && confirmationTypeReason));

  const doSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      setLoading(true);

      try {
        await onSubmit(
          getDiff<CreatePodRuleParams | UpdatePodRuleParams>(
            rule
              ? {
                  ...((rule as unknown) as
                    | CreatePodRuleParams
                    | UpdatePodRuleParams),
                  overriddenBy: rule?.overriddenBy?.map(({ id }) => id),
                  programExtensionId: rule?.programExtension?.id,
                  confirmationType: rule?.confirmationType?.value,
                  roles: rule?.roles?.map(({ value }) => value),
                  spacing: rule?.spacing,
                  triggers: rule?.triggers?.map(
                    ({
                      description,
                      delayValue,
                      delayType,
                      triggerSuccess,
                    }) => ({
                      triggerName: description,
                      delayValue,
                      delayType: delayType?.value,
                      triggerSuccess,
                    }),
                  ),
                }
              : {},
            {
              active,
              additional,
              confirmationType: confirmationType?.value,
              confirmationTypeReason,
              default: defaultValue,
              description,
              flexible,
              maxAttempts,
              name,
              overriddenBy: rules?.map(({ id }) => id),
              programExtensionId: programExtension?.value as number,
              requireConfirmation,
              rolloverAfterPeriod,
              roles: roles?.map(({ value }) => value as string),
              spacing: spacingDays,
              triggers: triggers
                ?.filter((item) => item.id)
                ?.map(
                  ({
                    id,
                    description,
                    delayValue,
                    delayType,
                    triggerSuccess,
                  }) => ({
                    triggerName: id !== -1 ? description : name,
                    delayValue,
                    delayType: delayType?.value,
                    triggerSuccess,
                  }),
                ),
            },
          ),
          rule?.id,
        );
        onClose();
      } finally {
        setSubmitted(false);
        setLoading(false);
      }
    }
  };

  const getTriggers = () => {
    let result = data.triggers;

    if (!data.triggers.find(({ description }) => description === rule?.name)) {
      result = [
        new PodRuleTrigger({
          id: -1,
          description: translate('common.self'),
        }),
        ...result,
      ];
    }

    return result;
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>
          {translate(`pods.rules.modal.title${rule ? 'Edit' : 'Add'}`, {
            rule: rule?.name || 'POD Rule',
          })}
        </h2>
      }
      size={600}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper fit">
          <TextInput
            className="grid-span-12"
            error={submitted && !name}
            label={translate('pods.rules.common.name')}
            onChange={(value) => {
              const safeValue = value
                ?.trim()
                .split(' ')
                .filter(Boolean)
                .join(' ');
              setName(safeValue || '');
            }}
            value={name}
            required
          />
          <TextArea
            className="grid-span-12"
            label={translate('pods.rules.common.description')}
            maxRows={3}
            onChange={setDescription}
            value={description || ''}
          />
          <Select
            className="grid-span-12"
            items={data.programExtensions}
            label={translate('pods.rules.common.programExtension')}
            loading={loadingData}
            onChange={setProgramExtension}
            value={programExtension}
          />
          <Select
            className="grid-span-12"
            disableClearable
            error={submitted && !roles?.length}
            items={EmployeeRole.toSelectable()}
            label={translate('pods.rules.common.roles')}
            multiple
            onChange={setRoles}
            value={roles}
            required
          />

          <div
            className={classNames('grid-span-12', styles.taskTriggercontainer)}
          >
            <Label className="flex middle">
              {translate('pods.rules.common.triggers')}
              {' * '}
              <a
                className={classNames('flex middle', styles.taskTriggerAddLink)}
                onClick={(evt) => {
                  evt.preventDefault();
                  setTriggers([...(triggers || []), new PodRuleTrigger({})]);
                }}
              >
                <AddIcon
                  className={styles.taskTriggerAddIcon}
                  fontSize="small"
                />
                {translate('pods.rules.modal.addAnotherTrigger')}
              </a>
            </Label>
            <div className="grid-wrapper fit">
              {triggers.map((item, idx) => (
                <Fragment key={`pod-rule-trigger-${idx}`}>
                  <Select
                    className="grid-span-11"
                    disableClearable
                    error={
                      (submitted &&
                        !item.id &&
                        item.triggerSuccess === undefined) ||
                      (errorDuplicateTriggers.includes(item.description) &&
                        translate('pods.rules.modal.errorDuplicateTrigger'))
                    }
                    getItemLabel={(item: PodRuleTrigger) => item.toString()}
                    items={getTriggers()}
                    loading={loadingData}
                    onChange={(newValue: PodRuleTrigger) => {
                      triggers[idx].id = newValue.id;
                      triggers[idx].description = newValue.description;
                      if (triggers[idx].triggerSuccess === undefined) {
                        triggers[idx].triggerSuccess =
                          TRIGGER_STATUSES[0].value;
                      }
                      if (triggers[idx].delayType === undefined) {
                        triggers[idx].delayType = TimeUnit.DAYS;
                      }
                      setTriggers([...triggers]);
                    }}
                    placeholder={translate('pods.rules.common.trigger')}
                    value={item}
                  />
                  <IconButton
                    className={classNames(
                      styles.taskTriggerIcon,
                      'grid-span-1',
                    )}
                    disabled={
                      triggers &&
                      triggers.length < 2 &&
                      !(
                        triggers[0]?.id ||
                        triggers[0]?.triggerSuccess !== undefined ||
                        triggers[0]?.delayValue ||
                        triggers[0]?.delayType
                      )
                    }
                    size="small"
                    onClick={() => {
                      if (triggers && triggers.length < 2) {
                        setTriggers([new PodRuleTrigger({})]);
                      } else {
                        triggers?.splice(idx, 1);
                        setTriggers([...triggers]);
                      }
                    }}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </IconButton>
                  <Select
                    className="grid-span-5"
                    disableClearable
                    items={TRIGGER_STATUSES}
                    loading={loadingData}
                    onChange={(newValue: { value: boolean | null }) => {
                      triggers[idx].triggerSuccess = newValue?.value;
                      setTriggers([...triggers]);
                    }}
                    placeholder={translate('pods.rules.common.status')}
                    value={TRIGGER_STATUSES.find(
                      ({ value }) => value === item.triggerSuccess,
                    )}
                  />
                  <div className={classNames('grid-span-3', styles.delayInput)}>
                    <TextInput
                      onChange={(delay?: string) => {
                        const number = parseInt(delay || '', 10);

                        triggers[idx].delayValue =
                          delay && !Number.isNaN(number) ? number : 0;

                        setTriggers([...triggers]);
                      }}
                      placeholder={translate('pods.rules.common.delay')}
                      value={item.delayValue ? item.delayValue : ''}
                    />
                  </div>
                  <Select
                    className="grid-span-4"
                    disableClearable
                    getItemLabel={(item: TimeUnit) => item.toString()}
                    items={[TimeUnit.DAYS, TimeUnit.MONTHS]}
                    onChange={(timeUnit: TimeUnit) => {
                      triggers[idx].delayType = timeUnit;
                      setTriggers([...triggers]);
                    }}
                    placeholder={translate('pods.rules.common.timeUnit')}
                    value={item.delayType}
                  />
                  {idx !== triggers.length - 1 && (
                    <hr className="grid-span-12 separator" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>

          <TextInput
            className="grid-span-6"
            error={submitted && !maxAttempts}
            label={translate('pods.rules.common.maxAttempts')}
            onChange={(newValue) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setMaxAttemps(isNaN(number) ? undefined : number);
            }}
            value={maxAttempts}
            type="numeric"
            required
          />
          <TextInput
            className="grid-span-6"
            label={translate('pods.rules.common.spacingDays')}
            onChange={(newValue) => {
              const number =
                newValue !== undefined ? parseInt(newValue, 10) : NaN;
              setSpacingDays(isNaN(number) ? undefined : number);
            }}
            value={spacingDays}
            type="numeric"
          />

          <Select
            className="grid-span-12"
            getItemLabel={(rule: PodRule) => rule.name}
            items={data.rules?.filter(({ id }) => id !== rule?.id)}
            label={translate('pods.rules.common.overriddenBy')}
            multiple
            onChange={setRules}
            value={rules}
          />

          <div className="grid-span-12 grid-wrapper no-gap">
            <SwitchGroup
              className="grid-span-6"
              items={[
                {
                  label: translate('pods.rules.common.additional'),
                  checked: additional,
                },
                {
                  label: translate('pods.rules.common.rolloverAfterPeriod'),
                  checked: rolloverAfterPeriod,
                },
                {
                  label: translate('pods.rules.common.requireConfirmation'),
                  checked: requireConfirmation,
                },
              ]}
              onChange={(items) => {
                setAdditional(!!items[0].checked);
                setRolloverAfterPeriod(!!items[1].checked);
                setRequireConfirmation(!!items[2].checked);
              }}
            />
            <SwitchGroup
              className="grid-span-6"
              items={[
                {
                  label: translate('pods.rules.common.default'),
                  checked: defaultValue,
                },
                {
                  label: translate('pods.rules.common.flexible'),
                  checked: flexible,
                },
              ]}
              onChange={(items) => {
                setDefault(!!items[0].checked);
                setFlexible(!!items[1].checked);
              }}
            />
          </div>
          {requireConfirmation && (
            <>
              <Select
                className="grid-span-6"
                disableClearable
                error={submitted && !confirmationType}
                getItemLabel={(item: PodRuleConfirmationType) =>
                  item.toString()
                }
                items={PodRuleConfirmationType.asArray}
                label={translate('pods.rules.common.requireConfirmationType')}
                onChange={setConfirmationType}
                value={confirmationType}
                size="xs"
                required
              />
              {confirmationType && (
                <TextInput
                  className="grid-span-6"
                  disabled={!confirmationType}
                  error={submitted && !confirmationTypeReason}
                  label={confirmationType?.toString() || '  '}
                  onChange={setConfirmationTypeReason}
                  value={confirmationTypeReason}
                  size="xs"
                  required
                />
              )}
            </>
          )}
          {rule && (
            <SwitchGroup
              className="grid-span-12"
              items={[
                {
                  label: translate('pods.rules.common.active'),
                  checked: active,
                },
              ]}
              onChange={(items) => setActive(!!items[0].checked)}
            />
          )}
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="add-pod-config-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="save-pod-config-submit"
          loading={loading}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default PodRuleEditModal;
