import moment, { Moment } from 'moment';

import {
  CCMTask,
  PodConfig,
  PodForecastTask,
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
  PodMetricsSummaryForecast,
  PodRule,
  PodRuleTrigger,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, {
  PaginatedResponse,
  PaginatedResponseWithTime,
  PaginationParams,
} from 'dash/src/services/Api';

export type GetPodConfigParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodConfigs = async (
  params?: GetPodConfigParams,
): Promise<PaginatedResponse<PodConfig>> => {
  const {
    pods: { items, pagination },
  } = await Api.getv2('pods', {
    ...(params || {}),
    sort: params?.sort || 'name asc',
  });
  return {
    items: items.map((item: any) => new PodConfig(item)),
    pagination,
  };
};

export const fetchAllPodConfigs = async (): Promise<PodConfig[]> => {
  const { items } = await fetchPodConfigs({
    active: true,
    limit: -1,
    sort: 'name asc',
  });
  return items;
};

export type PodConfigEmployeeSlot = {
  employeeId?: number;
  id?: number;
  maxMembers?: number;
  role: string;
};

export type CreatePodConfigParams = {
  employees: PodConfigEmployeeSlot[];
  languages: string[];
  managerId: number;
  maxMembers?: number;
  name: string;
  programExtensionIds: number[];
  referralSourceIds?: number[];
  states: string[];
};

export const createPodConfig = async (params?: CreatePodConfigParams) =>
  await Api.postv2JSON('pods', params);

export type UpdatePodConfigParams = Partial<
  CreatePodConfigParams & {
    active: boolean;
  }
>;

export const updatePodConfig = async (
  id: number,
  { employees, ...params }: UpdatePodConfigParams,
) =>
  await Api.patchv2JSON(
    `pods/${id}`,
    {
      employees: employees?.map(({ id, employeeId, maxMembers, ...item }) => ({
        id: id || undefined,
        employeeId: employeeId || undefined,
        maxMembers: maxMembers || undefined,
        ...item,
      })),
      ...params,
    },
    { showToast: true },
  );

export type GetPodRuleParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodRules = async (
  params?: GetPodRuleParams,
): Promise<PaginatedResponse<PodRule>> => {
  const {
    taskDefinitions: { items, pagination },
  } = await Api.getv2('program-tasks/definitions', params);
  return {
    items: items.map((item: any) => new PodRule(item)),
    pagination,
  };
};

export const fetchAllPodRules = async (): Promise<PodRule[]> => {
  const { items } = await fetchPodRules({ active: true, limit: -1 });
  // FIXME: Remove when BE filter works
  return items.filter(({ active }) => active);
};

export const fetchPodTriggers = async (): Promise<PodRuleTrigger[]> => {
  const {
    triggers: { items },
  } = await Api.getv2('triggers', { sort: 'name asc' });
  return items.map((item: any) => new PodRuleTrigger(item));
};

export type CreatePodRuleParams = {
  additional: boolean;
  confirmationType?: string;
  confirmationTypeReason?: string;
  default: boolean;
  description?: string;
  flexible: boolean;
  maxAttempts: number;
  name: string;
  overriddenBy?: number[];
  programExtensionId?: number;
  requireConfirmation: boolean;
  roles: string[];
  rolloverAfterPeriod: boolean;
  spacing?: number;
  triggers?: {
    triggerName: string;
    delayValue?: number;
    delayType?: string;
    status?: string;
  }[];
};

export type UpdatePodRuleParams = Partial<
  CreatePodRuleParams & {
    active: boolean;
  }
>;

export type GetCCMTaskParams = PaginationParams & {
  assigneeId?: number[];
  language?: string[];
};

export const fetchProgramTasksQueue = async (
  params: GetCCMTaskParams,
): Promise<PaginatedResponseWithTime<CCMTask>> => {
  const {
    ccmTasks: { items, pagination, lastUpdatedAt },
  } = await Api.getv2('program-tasks/queue', params);
  return {
    items: items.map((x: any) => new CCMTask(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export const attemptCCMTask = async (id: number): Promise<PodForecastTask> => {
  const { task } = await Api.patchv2JSON(
    `program-tasks/${id}/actions/move-to-in-progress`,
  );
  return new PodForecastTask(task);
};

export const updateCCMTaskAssignee = async (
  id: number,
  assigneeId: number,
): Promise<PodForecastTask> => {
  const { task } = await Api.patchv2JSON(
    `program-tasks/${id}/actions/reassign`,
    {
      assigneeId,
    },
  );
  return new PodForecastTask(task);
};

export const forceOverrideProgramTask = async (
  id: number,
): Promise<PodForecastTask> => {
  const { task } = await Api.patchv2JSON(
    `program-tasks/${id}/actions/force-override`,
    {
      taskDefinitionId: PodRule.WELLNESS_CHECK_IN_ID,
    },
  );
  return new PodForecastTask(task);
};

export const overrideProgramTask = async (
  id: number,
  taskDefinitionId: number,
  assigneeId: number,
): Promise<PodForecastTask> => {
  const { task } = await Api.patchv2JSON(
    `program-tasks/${id}/actions/override-and-reassign`,
    {
      assigneeId,
      taskDefinitionId,
    },
  );
  return new PodForecastTask(task);
};

export type GetPodMetricsParams = {
  month?: Moment;
} & PaginationParams;

export const fetchPodMetricsSummary = async ({
  month,
  ...rest
}: GetPodMetricsParams): Promise<PodMetricsSummary[]> => {
  const isCurrentMonth = month?.isSame(moment(), 'month');
  if (isCurrentMonth) {
    const {
      podSummaries: { items },
    } = await Api.getv2('pods/summary', rest);
    return items.map((item: any) => new PodMetricsSummary(item));
  }
  const {
    podSummaries: { items },
  } = await Api.getv2('pods/summary/snapshot', {
    month: month?.format(DATE_FORMAT),
    ...rest,
  });
  return items.map((item: any) => new PodMetricsSummary(item));
};

export const fetchPodMetricsForecast = async ({
  month,
  ...rest
}: GetPodMetricsParams): Promise<PodMetricsSummaryForecast[]> => {
  const {
    podForecast: { items },
  } = await Api.getv2('pods/forecast', {
    month: month?.format(DATE_FORMAT),
    ...rest,
  });
  return items.map((item: any) => new PodMetricsSummaryForecast(item));
};

export const fetchPodMetricEmployees = async (
  id: number,
): Promise<PodMetricsSummaryEmployee[]> => {
  const {
    podEmployees: { items },
  } = await Api.getv2(`pods/summary/${id}/employees`);
  return items.map((item: any) => new PodMetricsSummaryEmployee(item));
};

const prepareParams = ({
  additional,
  rolloverAfterPeriod,
  default: isDefault,
  flexible,
  ...params
}: CreatePodRuleParams | UpdatePodRuleParams) => ({
  isAdditional: additional,
  isRolloverAfterPeriod: rolloverAfterPeriod,
  isDefault,
  isFlexible: flexible,
  ...params,
});

export const createPodRule = async (params: CreatePodRuleParams) =>
  await Api.postv2JSON('program-tasks/definitions', prepareParams(params));

export const updatePodRule = async (id: number, params: UpdatePodRuleParams) =>
  await Api.patchv2JSON(
    `program-tasks/definitions/${id}`,
    prepareParams(params),
  );

export type GetProgramTaskParam = PaginationParams & {
  assigneeId?: number[];
  excludedTaskId?: number[];
  language?: string[];
  memberId?: number;
  month: Moment;
  podId?: (number | string)[];
  programExtensionId?: number;
  state?: string[];
  statusAge?: string;
  statusId?: number[];
  taskDateFrom?: string;
  taskDateTo?: string;
  taskDefinitionId?: (number | string)[];
  taskId?: number[];
};

export const fetchPogramTasks = async ({
  month,
  taskDateFrom,
  taskDateTo,
  ...params
}: GetProgramTaskParam): Promise<PaginatedResponse<PodForecastTask>> => {
  const {
    tasks: { items, pagination },
  } = await Api.getv2(`program-tasks`, {
    ...params,
    taskDateFrom: month
      ? month.startOf('month').format(DATE_FORMAT)
      : taskDateFrom,
    taskDateTo: month ? month.endOf('month').format(DATE_FORMAT) : taskDateTo,
  });
  return {
    items: items.map((item: any) => new PodForecastTask(item)),
    pagination,
  };
};

export type PodTaskBulkCommonParams = {
  filter?: {
    excludedTaskIds?: number[];
    month: string;
    podIds?: number[] | string[];
    taskDefinitionIds: number[] | string[];
  };

  taskIds?: number[];
};

type PodTaskBulkReassignParams = PodTaskBulkCommonParams & {
  assigneeId: number;
};

export const bulkReassignPodTasks = async (params: PodTaskBulkReassignParams) =>
  await Api.patchv2JSON('program-tasks/actions/reassign', params);

type PodTaskBulkOverrideParams = PodTaskBulkCommonParams & {
  assigneeId: number;
  taskDefinitionId: number;
};

export const bulkOverridePodTasks = async (params: PodTaskBulkOverrideParams) =>
  await Api.patchv2JSON('program-tasks/actions/override-and-reassign', params);

type PodTaskBulkForceOverrideParams = PodTaskBulkCommonParams & {};

export const bulkForceOverridePodTasks = async (
  params: PodTaskBulkForceOverrideParams,
) =>
  await Api.patchv2JSON('program-tasks/actions/force-override-and-reassign', {
    ...params,
    taskDefinitionId: PodRule.WELLNESS_CHECK_IN_ID,
  });
