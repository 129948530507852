import { ProgramExtensionStatusReason } from '../enums';
import { makeArray } from './Model';
import { PodMetricsSummaryCommon } from './PodMetricsSummaryCommon';
import { PodMetricsSummaryData } from './PodMetricsSummaryData';
import { PodMetricsSummaryTask } from './PodMetricsSummaryTask';

export class PodMetricsSummary extends PodMetricsSummaryCommon {
  /** PROGRAMS */

  ccmCompletedCount = 0;

  ccmNotStartedCount = 0;

  ccmAttemptedCount = 0;

  ccmHoldCount = 0;

  rpmAtRiskCount = 0;

  rpmOnTrackCount = 0;

  rpmMetCount = 0;

  tocDueCount = 0;

  tocDuePenCount = 0;

  tocDueCompCount = 0;

  tocDueRSCount = 0;

  onHoldCCMCounts: PodMetricsSummaryData[];

  /** TASKS */

  tasksCount = 0;

  tasksCompletedCount = 0;

  taskStatusCounts: PodMetricsSummaryTask[];

  constructor(obj: any) {
    super(obj);
    Object.assign(this, obj);
    this.onHoldCCMCounts = makeArray(
      obj.onHoldCCMCounts.map((state: any) => ({
        item: ProgramExtensionStatusReason.byKey[state.item as string],
        count: state.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.taskStatusCounts = Object.entries(obj.taskStatusCounts || {}).map(
      ([key, value]) => new PodMetricsSummaryTask(key, value),
    );
    this.tasksCount =
      this.taskStatusCounts?.reduce((acc, { total }) => acc + total, 0) || 0;
    this.tasksCompletedCount =
      this.taskStatusCounts?.reduce(
        (acc, { completed }) => acc + completed,
        0,
      ) || 0;
  }

  get ccmCompletedPercetage() {
    return Math.round((this.ccmCompletedCount / (this.ccmCount || 1)) * 100);
  }

  get rpmCompletedPercetage() {
    return Math.round(
      ((this.rpmMetCount + this.rpmOnTrackCount) / (this.rpmCount || 1)) * 100,
    );
  }

  get tocCompletedPercetage() {
    return Math.round((this.tocDueCompCount / (this.tocCount || 1)) * 100);
  }

  get taskCompletedPercetage() {
    return Math.round(
      (this.tasksCompletedCount / (this.tasksCount || 1)) * 100,
    );
  }
}

export default PodMetricsSummary;
