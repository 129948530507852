import { PodMetricsSummaryCommon } from './PodMetricsSummaryCommon';
import { PodMetricsSummaryTaskForecast } from './PodMetricsSummaryTaskForecast';

export class PodMetricsSummaryForecast extends PodMetricsSummaryCommon {
  /** TASKS */

  tasksCount = 0;

  taskStatusCounts: PodMetricsSummaryTaskForecast[];

  constructor(obj: any) {
    super(obj);
    this.taskStatusCounts = Object.entries(obj.taskStatusCounts || {})
      .map(
        ([key, value]) =>
          new PodMetricsSummaryTaskForecast(
            key,
            value as PodMetricsSummaryTaskForecast[],
          ),
      )
      .sort(PodMetricsSummaryTaskForecast.sort);
    this.tasksCount =
      this.taskStatusCounts?.reduce((acc, { count }) => acc + count, 0) || 0;
  }
}

export default PodMetricsSummaryForecast;
