import { Enum } from './Enum';

export class MemberTag extends Enum {
  translationKey = 'memberTag';

  static asArray: MemberTag[] = [];

  static byKey: { [name: string]: MemberTag } = {};

  static readonly UNREACHABLE = new MemberTag('UNREACHABLE');

  static readonly LITE_TO_CCM = new MemberTag('LITE_TO_CCM');

  constructor(public readonly value: string) {
    super();
    MemberTag.asArray.push(this);
    MemberTag.byKey[value] = this;
  }
}

export default MemberTag;
