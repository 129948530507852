import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import {
  Enum,
  Language,
  ProgramTaskStatus,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  FilterSelectable,
  PaginationType,
  PodConfig,
  PodForecastTask,
  PodRule,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  LS_PROGRAM_TASK_MANAGEMENT_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  FilterItem,
  Fonts,
  IconButton,
  Modal,
  Panel,
  Select,
  UNASSIGNED,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { PodTaskOverrideModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskOverrideModal';
import { PodTaskReassignModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskReassignModal';
import { PodTaskReviewModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskReviewModal';
import {
  FUTURE_MONTHS,
  PAST_MONTHS,
} from 'dash/src/pages/Admin/PODs/Metrics/constants';
import { CacheServices } from 'dash/src/services';
import {
  GetProgramTaskParam,
  PodTaskBulkCommonParams,
  bulkForceOverridePodTasks,
  bulkOverridePodTasks,
  bulkReassignPodTasks,
  fetchPogramTasks,
} from 'dash/src/services/PodServices';
import {
  LoadFilterOption,
  getServerFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { ProgramTaskManagementFilterBar } from './ProgramTaskManagementFilterBar';
import { ProgramTaskManagementTable } from './ProgramTaskManagementTable';

const uniq = (array: any[]) => [...new Set(array)];

const DEFAULT_PAGE_SIZE = 25;

const useStyles = makeStyles({
  actionItem: {
    fontSize: Fonts.fontSize,
    marginLeft: 0,
    width: '100%',
  },
  back: {
    fontFamily: Fonts.fontFamily,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
  },
  date: {
    width: '15rem',
  },
  disabledOption: {
    cursor: 'default',
    opacity: 0.38,
    width: '100%',
  },
});

type KeyGetCCMTasksParams = keyof GetProgramTaskParam;

export const ProgramTaskManagement = () => {
  const query = useQueryParams();
  const history = useHistory();
  const styles = useStyles();

  const monthValue = query.get('month')
    ? moment(query.get('month'), DATE_FORMAT)
    : moment();

  const [employees, setEmployees] = useState<Employee[]>();
  const [languages, setLanguages] = useState<Language[]>();
  const [nlcStates, setNLCStates] = useState<States[]>();
  const [podConfigs, setPodConfigs] = useState<PodConfig[]>();
  const [taskDefinitions, setTaskDefinitions] = useState<PodRule[]>();

  const [anchorActions, setAnchorActions] = useState<Element>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [month, setMonth] = useState<Moment>(
    (monthValue.isValid() ? monthValue : moment().add(1, 'month')).endOf(
      'month',
    ),
  );

  const [filters, setFilters] = useState<
    {
      [x in KeyGetCCMTasksParams]?: FilterItem;
    }
  >();

  const podConfigFilters = filters?.podId?.value as FilterSelectable<
    PodConfig
  >[];
  const podConfig =
    podConfigFilters?.length === 1 ? podConfigFilters[0]?.info : undefined;

  const taskDefinitionFilters = filters?.taskDefinitionId
    ?.value as FilterSelectable<PodRule>[];
  const taskDefinition =
    taskDefinitionFilters?.length === 1
      ? taskDefinitionFilters[0]?.info
      : undefined;

  const [tasks, setTasks] = useState<PodForecastTask[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [openReassignModal, setOpenReassignModal] = useState<boolean>(false);
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>(false);
  const [openForceOverrideModal, setOpenForceOverrideModal] = useState<boolean>(
    false,
  );
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);
  const [reassignEmloyee, setReassignEmployee] = useState<Employee>();
  const [overrideEmloyee, setOverrideEmployee] = useState<Employee>();
  const [overrideType, setOverrideType] = useState<PodRule>();

  const selectedTasks = selectAll
    ? (pagination?.total || 0) - selectedIds.length
    : selectedIds.length;

  const filtersData = useMemo(
    () => ({
      employees: employees
        ? [
            ...Employee.toSelectable([UNASSIGNED]),
            ...Employee.toSelectable(employees),
          ]
        : undefined,
      languages: languages
        ? Language.toSelectable(languages).filter(({ disabled }) => !disabled)
        : undefined,
      taskDefinitions: taskDefinitions
        ? PodRule.toSelectable(taskDefinitions)
        : undefined,
      podConfigs: podConfigs
        ? [
            { value: 0, label: translate('global.unassigned') } as Selectable,
            ...PodConfig.toSelectable(podConfigs),
          ]
        : undefined,
    }),
    [employees, taskDefinitions, podConfigs],
  );

  const licensedStates = useMemo(
    () =>
      getServerFilters(filters || {})?.state?.map(
        (st: string) => States.byKey[st],
      ),
    [filters?.state],
  );

  const storedFiltersConfiguration = useMemo(() => {
    if (
      filtersData?.employees?.length &&
      filtersData?.taskDefinitions?.length &&
      filtersData?.podConfigs?.length
    ) {
      return {
        assigneeId: {
          data: filtersData.employees,
          label: translate('programTasksManagement.filter.assignee'),
          multiple: true,
        },
        language: {
          data: filtersData.languages,
          label: translate('programTasksManagement.filter.language'),
          multiple: true,
        },
        podId: {
          data: filtersData.podConfigs,
          label: translate('programTasksManagement.filter.pod'),
          multiple: true,
        },
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('programTasksManagement.filter.state'),
          multiple: true,
        },
        statusAge: {
          label: translate('programTasksManagement.filter.statusAge'),
        },
        statusId: {
          data: Enum.toSelectable(ProgramTaskStatus.asArray),
          label: translate('programTasksManagement.filter.status'),
          multiple: true,
        },
        taskDefinitionId: {
          data: filtersData.taskDefinitions,
          label: translate('programTasksManagement.filter.type'),
          multiple: true,
        },
      } as { [x in KeyGetCCMTasksParams]: LoadFilterOption };
    }
  }, [filtersData]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const storedFilters = loadNewFiltersFromStorage(
        query.get('clearFilters') ? '' : LS_PROGRAM_TASK_MANAGEMENT_V2,
        storedFiltersConfiguration,
        query,
      );

      setFilters(storedFilters);
      history.replace('?');
    }
  }, [storedFiltersConfiguration]);

  useEffect(() => {
    if (filters) {
      saveNewFiltersToStorage(LS_PROGRAM_TASK_MANAGEMENT_V2, filters);
    }
  }, [filters]);

  const getInitialData = async () => {
    const [
      employees,
      languages,
      taskDefinitions,
      pods,
      nlcStates,
    ] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getLanguages(),
      CacheServices.getPodRules(),
      CacheServices.getPodConfigs(),
      CacheServices.getEmployeeNLCStates(),
    ]);
    setEmployees(employees);
    setLanguages(languages);
    setNLCStates(nlcStates);
    setTaskDefinitions(taskDefinitions);
    setPodConfigs(pods);
  };

  const getTasks = async () => {
    const offset = page * pageSize;
    if (!filters) return;
    if (offset !== 0 && offset < tasks?.length) return;

    const currentFilters = getServerFilters(filters);
    currentFilters.statusAge = currentFilters.statusAge?.replace(' days', '');

    setLoading(true);
    try {
      const { items, pagination } = await fetchPogramTasks({
        ...currentFilters,

        month,

        offset,
        limit: pageSize,
      });

      if (page > 0) {
        setTasks([...tasks, ...items]);
      } else {
        setTasks(items);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getTasks();
  }, [filters, page, pageSize, month]);

  useEffect(() => {
    setSelectedIds([]);
  }, [podConfig, taskDefinition, month]);

  const disabledChecks =
    !taskDefinition ||
    (taskDefinition.id !== PodRule.INITIAL_CARE_PLAN_ID && !podConfig?.id);
  const showChecks = month.isSame(moment(), 'month');

  return (
    <Panel>
      <Panel.Heading
        title={translate('programTasksManagement.title')}
        filtersV2
      >
        <Panel.Actions>
          <Select
            className={styles.date}
            data-cy="program-task-management-filter-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={[...[...FUTURE_MONTHS].reverse(), ...PAST_MONTHS]}
            onChange={(month?: Moment) => month && setMonth(month)}
            placeholder={translate('common.month')}
            value={month}
          />
          {showChecks && (
            <IconButton
              data-cy="program-task-management-filter-actions"
              disabled={!selectedTasks}
              onClick={(evt) => {
                evt.stopPropagation();
                if (anchorActions) {
                  setAnchorActions(undefined);
                } else {
                  setAnchorActions(
                    evt.currentTarget?.parentElement?.parentElement ||
                      undefined,
                  );
                }
              }}
              tooltip={translate('common.actions')}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorActions}
            open={Boolean(anchorActions)}
            onClose={() => setAnchorActions(undefined)}
            PaperProps={{
              style: {
                marginTop: '0.5rem',
                minWidth: '17rem',
              },
            }}
          >
            <MenuItem
              key="program-task-management-reassign"
              onClick={() => {
                setOverrideEmployee(undefined);
                setOverrideType(undefined);
                setReassignEmployee(undefined);
                setOpenReassignModal(true);
                setAnchorActions(undefined);
              }}
            >
              <span className={styles.actionItem}>
                {translate('programTasksManagement.actions.reassign')}
              </span>
            </MenuItem>
            {taskDefinition && (
              <>
                {taskDefinition.flexible ? (
                  <MenuItem
                    key="program-task-management-override"
                    onClick={() => {
                      setOverrideEmployee(undefined);
                      setOverrideType(undefined);
                      setReassignEmployee(undefined);
                      setOpenOverrideModal(true);
                      setAnchorActions(undefined);
                    }}
                  >
                    <span className={styles.actionItem}>
                      {translate('programTasksManagement.actions.override')}
                    </span>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="program-task-management-force-override"
                    onClick={() => {
                      setOverrideEmployee(undefined);
                      setOverrideType(undefined);
                      setReassignEmployee(undefined);
                      setOpenForceOverrideModal(true);
                      setAnchorActions(undefined);
                    }}
                  >
                    <span className={styles.actionItem}>
                      {translate(
                        'programTasksManagement.actions.forceOverride',
                      )}
                    </span>
                  </MenuItem>
                )}
              </>
            )}
          </Menu>
        </Panel.Actions>
        <Panel.FilterBar
          chips={filters}
          inputs={
            <ProgramTaskManagementFilterBar
              data={{
                employees: filtersData.employees || [],
                languages: filtersData.languages || [],
                pods: filtersData.podConfigs || [],
                taskDefinitions: filtersData.taskDefinitions || [],
              }}
              filters={filters}
              onChange={setFilters}
            />
          }
          onClearFilters={() => {
            setFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setFilters({
              ...filters,
              [key]: undefined,
            });
          }}
        />
        {showChecks && (
          <Panel.Tabs className="flex spaced buttons">
            <span>
              {translate('programTasksManagement.selectedItems', {
                count: selectedTasks,
              })}
            </span>
            <div className="flex gap">
              <IconButton
                data-cy="program-task-management-selection-button"
                disabled={selectAll || disabledChecks}
                onClick={() => {
                  setSelectedIds([]);
                  setSelectAll(!selectAll);
                }}
                tooltip={translate('programTasksManagement.selection')}
              >
                <CustomSelectionIcon />
              </IconButton>
              <IconButton
                data-cy="program-task-management-clear-button"
                disabled={
                  (!selectedIds?.length && !selectAll) || disabledChecks
                }
                onClick={() => {
                  setSelectedIds([]);
                  setSelectAll(false);
                }}
                tooltip={translate('global.clear')}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </Panel.Tabs>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <ProgramTaskManagementTable
          allChecked={
            !!(
              tasks?.length &&
              [...tasks]
                .slice(page * pageSize, (page + 1) * pageSize)
                .every((task) => selectAll !== selectedIds.includes(task.id))
            )
          }
          blacklist={selectAll}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          disabledChecks={disabledChecks}
          items={tasks}
          onChangePage={setPage}
          onChangePageSize={(ps) => {
            setPage(0);
            setPageSize(ps);
          }}
          onSelectAll={(checked) => {
            const ids =
              [...tasks]
                .slice(page * pageSize, (page + 1) * pageSize)
                .map((task) => task.id) || [];
            if (!ids.length) return;

            setSelectedIds(
              selectAll === checked
                ? uniq([...selectedIds, ...ids])
                : selectedIds.filter((id) => !ids.includes(id)),
            );
          }}
          onSelectTask={(task) => {
            const idx = selectedIds?.findIndex((id) => id === task.id);
            if (idx === -1) {
              selectedIds.push(task.id);
            } else {
              selectedIds.splice(idx, 1);
            }
            setSelectedIds([...selectedIds]);
          }}
          pagination={pagination}
          selectedIds={selectedIds}
          showChecks={showChecks}
        />
      </Panel.Body>
      {taskDefinition && (
        <PodTaskReassignModal
          employees={employees}
          open={openReassignModal}
          podConfig={podConfig}
          taskType={taskDefinition}
          review
          licensedStates={
            taskDefinition.id === PodRule.INITIAL_CARE_PLAN_ID &&
            licensedStates?.length
              ? licensedStates
              : undefined
          }
          nlcStates={nlcStates || []}
          onSubmit={(employee: Employee) => {
            setReassignEmployee(employee);
            setOpenReviewModal(true);
          }}
          onClose={() => setOpenReassignModal(false)}
        />
      )}
      {taskDefinition?.flexible && (
        <PodTaskOverrideModal
          employees={employees}
          open={openOverrideModal}
          podConfig={podConfig}
          taskTypes={(taskDefinitions || [])?.filter(
            ({ id, flexible }) => id !== taskDefinition?.id && flexible,
          )}
          review
          onSubmit={(taskType: PodRule, employee: Employee) => {
            setOverrideEmployee(employee);
            setOverrideType(taskType);
            setOpenReviewModal(true);
          }}
          onClose={() => setOpenOverrideModal(false)}
        />
      )}
      {taskDefinition && selectedIds && (
        <Modal
          open={openForceOverrideModal}
          onSubmit={async () => {
            await bulkForceOverridePodTasks({
              filter: selectAll
                ? {
                    excludedTaskIds: selectedIds?.length
                      ? selectedIds
                      : undefined,
                    month: month.format(DATE_FORMAT),
                    podIds: podConfig ? [podConfig.id] : undefined,
                    taskDefinitionIds: [taskDefinition.id],
                  }
                : undefined,
              taskIds: selectAll ? undefined : selectedIds,
            });

            setOpenForceOverrideModal(false);
            setSelectedIds([]);
            setSelectAll(false);
            if (page !== 0) {
              setPage(0);
            } else {
              getTasks();
            }
          }}
          onClose={() => setOpenForceOverrideModal(false)}
          body={translate(
            'programTasksManagement.actions.forceOverrideModalBody',
            { count: selectedTasks },
          )}
          title={translate('global.warning')}
        />
      )}
      {taskDefinition && (
        <PodTaskReviewModal
          assignee={overrideType ? overrideEmloyee : reassignEmloyee}
          filters={{
            month,
            podConfig,
            taskDefinition,
          }}
          open={openReviewModal}
          selectAll={selectAll}
          selectedIds={selectedIds}
          taskType={overrideType}
          onSubmit={async () => {
            const commonFilters: PodTaskBulkCommonParams = {
              filter: selectAll
                ? {
                    excludedTaskIds: selectedIds?.length
                      ? selectedIds
                      : undefined,
                    month: month.format(DATE_FORMAT),
                    podIds: podConfig ? [podConfig.id] : undefined,
                    taskDefinitionIds: [taskDefinition.id],
                  }
                : undefined,
              taskIds: selectAll ? undefined : selectedIds,
            };
            if (overrideType && overrideEmloyee) {
              await bulkOverridePodTasks({
                ...commonFilters,
                assigneeId: overrideEmloyee.id,
                taskDefinitionId: overrideType.id,
              });
            } else if (reassignEmloyee) {
              await bulkReassignPodTasks({
                ...commonFilters,
                assigneeId: reassignEmloyee.id,
              });
            }
            setOpenReassignModal(false);
            setOpenForceOverrideModal(false);
            setOpenOverrideModal(false);
            setOpenReviewModal(false);
            setSelectedIds([]);
            setSelectAll(false);
            if (page !== 0) {
              setPage(0);
            } else {
              getTasks();
            }
          }}
          onClose={() => setOpenReviewModal(false)}
        />
      )}
    </Panel>
  );
};

export default ProgramTaskManagement;
