import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  BackgroundColors,
  Colors,
  Fonts,
  IconButton,
  PlusMinusIcon,
  Table,
} from 'styleguide-v2';
import { Column, TableItem } from 'styleguide-v2/src/components/Table';

export type RecursiveTableData = {
  id?: number | string;
  name: string;
  column1: string | number;
  column2?: string | number;
  column3?: string | number;
  column4?: string | number;
  subtotals?: RecursiveTableData[];
} & TableItem;

type Props = {
  className?: string;
  data: RecursiveTableData[];
  getItemLabel?: (item: RecursiveTableData) => string;
  getRowClass?: (item: RecursiveTableData) => string;
  getRowOnClick?: (
    item: RecursiveTableData,
    parentItem?: RecursiveTableData,
  ) => ((item: RecursiveTableData) => void) | undefined;
  level?: number;
  header: string;
  header1?: string;
  header2?: string;
  header3?: string;
  header4?: string;
  parentItem?: RecursiveTableData;
};

const RECURSIVE_TABLE_COLUMNS = (
  {
    data,
    getItemLabel,
    getRowOnClick,
    header,
    header1,
    header2,
    header3,
    header4,
    level = 0,
    parentItem,
  }: Props,
  onOpenDetail: (item: RecursiveTableData) => void,
  styles: ClassNameMap<'collapseButton'>,
) => {
  const columns = [
    {
      headerName: header,
      component: (item: RecursiveTableData) => (
        <div className="flex gap" style={{ marginLeft: `${level * 15}px` }}>
          {data.some(({ subtotals }) => subtotals?.length) && (
            <>
              {item.subtotals?.length ? (
                <IconButton
                  className={classNames(styles.collapseButton, 'no-padding')}
                  onClick={() => onOpenDetail(item)}
                  size="small"
                >
                  <PlusMinusIcon open={!!item.open} size="small" />
                </IconButton>
              ) : (
                <div style={{ width: '10px' }} />
              )}
            </>
          )}
          <span>{getItemLabel ? getItemLabel(item) : item.name}</span>
        </div>
      ),
    },
    {
      headerName: header1 || translate('global.total'),
      field: 'column1',
      width: 100,
    },
  ];
  const clickableColumn = {
    headerName: '',
    component: (item: RecursiveTableData) => {
      const handler = getRowOnClick && getRowOnClick(item, parentItem);
      return handler ? (
        <IconButton
          className="no-padding"
          onClick={() => handler(item)}
          size="small"
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      ) : (
        <></>
      );
    },
    width: 40,
  };

  return [
    ...columns,
    header2 !== undefined && {
      headerName: header2,
      field: 'column2',
      width: 100,
    },
    header3 !== undefined && {
      headerName: header3,
      field: 'column3',
      width: 100,
    },
    header4 !== undefined && {
      headerName: header4,
      field: 'column4',
      width: 100,
    },
    clickableColumn,
  ].filter(Boolean) as Column[];
};

const useStyles = makeStyles({
  collapseButton: {
    '&&': {
      marginTop: '0.125rem',
    },
  },
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& > tr.detail > td': {
        background: Colors.white,
        padding: '0',
      },
    },
  },
  subtable: {
    '&& thead': {
      display: 'none',
    },
    '&& tbody': {
      borderRadius: 0,
      boxShadow: 'none',
      borderLeft: `1px solid ${BackgroundColors.grayLine}`,
      borderRight: `1px solid ${BackgroundColors.grayLine}`,

      '&& tr td': {
        padding: '6px 12px',
      },
    },
    '&& tbody tbody': {
      borderLeft: 0,
      borderRight: 0,
    },
  },
});

export const RecursiveTable = (props: Props) => {
  const styles = useStyles();
  const [data, setData] = useState<RecursiveTableData[]>([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onOpenDetail = (item: RecursiveTableData) => {
    const itemIdx = data.findIndex((i) => i.name === item.name);
    if (itemIdx === -1) return;

    data[itemIdx].open = !data[itemIdx].open;
    if (data[itemIdx].open) {
      data[itemIdx].detail = (
        <RecursiveTable
          className={styles.subtable}
          data={item.subtotals as RecursiveTableData[]}
          getItemLabel={props.getItemLabel}
          getRowClass={props.getRowClass}
          getRowOnClick={props.getRowOnClick}
          header=""
          header2={props.header2 ? '' : undefined}
          header3={props.header3 ? '' : undefined}
          header4={props.header4 ? '' : undefined}
          level={props.level ? props.level + 1 : 1}
          parentItem={item}
        />
      );
    } else {
      delete data[itemIdx].detail;
      setData([...data]);
    }
    setData([...data]);
  };

  return (
    <Table
      className={classNames(props.className, styles.table)}
      config={{
        columns: RECURSIVE_TABLE_COLUMNS(props, onOpenDetail, styles),
        compact: true,
        data: props.data,
        detail: true,
        paginationOptions: [-1],
      }}
      whiteBackground
    />
  );
};

export default RecursiveTable;
