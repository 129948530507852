import React from 'react';

import { ProgramTaskStatus, States } from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';

import { FilterItem, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { GetProgramTaskParam } from 'dash/src/services/PodServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetCCMTasksParams = keyof GetProgramTaskParam;

type Props = {
  data: {
    employees: Selectable[];
    languages: Selectable[];
    taskDefinitions: Selectable[];
    pods: Selectable[];
  };
  filters?: {
    [x in KeyGetCCMTasksParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetCCMTasksParams]?: FilterItem;
    },
  ) => void;
};

export const ProgramTaskManagementFilterBar = ({
  data,
  filters,
  loading,
  onChange,
}: Props) => (
  <>
    <Select
      data-cy="program-task-management-filter-bar-status"
      items={Enum.toSelectable(ProgramTaskStatus.asArray).sort(Enum.sort)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          statusId: getFilterItem(
            translate('programTasksManagement.filter.status'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.status')}
      placeholderV2
      size="xs"
      value={filters?.statusId?.value || []}
    />
    <Select
      data-cy="program-task-management-filter-bar-type"
      items={data.taskDefinitions.sort(Enum.sort)}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          taskDefinitionId: getFilterItem(
            translate('programTasksManagement.filter.type'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.type')}
      placeholderV2
      size="xs"
      value={filters?.taskDefinitionId?.value || []}
    />
    <Select
      data-cy="program-task-management-filter-bar-pod"
      items={data.pods}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          podId: getFilterItem(
            translate('programTasksManagement.filter.pod'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.pod')}
      placeholderV2
      size="xs"
      value={filters?.podId?.value || []}
    />
    <Select
      data-cy="program-task-management-filter-bar-state"
      items={Enum.toSelectable(States.asArray)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          state: getFilterItem(
            translate('programTasksManagement.filter.state'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.state')}
      placeholderV2
      size="xs"
      value={filters?.state?.value || []}
    />
    <Select
      data-cy="program-task-management-filter-bar-language"
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      items={data.languages}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(
            translate('programTasksManagement.filter.language'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <Select
      data-cy="program-task-management-filter-bar-assignee"
      filterOptions={FilterEmployeeOptions}
      items={data.employees}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          assigneeId: getFilterItem(
            translate('programTasksManagement.filter.assignee'),
            values,
          ),
        })
      }
      placeholder={translate('programTasksManagement.filter.assignee')}
      placeholderV2
      size="xs"
      value={filters?.assigneeId?.value || []}
    />
  </>
);
