import { Enum } from './Enum';

export class RosterFileHistorySource extends Enum {
  translationKey = 'rosterFileHistorySource';

  static asArray: RosterFileHistorySource[] = [];

  static byKey: { [name: string]: RosterFileHistorySource } = {};

  static readonly MANUAL = new RosterFileHistorySource('MANUAL');

  static readonly SFTP = new RosterFileHistorySource('SFTP');

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    RosterFileHistorySource.asArray.push(this);
    RosterFileHistorySource.byKey[value] = this;
  }
}

export default RosterFileHistorySource;
