import { make } from '../Model';
import { MemberWarningCategory } from './MemberWarningCategory';

export class MemberWarningType {
  id = '';

  description = '';

  category: MemberWarningCategory;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.category = make(obj.category, MemberWarningCategory);
  }

  static DUE_CARE_PLAN_INITIAL = 'DUE_CARE_PLAN_INITIAL';

  static DUE_CARE_PLAN_UPDATE = 'DUE_CARE_PLAN_UPDATE';

  static DUE_NP_BIANNUAL = 'DUE_NP_BIANNUAL';

  static MISSING_PCP = 'MISSING_PCP';

  static MISSING_PHARMACY = 'MISSING_PHARMACY';

  static MISSING_CONSENT_CCM = 'MISSING_CONSENT_CCM';

  static MISSING_CONSENT_HIE = 'MISSING_CONSENT_HIE';

  static MISSING_CONSENT_HIPAA = 'MISSING_CONSENT_HIPAA';

  static MISSING_CONSENT_TEXT = 'MISSING_CONSENT_TEXT';

  static MISSING_CONSENT_PROGRAM = 'MISSING_CONSENT_PROGRAM';

  static NO_CCM_TASKS = 'NO_CCM_TASKS';

  static NO_SHOW_CANCELLED_APPOINTMENTS = 'NO_SHOW_CANCELLED_APPOINTMENTS';

  static OPEN_EVENTS = 'OPEN_EVENTS';

  static OPEN_TASKS = 'OPEN_TASKS';

  static OPEN_ACTIONS = 'OPEN_ACTIONS';

  static RESCHEDULED_APPOINTMENTS = 'RESCHEDULED_APPOINTMENTS';

  static RPM_AT_RISK = 'RPM_AT_RISK';

  static RPM_MISSED = 'RPM_MISSED';

  static RPM_TIME = 'RPM_TIME';

  static CCM_TIME = 'CCM_TIME';

  static TOC = 'TOC';
}

export default MemberWarningType;
