/* eslint-disable react/no-find-dom-node */
import React, { useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PatientSearch as Patient } from '@vestahealthcare/common/models';

import { Colors, SelectHandle } from 'styleguide-v2';

import { SearchMember } from 'dash/src/components/SearchMember';

const useStyles = makeStyles({
  select: {
    minWidth: '30rem',

    '&& .MuiOutlinedInput-root': {
      backgroundColor: Colors.white,
      height: '3.8rem!important',
    },

    '&& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
  },
});

export const PatientSearch = () => {
  const styles = useStyles();
  const history = useHistory();

  const inputRef = useRef<SelectHandle>(null);

  return (
    <SearchMember
      className={styles.select}
      marginTopPaper
      placeholder={translate('search.title')}
      onChange={(member?: Patient) => {
        if (member) {
          inputRef.current?.clear();
          inputRef.current?.clearSearch();
          history.push(`/patients/${member.id}/`);
        }
      }}
      ref={inputRef}
      size="small"
    />
  );
};

export default withRouter(PatientSearch);
