import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import BulkEditIcon from '@mui/icons-material/AppRegistration';
import ClearIcon from '@mui/icons-material/Clear';
import SeeFiltersIcon from '@mui/icons-material/FilterAlt';
import CustomSelectionIcon from '@mui/icons-material/GroupAdd';
import AddAllIcon from '@mui/icons-material/PlaylistAdd';
import SeeSelectionIcon from '@mui/icons-material/RemoveRedEye';
import { makeStyles } from '@mui/styles';

import { Language } from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Brand,
  DBEnum,
  Employee,
  EmployeeGroup,
  FilterSelectable,
  MemberView,
  Organization,
  PaginationType,
  PodConfig,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';

import {
  FilterItem,
  IconButton,
  Panel,
  PanelInfo,
  UNASSIGNED,
} from 'styleguide-v2';

import { MemberViewFiltersBar } from 'dash/src/components/MemberViewFilters/MemberViewFiltersBar';
import { MemberViewFiltersModal } from 'dash/src/components/MemberViewFilters/MemberViewFiltersModal';
import { CacheServices } from 'dash/src/services';
import {
  MemberViewParams,
  getMembersView,
  getMembersViewIds,
} from 'dash/src/services/PatientServices';
import { getServerFilters } from 'dash/src/utils/filterUtils';

import BulkEditModal from './BulkEditModal';
import { BulkEditTable } from './BulkEditTable';
import CustomSelectionModal from './CustomSelectionModal';

const PAGE_SIZE = 10;

const UNASSIGNED_CARE_PLAN_GROUP = new DBEnum({
  description: translate('global.unassigned'),
  id: null,
});

const UNASSIGNED_EMPLOYEE_GROUP = new EmployeeGroup({
  name: translate('global.unassigned'),
  id: null,
});

const UNASSIGNED_SELECTABLE = {
  label: translate('global.unassigned'),
  value: 0,
};

const uniq = (array: any[]) => [...new Set(array)];

const useStyles = makeStyles({
  tabs: {
    '&&': {
      paddingBottom: '0.5rem',
      paddingTop: '0.5rem',
    },
  },
});

type KeyGetMemberParams = keyof MemberViewParams;

export const MemberSearch = () => {
  const styles = useStyles();
  const [seeSelection, setSeeSelection] = useState<boolean>(false);
  const [selection, setSelection] = useState<number[]>([]);

  const [brands, setBrands] = useState<Selectable[]>([]);
  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [reporterStatuses, setReporterStatuses] = useState<Selectable[]>([]);
  const [referralSources, setReferralSources] = useState<
    SelectableInfo<Organization>[]
  >([]);

  const [activeCareCoordinators, setActiveCareCoordinators] = useState<
    Selectable[]
  >([]);
  const [activeEngagementOwners, setActiveEngagementOwners] = useState<
    Selectable[]
  >([]);
  const [activeHealthCoaches, setActiveHealthCoaches] = useState<Selectable[]>(
    [],
  );
  const [activeRNs, setActiveRNs] = useState<SelectableInfo<Employee>[]>([]);
  const [activeNPs, setActiveNPs] = useState<SelectableInfo<Employee>[]>([]);
  const [activePodManagers, setActivePodManagers] = useState<
    SelectableInfo<Employee>[]
  >([]);
  const [allCarePlanGroups, setAllCarePlanGroups] = useState<DBEnum[]>([]);
  const [allProgramExtensions, setAllProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [
    allProgramExtensionStatuses,
    setAllProgramExtensionStatuses,
  ] = useState<ProgramExtensionStatus[]>([]);
  const [allWorklistGroups, setAllWorklistGroups] = useState<EmployeeGroup[]>(
    [],
  );
  const [podConfigs, setPodConfigs] = useState<SelectableInfo<PodConfig>[]>([]);

  const [allLoading, setAllLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [newFiltersOpened, setNewFiltersOpened] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(1);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetMemberParams]?: FilterItem;
    }
  >({});
  const [members, setMembers] = useState<MemberView[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [openBulkEditModal, setOpenBulkEditModal] = useState<boolean>(false);
  const [openCustomSelectionModal, setOpenCustomSelectionModal] = useState<
    boolean
  >(false);

  const hasSelection = !!selection.length;
  const hasResults = !!members?.length;

  const emptyFilters = !Object.values(newFilters || {})
    .map((item) => item?.value)
    ?.filter(Boolean)?.length;

  const fetchInitialData = async () => {
    setLoadingFilters(true);
    const [
      employees,
      cpgs,
      allProgramExtensions,
      allProgramExtensionStatuses,
      allWorklistGroups,
      languages,
      referrals,
      brands,
      rs,
      podConfigs,
    ] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getLanguages(),
      CacheServices.getOrganizations(),
      CacheServices.getOrganizationBrands(),
      CacheServices.getCareTeamPersonStatuses(),
      CacheServices.getPodConfigs(),
    ]);

    setBrands(Brand.toSelectable(brands));
    setEmployees([
      ...Employee.toSelectable([UNASSIGNED]),
      ...mapEmployees(employees),
    ]);
    setLanguages(
      Language.toSelectable(languages).filter(({ disabled }) => !disabled),
    );
    setReferralSources(Organization.toSelectable(referrals));
    setReporterStatuses(BaseEnum.toSelectable(rs));

    setActiveCareCoordinators(
      mapEmployees(employees.filter((e) => e.isInCareCoordinator)),
    );
    setActiveEngagementOwners([
      UNASSIGNED_SELECTABLE,
      ...mapEmployees(employees.filter((e) => e.isInEngagement)),
    ]);
    setActiveHealthCoaches([
      UNASSIGNED_SELECTABLE,
      ...mapEmployees(employees.filter((e) => e.isInHealthCoaches)),
    ]);
    setActiveRNs(mapEmployees(employees.filter((e) => e.isRN)));
    setActiveNPs(mapEmployees(employees.filter((e) => e.isNP)));
    setActivePodManagers(mapEmployees(employees.filter((e) => e.isPodManager)));
    setAllCarePlanGroups([
      UNASSIGNED_CARE_PLAN_GROUP,
      ...cpgs.filter(({ active }) => active),
    ]);
    setAllProgramExtensions(allProgramExtensions);
    setAllProgramExtensionStatuses(allProgramExtensionStatuses);
    setAllWorklistGroups([UNASSIGNED_EMPLOYEE_GROUP, ...allWorklistGroups]);
    setPodConfigs([
      UNASSIGNED_SELECTABLE,
      ...(PodConfig.toSelectable(podConfigs) || []),
    ]);
    setLoadingFilters(false);
  };

  const getMembers = async (params: MemberViewParams) => {
    const offset = page * pageSize;
    if (!offset || offset >= (members?.length || 0)) {
      setLoading(true);
      const { items, pagination } = await getMembersView(params);
      setMembers(offset ? [...(members || []), ...items] : items);
      setPagination(pagination);
      setLoading(false);
    }
  };

  const getMembersList = async (id: number[]) => {
    const offset = page * pageSize;
    if (offset && offset + pageSize < members?.length + 1) return;
    setLoading(true);

    const ids = [...id].slice(offset, offset + pageSize);
    const { items } = await getMembersView({
      id: ids,
      offset: 0,
      limit: pageSize,
    });

    if (offset !== 0) {
      setMembers([...members, ...items]);
    } else {
      setMembers(items);
    }
    setPagination({
      limit: pageSize,
      offset,
      total: selection?.length || 0,
    } as PaginationType);
    setLoading(false);
  };

  const mapEmployees = (array: Employee[]) =>
    Employee.toSelectable(array).sort(({ label: a }, { label: b }) =>
      (a as string).localeCompare(b as string),
    );

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (seeSelection) {
      if (selection.length) {
        getMembersList(selection);
      }
    } else if (emptyFilters) {
      setMembers([]);
      setPagination(undefined);
      setLoading(false);
    } else {
      if (!newFilters) return;
      const currentFilters = getServerFilters(newFilters);

      getMembers({
        ...currentFilters,
        offset: page * pageSize,
        limit: pageSize,
      });
    }
  }, [page, pageSize, seeSelection, refresh, newFilters]);

  const updateSelection = (newSelection: number[]) => {
    setSelection(newSelection);
  };

  const seeSelectionMode = () => {
    setPage(0);
    setMembers([]);
    setSeeSelection(true);
  };

  const updateSelectionList = () => {
    setPage(0);
    setSeeSelection(true);
    setRefresh(refresh + 1);
  };

  const filterMode = (avoidClearSelection?: boolean) => {
    setPage(0);
    if (!avoidClearSelection) {
      setMembers([]);
    }
    setSeeSelection(false);
  };

  const selectAll = async () => {
    setAllLoading(true);
    if (!newFilters) return;
    const currentFilters = getServerFilters(newFilters);
    const ids = await getMembersViewIds(currentFilters);
    updateSelection(uniq([...selection, ...ids]));
    setAllLoading(false);
  };

  return (
    <Panel>
      <Panel.Heading title={translate('memberBulkEdit.header')} filtersV2>
        <Panel.FilterBar
          onClearFilters={() => {
            setNewFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setNewFiltersOpened(!newFiltersOpened)}
          chips={newFilters}
          inputs={
            <MemberViewFiltersBar
              data={{
                employees,
                languages,
                referralSources,
                programExtensions: ProgramExtension.toSelectable(
                  allProgramExtensions,
                ),
                programExtensionStatuses: ProgramExtensionStatus.toSelectable(
                  allProgramExtensionStatuses,
                ),
              }}
              loading={loadingFilters}
              filters={newFilters}
              onChange={(filters) => {
                setPage(0);
                setNewFilters(filters);
              }}
            />
          }
        />
        <Panel.Actions>
          <IconButton
            data-cy="bulk-custom-selection-button"
            disabled={allLoading}
            onClick={() => setOpenCustomSelectionModal(true)}
            tooltip={translate('memberBulkEdit.customSelection')}
          >
            <CustomSelectionIcon />
          </IconButton>
          <IconButton
            data-cy="bulk-see-selection-button"
            disabled={!hasSelection || allLoading}
            onClick={() => (!seeSelection ? seeSelectionMode() : filterMode())}
            tooltip={translate(
              `memberBulkEdit.${seeSelection ? 'seeFilters' : 'seeSelection'}`,
            )}
          >
            {seeSelection ? <SeeFiltersIcon /> : <SeeSelectionIcon />}
          </IconButton>
          <IconButton
            data-cy="bulk-edit-button"
            disabled={!hasSelection || allLoading}
            onClick={() => setOpenBulkEditModal(true)}
            tooltip={translate('memberBulkEdit.bulkEdit')}
          >
            <BulkEditIcon />
          </IconButton>
        </Panel.Actions>
        <Panel.Tabs className={classNames('flex spaced buttons', styles.tabs)}>
          <span>
            {translate('memberBulkEdit.selectedItems', {
              count: selection.length,
            })}
          </span>
          <div className="flex gap">
            <IconButton
              data-cy="bulk-add-all-button"
              disabled={seeSelection || !hasResults}
              loading={allLoading}
              onClick={() => selectAll()}
              tooltip={translate('memberBulkEdit.addAllToSelection')}
            >
              <AddAllIcon />
            </IconButton>
            <IconButton
              data-cy="bulk-clear-button"
              disabled={!hasSelection || allLoading}
              onClick={() => {
                updateSelection([]);
                filterMode(true);
              }}
              tooltip={translate('memberBulkEdit.clearSelection')}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <MemberViewFiltersModal
          data={{
            brands,
            employees,
            hciReporterStatuses: reporterStatuses,
            languages,
            pods: podConfigs,
            programExtensions: ProgramExtension.toSelectable(
              allProgramExtensions,
            ),
            programExtensionStatuses: ProgramExtensionStatus.toSelectable(
              allProgramExtensionStatuses,
            ),
            referralSources,
            statuses: [],
            worklistGroups: DBEnum.toSelectable(allCarePlanGroups),
          }}
          filters={newFilters}
          loadingFilters={loadingFilters}
          open={newFiltersOpened}
          onChange={(filters) => {
            setPage(0);
            setNewFilters(filters);
          }}
          onClose={() => setNewFiltersOpened(false)}
        />
        {!members?.length && emptyFilters ? (
          <PanelInfo
            type="info"
            title={translate('memberBulkEdit.noDataInfo')}
          />
        ) : (
          <BulkEditTable
            allChecked={
              !!(
                members?.length &&
                (seeSelection
                  ? members
                  : [...members]?.slice(page * pageSize, (page + 1) * pageSize)
                )?.every((member) => selection.includes(member.id))
              )
            }
            members={members || []}
            membersSelected={selection}
            onChangePage={setPage}
            onChangePageSize={(ps) => {
              setMembers([]);
              setPage(0);
              setPageSize(ps);
            }}
            onClickCheckbox={(member) =>
              updateSelection(
                selection.includes(member.id)
                  ? selection.filter((id) => member.id !== id)
                  : uniq([...selection, member.id]),
              )
            }
            onClickCheckboxAll={(checked) => {
              const offset = page * pageSize;
              const ids =
                (seeSelection
                  ? members
                  : [...members]?.slice(offset, offset + pageSize)
                )?.map((member) => member.id) || [];
              if (!ids.length) return;

              updateSelection(
                checked
                  ? selection.filter((id) => !ids.includes(id))
                  : uniq([...selection, ...ids]),
              );
            }}
            pagination={pagination}
          />
        )}
        <BulkEditModal
          data={{
            careplanGroups: allCarePlanGroups,
            ccs: activeCareCoordinators,
            engagementOwners: activeEngagementOwners,
            hcs: activeHealthCoaches,
            nps: activeNPs,
            pes: allProgramExtensions,
            pess: allProgramExtensionStatuses,
            podConfigs,
            podManagers: activePodManagers,
            rns: activeRNs,
            worklists: allWorklistGroups,
          }}
          onClose={() => setOpenBulkEditModal(false)}
          onSubmit={updateSelectionList}
          open={openBulkEditModal}
          selection={selection}
          selectedPod={
            (newFilters?.podId?.value as FilterSelectable<number>[])?.length ===
            1
              ? (newFilters?.podId?.value as FilterSelectable<number>[])[0]
                  ?.value
              : undefined
          }
        />
        <CustomSelectionModal
          onClose={() => setOpenCustomSelectionModal(false)}
          onSubmit={(selection: number[]) => {
            updateSelection(selection);
            seeSelectionMode();
          }}
          open={openCustomSelectionModal}
        />
        <br />
        <br />
      </Panel.Body>
    </Panel>
  );
};

export default MemberSearch;
