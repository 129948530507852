import { Moment } from 'moment';

import { ReferralMappingOperationType } from '../enums';
import { Employee } from './Employee';
import { UpdateInfo } from './Helpers/UpdateInfo';
import { make, makeArray, makeUnix } from './Model';
import { Organization } from './Organization';
import { ReferralMappingColumn } from './ReferralMappingColumn';
import { RosterFileHistory } from './RosterFileHistory';

export class ReferralMapping implements UpdateInfo {
  id = 0;

  active = false;

  name = '';

  columns: ReferralMappingColumn[];

  createdAt: Moment;

  createdBy: Employee;

  file: RosterFileHistory;

  organizations: Organization[];

  separator = ',';

  updatedAt: Moment;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.columns = makeArray(obj.columns, ReferralMappingColumn);
    this.createdAt = makeUnix(obj.createdAt) as Moment;
    this.createdBy = make(obj.createdBy, Employee);
    this.file = make(obj.file, RosterFileHistory);
    this.organizations = makeArray(obj.organizations, Organization);
    this.updatedAt = makeUnix(obj.updatedAt) as Moment;
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  get branchColumn() {
    return this.columns?.find(
      ({ operation }) =>
        operation?.action === ReferralMappingOperationType.BRANCH,
    );
  }

  get genderColumn() {
    return this.columns?.find(
      ({ operation }) =>
        operation?.action === ReferralMappingOperationType.GENDER,
    );
  }

  get languageColumn() {
    return this.columns?.find(
      ({ operation }) =>
        operation?.action === ReferralMappingOperationType.LANGUAGE,
    );
  }
}
