export class PodReportingData {
  id = 0;

  name = '';

  total = 0;

  __order = -1;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static sort(
    { name: a, __order: ao }: PodReportingData,
    { name: b, __order: bo }: PodReportingData,
  ) {
    if (ao !== -1 && bo !== -1) return ao - bo;
    return a.localeCompare(b);
  }
}

export default PodReportingData;
