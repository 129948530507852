import React, { useEffect, useMemo, useState } from 'react';

import { EmployeeRole, States } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, PodConfig, PodRule } from '@vestahealthcare/common/models';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';

import { Button, CollapsableSidebar, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

interface Props {
  'data-cy'?: string;
  employees?: Employee[];
  licensedStates?: States[];
  nlcStates?: States[];
  open?: boolean;
  podConfig?: PodConfig;
  taskType: PodRule;
  review?: boolean;

  onClose: () => void;
  onSubmit: (employee: Employee) => void;
}

export const PodTaskReassignModal = (props: Props) => {
  const {
    employees: allEmployees,
    licensedStates,
    nlcStates,
    open,
    onClose,
    onSubmit,
    podConfig,
    taskType,
    review,
  } = props;

  const [submitted, setSubmitted] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const mapEmployeeSlot = (role: EmployeeRole) => (s: PodConfigEmployeeSlot) =>
    new Employee({ ...s.employee, role: role.value });

  const employees = useMemo(
    () =>
      taskType.roles
        .reduce((acc, role) => {
          let employees = [] as Employee[];
          if (podConfig) {
            if (role === EmployeeRole.MA) {
              employees = podConfig.assignedCareCoordinators.map(
                mapEmployeeSlot(EmployeeRole.MA),
              );
            } else if (role === EmployeeRole.ENGAGEMENT_COORDINATOR) {
              employees = podConfig.assignedEngagementOwners.map(
                mapEmployeeSlot(EmployeeRole.ENGAGEMENT_COORDINATOR),
              );
            } else if (role === EmployeeRole.HEALTH_COACH) {
              employees = podConfig.assignedHealthCoaches.map(
                mapEmployeeSlot(EmployeeRole.HEALTH_COACH),
              );
            } else if (role === EmployeeRole.NP) {
              employees = podConfig.assignedNPs.map(
                mapEmployeeSlot(EmployeeRole.NP),
              );
            } else if (role === EmployeeRole.RN) {
              employees = podConfig.assignedRNs.map(
                mapEmployeeSlot(EmployeeRole.RN),
              );
            } else {
              employees =
                allEmployees?.filter(
                  ({ role: employeeRole }) => employeeRole === role,
                ) || [];
            }
          } else {
            employees =
              allEmployees?.filter(
                ({ role: employeeRole, licences, nlc }) =>
                  employeeRole === role &&
                  (!licensedStates?.length ||
                    role !== EmployeeRole.RN ||
                    licences?.some(
                      (st) =>
                        licensedStates.includes(st) ||
                        (nlc && nlcStates?.includes(st)),
                    )),
              ) || [];
          }
          return [...acc, ...employees];
        }, [] as Employee[])
        .filter(Boolean),
    [podConfig, taskType, licensedStates],
  );
  const [employee, setEmployee] = useState<Employee>();

  const reset = () => {
    setEmployee(undefined);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (!employee) return;
    setLoadingSubmit(true);
    await onSubmit(employee);
    setLoadingSubmit(false);
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={<h2>{translate('pods.metrics.reassignModal.title')}</h2>}
      size={450}
    >
      <CollapsableSidebar.Body data-cy="upload-referral-file-body">
        <Select
          data-cy="pod-task-reassign-employee"
          error={submitted && !employee}
          filterOptions={FilterEmployeeOptions}
          getItemLabel={({ fullName }: Employee) => fullName}
          grouped={({ role }) => role.toString()}
          items={employees || []}
          label={translate('pods.metrics.reassignModal.employee')}
          onChange={setEmployee}
          required
          value={employee}
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button data-cy="close-button" color="tertiary" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          data-cy="review-button"
          color={review ? 'primary' : 'secondary'}
          loading={loadingSubmit}
          onClick={doSubmit}
        >
          {translate(
            review ? 'pods.metrics.reassignModal.review' : 'global.save',
          )}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
