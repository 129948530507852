import classNames from 'classnames';
import React, { useState } from 'react';

import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryCommon } from '@vestahealthcare/common/models';

import {
  Collapse,
  CollapseIcon,
  Colors,
  IconButton,
  PieChart,
} from 'styleguide-v2';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryCommon;
};

export const PodSummaryCardMembers = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.membersInfo')}
        </span>
        <div className={styles.titleInfo}>
          <PeopleIcon htmlColor={Colors.iconGreen} fontSize="large" />
          <span className={styles.textWithIcon}>{pod.memberCount}</span>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={styles.itemContainer}>
          <div className="grid-wrapper" style={{ width: '100%' }}>
            <PieChart
              className="grid-span-6"
              data={pod.topThreeLanguages.map((lang) => ({
                id: `pod-${pod.id}-language-${lang.item?.valueOf()}`,
                label: lang.item?.toString() || translate('global.other'),
                value: lang.count,
              }))}
              showLegend
              size="xs"
              title="Language"
              type="outlined"
            />
            <PieChart
              className="grid-span-6"
              data={pod.topThreeStates.map((lang) => ({
                id: `pod-${pod.id}-states-${lang.item?.valueOf()}`,
                label: lang.item?.toString() || translate('global.other'),
                value: lang.count,
              }))}
              showLegend
              size="xs"
              title="State"
              type="outlined"
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
