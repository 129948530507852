import classnames from 'classnames';
import React, {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  FilterOptionsState,
  TextField,
  Theme,
} from '@mui/material';
import { createFilterOptions as mCreateFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

import { BaseEnum } from '@vestahealthcare/common/models';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';
import { ColorProps, getColors } from 'styleguide-v2/src/utils/colors';

import { Chip, MChip } from '../Chip';
import { ErrorLabel } from '../ErrorLabel';
import { Label } from '../Label';
import { Data, Props } from './Props';

const useStyles = makeStyles<Theme, ColorProps>({
  container: {
    display: 'flex',
    flexFlow: 'column',
    '&.disabled *': {
      cursor: 'not-allowed',

      '& > .MuiFormControl-root > .MuiOutlinedInput-root': {
        backgroundColor: Colors.lighterGray,
      },
    },
  },
  containerHorizontal: {
    alignItems: 'center',
    flexFlow: 'row',
    gap: '1rem',
  },
  internalLabelCommon: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '20px',
    marginTop: 1,
    maxWidth: 'calc(100% - 30px)',

    '& .placeholder-chip-counter.disabled': {
      opacity: 0.6,
    },
  },
  internalSublabel: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  internalLabel: {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
  },
  internalLabelSmall: {
    fontSize: Fonts.fontSize,
  },
  internalLabelExtraSmall: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
  },
  labelHorizontal: {
    margin: 0,
  },
  note: {
    color: Colors.iconGray,
    fontStyle: 'italic',
    marginRight: '0.25rem',
  },
  noteContainer: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    marginTop: '.4rem',
  },
  readOnly: {
    margin: 'auto 0',
  },
  iconContainer: {
    color: 'rgba(0, 0, 0, .54)',
    position: 'absolute',
    right: '10px',
    top: 'calc(50% - 12px)',
  },
  hideIcon: {
    '& .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': {
      margin: '0.25rem 3rem',
    },
    '& .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
  },
  input: {
    '&& .MuiOutlinedInput-root': {
      background: ({ bkgColor }: ColorProps) => bkgColor,
    },
    '&& :not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ borderColor, bkgColor }: ColorProps) =>
        borderColor || bkgColor,
    },
    '&& input': {
      color: ({ color }: ColorProps) => color,
    },
    '&& svg': {
      color: ({ color }: ColorProps) => color,
    },
  },
  itemSelected: {
    color: Colors.textGreen,
    fontWeight: 500,
    '& > span': {
      minWidth: '100%',
    },
  },
  itemSelectedHover: {
    '&:hover::after': {
      backgroundColor: '#E7F4F0CC',
      borderRadius: '5px',
      content:
        'url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2218%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2218%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%3C%2Fsvg%3E")',
      lineHeight: '16px',
      marginLeft: '-2rem',
      padding: '0rem 0.3rem 0rem 0rem',
    },
  },
  select: {
    flex: 1,
  },
});

const autocompleteStyles = {
  '.MuiOutlinedInput-root input.MuiOutlinedInput-input': {
    fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    padding: '0.1rem 0.6rem 0.1rem 0',
  },
  '& svg': {
    fontSize: `calc(${Fonts.fontSize} * 1.5)`,
  },
};

const autocompleteSmallStyles = {
  '.MuiOutlinedInput-root input.MuiOutlinedInput-input': {
    fontSize: Fonts.fontSize,
    padding: '0.1rem 0.6rem 0.1rem 0',
  },
};

const autocompleteExtraSmallStyles = {
  '&&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
    paddingRight: '4.5rem',
  },
  '.MuiOutlinedInput-root input.MuiOutlinedInput-input': {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    padding: '0.1rem 0.3rem 0.1rem 0',
  },
  '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
    marginTop: '0.4rem',

    '& > button > svg': {
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
    },
  },
};

const inputStyles = {
  '&& > .MuiInputBase-root ': {
    maxHeight: '20rem',
    minHeight: '4.59rem',
    overflow: 'auto',
    padding: '.4rem 5rem .4rem 1rem',
    paddingRight: '5rem',
    position: 'static',
  },
  '&.MuiFormControl-root > div:not(.Mui-focused) > input ': {
    minWidth: 5,
    padding: 0,
  },
  '& > div > fieldset > legend > span': {
    display: 'none',
  },
  '& .MuiChip-root': {
    background: Colors.lightMint,
    '&:hover': {
      backgroundColor: Colors.mint,
    },
    '.MuiChip-deleteIcon': {
      color: Colors.green,
      '&:hover': {
        color: Colors.darkGreen,
      },
    },
    '.MuiChip-label': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      padding: '0 .6rem',
    },
  },
  '& .MuiAutocomplete-tag': {
    fontSize: Fonts.fontSize,
    maxWidth: 'calc(100% - 35px)',
  },
};

const inputStylesSmall = {
  '&&.MuiFormControl-root > .MuiInputBase-root ': {
    maxHeight: '20rem',
    minHeight: '3.59rem',
    overflow: 'auto',
    padding: '.2rem 3.5rem .2rem 1rem',
    paddingRight: '3.5rem',
    position: 'static',
  },
  '&&.MuiAutocomplete-endAdornment': {
    top: 'auto',
  },
  '& .MuiChip-root': {
    background: Colors.lightMint,
    height: '24px',
    '.MuiChip-deleteIcon': {
      color: Colors.green,
      '&:hover': {
        color: Colors.darkGreen,
      },
    },
    '&:hover': {
      backgroundColor: Colors.mint,
    },
    '.MuiChip-label': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      padding: '0 1rem',
    },

    '& .MuiSvgIcon-root': {
      fontSize: `calc(${Fonts.fontSize} * 1.125)`,
    },
  },
};

const inputStylesExtraSmall = {
  '&&.MuiFormControl-root > .MuiInputBase-root ': {
    maxHeight: '20rem',
    minHeight: '3.2rem',
    overflow: 'auto',
    padding: '.2rem 1.25rem .2rem .8rem',
    paddingRight: '1.25rem',
    position: 'static',
  },
  '&&.MuiAutocomplete-endAdornment': {
    top: 'auto',
  },
  '& .MuiChip-root': {
    background: Colors.lightMint,
    height: '22px',
    margin: '2px',

    '.MuiChip-deleteIcon': {
      color: Colors.green,
      '&:hover': {
        color: Colors.darkGreen,
      },
    },
    '&:hover': {
      backgroundColor: Colors.mint,
    },
    '.MuiChip-label': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      padding: '0 1rem',
    },

    '& .MuiSvgIcon-root': {
      fontSize: `calc(${Fonts.fontSize} * 1)`,
    },
  },
  '& .MuiAutocomplete-tag': {
    fontSize: Fonts.fontSize,
    maxWidth: 'calc(100% - 5rem)',
  },
};

const inputStylesBlackPlaceholder = {
  input: {
    fontWeight: 500,
    '&::placeholder': {
      color: `${Colors.textBlack}!important`,
      opacity: 1,
    },
  },
};

export const createFilterOptions = mCreateFilterOptions;

export type SelectHandle = {
  clearSearch: () => void;
  clear: () => void;
  close: () => void;
  open: () => void;
};

export const SelectComponent = forwardRef(
  (
    {
      'data-cy': dataCy,
      allowAddNewValue,
      avoidBlurClean,
      className,
      defaultValue,
      disableClearable,
      disableClearItem,
      disabled,
      color = 'action',
      error,
      filterOptions,
      flow = 'vertical',
      footer,
      getItemDisabled,
      getItemClass,
      getItemLabel,
      grouped,
      icon,
      items,
      inputType,
      isOptionEqualToValue,
      label,
      limitTags = 2,
      loading,
      marginTopPaper,
      multiple,
      noOptionsText,
      note,
      onChange,
      onClickItem,
      onInputChange,
      placeholder,
      placeholderV2,
      readOnly,
      renderGroup,
      renderOption,
      renderTags,
      required,
      scrollTo,
      size = 'medium',
      type = 'outlined',
      value,
    }: Props,
    ref,
  ) => {
    const styles = useStyles(getColors(color, type));
    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentValue, setValue] = useState<Data | null>(
      value || (multiple ? [] : null),
    );
    const classes = classnames(
      className,
      disabled && 'disabled',
      styles.container,
      icon && styles.hideIcon,
      flow === 'horizontal' && styles.containerHorizontal,
    );

    useImperativeHandle(ref, () => ({
      close: () => setTimeout(() => setOpen(false), 0),
      clear: () => setValue(multiple ? [] : null),
      clearSearch: () => setSearchValue(''),
      open: () => setTimeout(() => setOpen(true), 0),
    }));

    useEffect(() => setValue(value || (multiple ? [] : null)), [value]);

    const getLabelDefault = (item: Data, header?: boolean) => {
      if (allowAddNewValue) {
        // Value selected with enter, right from the input
        if (typeof item === 'string') {
          return item;
        }
        if (item?.inputValue) {
          return item.inputValue;
        }
      }

      const placeholderValue =
        item instanceof BaseEnum ? item.description : item?.label;
      if (
        placeholderV2 &&
        placeholder &&
        !multiple &&
        header &&
        placeholderValue
      ) {
        return `${placeholder}: ${placeholderValue}`;
      }
      if (item instanceof BaseEnum) {
        return item.description;
      }
      // Regular option
      return item?.label || '';
    };
    const getLabel: (item: Data, header?: boolean) => string =
      getItemLabel || getLabelDefault;

    const filter = createFilterOptions<Data>();

    const addNewValueFilterOptions = (
      options: Data[],
      params: FilterOptionsState<any>,
    ) => {
      const filtered = filter(options, params);
      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
          value: -1,
        });
      }
      return filtered;
    };

    const getGrouped = () => {
      if (grouped) {
        if (typeof grouped === 'function') {
          return grouped;
        }
        return (item: Data) => item && getLabel(item)?.charAt(0)?.toUpperCase();
      }
      return undefined;
    };

    const areOptionsEqual = (a: Data, b: Data) => {
      if (a.id !== undefined && b.id !== undefined) return a.id === b.id;
      if (a.value !== undefined && b.value !== undefined)
        return a.value === b.value;
      if (a.label && b.label) return a.label === b.label;
      return false;
    };

    return (
      <div className={classes} data-cy={dataCy}>
        {label && (
          <Label
            className={flow === 'horizontal' ? styles.labelHorizontal : ''}
            error={error}
            required={required}
          >
            {label}
            {flow === 'horizontal' && ':'}
          </Label>
        )}
        {readOnly && color === 'action' && (
          <span className={styles.readOnly}>
            {value instanceof Array
              ? value?.map((i) => getLabel(i)).join(',')
              : getLabel(value) || <>&nbsp;</>}
          </span>
        )}
        {readOnly &&
          color !== 'action' &&
          (value instanceof Array ? (
            <>
              {value?.map(() => (
                <Chip color={color} key={getLabel(value)}>
                  {getLabel(value) || <>&nbsp;</>}
                </Chip>
              ))}
            </>
          ) : (
            <Chip color={color}>{getLabel(value) || <>&nbsp;</>}</Chip>
          ))}
        {!readOnly && (
          <Autocomplete
            className={styles.select}
            defaultValue={defaultValue}
            disableClearable={disableClearable}
            disabled={disabled}
            getOptionDisabled={getItemDisabled || ((data) => data?.disabled)}
            getOptionLabel={(i) => getLabel(i, true)}
            groupBy={getGrouped()}
            filterOptions={
              filterOptions ||
              (allowAddNewValue && !multiple
                ? addNewValueFilterOptions
                : createFilterOptions({
                    stringify: (option) => JSON.stringify(option),
                  }))
            }
            isOptionEqualToValue={isOptionEqualToValue || areOptionsEqual}
            limitTags={limitTags}
            loading={loading}
            multiple={multiple}
            noOptionsText={noOptionsText}
            options={items}
            open={isOpen}
            openOnFocus
            onClick={(evt) => evt.stopPropagation()}
            onClose={(evt, reason) => {
              evt?.stopPropagation();
              if (
                !multiple ||
                ['removeOption', 'selectOption'].indexOf(reason) === -1
              ) {
                setOpen(false);
              }
              if (!avoidBlurClean && reason === 'blur') {
                setSearchValue('');
                if (onInputChange) {
                  onInputChange('', reason);
                }
              }
            }}
            onOpen={() => {
              if (scrollTo) {
                setTimeout(() => {
                  const el = document.querySelector(scrollTo);
                  el?.scrollIntoView();
                }, 10);
              }
              setOpen(true);
              if (inputRef.current) {
                inputRef.current.getElementsByTagName('input')[0].focus();
              }
            }}
            onInputChange={(evt, search: string, reason) => {
              if (!multiple || reason !== 'reset') {
                if (reason !== 'reset' || !avoidBlurClean) {
                  setSearchValue(search);
                }
              }
              if (onInputChange) {
                onInputChange(search, reason);
              }
            }}
            onFocus={() => {
              if (!multiple && !avoidBlurClean) {
                setSearchValue('');
              }
            }}
            onChange={(
              _: SyntheticEvent<Element, Event>,
              newValue: Data | Data[] | null,
            ) => {
              if (allowAddNewValue) {
                if (multiple) {
                  const addValue = newValue.map((item: Data) => {
                    if (typeof item === 'string') {
                      return { label: item, new: true };
                    }
                    if (item?.inputValue) {
                      return { label: item.inputValue, new: true };
                    }
                    return item;
                  });
                  setValue(addValue);
                  onChange(addValue);
                } else if (typeof newValue === 'string') {
                  setValue({ label: newValue, new: true });
                  onChange({ label: newValue, new: true } as Data);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue(newValue.inputValue);
                  onChange({ label: newValue.inputValue, new: true } as Data);
                } else {
                  setValue(newValue);
                  onChange(newValue);
                }
              } else {
                setValue(newValue);
                onChange(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                className={styles.input}
                onClick={(evt) => evt.stopPropagation()}
                error={!!error}
                placeholder={currentValue?.length ? '' : placeholder}
                sx={{
                  ...inputStyles,
                  ...(size === 'small' ? inputStylesSmall : undefined),
                  ...(size === 'xs' ? inputStylesExtraSmall : undefined),
                  ...(placeholder && placeholderV2
                    ? inputStylesBlackPlaceholder
                    : undefined),
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress
                          color="inherit"
                          size={size === 'xs' ? 16 : 20}
                          sx={{ marginRight: size === 'xs' ? '20px' : '22px' }}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                      {icon && (
                        <div className={styles.iconContainer}>{icon}</div>
                      )}
                    </>
                  ),
                }}
                label=""
                ref={inputRef}
                type={inputType}
              />
            )}
            inputValue={searchValue}
            fullWidth={multiple}
            renderGroup={renderGroup}
            renderOption={
              // eslint-disable-next-line no-nested-ternary
              renderOption
                ? ({ key, ...props }: any, item) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Box component="li" key={key || props.id} {...props}>
                      {renderOption(item)}
                    </Box>
                  )
                : getItemClass
                ? (
                    { className: classNameItem, ...other },
                    item,
                    { selected },
                  ) => (
                    <Box
                      className={classnames(
                        getItemClass(item),
                        classNameItem,
                        selected && styles.itemSelected,
                        selected && multiple && styles.itemSelectedHover,
                      )}
                      component="li"
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...other}
                    >
                      <span>{getLabel(item)}</span>
                    </Box>
                  )
                : (
                    { className: classNameItem, ...other },
                    item,
                    { selected },
                  ) => (
                    <Box
                      className={classnames(
                        classNameItem,
                        selected && styles.itemSelected,
                        selected && multiple && styles.itemSelectedHover,
                      )}
                      component="li"
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...other}
                    >
                      <span>{getLabel(item)}</span>
                    </Box>
                  )
            }
            renderTags={
              renderTags ||
              ((tagValue, getTagProps) => {
                if (!tagValue?.length) {
                  return <></>;
                }
                if (placeholder && placeholderV2) {
                  return (
                    <span
                      className={classnames(
                        styles.internalLabelCommon,
                        size === 'medium' && styles.internalLabel,
                        size === 'small' && styles.internalLabelSmall,
                        size === 'xs' && styles.internalLabelExtraSmall,
                        'semi-bold',
                      )}
                    >
                      <span className={styles.internalSublabel}>
                        {placeholder}
                      </span>
                      &nbsp;
                      {!disabled && (
                        <>
                          <Chip
                            color="secondary"
                            type="contained-2"
                            className={classnames(
                              'semi-bold placeholder-chip-counter',
                              disabled && 'disabled',
                            )}
                            size="small"
                          >
                            {tagValue.length}
                          </Chip>
                          &nbsp;
                        </>
                      )}
                    </span>
                  );
                }
                return tagValue.map((option, index) => {
                  const { onDelete, ...rest } = getTagProps({ index });
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <MChip
                      label={getLabel(option)}
                      onClick={
                        onClickItem ? () => onClickItem(option) : undefined
                      }
                      onDelete={
                        (getItemDisabled && getItemDisabled(option)) ||
                        disableClearItem
                          ? undefined
                          : onDelete
                      }
                      size={size === 'medium' ? 'medium' : 'small'}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...rest}
                    />
                  );
                });
              })
            }
            sx={{
              ...autocompleteStyles,
              ...(size === 'small' ? autocompleteSmallStyles : undefined),
              ...(size === 'xs' ? autocompleteExtraSmallStyles : undefined),
            }}
            componentsProps={{
              paper: marginTopPaper
                ? {
                    sx: {
                      marginTop: '1rem',
                    },
                  }
                : undefined,
            }}
            value={currentValue}
            freeSolo={allowAddNewValue}
          />
        )}
        {(note || typeof error === 'string' || (error && required)) &&
          !readOnly && (
            <div className={classnames('flex spaced', styles.noteContainer)}>
              <ErrorLabel error={error} required={required} />
              {note && !(typeof error === 'string' || (error && required)) && (
                <div />
              )}
              {note && <span className={styles.note}>{note}</span>}
            </div>
          )}
        {footer}
      </div>
    );
  },
);
