import classNames from 'classnames';
import { Moment } from 'moment';
import React, { useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { PhoneType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { MemberCommunityResource } from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapseIcon,
  Colors,
  IconButton,
  Panel,
  PhoneLink,
  Table,
  Tooltip,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

type Props = {
  className?: string;
  communityResources: MemberCommunityResource[];
  onAdd: () => void;
  onDelete: (mcr: MemberCommunityResource) => void;
  onEdit: (mcr: MemberCommunityResource) => void;
};

const COMMUNITY_RESOURCE_COLUMNS = (
  { onEdit, onDelete }: Props,
  active: boolean,
) => {
  const columns: Column[] = [
    {
      headerName: translate('communityResources.table.type'),
      component: ({
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        communityResource: { type, specialities },
      }: MemberCommunityResource) => (
        <>
          <p className="no-margin flex min-gap">
            {type.description}{' '}
            <Tooltip
              text={
                <>
                  <p className="white no-margin">
                    {translate('global.createdByUser', {
                      date: createdAt?.format(DATE_FORMAT_SHORT),
                      editor: createdBy?.fullName,
                    })}
                  </p>
                  <p className="white no-margin">
                    {translate('global.lastUpdatedBy', {
                      date: updatedAt?.format(DATE_FORMAT_SHORT),
                      editor: updatedBy?.fullName,
                    })}
                  </p>
                </>
              }
            >
              <InfoIcon color="info" />
            </Tooltip>
          </p>
          {specialities?.length && (
            <p className="no-margin gray">
              {specialities?.map((s) => s.description).join(', ')}
            </p>
          )}
        </>
      ),
      width: 300,
    },
    {
      headerName: translate('communityResources.table.name'),
      field: 'communityResource.name',
      width: 200,
    },
    {
      headerName: translate('communityResources.table.phone'),
      component: ({
        communityResource: { phone, fax },
      }: MemberCommunityResource) =>
        phone || fax ? (
          <PhoneLink
            compact
            hidePrimary
            phone={phone || fax}
            type={
              phone ? PhoneType.BUSINESS.toString() : PhoneType.FAX.toString()
            }
          />
        ) : (
          ''
        ),
      width: 165,
    },
    {
      headerName: translate('communityResources.table.mltc'),
      component: ({
        communityResource,
        externalId,
      }: MemberCommunityResource) => {
        return communityResource.mltcPlan ? (
          <Tooltip
            text={
              <>
                <p className="white no-margin">
                  <span className="semi-bold">
                    {translate('communityResources.table.mltcPlanName')}
                    {': '}
                  </span>
                  <span>{communityResource.mltcPlan?.name || EMPTY}</span>
                </p>
                <p className="white no-margin">
                  <span className="semi-bold">
                    {translate('communityResources.table.mltcPlanId')}
                    {': '}
                  </span>
                  <span>{communityResource.mltcPlan?.mltcId || EMPTY}</span>
                </p>
                <p className="white no-margin">
                  <span className="semi-bold">
                    {translate('communityResources.table.mltcSubscriberId')}
                    {': '}
                  </span>
                  <span>{externalId || EMPTY}</span>
                </p>
              </>
            }
          >
            <div>
              <p
                className="no-margin flex gap wrap"
                style={{ gap: '0 0.5rem' }}
              >
                <span style={{ marginTop: '0.25rem' }}>
                  {translate('communityResources.table.mltcPlanIdMin')}:
                </span>
                <span style={{ marginTop: '0.25rem' }}>
                  {communityResource?.mltcPlan?.mltcId || EMPTY}
                </span>
                <InfoIcon color="info" />
              </p>
              <p className="no-margin flex wrap" style={{ gap: '0 0.5rem' }}>
                <span>
                  {translate('communityResources.table.mltcSubscriberIdMin')}:
                </span>
                <span>{externalId || EMPTY}</span>
              </p>
            </div>
          </Tooltip>
        ) : (
          EMPTY
        );
      },
      width: 175,
    },
    {
      headerName: translate('communityResources.table.address'),
      component: ({
        communityResource: { address },
      }: MemberCommunityResource) =>
        address ? (
          <>
            <p className="no-margin">
              {[address?.address1, address.address2].filter(Boolean).join(', ')}
            </p>
            <p className="no-margin">
              {[address.city, address.state?.value, address.zipCode]
                .filter(Boolean)
                .join(', ')}
            </p>
          </>
        ) : (
          ''
        ),
      width: 220,
    },
    {
      headerName: translate('communityResources.table.notes'),
      component: ({ notes }: MemberCommunityResource) => notes || '',
    },
  ];

  if (active) {
    return [
      ...columns,
      {
        headerName: ' ',
        component: (mcr: MemberCommunityResource) => (
          <div className="flex">
            <IconButton
              size="small"
              onClick={() => {
                onEdit(mcr);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => onDelete(mcr)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
        width: 50,
      },
    ];
  }

  return [
    ...columns,
    {
      headerName: translate('communityResources.table.terminatedDate'),
      component: ({ discontinuedAt, updatedBy }: MemberCommunityResource) => (
        <UpdatedBy
          contrast
          date={discontinuedAt as Moment}
          dateFormat={DATE_FORMAT_SHORT}
          user={updatedBy.fullName}
        />
      ),
      width: 175,
    },
  ];
};

const useStyles = makeStyles({
  table: {
    '&&.table-empty': {
      padding: '1rem 0',
      textAlign: 'start',
    },
    td: {
      '&&': {
        padding: '6px',
      },
    },
  },
  showInactiveButton: {
    '&&': {
      display: 'flex',
      margin: '0.5rem 0 0 auto',
    },
  },
  inactiveRow: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
    },
  },
});

export const CommunityResourcesTable = (props: Props) => {
  const styles = useStyles();
  const { className, communityResources, onDelete, onEdit } = props;

  const activeCRs = communityResources.filter(({ active }) => active);
  const inactiveCRs = communityResources.filter(({ active }) => !active);

  const [showInactive, setShowInactive] = useState<boolean>(false);

  const activecolumns = useMemo(() => COMMUNITY_RESOURCE_COLUMNS(props, true), [
    onDelete,
    onEdit,
  ]);

  const inactivecolumns = useMemo(
    () => COMMUNITY_RESOURCE_COLUMNS(props, false),
    [onDelete, onEdit],
  );

  return (
    <>
      <Table
        className={classNames(className, styles.table)}
        config={{
          columns: activecolumns,
          compact: true,
          data: activeCRs,
          paginationOptions: [-1],
        }}
        empty
        emptyLink={translate('memberProfile.network.communityResourcesAdd')}
        onClickEmptyLink={props.onAdd}
        fontSize="small"
      />
      {!!inactiveCRs?.length && (
        <>
          <Button
            className={styles.showInactiveButton}
            onClick={() => setShowInactive(!showInactive)}
            color="quaternary"
            size="xs"
          >
            {showInactive
              ? 'Hide Inactive Community Resources'
              : 'Show Inactive Community Resources'}
            <CollapseIcon open={showInactive} color="disabled" />
          </Button>
          <Panel.Collapse open={showInactive}>
            <Table
              className={classNames(className, styles.table)}
              config={{
                columns: inactivecolumns,
                compact: true,
                data: inactiveCRs,
                getRowClass: () => styles.inactiveRow,
                paginationOptions: [-1],
              }}
              fontSize="small"
            />
          </Panel.Collapse>
        </>
      )}
    </>
  );
};

export default CommunityResourcesTable;
