/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  BiometricsType,
  CollectionMethod,
  DayOfWeek,
  MeasurementFrequency,
} from '../enums';
import {
  BLOOD_GLUCOSE,
  BLOOD_OXYGEN,
  BLOOD_PRESSURE,
  FAHRENHEIT,
  HEART_RATE,
  POUNDS,
} from '../utils/constants';
import { BiometricsTime } from './BiometricsTime';
import { Employee } from './Employee';
import { Measurement } from './Measurement';
import { make, makeArray } from './Model';
import { Patient } from './Patient';

export class BiometricsConfig {
  baseline?: Measurement;

  biometricsTimes: BiometricsTime[];

  collectionMethod?: CollectionMethod;

  createdAt = 0;

  createdBy: Employee;

  frequency?: MeasurementFrequency;

  id = 0;

  lastReading?: Measurement;

  lowerLimit?: number;

  note = '';

  patient: Patient;

  rpmDeviceId = '';

  rpmOrderId = '';

  rpmOrderDeviceId = '';

  type: BiometricsType;

  updatedAt = 0;

  upperLimit?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.baseline = make(obj.baseline, Measurement);
    this.biometricsTimes = makeArray(obj.biometricsTimes, BiometricsTime) || [];
    this.collectionMethod = CollectionMethod.byKey[obj.collectionMethod];
    this.createdBy = make(obj.createdBy, Employee);
    this.lastReading = make(obj.lastReading, Measurement);

    // Transform the frequency to what we need on the frontend
    if (obj.frequency === MeasurementFrequency.DAYS.valueOf()) {
      const numPerDay = this.biometricsTimes.length;
      if (numPerDay <= 1) {
        this.frequency = MeasurementFrequency.ONCE_PER_DAY;
      } else if (numPerDay === 2) {
        this.frequency = MeasurementFrequency.TWICE_PER_DAY;
      } else if (numPerDay === 3) {
        this.frequency = MeasurementFrequency.THREE_PER_DAY;
      }
    } else {
      this.frequency = MeasurementFrequency.byKey[obj.frequency];
    }

    this.patient = make(obj.patient, Patient);
    this.type = BiometricsType.byKey[obj.type];
  }

  get measurementDays(): DayOfWeek[] {
    return this.biometricsTimes
      .filter((time) => !!time.measurementDay)
      .map((time) => time.measurementDay) as DayOfWeek[];
  }

  get unit() {
    return BIOMETRICS_UNIT[this.type.valueOf()];
  }
}

export const BIOMETRICS_UNIT = {
  [BiometricsType.BLOOD_SUGAR.valueOf()]: BLOOD_GLUCOSE,
  [BiometricsType.BP_DIASTOLIC.valueOf()]: BLOOD_PRESSURE,
  [BiometricsType.BP_SYSTOLIC.valueOf()]: BLOOD_PRESSURE,
  [BiometricsType.OXYGEN.valueOf()]: BLOOD_OXYGEN,
  [BiometricsType.PULSE.valueOf()]: HEART_RATE,
  [BiometricsType.TEMPERATURE.valueOf()]: FAHRENHEIT,
  [BiometricsType.WEIGHT.valueOf()]: POUNDS,
};

export default BiometricsConfig;
