import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useMemo } from 'react';

import AddCallIcon from '@mui/icons-material/AddIcCall';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  DayOfWeek,
  Enum,
  MemberContactTimeType,
  ProgramTaskStatus,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CCMTask,
  PaginationType,
  PodConfig,
  PodRule,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
  TIME_FORMAT,
} from '@vestahealthcare/common/utils/constants';

import {
  Actions,
  BackgroundColors,
  Chip,
  Colors,
  IconButton,
  MemberInfo,
  StatusIndicator,
  Table,
  TableItem,
  Tooltip,
  UpdatedBy,
} from 'styleguide-v2';

import MemberCCMTaskAction from 'dash/src/pages/MemberProfile/CCMTasks/types/MemberCCMTaskAction';
import Session from 'dash/src/services/SessionServices';

export interface TaskTableModel extends CCMTask, TableItem {
  actionLoading?: boolean;
}

interface Props {
  data: {
    pods: PodConfig[];
    taskDefinitions: PodRule[];
  };
  tasks: CCMTask[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onClickRow?: (task: CCMTask) => void;
  onLogCall: (task: CCMTask) => void;
  onTaskAction: (action: Selectable, event: CCMTask, index: number) => void;
}

const useStyles = makeStyles({
  iconMargin: {
    marginLeft: '0.5rem',
  },
  minutes: {
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontWeight: 500,
    minWidth: '3rem',
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
  },
  warningMinutes: {
    background: BackgroundColors.lightYellow,
  },
  errorMinutes: {
    color: Colors.textRed2,
    background: `${Colors.textRed}33`,
  },
});

const CCM_TASK_COLUMNS = (
  { data, onClickRow, onLogCall, onTaskAction }: Props,
  showHouseHoldMembers: boolean,
  styles: ClassNameMap<
    'iconMargin' | 'warningMinutes' | 'errorMinutes' | 'minutes'
  >,
) => [
  {
    headerName: translate('ccmTasks.table.member'),
    component: ({ member }: CCMTask) => (
      <>
        <MemberInfo
          icon={
            <>
              {member.unreachable && (
                <Tooltip text={translate('ccmTasks.table.unreachable')}>
                  <PhoneDisabledIcon color="error" />
                </Tooltip>
              )}
              {member.liteToCcm && (
                <Chip
                  color="secondary"
                  title={translate('ccmTasks.table.ccmActiveTag')}
                  type="outlined"
                  size="small"
                >
                  <div className="flex min-gap semi-bold">
                    LITE
                    <ArrowForwardIcon
                      color="primary"
                      fontSize="small"
                      sx={{ marginTop: '-0.125rem' }}
                    />
                    CCM
                  </div>
                </Chip>
              )}
            </>
          }
          member={member}
          link={onClickRow ? `#/patients/${member?.id}` : undefined}
          showHouseHoldMembers={showHouseHoldMembers}
          showLanguage
          showState
        />
      </>
    ),
  },
  {
    headerName: translate('ccmTasks.table.task'),
    component: ({ taskDate, name }: CCMTask) =>
      taskDate ? (
        <UpdatedBy user={name} date={taskDate} dateFormat={DATE_FORMAT_SHORT} />
      ) : (
        name
      ),
  },
  {
    headerName: translate('ccmTasks.table.taskStatus'),
    component: ({ status }: CCMTask) =>
      status ? (
        <StatusIndicator
          status={status.toString()}
          color={ProgramTaskStatus.getColor(status)}
        />
      ) : (
        EMPTY
      ),
  },
  {
    headerName: translate('ccmTasks.table.contactPreference'),
    component: ({ id, member }: CCMTask) =>
      member
        .getDaysOfService()
        ?.map(({ dates, startTime, endTime, type }, index) => (
          <p className="no-margin" key={`ccm-task-${id}-days-${index}`}>
            {!!dates?.length && (
              <span>
                {DayOfWeek.stringify(dates)}
                {(type || startTime || endTime) && ': '}
              </span>
            )}
            {type && type !== MemberContactTimeType.CUSTOM && (
              <span>{`${type?.toString()} `}</span>
            )}
            {(startTime || endTime) && (
              <span>
                {startTime
                  ? moment(startTime.getTime()).format(TIME_FORMAT)
                  : '*'}
                {' - '}
                {endTime ? moment(endTime.getTime()).format(TIME_FORMAT) : '*'}
              </span>
            )}
          </p>
        )) || EMPTY,
  },
  {
    headerName: translate('ccmTasks.table.attempts'),
    component: ({ attempts }: CCMTask) =>
      attempts !== undefined ? attempts : EMPTY,
  },
  {
    headerName: translate('ccmTasks.table.validMins'),
    component: ({ ccmValidMinutes }: CCMTask) =>
      ccmValidMinutes ? (
        <Tooltip text={ccmValidMinutes}>
          <CheckCircleIcon className={styles.iconMargin} color="primary" />
        </Tooltip>
      ) : (
        <CancelIcon className={styles.iconMargin} color="error" />
      ),
  },
  {
    headerName: translate('ccmTasks.table.ccmMinMinutesNextCpt'),
    component: ({ minMinutesNextCPT }: CCMTask) => {
      if (minMinutesNextCPT === null)
        return (
          <span className={classNames(styles.errorMinutes, styles.minutes)}>
            MAX
          </span>
        );

      if (!minMinutesNextCPT) return EMPTY;
      return (
        <span className={classNames(styles.warningMinutes, styles.minutes)}>
          {minMinutesNextCPT}
        </span>
      );
    },
  },
  {
    headerName: translate('ccmTasks.table.callback'),
    component: ({ callback }: CCMTask) =>
      callback ? <UpdatedBy date={callback} contrast /> : EMPTY,
  },
  {
    headerName: ' ',
    component: (task: CCMTask) => (
      <IconButton
        size="small"
        onClick={(evt) => {
          evt.stopPropagation();
          onLogCall(task);
        }}
        tooltip={translate('ccmTasks.table.logCall')}
      >
        <AddCallIcon />
      </IconButton>
    ),
    width: 50,
  },
  {
    headerName: ' ',
    component: (task: TaskTableModel, index: number) => {
      const actions = MemberCCMTaskAction.asActionsArray(
        task,
        data.taskDefinitions?.find(({ name }) => name === task.name),
        data.pods?.find(({ id }) => id === task.member?.pod?.id),
        Session.actingUser,
      ).filter((action) => action !== MemberCCMTaskAction.FORCE_OVERRIDE);

      return actions?.length ? (
        <Actions
          actions={Enum.toSelectable(actions)}
          data-cy={`ccm-task-item-${index}-actions`}
          loading={task.actionLoading}
          onActionSelected={(action) => onTaskAction(action, task, index)}
        />
      ) : (
        <div style={{ height: '3.3rem' }} />
      );
    },
    width: 40,
  },
];

export const CCMTasksTable = (props: Props) => {
  const styles = useStyles();
  const { showHouseHoldMembers } = useFlags();

  const columns = useMemo(
    () => CCM_TASK_COLUMNS(props, showHouseHoldMembers, styles),
    [props.onLogCall],
  );

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.tasks,
        defaultPageSize: 25,
        getRowBrand: ({ member }: CCMTask) => {
          if (member.isVPC() || member.isVPCLite()) return 'VPC';
          return member.brand;
        },
        pagination: props.pagination,
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
      onClickRow={props.onClickRow}
    />
  );
};

export default CCMTasksTable;
