import moment, { Moment } from 'moment';

import { CommunityResource, Employee } from '.';
import { UpdateInfo, initUpdateInfo } from './Helpers/UpdateInfo';
import { make } from './Model';

export class MemberCommunityResource implements UpdateInfo {
  id = 0;

  memberId = 0;

  communityResource: CommunityResource;

  pocName = '';

  pocNumber = '';

  pocExtension = '';

  pocEmail = '';

  externalId = '';

  notes = '';

  discontinuedAt?: Moment;

  discontinuedBy?: Employee;

  createdBy!: Employee;

  updatedBy!: Employee;

  createdAt!: Moment;

  updatedAt!: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    initUpdateInfo(this, obj);
    this.communityResource = make(obj.communityResource, CommunityResource);
    this.discontinuedAt = obj.discontinuedAt
      ? moment.unix(obj.discontinuedAt)
      : undefined;
    this.discontinuedBy = make(obj.discontinuedBy, Employee);
  }

  get active() {
    return !this.discontinuedAt;
  }
}

export default MemberCommunityResource;
