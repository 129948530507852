import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee } from '@vestahealthcare/common/models';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';

import { Colors, IconButton, Select, TextInput } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

type Props = {
  className?: string;
  error?: boolean;
  items: SelectableInfo<Employee>[];
  onChange: (items: PodConfigEmployeeSlot[]) => void;
  label: string;
  loading?: boolean;
  role: string;
  value: PodConfigEmployeeSlot[];
  showUnassigned?: boolean;
  submitted?: boolean;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    gap: '0.5rem',
  },
  icon: {
    '&&': {
      margin: '0.375rem auto auto',
    },
  },
  firstIcon: {
    '&&': {
      margin: '2.375rem auto auto',
    },
  },
  addEmployeeIcon: {
    marginTop: '-0.25rem',
  },
  addEmployeeLink: {
    color: Colors.textGreen,
    fontSize: '0.875em',
    marginLeft: '0.5rem',
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.iconGreen,
    },
  },
});

export const PodEmployeeSlotField = ({
  className,
  items,
  label,
  loading,
  onChange,
  role,
  value,
  showUnassigned,
  submitted,
}: Props) => {
  const styles = useStyles();

  const UNASSIGNED_EMPLOYEE = new Employee({
    id: 0,
  });
  const UNASSIGNED = new PodConfigEmployeeSlot({
    role,
    employee: UNASSIGNED_EMPLOYEE,
  });
  const UNASSIGNED_WITHOUT_EMPLOYEE = new PodConfigEmployeeSlot({
    role,
    employee: null,
  });

  const [currentValue, setValue] = useState<PodConfigEmployeeSlot[]>(
    value?.length
      ? value
      : [showUnassigned ? UNASSIGNED : UNASSIGNED_WITHOUT_EMPLOYEE],
  );

  const excludeOtherValues = (
    items: Employee[],
    values: Employee[],
    currenValue?: Employee,
  ) =>
    items.filter(
      (item) =>
        !item?.id ||
        item.id === currenValue?.id ||
        !values?.find((value) => value?.id === item?.id),
    );

  useEffect(
    () =>
      setValue(
        value?.length
          ? [...value.map((item) => ({ ...item }))]
          : [showUnassigned ? UNASSIGNED : UNASSIGNED_WITHOUT_EMPLOYEE],
      ),
    [value],
  );

  return (
    <div className={classNames(className, styles.container)}>
      <div className="grid-wrapper fit">
        {currentValue.map((item, idx) => (
          <Fragment key={`employee-slot-${label}-${item.id}-${idx}`}>
            <Select
              className="grid-span-8"
              disableClearable
              error={
                submitted &&
                !showUnassigned &&
                !item?.employee?.id &&
                translate('global.missingRequiredFieldMin')
              }
              filterOptions={FilterEmployeeOptions}
              getItemLabel={(item: Employee) => {
                if (!item) return '';
                return item?.fullName || translate('global.unassigned');
              }}
              items={[
                ...(showUnassigned ? [UNASSIGNED_EMPLOYEE] : []),
                ...excludeOtherValues(
                  items?.map((item) => item.info) as Employee[],
                  currentValue?.map((item) => item.employee) as Employee[],
                  item?.employee,
                ),
              ]}
              label={
                idx === 0 ? (
                  <span className="flex middle" style={{ height: '17.14px' }}>
                    {label}
                    {' * '}
                    <a
                      className={classNames(
                        'flex middle',
                        styles.addEmployeeLink,
                      )}
                      onClick={(evt) => {
                        evt.preventDefault();
                        onChange([
                          ...(currentValue || []),
                          new PodConfigEmployeeSlot({
                            role,
                            employee: showUnassigned
                              ? UNASSIGNED_EMPLOYEE
                              : undefined,
                          }),
                        ]);
                      }}
                    >
                      <AddIcon
                        className={styles.addEmployeeIcon}
                        fontSize="small"
                      />
                      {translate('pods.config.modal.addAnotherEmployee')}
                    </a>
                  </span>
                ) : undefined
              }
              loading={loading}
              onChange={(newValue: Employee) => {
                currentValue[idx].employee = newValue;
                onChange([...currentValue]);
              }}
              value={
                item.employee ||
                (showUnassigned ? UNASSIGNED_EMPLOYEE : undefined)
              }
            />
            <TextInput
              className="grid-span-3"
              error={submitted && !currentValue[idx].maxMembers}
              onChange={(value) => {
                currentValue[idx].maxMembers =
                  value !== undefined ? parseInt(value, 10) : 0;
                onChange([...currentValue]);
              }}
              label={
                idx === 0
                  ? translate('pods.config.common.maxMembers')
                  : undefined
              }
              value={currentValue[idx].maxMembers}
              type="number"
              required
            />
            <IconButton
              className={classNames(
                styles.icon,
                idx === 0 && styles.firstIcon,
                'grid-span-1',
              )}
              disabled={
                value &&
                value.length < 2 &&
                !value[0]?.employee?.id &&
                !value[0]?.maxMembers
              }
              size="small"
              onClick={() => {
                if (value && value.length < 2) {
                  onChange([UNASSIGNED]);
                } else {
                  currentValue?.splice(idx, 1);
                  onChange([...currentValue]);
                }
              }}
            >
              <DeleteOutlineIcon fontSize="large" />
            </IconButton>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
