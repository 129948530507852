import {
  BaseEnum,
  PatientBase,
  PatientSearch,
} from '@vestahealthcare/common/models';

import Api from './Api';

export type UpdateHouseHoldParams = {
  relationship: BaseEnum;
};

export type CreateHouseHoldParams = UpdateHouseHoldParams & {
  memberId: PatientBase;
  relatedMemberId: PatientSearch;
};

export const createHouseHoldMember = async ({
  memberId,
  relatedMemberId,
  relationship,
}: CreateHouseHoldParams) =>
  await Api.postv2JSON('households', {
    memberId: memberId.id,
    relatedMemberId: relatedMemberId.id,
    relationship: relationship.id,
  });

export const updateHouseHoldMember = async (
  id: number,
  { relationship }: UpdateHouseHoldParams,
) =>
  await Api.patchv2JSON(`households/${id}`, {
    relationship: relationship.id,
  });

export const deleteHouseHoldMember = async (id: number) =>
  await Api.deletev2JSON(`households/${id}`);

export const fetchHouseHoldRelationships = async (): Promise<BaseEnum[]> => {
  const {
    relationships: { items },
  } = await Api.getv2('households/relationships', { sort: 'id desc' });

  return items
    .map((item: any) => new BaseEnum(item))
    .sort(BaseEnum.sortOtherLast);
};
