import { Moment } from 'moment';

import { make, makeArray, makeUnix } from '../Model';
import { PodReportingData } from '../PodReportingData';
import { PodReportingDataExtended } from '../PodReportingDataExtended';
import { PodReportingTOCSummary } from './PodReportingTOCSummary';

export class PodReportingTOC {
  apptsDue: PodReportingDataExtended[];

  chaseList: PodReportingDataExtended[];

  chaseList24Count = 0;

  lastUpdatedAt?: Moment;

  status: PodReportingData[];

  summary: PodReportingTOCSummary;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.apptsDue = makeArray(obj.toc.apptsDue, PodReportingDataExtended);
    const chaseList48 = obj.toc.chaseList?.find(
      ({ name }: PodReportingDataExtended) => name.includes('2 business'),
    );
    const chaseList24Count = chaseList48?.subtotals?.find(
      ({ name }: PodReportingDataExtended) => name.includes('24'),
    );
    this.chaseList = makeArray(
      obj.toc.chaseList?.map((item: PodReportingDataExtended) => ({
        ...item,
        subtotals: item?.subtotals?.filter(
          ({ name }: PodReportingDataExtended) =>
            name !== chaseList24Count?.name,
        ),
      })),
      PodReportingDataExtended,
    );
    this.chaseList24Count = chaseList24Count?.total || 0;
    this.lastUpdatedAt = makeUnix(obj.lastUpdatedAt);
    this.status = makeArray(obj.status, PodReportingData);
    this.summary = make(obj.summary, PodReportingTOCSummary);
  }
}

export default PodReportingTOC;
