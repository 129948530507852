import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { EncounterMethod, EncounterType } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Task } from '@vestahealthcare/common/models';

import { Button, Modal } from 'styleguide-v2';

import AddEncounterModal from './AddEncounterModal';

interface Props {
  className?: string;
  method?: EncounterMethod;
  patientId: number | string;
  onSubmit?: () => void;
  task?: Task;
}
export const AddEncounterButton = (props: Props) => {
  const { method, task, onSubmit } = props;
  const [showEncounterModal, setShowEncounterModal] = useState(false);
  const [showEncounterSuccessModal, setShowEncounterSuccessModal] = useState(
    false,
  );

  return (
    <>
      <Button
        data-cy="add-encounter-button"
        className={props.className}
        color="info"
        type="outlined"
        onClick={() => setShowEncounterModal(true)}
        style={{ whiteSpace: 'nowrap' }}
      >
        <AddIcon />
        {translate('encounters.addEncounter')}
      </Button>
      <AddEncounterModal
        avoidRequiredMinutes
        hideTypes={[EncounterType.CCM]}
        method={method}
        task={task}
        patient={+props.patientId}
        onClose={() => setShowEncounterModal(false)}
        onSubmit={() => {
          setShowEncounterModal(false);
          setShowEncounterSuccessModal(true);
          if (onSubmit) onSubmit();
        }}
        open={showEncounterModal}
      />
      {showEncounterSuccessModal && (
        <Modal
          onClose={() => setShowEncounterSuccessModal(false)}
          open
          title={translate('encounters.addEncounter')}
        >
          {translate('encounters.addSuccess')}
        </Modal>
      )}
    </>
  );
};
