import {
  AsyncTaskLeadStatus,
  AsyncTaskType,
} from '@vestahealthcare/common/enums';
import { AsyncTask, CareTeamRosterTask } from '@vestahealthcare/common/models';

import Api, {
  PaginatedResponse,
  PaginationParams,
} from 'dash/src/services/Api';

export const getAsyncTaskStatus = async (uuid: string) => {
  const { asyncTask }: { asyncTask: AsyncTask } = await Api.getv2(
    `/async/${uuid}`,
  );
  return asyncTask;
};

export type GetTaskHistoryParams = PaginationParams & {
  count?: boolean;
  inProgress?: boolean;
  leadStatus?: AsyncTaskLeadStatus;
  referralSourceName?: string[];
};

export const getTaskMemberHistory = async (
  params?: GetTaskHistoryParams,
): Promise<PaginatedResponse<AsyncTask>> => {
  const {
    asyncTasks: { items, pagination },
  } = await Api.getv2(`/async`, {
    ...params,
    leadStatus: params?.leadStatus?.value,
    type: [AsyncTaskType.MEMBER_ROSTER],
  });

  return { items: items.map((i: any) => new AsyncTask(i)), pagination };
};

export const getTaskCareTeamHistory = async (
  params?: GetTaskHistoryParams,
): Promise<PaginatedResponse<CareTeamRosterTask>> => {
  const {
    careTeamRosters: { items, pagination },
  } = await Api.getv2(`/care-team-roster`, {
    ...params,
    leadStatus: params?.leadStatus?.value,
    type: [AsyncTaskType.CARE_TEAM_ROSTER],
  });

  return {
    items: items.map((i: any) => new CareTeamRosterTask(i)),
    pagination,
  };
};

export const downloadAsyncResult = async (uuid: string, fileName: string) => {
  const blob: Blob = await Api.getv2Blob(`async/${uuid}/download`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const downloadCTAsyncResult = async (uuid: string, fileName: string) => {
  const blob: Blob = await Api.getv2Blob(`care-team-roster/${uuid}/download`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const downloadCareTeamRoster = async (
  uuid: string,
  fileName: string,
) => {
  const blob: Blob = await Api.getv2Blob(`care-team-roster/${uuid}/download`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const downloadAsyncComparisonReport = async (
  archiveMetadataId: string,
  fileName: string,
) => {
  const blob: Blob = await Api.getv2Blob(`roster-files/${archiveMetadataId}`);
  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const releaseToSF = async (asyncTaskId: string, rosterId: string) =>
  await Api.postv2JSON(`/async/${asyncTaskId}/release-future-leads`, {
    rosterId,
  });
