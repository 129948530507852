import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { Enum, Language, MemberStatus } from '@vestahealthcare/common/enums';
import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  EmployeeGroup,
  PodConfig,
  PodReportingCCM,
  PodReportingOverview,
  PodReportingRPM,
  PodReportingTOC,
  ProgramExtension,
  ProgramExtensionStatus,
  StoredFilter,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT,
  LS_POD_REPORTING_FILTERS,
  LS_POD_REPORTING_FILTERS_V2,
} from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  Colors,
  FilterItem,
  FilterSelectable,
  Fonts,
  LastUpdated,
  Panel,
  Select,
  SelectProgramExtensionData,
  Tabs,
} from 'styleguide-v2';
import { TabsItem } from 'styleguide-v2/src/components/Tabs';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import {
  GetPodReportDataParams,
  fetchPodReportingCCM,
  fetchPodReportingOverview,
  fetchPodReportingRPM,
  fetchPodReportingTOC,
} from 'dash/src/services/CCMServices';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import { dataUtils } from 'dash/src/utils/dataUtils';
import {
  LoadFilterOption,
  getAsQuery,
  getFilterItem,
  getServerFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
  saveStoredFilters,
} from 'dash/src/utils/filterUtils';

import Session from '../../../services/SessionServices';
import { PodReportingCCMPage } from './PodReportingCCMPage';
import { PodReportingFiltersBar } from './PodReportingFiltersBar';
import { PodReportingFiltersModal } from './PodReportingFiltersModal';
import { PodReportingOverviewPage } from './PodReportingOverviewPage';
import { PodReportingRPMPage } from './PodReportingRPMPage';
import { PodReportingTOCPage } from './PodReportingTOCPage';
import {
  POD_REPORTING_TAB_ALL,
  POD_REPORTING_TAB_COMPLEX_CCM,
} from './PodReportingUtils';
import {
  onClickAdherence,
  onClickApptList,
  onClickCCMTaskEscalation,
  onClickChaseList48,
  onClickProgramTaskStatus,
} from './redirects';

const UNASSIGNED = {
  label: translate('global.unassigned'),
  value: 0,
  info: undefined,
} as SelectableInfo<Employee>;
const NUMBER_OF_MONTHS = 12;
const ALL_PODS = new Employee({
  id: -1,
  fullName: translate('podReporting.allPods'),
});
const ALL_GROUPS = new EmployeeGroup({
  id: -1,
  name: translate('podReporting.allGroups'),
});
const UNASSIGNED_GROUP = new EmployeeGroup({
  name: translate('global.unassigned'),
});

const POD_REPORTING_TAB_OVERVIEW = 'care-team-tab-overview';
const POD_REPORTING_TAB_CCM = 'care-team-tab-ccm';
const POD_REPORTING_TAB_RPM = 'care-team-tab-rpm';
const POD_REPORTING_TAB_TOC = 'care-team-tab-toc';
const POD_REPORTING_TAB_ITEMS = [
  {
    label: translate('podReporting.tabs.overview'),
    value: POD_REPORTING_TAB_OVERVIEW,
  },
  {
    label: translate('podReporting.tabs.ccm'),
    value: POD_REPORTING_TAB_CCM,
  },
  {
    label: translate('podReporting.tabs.rpm'),
    value: POD_REPORTING_TAB_RPM,
  },
  {
    label: `${translate('podReporting.tabs.toc')}`,
    value: POD_REPORTING_TAB_TOC,
  },
];

type KeyGetPodReportParams = keyof GetPodReportDataParams;

const useStyles = makeStyles({
  borderContainer: {
    gap: 0,
    '& > div': {
      padding: '2rem 3.2rem',
      borderBottom: `1px solid ${BackgroundColors.lightGray}`,
      borderRight: `1px solid ${BackgroundColors.lightGray}`,
    },
  },
  chip: {
    '&&': {
      alignItems: 'center',
      border: `1px solid ${Colors.gray}AA`,
      display: 'flex',
      justifyContent: 'center',
      height: '3rem',
      marginRight: '1rem',
      padding: '0.25rem',
      width: '3rem',
    },
  },
  date: {
    width: '15rem',
  },
  exportButton: {
    fontSize: '0.75em',
    marginLeft: 'auto',
  },
  paddingContainer: {
    padding: '2rem 3.2rem',
  },
  panelWhiteBackground: {
    '&& .heading .tabs': {
      borderColor: BackgroundColors.lightGray,
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${BackgroundColors.lightGray}`,
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 400,
      gap: '0.5rem',
      flexWrap: 'wrap',

      '&& > *': {
        minWidth: 'max-content',
      },
    },
  },
  whiteBackground: {
    backgroundColor: Colors.white,
    transition: 'none!important',
  },
});

let PodReportingStringifyFilters = '';

const POD_PAGE_FILTER = 'pod-reporting-page';

export const PodReportingDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const { enablePodReportingToc } = useFlags();

  const storedFilters = useSelector((state) =>
    state.employeeSlice.storedFilters?.filter(
      StoredFilter.filter(POD_PAGE_FILTER),
    ),
  );

  const tabs = useMemo(
    () =>
      POD_REPORTING_TAB_ITEMS.map((item) =>
        item.value === POD_REPORTING_TAB_TOC
          ? {
              ...item,
              disabled: !enablePodReportingToc,
              label: `${item.label}${
                enablePodReportingToc ? '' : ' (Coming Soon)'
              }`,
            }
          : item,
      ),
    [enablePodReportingToc],
  );

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>();

  const [groups, setGroups] = useState<EmployeeGroup[]>([]);
  const [pods, setPods] = useState<Employee[]>([]);
  const [podConfigs, setPodConfigs] = useState<PodConfig[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [programExtensions, setProgramExtensions] = useState<
    ProgramExtension[]
  >([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    ProgramExtensionStatus[]
  >([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const months = [] as Moment[];

  months.push(moment());
  // Last day of month for previous months
  for (let i = NUMBER_OF_MONTHS - 1; i > 0; i -= 1) {
    months.push(
      moment()
        .subtract(NUMBER_OF_MONTHS - i, 'month')
        .endOf('month'),
    );
  }

  const [overviewResponse, setOverviewResponse] = useState<
    PodReportingOverview
  >();
  const [ccmResponse, setCCMResponse] = useState<PodReportingCCM>();
  const [rpmResponse, setRPMResponse] = useState<PodReportingRPM>();
  const [tocResponse, setTOCResponse] = useState<PodReportingTOC>();

  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetPodReportParams]?: FilterItem;
    }
  >();
  const [month, setMonth] = useState<Moment | undefined>(moment());

  const [filtersString, setFiltersString] = useState<string>('');
  const [filtersStringWithoutGroups, setFiltersStringWithoutGroups] = useState<
    string
  >('');
  const [filtersStringWithoutPE, setFiltersStringWithoutPE] = useState<string>(
    '',
  );

  const filtersData = useMemo(
    () => ({
      employees: [UNASSIGNED, ...Employee.toSelectable(employees)],
      languages: Language.toSelectable(languages),
      pods: PodConfig.toSelectable(podConfigs) || [],
      programExtensions: ProgramExtension.toSelectable(programExtensions),
      status: Enum.toSelectable(
        MemberStatus.asArray.filter((item) => item !== MemberStatus.INVALID),
      ),
      worklistGroups: EmployeeGroup.toSelectable(
        groups.filter(({ id }) => id !== -1),
      ),
    }),
    [languages, pods, podConfigs, programExtensions, groups],
  );

  const getFiltersData = async () => {
    setLoadingFilters(true);
    const [
      groups,
      podConfigs,
      employees,
      pes,
      pess,
      languages,
    ] = await Promise.all([
      CacheServices.getEmployeeGroupsWorklist(),
      CacheServices.getPodConfigs(),
      CacheServices.getEmployees(),
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      fetchSupportedLanguages(),
    ]);

    setGroups([ALL_GROUPS, UNASSIGNED_GROUP, ...groups]);
    setEmployees(employees);
    setPodConfigs(podConfigs);
    setPods([ALL_PODS, ...employees.filter((e) => e.isNP)]);
    setProgramExtensions(pes);
    setProgramExtensionStatuses(pess);
    setLanguages(languages);
    setLoadingFilters(false);
  };

  const getDataOverview = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters, {
        programExtensionFilter: dataUtils.programExtensions.parser,
      }),
      month,
    };

    currentFilters.status = currentFilters.status?.length
      ? currentFilters.status
      : [MemberStatus.ACTIVE.value];

    PodReportingStringifyFilters = JSON.stringify(currentFilters);
    setLoading(true);

    try {
      const overviewResponse = await fetchPodReportingOverview(currentFilters);

      if (PodReportingStringifyFilters === JSON.stringify(currentFilters)) {
        setOverviewResponse(overviewResponse);
        setLoading(false);
      }
    } catch (e) {
      showGlobalError(e as string);
      setLoading(false);
    }
  };

  const getDataCCM = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters, {
        programExtensionFilter: dataUtils.programExtensions.parser,
      }),
      month,
    };

    currentFilters.excludedProgramExtensionId = currentFilters.excludedProgramExtensionId?.filter(
      (id) => id !== ProgramExtension.CCM,
    );
    currentFilters.programExtensionFilter = currentFilters.programExtensionFilter?.filter(
      (filter) => filter.startsWith(`${ProgramExtension.CCM}:`),
    );
    currentFilters.status = currentFilters.status?.length
      ? currentFilters.status
      : [MemberStatus.ACTIVE.value];

    PodReportingStringifyFilters = JSON.stringify(currentFilters);
    setLoading(true);

    try {
      const ccmResponse = await fetchPodReportingCCM(currentFilters);

      if (PodReportingStringifyFilters === JSON.stringify(currentFilters)) {
        setCCMResponse(ccmResponse);
        setLoading(false);
      }
    } catch (e) {
      showGlobalError(e as string);
      setLoading(false);
    }
  };

  const getDataRPM = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters, {
        programExtensionFilter: dataUtils.programExtensions.parser,
      }),
      month,
    };

    currentFilters.excludedProgramExtensionId = currentFilters.excludedProgramExtensionId?.filter(
      (id) => id !== ProgramExtension.RPM,
    );
    currentFilters.programExtensionFilter = currentFilters.programExtensionFilter?.filter(
      (filter) => filter.startsWith(`${ProgramExtension.RPM}:`),
    );
    currentFilters.status = currentFilters.status?.length
      ? currentFilters.status
      : [MemberStatus.ACTIVE.value];

    PodReportingStringifyFilters = JSON.stringify(currentFilters);
    setLoading(true);

    try {
      const rpmResponse = await fetchPodReportingRPM(currentFilters);

      if (PodReportingStringifyFilters === JSON.stringify(currentFilters)) {
        setRPMResponse(rpmResponse);
        setLoading(false);
      }
    } catch (e) {
      showGlobalError(e as string);
      setLoading(false);
    }
  };

  const getDataTOC = async () => {
    if (!newFilters) return;

    const currentFilters: GetPodReportDataParams = {
      ...getServerFilters(newFilters, {
        programExtensionFilter: dataUtils.programExtensions.parser,
      }),
      month,
    };

    currentFilters.excludedProgramExtensionId = currentFilters.excludedProgramExtensionId?.filter(
      (id) => id !== ProgramExtension.TCM,
    );
    currentFilters.programExtensionFilter = currentFilters.programExtensionFilter?.filter(
      (filter) => filter.startsWith(`${ProgramExtension.TCM}:`),
    );
    currentFilters.status = currentFilters.status?.length
      ? currentFilters.status
      : [MemberStatus.ACTIVE.value];

    PodReportingStringifyFilters = JSON.stringify(currentFilters);
    setLoading(true);

    try {
      const tocReponse = await fetchPodReportingTOC(currentFilters);

      if (PodReportingStringifyFilters === JSON.stringify(currentFilters)) {
        setTOCResponse(tocReponse);
        setLoading(false);
      }
    } catch (e) {
      showGlobalError(e as string);
      setLoading(false);
    }
  };

  const getData = () => {
    if (selectedTab === POD_REPORTING_TAB_OVERVIEW) getDataOverview();
    if (selectedTab === POD_REPORTING_TAB_CCM) {
      getDataCCM();
    }
    if (selectedTab === POD_REPORTING_TAB_RPM) getDataRPM();
    if (selectedTab === POD_REPORTING_TAB_TOC) {
      getDataTOC();
    }
  };

  const getLastUpdated = () => {
    if (selectedTab === POD_REPORTING_TAB_OVERVIEW)
      return overviewResponse?.lastUpdatedAt;
    if (selectedTab === POD_REPORTING_TAB_CCM)
      return ccmResponse?.lastUpdatedAt;
    if (selectedTab === POD_REPORTING_TAB_RPM)
      return rpmResponse?.lastUpdatedAt;
    if (selectedTab === POD_REPORTING_TAB_TOC)
      return tocResponse?.lastUpdatedAt;
  };

  const getSelectedPE = () => {
    if (selectedTab === POD_REPORTING_TAB_CCM) return ProgramExtension.CCM;
    if (selectedTab === POD_REPORTING_TAB_RPM) return ProgramExtension.RPM;
    if (selectedTab === POD_REPORTING_TAB_TOC) return ProgramExtension.TCM;
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    if (!newFilters || !month || !selectedTab) return;
    if (
      selectedTab !== POD_REPORTING_TAB_OVERVIEW &&
      !newFilters?.programExtensionFilter?.value
    )
      return;
    getData();
  }, [JSON.stringify(newFilters), month?.toISOString()]);

  const initPEFilter = () => {
    const pesActive = programExtensionStatuses?.find(
      ({ id }) => id === ProgramExtensionStatus.ACTIVE,
    );
    if (selectedTab === POD_REPORTING_TAB_CCM) {
      const ccm = programExtensions?.find(
        ({ id }) => id === ProgramExtension.CCM,
      );
      setNewFilters({
        ...newFilters,
        programExtensionFilter: getFilterItem(
          translate('podReporting.filters.programExtensions'),
          [
            {
              programExtension: ccm,
              programExtensionStatus: [pesActive],
            } as SelectProgramExtensionData,
          ],
          dataUtils.programExtensions.getFilterItem,
        ),
      });
    } else if (selectedTab === POD_REPORTING_TAB_RPM) {
      const rpm = programExtensions?.find(
        ({ id }) => id === ProgramExtension.RPM,
      );
      setNewFilters({
        ...newFilters,
        programExtensionFilter: getFilterItem(
          translate('podReporting.filters.programExtensions'),
          [
            {
              programExtension: rpm,
              programExtensionStatus: [pesActive],
            } as SelectProgramExtensionData,
          ],
          dataUtils.programExtensions.getFilterItem,
        ),
      });
    } else if (selectedTab === POD_REPORTING_TAB_TOC) {
      const tcm = programExtensions?.find(
        ({ id }) => id === ProgramExtension.TCM,
      );
      setNewFilters({
        ...newFilters,
        programExtensionFilter: getFilterItem(
          translate('podReporting.filters.programExtensions'),
          [
            {
              programExtension: tcm,
              programExtensionStatus: [pesActive],
            } as SelectProgramExtensionData,
          ],
          dataUtils.programExtensions.getFilterItem,
        ),
      });
    } else {
      setNewFilters({
        ...newFilters,
        programExtensionFilter: undefined,
      });
    }
  };

  useEffect(() => {
    if (!newFilters || !selectedTab) return;
    if (
      selectedTab !== POD_REPORTING_TAB_OVERVIEW &&
      !newFilters?.programExtensionFilter?.value
    )
      initPEFilter();
  }, [selectedTab, newFilters?.programExtensionFilter]);

  useEffect(() => {
    if (!newFilters || !selectedTab) return;
    initPEFilter();
  }, [selectedTab]);

  useEffect(() => {
    if (!newFilters) return;

    const unifiedFilters: GetPodReportDataParams = getServerFilters(
      newFilters,
      {
        programExtensionFilter: dataUtils.programExtensions.parser,
      },
    );

    const currentFilters = [
      selectedTab === POD_REPORTING_TAB_CCM ? `selectedTab=ccm-tab-ccm` : '',
      selectedTab === POD_REPORTING_TAB_RPM ? `selectedTab=ccm-tab-rpm` : '',
      unifiedFilters.isUnreachable ? 'isUnreachable=true' : '',
      unifiedFilters.careCoordinatorId?.length
        ? unifiedFilters.careCoordinatorId
            .map((id) => `careCoordinatorId=${id}`)
            .join('&')
        : '',
      unifiedFilters?.healthCoachId?.length
        ? unifiedFilters.healthCoachId
            .map((id) => `healthCoachId=${id}`)
            .join('&')
        : '',
      unifiedFilters.npOwnerId?.length
        ? unifiedFilters.npOwnerId.map((id) => `npOwnerId=${id}`).join('&')
        : '',
      unifiedFilters.podId?.length
        ? unifiedFilters.podId.map((id) => `podId=${id}`).join('&')
        : '',
      unifiedFilters.podManagerId?.length
        ? unifiedFilters.podManagerId
            .map((id) => `podManagerId=${id}`)
            .join('&')
        : '',
      unifiedFilters.rnOwnerId?.length
        ? unifiedFilters.rnOwnerId.map((id) => `rnOwnerId=${id}`).join('&')
        : '',
      unifiedFilters.excludedProgramExtensionId?.length
        ? unifiedFilters.excludedProgramExtensionId
            .map((id) => `excludedProgramExtensionId=${id}`)
            .join('&')
        : '',
      unifiedFilters.language?.length
        ? unifiedFilters.language.map((id) => `language=${id}`).join('&')
        : '',
      unifiedFilters.language?.length ? 'hasLanguage=true' : '',
    ];
    setFiltersString(
      [
        ...currentFilters,
        unifiedFilters.programExtensionFilter?.length
          ? unifiedFilters.programExtensionFilter
              .map((id) => `programExtensionFilter=${id}`)
              .join('&')
          : '',
        unifiedFilters.worklistGroupId?.length
          ? unifiedFilters.worklistGroupId
              .map((id) => `worklistGroupId=${id}`)
              .join('&')
          : '',
      ]
        .filter(Boolean)
        .join('&'),
    );
    setFiltersStringWithoutGroups(
      [
        ...currentFilters,
        unifiedFilters.programExtensionFilter?.length
          ? unifiedFilters.programExtensionFilter
              .map((id) => `programExtensionFilter=${id}`)
              .join('&')
          : '',
      ]
        .filter(Boolean)
        .join('&'),
    );
    setFiltersStringWithoutPE(
      [
        ...currentFilters,
        unifiedFilters.worklistGroupId?.length
          ? unifiedFilters.worklistGroupId
              .map((id) => `worklistGroupId=${id}`)
              .join('&')
          : '',
      ]
        .filter(Boolean)
        .join('&'),
    );
  }, [newFilters, selectedTab]);

  useEffect(() => {
    const flatFilters = {
      month: month?.format(DATE_FORMAT),
    };
    const query = getAsQuery(flatFilters);
    history.replace(`?${query}`);
    saveStoredFilters(LS_POD_REPORTING_FILTERS, flatFilters);
  }, [month]);

  const storedFiltersConfiguration = useMemo(() => {
    if (
      filtersData.employees?.length &&
      filtersData.languages?.length &&
      filtersData.programExtensions?.length &&
      programExtensionStatuses?.length &&
      filtersData.worklistGroups?.length
    ) {
      return {
        careCoordinatorId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.clinicalAssistant'),
          multiple: true,
        },
        excludedProgramExtensionId: {
          data: filtersData.programExtensions,
          default: [
            ProgramExtension.MEDICAID,
            ProgramExtension.TCM,
            ProgramExtension.VESTA_LITE,
          ],
          label: translate('podReporting.filters.excludedProgramExtensions'),
          multiple: true,
        },
        healthCoachId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.healthCoach'),
          multiple: true,
        },
        isComplex: {},
        isUnreachable: {
          default: false,
          label: translate('podReporting.filters.unreachable'),
        },
        language: {
          data: filtersData.languages,
          label: translate('podReporting.filters.language'),
          multiple: true,
        },
        npOwnerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.npOwner'),
          multiple: true,
        },
        podId: {
          data: filtersData.pods,
          label: translate('podReporting.filters.pod'),
          multiple: true,
        },
        podManagerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.podManager'),
          multiple: true,
        },
        programExtensionFilter: {
          label: translate('podReporting.filters.programExtensions'),
          loader: dataUtils.programExtensions.loader(
            programExtensions,
            programExtensionStatuses,
          ),
          valueLabel: dataUtils.programExtensions.labeler,
          multiple: true,
        },
        rnOwnerId: {
          data: filtersData.employees,
          label: translate('podReporting.filters.rnOwner'),
          multiple: true,
        },
        selectedTab: {},
        status: {
          data: Enum.toSelectable(
            MemberStatus.asArray.filter(
              (item) => item !== MemberStatus.INVALID,
            ),
          ),
          label: translate('podReporting.filters.memberStatus'),
          multiple: true,
        },
        worklistGroupId: {
          data: filtersData.worklistGroups,
          label: translate('podReporting.filters.groups'),
          multiple: true,
        },
      } as { [x in KeyGetPodReportParams | 'selectedTab']: LoadFilterOption };
    }
  }, [filtersData, programExtensionStatuses]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const { selectedTab, ...storedFilters } = loadNewFiltersFromStorage(
        LS_POD_REPORTING_FILTERS_V2,
        storedFiltersConfiguration,
      );
      const selectedTabValue = (selectedTab?.value as FilterSelectable)
        ?.value as string;
      if (
        !selectedTabValue ||
        (!enablePodReportingToc && selectedTabValue === POD_REPORTING_TAB_TOC)
      ) {
        setSelectedTab(POD_REPORTING_TAB_OVERVIEW);
      } else {
        setSelectedTab(selectedTabValue);
      }

      setNewFilters(storedFilters);
    }
  }, [storedFiltersConfiguration]);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(
        LS_POD_REPORTING_FILTERS_V2,
        {
          ...newFilters,
          selectedTab: {
            label: '',
            value: { label: '', value: selectedTab },
          },
        },
        {
          programExtensionFilter: dataUtils.programExtensions.parser,
        },
      );
    }
  }, [newFilters, selectedTab]);

  return (
    <Panel className={styles.panelWhiteBackground}>
      <Panel.Heading
        className={styles.borderBottom}
        title={translate('podReporting.title')}
        filtersV2
      >
        <Panel.Actions>
          {getLastUpdated() && (
            <LastUpdated
              lastUpdatedAt={getLastUpdated() as Moment}
              tooltip={translate('podReporting.lastUpdatedTooltip')}
            />
          )}
          <Select
            className={styles.date}
            data-cy="pod-reporting-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={months}
            onChange={(month?: Moment) => {
              setMonth(month);
            }}
            placeholder={translate('podReporting.filters.month')}
            value={month}
          />
        </Panel.Actions>
        <Panel.FilterBar
          onClearFilters={() => {
            setNewFilters({
              isUnreachable: getFilterItem(
                translate('podReporting.filters.unreachable'),
                false,
              ),
            });
          }}
          onDeleteFilter={(key: string) => {
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpenFilters(!openFilters)}
          onSelectFilter={(filter) =>
            storedFiltersConfiguration &&
            setNewFilters(
              loadNewFilters(filter.filters, storedFiltersConfiguration),
            )
          }
          chips={{
            ...newFilters,
            status:
              !newFilters ||
              (newFilters?.status?.value as FilterSelectable[])?.length
                ? newFilters?.status
                : {
                    label: translate('podReporting.filters.memberStatus'),
                    value: {
                      label: MemberStatus.ACTIVE.description,
                      value: MemberStatus.ACTIVE.value,
                    },
                    readonly: true,
                  },
            excludedProgramExtensionId: newFilters?.excludedProgramExtensionId
              ? ({
                  ...newFilters.excludedProgramExtensionId,
                  value: (newFilters.excludedProgramExtensionId
                    .value as FilterSelectable[])?.filter(
                    ({ value }) => value !== getSelectedPE(),
                  ),
                } as FilterItem)
              : undefined,
            isUnreachable: newFilters?.isUnreachable
              ? ({
                  ...newFilters.isUnreachable,
                  readonly: true,
                } as FilterItem & { readonly: boolean })
              : undefined,
            programExtensionFilter: newFilters?.programExtensionFilter
              ? ({
                  ...newFilters.programExtensionFilter,
                  readonly: selectedTab !== POD_REPORTING_TAB_OVERVIEW,
                } as FilterItem & { readonly: boolean })
              : undefined,
          }}
          inputs={
            <PodReportingFiltersBar
              data={filtersData}
              disabledFilters={[]}
              filters={newFilters}
              loading={loadingFilters}
              onChange={setNewFilters}
              selectedPE={getSelectedPE()}
            />
          }
          storedFilters={storedFilters}
        />
        <Panel.Tabs>
          <Tabs
            disabled={loading}
            items={tabs as TabsItem[]}
            onChange={setSelectedTab}
            value={selectedTab}
          />
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body
        className={classNames('no-padding', styles.whiteBackground)}
        loading={loading}
      >
        <PodReportingFiltersModal
          data={filtersData}
          disabledFilters={[]}
          filters={newFilters}
          loadingFilters={loadingFilters}
          open={openFilters}
          onChange={setNewFilters}
          onClose={() => setOpenFilters(false)}
          onDeleteFilter={async (filter) => {
            await deleteSelfStoredFilter(filter.id);
            dispatch(onStoredFilterDeleted(filter));
          }}
          onEditFilter={async (filter) => {
            await updateSelfStoredFilter(filter.id, filter);
            dispatch(onStoredFilterChange(filter));
          }}
          onSaveFilter={async (filter) => {
            if (filter.id) {
              await updateSelfStoredFilter(filter.id, {
                filters: saveNewFilters(newFilters || {}),
              });
              dispatch(onStoredFilterChange(filter));
            } else if (newFilters) {
              const updated = await createSelfStoredFilter({
                description: filter.description,
                filters: saveNewFilters(newFilters || {}),
                page: POD_PAGE_FILTER,
                preferred: false,
              });
              dispatch(onStoredFilterAdd(updated));
            }
          }}
          selectedPE={getSelectedPE()}
          storedFilters={storedFilters}
        />
        {selectedTab === POD_REPORTING_TAB_OVERVIEW && (
          <PodReportingOverviewPage
            data={overviewResponse}
            peStatuses={programExtensionStatuses}
            onClickStatus={(memberStatuses) =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&${memberStatuses
                  .map(({ value }) => `statusId=${value}`)
                  .join('&')}&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickProgram={(data) => {
              if (data.name === 'CCM') setSelectedTab(POD_REPORTING_TAB_CCM);
              if (data.name === 'RPM') setSelectedTab(POD_REPORTING_TAB_RPM);
              if (data.name === 'TOC') setSelectedTab(POD_REPORTING_TAB_TOC);
            }}
          />
        )}
        {selectedTab === POD_REPORTING_TAB_CCM && (
          <PodReportingCCMPage
            data={ccmResponse}
            peStatuses={programExtensionStatuses}
            onClickAttempted={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&hasProgramExtensions=true&ccmCallStatus=ATTEMPTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickCompleted={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&hasProgramExtensions=true&ccmCallStatus=COMPLETED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickNotStarted={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&hasProgramExtensions=true&ccmCallStatus=NOT_STARTED&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickMinutes={(data) => {
              let minutesFrom = '';
              let minutesTo = '';
              if (data.name.includes('-')) {
                minutesFrom = data.name.split('-')[0].trim();
                minutesTo = data.name.split('-')[1].trim();
              } else {
                minutesFrom = '90';
              }
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.CCM}:${ProgramExtensionStatus.ACTIVE}&hasProgramExtensions=true&totalMinutesFrom=${minutesFrom}&totalMinutesTo=${minutesTo}&${filtersString}`,
                '_blank',
                'noreferrer',
              );
            }}
            onClickProvider={(data, tab) =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${
                  ProgramExtension.CCM
                }:${
                  ProgramExtensionStatus.ACTIVE
                }&hasProgramExtensions=true&from&totalMinutesFrom=${
                  tab === POD_REPORTING_TAB_COMPLEX_CCM ? '90' : ''
                }&hasProfessionalTimeOrTouchedByNp=${
                  tab === POD_REPORTING_TAB_COMPLEX_CCM
                    ? data.name === 'Yes'
                    : ''
                }&hasProfessionalTime=${
                  tab === POD_REPORTING_TAB_ALL ? data.name === 'Yes' : ''
                }&${filtersString}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickStatus={(data) => {
              const item = programExtensionStatuses.find(
                ({ description }) => description === data.name,
              );
              if (item) {
                window.open(
                  `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.CCM}:${item.id}&${filtersStringWithoutPE}`,
                  '_blank',
                  'noreferrer',
                );
              }
            }}
            onClickTaskEscalations={
              Session.actingUser.canViewPagePOD
                ? onClickCCMTaskEscalation(
                    filtersString,
                    month?.format(DATE_FORMAT) || '',
                  )
                : undefined
            }
            onClickTaskStatus={
              Session.actingUser.canViewPagePOD
                ? onClickProgramTaskStatus(
                    filtersString,
                    month?.format(DATE_FORMAT) || '',
                  )
                : undefined
            }
            onClickWorklist={(data) =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&hasProgramExtensions=true&worklistGroupId=${data.worklistId}&${filtersStringWithoutGroups}`,
                '_blank',
                'noreferrer',
              )
            }
          />
        )}

        {selectedTab === POD_REPORTING_TAB_RPM && (
          <PodReportingRPMPage
            data={rpmResponse}
            peStatuses={programExtensionStatuses}
            onClickActive={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${ProgramExtensionStatus.ACTIVE}&hasProgramExtensions=true&${filtersStringWithoutPE}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickInProgress={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${ProgramExtensionStatus.IN_PROGRESS}&hasProgramExtensions=true&${filtersStringWithoutPE}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickNotStarted={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${ProgramExtensionStatus.NOT_STARTED}&hasProgramExtensions=true&${filtersStringWithoutPE}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickReadyForOutreach={() =>
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${ProgramExtensionStatus.READY_FOR_OUTREACH}&hasProgramExtensions=true&${filtersStringWithoutPE}`,
                '_blank',
                'noreferrer',
              )
            }
            onClickTotal={() => {
              const pes = programExtensions
                ?.find((item) => item.id === ProgramExtension.RPM)
                ?.statuses?.filter(
                  (item) =>
                    ![
                      ProgramExtensionStatus.DISENROLLED,
                      ProgramExtensionStatus.DENIED_DECLINED,
                    ].includes(item.id),
                )
                .map((item) => item.id)
                .join('|');
              window.open(
                `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${pes}&${filtersStringWithoutPE}`,
                '_blank',
                'noreferrer',
              );
            }}
            onClickAdherence={onClickAdherence(filtersStringWithoutPE)}
            onClickStatus={(data) => {
              const item = programExtensionStatuses.find(
                ({ description }) => description === data.name,
              );
              if (item) {
                window.open(
                  `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${item.id}&${filtersStringWithoutPE}`,
                  '_blank',
                  'noreferrer',
                );
              }
            }}
          />
        )}
        {selectedTab === POD_REPORTING_TAB_TOC && (
          <PodReportingTOCPage
            data={tocResponse}
            peStatuses={programExtensionStatuses}
            onClickApptList={onClickApptList(filtersString)}
            onClickChaseList24={undefined}
            onClickChaseList48={onClickChaseList48(filtersString)}
            onClickStatus={(data) => {
              const item = programExtensionStatuses.find(
                ({ description }) => description === data.name,
              );
              if (item) {
                window.open(
                  `#/dashboard/members?clearFilters=true&programExtensionId=${ProgramExtension.TCM}&programExtensionStatus=${item.id}&${filtersString}`,
                  '_blank',
                  'noreferrer',
                );
              }
            }}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default PodReportingDashboard;
