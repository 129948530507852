import { ReferralMappingOperationType } from '../enums';
import { ReferralMappingMappedValue } from './ReferralMappingMappedValue';

export class ReferralMappingOperation {
  id = 0;

  action: ReferralMappingOperationType;

  param1 = '';

  param2 = '';

  dataDictionary: ReferralMappingMappedValue[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.action = ReferralMappingOperationType.byKey[obj.action];
    this.dataDictionary = Object.entries(obj.dataDictionary || {}).map(
      ([key, value]) =>
        new ReferralMappingMappedValue({
          sourceValue: key,
          destinationValue: value,
        }),
    );
  }
}
