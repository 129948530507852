import classNames from 'classnames';
import React, { useMemo } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import { ProgramTaskStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PaginationType,
  PodForecastTask,
} from '@vestahealthcare/common/models';
import {
  DATE_FORMAT_SHORT,
  EMPTY,
} from '@vestahealthcare/common/utils/constants';

import {
  BrandMemberIcon,
  CheckboxWithLabel,
  Chip,
  StatusIndicator,
  Table,
  Tooltip,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { HouseHoldMembersChip } from 'dash/src/components/HouseHoldMembersChip';

interface Props {
  allChecked: boolean;
  blacklist: boolean;
  defaultPageSize?: number;
  disabledChecks?: boolean;
  defaultSort?: string;
  // TODO: Fix type
  items: PodForecastTask[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onSelectTask: (member: PodForecastTask) => void;
  onSelectAll: (allChecked: boolean) => void;
  selectedIds: number[];
  showChecks?: boolean;
}

const TABLE_HEADERS = {
  assignee: translate('pods.metrics.forecastDetail.table.assignee'),
  name: translate('pods.metrics.forecastDetail.table.name'),
  member: translate('pods.metrics.forecastDetail.table.member'),
  language: translate('pods.metrics.forecastDetail.table.language'),
  state: translate('pods.metrics.forecastDetail.table.state'),
  lastCCM: translate('pods.metrics.forecastDetail.table.lastCCMTouchpoint'),
  lastAppointmentDate: translate('pods.metrics.forecastDetail.table.lastAppt'),
  nextAppointmentDate: translate('pods.metrics.forecastDetail.table.nextAppt'),
  status: translate('pods.metrics.forecastDetail.table.status'),
  taskDue: translate('pods.metrics.forecastDetail.table.taskDue'),
};

const FORECAST_TASK_COLUMNS = (
  styles: ClassNameMap<'small' | 'extraSmall'>,
  {
    allChecked,
    blacklist,
    disabledChecks,
    items,
    onSelectAll,
    onSelectTask,
    selectedIds,
    showChecks,
  }: Props,
) => {
  const colums: Column[] = [
    {
      headerName: TABLE_HEADERS.member,
      component: ({ member }: PodForecastTask) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            <HouseHoldMembersChip householdMembers={member?.householdMembers} />
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          <a
            className={styles.small}
            href={`#/patients/${member?.id}`}
            rel="noreferrer"
            target="_blank"
            onClick={(evt) => evt.stopPropagation()}
          >
            {member?.id}
          </a>
          )
          {member?.language && (
            <>
              {' '}
              <Chip color="info" size="small" type="outlined">
                {member.language?.toString()}
              </Chip>
            </>
          )}
        </>
      ),
      width: 200,
    },
    {
      headerName: TABLE_HEADERS.status,
      component: ({ status }: PodForecastTask) =>
        status ? (
          <StatusIndicator
            status={status.toString()}
            color={ProgramTaskStatus.getColor(status)}
          />
        ) : (
          EMPTY
        ),
      width: 200,
    },
    {
      headerName: TABLE_HEADERS.state,
      component: ({ member: { state } }: PodForecastTask) =>
        state?.value || EMPTY,
    },
    {
      headerName: TABLE_HEADERS.lastCCM,
      component: ({ lastCCMDate, lastCCMReason }: PodForecastTask) =>
        lastCCMDate ? (
          <Tooltip text={lastCCMReason}>
            <span className={styles.small}>
              {lastCCMDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
    },
    {
      headerName: TABLE_HEADERS.lastAppointmentDate,
      component: ({
        lastAppointmentDate,
        lastAppointmentType,
      }: PodForecastTask) =>
        lastAppointmentDate ? (
          <Tooltip text={lastAppointmentType}>
            <span className={styles.small}>
              {lastAppointmentDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
    },
    {
      headerName: TABLE_HEADERS.nextAppointmentDate,
      component: ({
        nextAppointmentDate,
        nextAppointmentType,
      }: PodForecastTask) =>
        nextAppointmentDate ? (
          <Tooltip text={nextAppointmentType}>
            <span className={styles.small}>
              {nextAppointmentDate?.format(DATE_FORMAT_SHORT)}
            </span>
          </Tooltip>
        ) : (
          EMPTY
        ),
    },
    {
      headerName: TABLE_HEADERS.taskDue,
      component: ({ taskDate }: PodForecastTask) =>
        taskDate ? (
          <span className={classNames(styles.small)}>
            {taskDate?.format(DATE_FORMAT_SHORT)}
          </span>
        ) : (
          EMPTY
        ),
    },
    {
      headerName: TABLE_HEADERS.assignee,
      field: 'assignee.fullName',
    },
  ];

  return showChecks
    ? [
        {
          headerName: (
            <CheckboxWithLabel
              checked={allChecked}
              disabled={!items.length || disabledChecks}
              onChange={() => onSelectAll(allChecked)}
            />
          ),
          component: (task: PodForecastTask) => (
            <CheckboxWithLabel
              disabled={disabledChecks}
              checked={blacklist !== selectedIds.includes(task.id)}
              onChange={() => onSelectTask(task)}
            />
          ),
          width: 50,
        },
        ...colums,
      ]
    : colums;
};

const useStyles = makeStyles({
  small: { fontSize: '0.875em', lineHeight: '16px' },
  extraSmall: { fontSize: '0.75em', lineHeight: '16px' },
});

export const ForecastDetailTaskTable = (props: Props) => {
  const styles = useStyles();

  const columns = useMemo(() => FORECAST_TASK_COLUMNS(styles, props), [
    props.onSelectTask,
    styles,
  ]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.items,
        defaultPageSize: props.defaultPageSize,
        getRowBrand: ({ member }: PodForecastTask) => {
          if (member?.isVPC() || member?.isVPCLite()) return 'VPC';
          return member?.brand;
        },
        pagination: props.pagination,
        paginationOptions: [25, 50, 100],
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default ForecastDetailTaskTable;
