import React, { Fragment, useEffect, useState } from 'react';

import {
  Enum,
  RPMVendorDeviceType,
  States,
} from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Address,
  BaseEnum,
  Patient,
  RPMVendorModel,
} from '@vestahealthcare/common/models';
import RPMVendorDevice from '@vestahealthcare/common/models/RPMVendorDevice';
import {
  isValidAddress,
  isValidCity,
  isValidZipCode,
} from '@vestahealthcare/common/utils/format';

import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  Label,
  Modal,
  Select,
  TextInput,
  Tooltip,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import { OrderDeviceParams } from 'dash/src/services/RPMVendorServices';

interface Props {
  member: Patient;
  onClose: () => void;
  onSubmit: (
    params: Pick<
      OrderDeviceParams,
      'address' | 'deviceId' | 'fullName' | 'shippingMethod'
    >,
  ) => void;
  open: boolean;
}

export const OrderDeviceModal = ({
  member,
  onClose,
  onSubmit,
  open,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [models, setModels] = useState<RPMVendorModel[]>([]);
  const [shippingMethods, setShippingMethods] = useState<BaseEnum[]>([]);
  const [selectedModels, setSelectedModels] = useState<RPMVendorModel[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<{
    [x: string]: RPMVendorDevice;
  }>({});

  const [fullName, setFullName] = useState<string>('');

  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<States>();
  const [zip, setZIP] = useState<string>();

  const [shippingMethod, setShippingMethod] = useState<BaseEnum>();

  const getInitialData = async () => {
    const [models, methods] = await Promise.all([
      CacheServices.getSmartMeterModels(),
      CacheServices.getSmartMeterShippingMethods(),
    ]);
    setModels(models);
    setShippingMethods(methods);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const validateAddress = () =>
    isValidAddress({
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state,
      zipCode: zip || '',
    } as Address);

  const validate = () => {
    setSubmitted(true);

    if (!fullName) return;
    if (!validateAddress()) return;
    if (
      !selectedModels?.length ||
      !!selectedModels.find(
        ({ id, devices }) => devices?.length > 1 && !selectedDevices[`${id}`],
      )
    )
      return;
    if (!shippingMethod) return;

    setShowConfirmModal(true);
  };

  const onSave = async () => {
    setLoading(true);
    try {
      await onSubmit({
        address: {
          address1,
          address2,
          city,
          state,
          zipCode: zip,
        } as Address,
        deviceId: selectedModels
          .map(({ id, devices }) =>
            devices.length > 1 ? selectedDevices[`${id}`]?.id : devices[0].id,
          )
          .filter(Boolean),
        fullName,
        shippingMethod: shippingMethod?.id as string,
      });
      onClose();
    } finally {
      setShowConfirmModal(false);
      setLoading(false);
    }
    return true;
  };

  useEffect(() => {
    if (open) {
      setSelectedModels([]);
      setSelectedDevices({});
      setSubmitted(false);
      setLoading(false);

      setFullName(member.fullName);

      setAddress1(member.address.address1);
      setAddress2(member.address.address2);
      setCity(member.address.city);
      setState(member.address.state);
      setZIP(member.address.zipCode);
    }
  }, [open]);

  return (
    <>
      <CollapsableSidebar
        title={<h2>{translate('tasks.orderDeviceTitle')}</h2>}
        onClose={onClose}
        open={open}
        size={550}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper no-gap">
            <Label className="grid-span-12">{translate('tasks.devices')}</Label>
            {models.map((model) => {
              const checked = !!selectedModels?.find(
                ({ id }) => id === model.id,
              );
              return (
                <Fragment key={`order-device-${model.vendorId}`}>
                  <CheckboxWithLabel
                    className={`grid-span-${
                      checked && model.devices?.length > 1 ? 6 : 12
                    }`}
                    label={
                      <span className="flex gap">
                        <Tooltip text={model.type.toString()}>
                          <i
                            className={`icon-vesta ${RPMVendorDeviceType.getIcon(
                              model.type,
                            )}`}
                          />
                        </Tooltip>
                        {model.name}
                      </span>
                    }
                    checked={checked}
                    onChange={(checked) =>
                      setSelectedModels(
                        checked
                          ? [...selectedModels, model]
                          : [
                              ...selectedModels.filter(
                                ({ id }) => id !== model.id,
                              ),
                            ],
                      )
                    }
                  />
                  {checked && model.devices?.length > 1 && (
                    <Select
                      className="grid-span-6"
                      disableClearable
                      error={
                        submitted && checked && !selectedDevices[`${model.id}`]
                      }
                      getItemLabel={(item: RPMVendorDevice) =>
                        item?.size.toString()
                      }
                      items={model.devices}
                      onChange={(val: RPMVendorDevice) =>
                        setSelectedDevices({
                          ...selectedDevices,
                          [`${model.id}`]: val,
                        })
                      }
                      placeholder={translate('tasks.size')}
                      size="xs"
                      value={selectedDevices[`${model.id}`]}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
          <div className="grid-wrapper fit">
            <TextInput
              className="grid-span-12"
              data-cy="order-device-address-1"
              error={submitted && !fullName}
              label={translate('personalDetails.fullName')}
              onChange={(value) => setFullName(value || '')}
              value={fullName}
              size="small"
            />
            <TextInput
              className="grid-span-12"
              data-cy="order-device-address-1"
              error={submitted && !validateAddress()}
              label={translate('personalDetails.addressModal.address1')}
              onChange={setAddress1}
              value={address1}
              size="small"
            />
            <TextInput
              className="grid-span-12"
              data-cy="order-device-address-2"
              label={translate('personalDetails.addressModal.address2')}
              onChange={setAddress2}
              value={address2}
              size="small"
            />
            <TextInput
              className="grid-span-6"
              data-cy="order-device-city"
              error={
                (submitted &&
                  city &&
                  !isValidCity(city) &&
                  translate('global.cityFormatError')) ||
                (submitted && !validateAddress())
              }
              label={translate('personalDetails.addressModal.city')}
              onChange={setCity}
              value={city}
              size="small"
            />
            <Select
              className="grid-span-3"
              data-cy="care-team-state"
              disableClearable
              error={submitted && !validateAddress()}
              getItemLabel={(item: Selectable) => item.value}
              items={Enum.toSelectable(States.asArray)}
              label={translate('personalDetails.addressModal.state')}
              onChange={(val?: Selectable) => {
                const state = val ? States.byKey[val.value] : undefined;
                setState(state);
              }}
              value={state ? Enum.toSelectable([state])[0] : undefined}
              size="small"
            />
            <TextInput
              className="grid-span-3"
              data-cy="order-device-zip"
              error={
                (submitted &&
                  zip &&
                  !isValidZipCode(zip) &&
                  translate('global.zipFormatError')) ||
                (submitted && !validateAddress())
              }
              label={translate('personalDetails.addressModal.zip')}
              onChange={setZIP}
              value={zip}
              size="small"
            />
          </div>
          <div className="grid-span-12" />
          <Select
            className="grid-span-12"
            error={submitted && !shippingMethod}
            items={shippingMethods}
            label={translate('tasks.shippingMethod')}
            onChange={setShippingMethod}
            size="small"
            value={shippingMethod}
          />
          <br />
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="add-task-close" onClick={onClose}>
            {translate('global.close')}
          </Button>
          <Button
            color="secondary"
            data-cy="add-task-submit"
            disabled={
              !selectedModels.length ||
              !!selectedModels.find(
                ({ id, devices }) =>
                  devices?.length > 1 && !selectedDevices[`${id}`],
              )
            }
            loading={loading}
            onClick={validate}
          >
            {translate('tasks.placeOrder')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <Modal
        title={translate('global.warning')}
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onSubmit={onSave}
      >
        <p>{translate('tasks.confirmOrderDevice')}</p>
        <hr />
        <div className="grid-wrapper">
          <span className="grid-span-3">{translate('tasks.devices')}: </span>
          <span className="grid-span-9 semi-bold">
            {selectedModels
              .map(({ id, name, devices }) =>
                devices.length > 1
                  ? `${name} (${selectedDevices[`${id}`]?.size?.toString()})`
                  : name,
              )
              ?.filter(Boolean)
              .join(', ')}
          </span>
          <span className="grid-span-3">{translate('tasks.fullName')}: </span>
          <span className="grid-span-9 semi-bold">{fullName}</span>
          <p className="grid-span-3">{translate('tasks.address')}:</p>
          <div className="grid-span-9">
            <p className="no-margin semi-bold">{address1}</p>
            {address2 && <p className="no-margin semi-bold">{address2}</p>}
            <p className="no-margin semi-bold">
              {city}, {state?.value}, {zip}
            </p>
          </div>
          <span className="grid-span-3">
            {translate('tasks.shippingMethod')}:{' '}
          </span>
          <span className="grid-span-9 semi-bold">
            {shippingMethod?.description}
          </span>
        </div>
      </Modal>
    </>
  );
};

export default OrderDeviceModal;
