import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodReportingDataExtended } from '@vestahealthcare/common/models';

import {
  BackgroundColors,
  Colors,
  Fonts,
  IconButton,
  PlusMinusIcon,
  Table,
} from 'styleguide-v2';
import { Column, TableItem } from 'styleguide-v2/src/components/Table';

type Data = PodReportingDataExtended & TableItem;

type Props = {
  actionItems?: string[];
  className?: string;
  data: Data[];
  extraData?: { [key: string]: React.ReactNode };
  getItemLabel?: (item: Data) => string;
  getRowClass?: (item: Data) => string;
  getRowOnClick?: (
    item: Data,
    parentItem?: Data,
  ) => ((item: Data) => void) | undefined;
  level?: number;
  header: string;
  onClick?: (data: Data) => void;
  parentItem?: Data;
};

const POD_REPORTING_DATA_COLUMNS = (
  {
    actionItems,
    getItemLabel,
    getRowOnClick,
    header,
    extraData,
    level = 0,
    onClick,
    parentItem,
  }: Props,
  onOpenDetail: (item: Data) => void,
  styles: ClassNameMap<'collapseButton'>,
) => {
  const columns = [
    {
      headerName: header,
      component: (item: Data) => (
        <div className="flex gap" style={{ marginLeft: `${level * 15}px` }}>
          {item.subtotals?.length ? (
            <IconButton
              className={classNames(styles.collapseButton, 'no-padding')}
              onClick={() => onOpenDetail(item)}
              size="small"
            >
              <PlusMinusIcon open={!!item.open} size="small" />
            </IconButton>
          ) : (
            <div style={{ width: '10px' }} />
          )}
          <span>{getItemLabel ? getItemLabel(item) : item.name}</span>
        </div>
      ),
    },
    {
      headerName: translate('podReporting.table.total'),
      component: ({ name, total }: Data) => (
        <span className="flex gap">
          {total}
          {(extraData && extraData[name]) || ''}
        </span>
      ),
      width: 100,
    },
  ];
  const clickableColumn = {
    headerName: '',
    component: (item: Data) => {
      const handler =
        onClick || (getRowOnClick && getRowOnClick(item, parentItem));
      return handler ? (
        (!actionItems || actionItems?.includes(item.name)) && (
          <IconButton
            className="no-padding"
            onClick={() => handler(item)}
            size="small"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        )
      ) : (
        <></>
      );
    },
    width: 40,
  };

  return [...columns, clickableColumn].filter(Boolean) as Column[];
};

const useStyles = makeStyles({
  collapseButton: {
    '&&': {
      marginTop: '0.125rem',
    },
  },
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& > tr.detail > td': {
        background: Colors.white,
        padding: '0',
      },
    },
  },
  subtable: {
    '&& thead': {
      display: 'none',
    },
    '&& tbody': {
      borderRadius: 0,
      boxShadow: 'none',
      borderLeft: `1px solid ${BackgroundColors.grayLine}`,
      borderRight: `1px solid ${BackgroundColors.grayLine}`,

      '&& tr td': {
        padding: '6px 12px',
      },
    },
    '&& tbody tbody': {
      borderLeft: 0,
      borderRight: 0,
    },
  },
});

export const PodReportingProgramTable = (props: Props) => {
  const styles = useStyles();
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onOpenDetail = (item: Data) => {
    const itemIdx = data.findIndex((i) => i.name === item.name);
    if (itemIdx === -1) return;

    data[itemIdx].open = !data[itemIdx].open;
    if (data[itemIdx].open) {
      data[itemIdx].detail = (
        <PodReportingProgramTable
          className={styles.subtable}
          data={item.subtotals as Data[]}
          extraData={props.extraData}
          getItemLabel={props.getItemLabel}
          getRowClass={props.getRowClass}
          getRowOnClick={props.getRowOnClick}
          header=""
          level={props.level ? props.level + 1 : 1}
          parentItem={item}
        />
      );
    } else {
      delete data[itemIdx].detail;
      setData([...data]);
    }
    setData([...data]);
  };

  return (
    <Table
      className={classNames(props.className, styles.table)}
      config={{
        columns: POD_REPORTING_DATA_COLUMNS(props, onOpenDetail, styles),
        compact: true,
        data: props.data,
        detail: true,
        getRowClass: (data) =>
          classNames(
            !props.actionItems?.includes(data.name) && 'row-no-clickable',
            props.getRowClass && props.getRowClass(data),
          ),
        paginationOptions: [-1],
      }}
      whiteBackground
    />
  );
};

export default PodReportingProgramTable;
