import React, { forwardRef, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '@vestahealthcare/common/i18n';
import { Patient, PatientSearch } from '@vestahealthcare/common/models';

import { SelectMemberOption } from 'styleguide-v2';
import { Select } from 'styleguide-v2/src/components/Select';

import { searchPatientsV2 } from 'dash/src/services/PatientServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';

type Props = {
  className?: string;
  'data-cy'?: string;
  defaultValue?: PatientSearch | Patient | Patient[];
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  marginTopPaper?: boolean;
  multiple?: boolean;
  onChange:
    | ((member: PatientSearch) => void)
    | ((members: PatientSearch[]) => void);
  placeholder?: string;
  value?: PatientSearch | Patient | Patient[] | null;
  required?: boolean;
  size?: 'xs' | 'small' | 'medium';
};

export const SearchMember = forwardRef(
  (
    {
      className,
      'data-cy': dataCy,
      defaultValue,
      disabled,
      error,
      label,
      marginTopPaper,
      multiple,
      onChange,
      placeholder,
      value,
      required,
      size,
    }: Props,
    ref,
  ) => {
    const [members, setMembers] = useState<PatientSearch[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const queryString = useDebounce(filter ? String(filter) : undefined, 300);

    const searchMembers = async (queryString: string) => {
      setLoading(true);
      const { items: members } = await searchPatientsV2({
        queryString,
        limit: 25,
      });
      setMembers(members);
      setTimeout(() => setLoading(false), 0);
    };

    const handleInputChange = (value: string, reason?: string) => {
      if (reason !== 'reset') {
        setFilter(value);
      }
    };

    useEffect(() => {
      if (filter?.length > 2) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }, [filter]);

    useEffect(() => {
      if (queryString && queryString?.length > 2) {
        searchMembers(queryString);
      } else if (!queryString) {
        setMembers([]);
      }
    }, [queryString]);

    return (
      <Select
        avoidBlurClean
        className={className}
        data-cy={dataCy}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        getItemLabel={({ fullName }) => fullName || ''}
        filterOptions={(options) => options}
        icon={<SearchIcon />}
        items={loading ? [] : members}
        label={label}
        loading={loading}
        marginTopPaper={marginTopPaper}
        multiple={multiple}
        noOptionsText={
          filter.length > 2
            ? translate('components.searchMembers.noMembersFound')
            : translate('components.searchMembers.typeToSearch')
        }
        onChange={onChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        renderOption={(member: Patient) => (
          <SelectMemberOption member={member} filterString={filter} />
        )}
        value={value}
        required={required}
        ref={ref}
        size={size}
      />
    );
  },
);
