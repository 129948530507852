import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';

import { ProgramTaskStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PodReportingCCM,
  PodReportingData,
  PodReportingGroup,
  PodReportingTask,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, Summary, ToggleButton, Tooltip } from 'styleguide-v2';

import RecursiveTable, {
  RecursiveTableData,
} from 'dash/src/components/RecursiveTable';

import { PodReportingDataTable } from './PodReportingDataTable';
import { PodReportingStyles } from './PodReportingStyles';
import { PodReportingTaskTable } from './PodReportingTaskTable';
import {
  POD_REPORTING_TAB_ALL,
  POD_REPORTING_TAB_ITEMS_COMPLEX,
  POD_REPORTING_TAB_ITEMS_WORKLIST,
  POD_REPORTING_TAB_WORKLIST_GROUPS,
  POD_REPORTING_TAB_WORKLIST_TASKS,
} from './PodReportingUtils';
import { PodReportingWorklistTable } from './PodReportingWorklistTable';

type Props = {
  data?: PodReportingCCM;

  defaultComplexTab?: string;
  defaultWorklistTab?: string;

  onClickCompleted: () => void;
  onClickAttempted: () => void;
  onClickNotStarted: () => void;

  onClickMinutes: (item: PodReportingData) => void;
  onClickProvider: (item: PodReportingData, providerTab: string) => void;
  onClickStatus: (item: PodReportingData) => void;
  onClickTaskEscalations:
    | ((
        item: RecursiveTableData,
      ) => ((item: RecursiveTableData) => void) | undefined)
    | undefined;
  onClickTaskStatus?: (
    item: PodReportingTask,
    status?: ProgramTaskStatus[],
  ) => void;
  onClickWorklist: (item: PodReportingGroup) => void;

  peStatuses: ProgramExtensionStatus[];
};

export const PodReportingCCMPage = ({
  data,
  defaultComplexTab,
  defaultWorklistTab,
  onClickAttempted,
  onClickCompleted,
  onClickMinutes,
  onClickNotStarted,
  onClickProvider,
  onClickStatus,
  onClickTaskEscalations,
  onClickTaskStatus,
  onClickWorklist,
  peStatuses,
}: Props) => {
  const { showAdminPodsPage } = useFlags();
  const styles = PodReportingStyles();

  const [providerTab, setProviderTab] = useState<string>(
    defaultComplexTab || POD_REPORTING_TAB_ALL,
  );
  const [worklistTab, setWorklistTab] = useState<string>(
    defaultWorklistTab || POD_REPORTING_TAB_WORKLIST_TASKS,
  );

  if (!data) return <></>;

  const {
    ccmTasks,
    ccmEscalations,
    minutes,
    minutesToNextCpt,
    providerTime,
    providerTimeComplex,
    status,
    summary,
    worklistGroups,
  } = data;

  return (
    <>
      <div
        className={classNames(
          'flex spaced',
          styles.borderBottom,
          styles.paddingContainer,
        )}
      >
        <Summary
          color={Colors.textGreen}
          title={translate('podReporting.summary.total')}
          body={summary?.total !== undefined ? summary.total : EMPTY}
          footer={
            <>
              <span className="bold">{summary ? 100 : EMPTY}%</span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          tooltip={translate('podReporting.tooltips.total')}
        />
        <Summary
          color={Colors.iconGreen}
          title={translate('podReporting.summary.completed')}
          body={summary?.completed !== undefined ? summary.completed : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.completed / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={onClickCompleted}
          tooltip={translate('podReporting.tooltips.completed')}
        />
        <Summary
          color={Colors.gold}
          title={translate('podReporting.summary.attempted')}
          body={summary?.attempted !== undefined ? summary.attempted : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.attempted / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={onClickAttempted}
          tooltip={translate('podReporting.tooltips.attempted')}
        />
        <Summary
          color={Colors.darkOrange}
          title={translate('podReporting.summary.notStarted')}
          body={summary?.notStarted !== undefined ? summary.notStarted : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.notStarted / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={onClickNotStarted}
          tooltip={translate('podReporting.tooltips.notStarted')}
        />
        <Summary
          color={Colors.gray}
          title={translate('podReporting.summary.businessDays')}
          body={
            summary
              ? `${summary.businessDaysRemaining} / ${summary.businessDaysTotal}`
              : EMPTY
          }
          footer={<i>{translate('podReporting.summary.businessDaysFooter')}</i>}
          size="s"
          tooltip={translate('podReporting.tooltips.businessDays')}
        />
        <Summary
          color={Colors.lightGray}
          title={translate('podReporting.summary.patientsPerDay')}
          body={summary ? `${summary.perDay} / ${summary.perDayGoal}` : EMPTY}
          footer={
            <i>{translate('podReporting.summary.patientsPerDayFooter')}</i>
          }
          size="s"
          tooltip={translate('podReporting.tooltips.patientsPerDay')}
        />
      </div>
      <div className={classNames('grid-wrapper', styles.borderContainer)}>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleMinutes')}
            </h2>
          </div>
          <PodReportingDataTable
            data={minutes}
            header={translate('podReporting.table.minutes')}
            onClick={onClickMinutes}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleMinutesCPT')}
            </h2>
          </div>
          <PodReportingDataTable
            data={minutesToNextCpt}
            header={translate('podReporting.table.minutes')}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex spaced wrap">
            <div className="flex gap">
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleProviderTime')}
              </h2>
            </div>
            <ToggleButton
              color="info"
              items={POD_REPORTING_TAB_ITEMS_COMPLEX}
              onChange={setProviderTab}
              size="xs"
              value={providerTab}
            />
          </div>
          <PodReportingDataTable
            data={
              providerTab === POD_REPORTING_TAB_ALL
                ? providerTime
                : providerTimeComplex
            }
            header={translate('podReporting.table.providerTime')}
            onClick={(data) => onClickProvider(data, providerTab)}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleRPMStatus')}
              {' - '}
              <span className={styles.subsubtitle}>
                {translate('podReporting.monthDoesNotApply')}
              </span>
            </h2>
          </div>
          <PodReportingDataTable
            data={status.map((item) => ({
              ...item,
              name:
                peStatuses?.find(({ id }) => id === item.name)?.description ||
                item.name,
            }))}
            header={translate('podReporting.table.status')}
            onClick={onClickStatus}
          />
        </div>
        {showAdminPodsPage && (
          <div className="grid-span-6">
            <div className="flex gap">
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleEscalations')}
              </h2>
            </div>
            <RecursiveTable
              data={ccmEscalations.map((escalation) => ({
                id: escalation.id,
                name: escalation.name,
                column1: escalation.total,
                subtotals: escalation.subtotals?.map((e) => ({
                  name: e.name,
                  column1: e.total,
                })),
              }))}
              header="Task"
              getRowOnClick={onClickTaskEscalations}
            />
          </div>
        )}
        <div className={classNames('grid-span-12', styles.paddingContainer)}>
          <div className="flex spaced wrap">
            <h2 className={styles.subtitle}>
              <div className="flex gap">
                <h2 className={styles.subtitle}>
                  {translate(
                    `podReporting.${
                      !showAdminPodsPage ||
                      worklistTab === POD_REPORTING_TAB_WORKLIST_GROUPS
                        ? 'titleWorklist'
                        : 'titleTasks'
                    }`,
                  )}
                </h2>
              </div>
              {worklistTab === POD_REPORTING_TAB_WORKLIST_GROUPS && (
                <Tooltip
                  text={translate('podReporting.tooltips.worklistGroups')}
                >
                  <HelpIcon color="action" />
                </Tooltip>
              )}
            </h2>
            {showAdminPodsPage && (
              <ToggleButton
                color="info"
                items={POD_REPORTING_TAB_ITEMS_WORKLIST}
                onChange={setWorklistTab}
                size="xs"
                value={worklistTab}
              />
            )}
          </div>
          {(!showAdminPodsPage ||
            worklistTab === POD_REPORTING_TAB_WORKLIST_GROUPS) && (
            <PodReportingWorklistTable
              data={worklistGroups}
              onClick={onClickWorklist}
            />
          )}
          {showAdminPodsPage &&
            worklistTab === POD_REPORTING_TAB_WORKLIST_TASKS && (
              <PodReportingTaskTable
                data={ccmTasks}
                onClickTaskStatus={onClickTaskStatus}
              />
            )}
        </div>
      </div>
    </>
  );
};
