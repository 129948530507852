import { Moment } from 'moment-timezone';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterEscalatedReason,
  EncounterMethod,
  EncounterMethodOutcome,
  EncounterType,
} from '@vestahealthcare/common/enums';
import { Encounter, Task } from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api from 'dash/src/services/Api';

import { PaginatedResponse } from '.';

export type EncounterParams = {
  callbackDateTime: Moment;
  careTeamMemberId?: number;
  channel?: EncounterChannel;
  communityResourceId?: number;
  direction: EncounterDirection;
  duration: number;
  encounterDate: Moment;
  escalatedToId?: number;
  escalationReason?: EncounterEscalatedReason;
  escalationReasonOther?: string;
  method: EncounterMethod;
  methodOutcome?: EncounterMethodOutcome;
  note?: string;
  otherChannel?: string;
  programTaskId?: number;
  task?: Task;
  transferredToId?: number;
  type: EncounterType;
};

export interface EncounterFetchParams {
  ids?: string[];
  direction?: string;
  method?: string;
  channel?: string;
  encounterDateTimeStart?: string;
  encounterDateTimeEnd?: string;
  source?: string;
  createdById?: number;
  taskIds?: number[];
  alertIds?: number[];
  valid?: boolean;
  offset?: number;
  limit?: number;
  sort?: string;
}

export const fetchAllEncounters = async (
  patientId: number | string,
  params: EncounterFetchParams,
): Promise<PaginatedResponse<Encounter>> => {
  const {
    encounterTimeTrackings: { items, pagination },
  } = await Api.getv2(`patients/${patientId}/encounter-time-trackings`, params);
  return { items: items.map((i: any) => new Encounter(i)), pagination };
};

export const fetchEncounters = async (patientId: number | string) => {
  const { encounterTimeTrackings } = await Api.getv2(
    `patients/${patientId}/encounter-time-trackings`,
  );
  return encounterTimeTrackings.items.map((item: any) => new Encounter(item));
};

export const addEncounter = async (
  patientId: number | string,
  params: EncounterParams,
) => {
  const transformedParams = {
    callbackDateTime: params.callbackDateTime?.format(),
    careTeamMemberId: params.careTeamMemberId,
    communityResourceId: params.communityResourceId,
    channel: params.channel?.valueOf(),
    direction: params.direction.valueOf(),
    duration: params.duration,
    encounterDate: params.encounterDate.format(DATE_FORMAT),
    encounterDateTime: params.encounterDate.format(),
    escalationReason: params.escalationReason?.value,
    escalationReasonOther: params.escalationReasonOther,
    escalatedToId: params.escalatedToId,
    method: params.method.valueOf(),
    methodOutcome: params.methodOutcome?.valueOf(),
    note: params.note,
    otherChannel: params.otherChannel,
    programTaskId: params.programTaskId,
    source: 'dash',
    taskId: params.task?.id,
    transferredToId: params.transferredToId,
    type: params.type.valueOf(),
  };
  const { encounterTimeTracking } = await Api.postv2JSON(
    `patients/${patientId}/encounter-time-trackings`,
    transformedParams,
  );
  return new Encounter(encounterTimeTracking);
};

export const editEncounter = async (
  patientId: number | string,
  encounterId: number | string,
  params: EncounterParams,
) => {
  const transformedParams = {
    callbackDateTime: params.callbackDateTime?.format(),
    careTeamMemberId: params.careTeamMemberId,
    communityResourceId: params.communityResourceId,
    channel: params.channel?.valueOf(),
    direction: params.direction.valueOf(),
    duration: params.duration,
    escalationReason: params.escalationReason?.value,
    escalationReasonOther: params.escalationReasonOther,
    escalatedToId: params.escalatedToId,
    encounterDate: params.encounterDate.format(DATE_FORMAT),
    encounterDateTime: params.encounterDate.format(),
    method: params.method.valueOf(),
    methodOutcome: params.methodOutcome?.valueOf(),
    note: params.note,
    otherChannel: params.otherChannel,
    programTaskId: params.programTaskId,
    source: 'dash',
    transferredToId: params.transferredToId,
    type: params.type.valueOf(),
  };
  const { encounterTimeTracking } = await Api.putv2JSON(
    `patients/${patientId}/encounter-time-trackings/${encounterId}`,
    transformedParams,
  );
  return new Encounter(encounterTimeTracking);
};

export const invalidateEncounter = async (
  patientId: number | string,
  encounterId: number,
) =>
  await Api.postv2JSON(
    `patients/${patientId}/encounter-time-trackings/${encounterId}/actions/invalidate`,
  );
