import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { Organization } from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, FileInput, Select } from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';

interface Props {
  'data-cy'?: string;
  open?: boolean;
  onCancel?: () => void;
  onSubmit: (referral: Organization, file: File) => void;
}

export const UploadRosterFileModal = (props: Props) => {
  const { open, onCancel, onSubmit } = props;

  const [submitted, setSubmitted] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [referrals, setReferrals] = useState<Organization[]>([]);

  const [referral, setReferral] = useState<Organization>();
  const [file, setFile] = useState<File>();

  const fetchData = async () => {
    const organizations = await CacheServices.getOrganizations();
    setReferrals(organizations.filter(({ hasValidDate }) => hasValidDate));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const reset = () => {
    setFile(undefined);
    setReferral(undefined);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const validate = () =>
    file && ['csv', 'txt'].includes(file.name?.split('.')?.pop() || '');

  const submit = async () => {
    setSubmitted(true);
    if (!referral || !file || !validate()) return;
    setLoadingSubmit(true);
    await onSubmit(referral, file);
    setLoadingSubmit(false);
  };

  const cancelCB = () => {
    onCancel && onCancel();
  };

  return (
    <CollapsableSidebar
      onClose={cancelCB}
      open={!!open}
      title={<h2>{translate('rosterFileHistory.uploadRawRosterFile')}</h2>}
      size={450}
    >
      <CollapsableSidebar.Body data-cy="upload-referral-file-body">
        <Select
          data-cy="upload-roster-file-history-referral"
          error={submitted && !referral}
          getItemLabel={({ name }: Organization) => name}
          items={referrals}
          label={translate('rosterFileHistory.referralSource')}
          onChange={setReferral}
          required
          value={referral}
        />
        <FileInput
          accept=".csv,.txt"
          data-cy="upload-roster-file-history-file"
          error={
            submitted &&
            (file
              ? !validate() && translate('rosterFileHistory.errorFileExtension')
              : true)
          }
          file={file}
          label={translate('rosterFileHistory.uploadFile')}
          onChange={setFile}
          required
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button data-cy="cancel-button" color="tertiary" onClick={cancelCB}>
          {translate('global.cancel')}
        </Button>
        <Button
          data-cy="save-button"
          color="secondary"
          loading={loadingSubmit}
          onClick={submit}
        >
          {translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
