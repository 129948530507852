import { Moment } from 'moment';

import { makeUnix } from './Model';
import { ProgramTaskBase } from './ProgramTaskBase';

export class CCMTask extends ProgramTaskBase {
  callback?: Moment;

  ccmValidMinutes = 0;

  minMinutesNextCPT = 0;

  attempts = 0;

  constructor(obj: any) {
    super({
      id: obj.taskId,
      name: obj.taskName,
      ...obj,
    });

    this.ccmValidMinutes = obj.ccmValidMinutes;
    this.minMinutesNextCPT = obj.minMinutesNextCPT;
    this.attempts = obj.attempts;

    this.callback = makeUnix(obj.callback);
  }
}

export default CCMTask;
