import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';

type Props = {
  className?: string;
  children?: ReactNode;
  'data-cy'?: string;
};

const useStyles = makeStyles({
  container: {
    alignItems: 'end',
    background: Colors.iconGray,
    display: 'flex',
    flexGrow: 1,
    height: '4px',
    justifyContent: 'center',
    minWidth: '1rem',
    paddingRight: '4px',
    position: 'relative',

    '&::after': {
      borderBottom: '6px solid transparent',
      borderLeft: `10px solid ${Colors.iconGray}`,
      borderTop: '6px solid transparent',
      bottom: '-4px',
      content: '""',
      height: 0,
      position: 'absolute',
      right: '-4px',
      width: 0,
    },
  },
});

export const Arrow = ({ className, children, 'data-cy': dataCy }: Props) => {
  const styles = useStyles();
  return (
    <div className={classNames(className, styles.container)} data-cy={dataCy}>
      {children}
    </div>
  );
};
