import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ForwardIcon from '@mui/icons-material/Forward';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { CareTeamCandidate } from '@vestahealthcare/common/models';

import {
  Button,
  CollapseIcon,
  Colors,
  IconButton,
  Panel,
  PhoneLink,
  Table,
  UpdatedBy,
} from 'styleguide-v2';

type Props = {
  className?: string;
  candidates: CareTeamCandidate[];
  onCreate: (candidate: CareTeamCandidate) => void;
  onDelete: (candidate: CareTeamCandidate) => void;
};

const CARE_TEAM_CANDIDATE_COLUMNS = (
  { onCreate, onDelete }: Props,
  active: boolean,
) => [
  {
    headerName: translate('careTeam.common.name'),
    component: ({ fullName }: CareTeamCandidate) => fullName,
    width: 350,
  },
  {
    headerName: translate('careTeam.common.role'),
    component: ({ typeWithSubTypeLabel }: CareTeamCandidate) =>
      typeWithSubTypeLabel,
    width: 350,
  },
  {
    headerName: translate('careTeam.common.phones'),
    component: ({ phone }: CareTeamCandidate) =>
      phone && (
        <PhoneLink phone={phone?.number} type={phone?.type?.toString()} />
      ),
    width: 175,
  },
  {
    headerName: translate(`careTeam.common.${active ? 'created' : 'deleted'}`),
    component: ({ createdAt, deletedAt }: CareTeamCandidate) => (
      <UpdatedBy date={deletedAt || createdAt} />
    ),
  },
  {
    headerName: ' ',
    component: (candidate: CareTeamCandidate) =>
      active ? (
        <div className="flex gap">
          <IconButton
            size="small"
            onClick={() => onCreate(candidate)}
            tooltip={translate('careTeam.actions.createCandidate')}
          >
            <ForwardIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => onDelete(candidate)}
            tooltip={translate('careTeam.actions.deleteCandidate')}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <></>
      ),
    width: 50,
  },
];

const useStyles = makeStyles({
  table: {
    '&&.table-empty': {
      padding: '1rem 0',
      textAlign: 'start',
    },
  },
  showInactiveButton: {
    '&&': {
      display: 'flex',
      margin: '0.5rem 0 0 auto',
    },
  },
  inactiveRow: {
    '&&': {
      backgroundColor: `${Colors.lighterGray}!important`,
    },
  },
});

export const CareTeamCandidateTable = (props: Props) => {
  const styles = useStyles();
  const { className, candidates, onDelete } = props;

  const [showInactive, setShowInactive] = useState<boolean>(false);

  const activeCandidates = candidates.filter(({ deletedAt }) => !deletedAt);
  const inactiveCandidates = candidates.filter(({ deletedAt }) => deletedAt);

  const activeColumns = useMemo(
    () => CARE_TEAM_CANDIDATE_COLUMNS(props, true),
    [onDelete],
  );
  const inactiveColumns = useMemo(
    () => CARE_TEAM_CANDIDATE_COLUMNS(props, false),
    [onDelete],
  );

  return (
    <>
      <Table
        className={classNames(className, styles.table)}
        config={{
          columns: activeColumns,
          compact: true,
          data: activeCandidates,
          paginationOptions: [-1],
        }}
        fontSize="small"
        empty
      />
      {!!inactiveCandidates?.length && (
        <>
          <Button
            className={styles.showInactiveButton}
            onClick={() => setShowInactive(!showInactive)}
            color="quaternary"
            size="xs"
          >
            {showInactive
              ? 'Hide Inactive Candidates'
              : 'Show Inactive Candidates'}
            <CollapseIcon open={showInactive} color="disabled" />
          </Button>
          <Panel.Collapse open={showInactive}>
            <Table
              className={classNames(className, styles.table)}
              config={{
                columns: inactiveColumns,
                compact: true,
                data: inactiveCandidates,
                getRowClass: () => styles.inactiveRow,
                paginationOptions: [-1],
              }}
              fontSize="small"
            />
          </Panel.Collapse>
        </>
      )}
    </>
  );
};

export default CareTeamCandidateTable;
