import { Moment } from 'moment';

import { ProgramTaskStatus } from '../enums';
import { Employee } from './Employee';
import { make, makeMoment } from './Model';
import { ProgramTaskMember } from './ProgramTaskMember';

export class ProgramTaskBase {
  id = 0;

  name = '';

  assignee: Employee;

  taskDate: Moment;

  member: ProgramTaskMember;

  status: ProgramTaskStatus;

  constructor(obj: any) {
    Object.assign(this, obj);

    this.assignee = make(obj.assignee, Employee);
    this.member = make(obj.member, ProgramTaskMember);
    this.taskDate = makeMoment(obj.taskDate) as Moment;
    this.status = ProgramTaskStatus.byKey[obj.status];
  }
}

export default ProgramTaskBase;
