import { Enum } from './Enum';

export class ProgramTaskStatus extends Enum {
  translationKey = 'programTaskStatus';

  static asArray: ProgramTaskStatus[] = [];

  static asArrayOpened: ProgramTaskStatus[] = [];

  static byKey: { [name: string]: ProgramTaskStatus } = {};

  static readonly NOT_STARTED = new ProgramTaskStatus('NOT_STARTED');

  static readonly IN_PROGRESS = new ProgramTaskStatus('IN_PROGRESS');

  static readonly AWAITING_CONFIRMATION = new ProgramTaskStatus(
    'AWAITING_CONFIRMATION',
  );

  static readonly TRANSFERRED = new ProgramTaskStatus('TRANSFERRED');

  static readonly ESCALATED = new ProgramTaskStatus('ESCALATED');

  static readonly COMPLETED = new ProgramTaskStatus('COMPLETED', true);

  static readonly VOID = new ProgramTaskStatus('VOID', true);

  static readonly CANCELLED = new ProgramTaskStatus('CANCELLED', true);

  static readonly INCOMPLETE = new ProgramTaskStatus('INCOMPLETE', true);

  constructor(public readonly value: string, closed?: boolean) {
    super();
    ProgramTaskStatus.asArray.push(this);
    if (!closed) {
      ProgramTaskStatus.asArrayOpened.push(this);
    }
    ProgramTaskStatus.byKey[value] = this;
  }

  static getColor(status: ProgramTaskStatus | string) {
    const value = typeof status === 'string' ? status : status.value;
    switch (value) {
      case ProgramTaskStatus.NOT_STARTED.value:
        return 'info';
      case ProgramTaskStatus.ESCALATED.value:
      case ProgramTaskStatus.INCOMPLETE.value:
        return 'error';
      case ProgramTaskStatus.COMPLETED.value:
        return 'success';
      case ProgramTaskStatus.VOID.value:
        return 'closed';
      default:
        return 'warning';
    }
  }
}

export default ProgramTaskStatus;
