import classNames from 'classnames';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryForecast } from '@vestahealthcare/common/models';

import { Chip } from 'styleguide-v2';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryForecast;
};

export const PodSummaryCardProgramForecast = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();

  return (
    <div className={className}>
      <div className={classNames(styles.itemContainer)}>
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.programSummary')}
        </span>
        <div className={styles.titleInfo}>
          <div className={styles.chipsContainer}>
            <Chip color="info" type="contained-2">
              <span className="bold">CCM: {pod.ccmCount}</span>
            </Chip>
            <Chip color="info" type="contained-2">
              <span className="bold">RPM: {pod.rpmCount}</span>
            </Chip>
            <Chip color="info" type="contained-2">
              <span className="bold">TOC: {pod.tocCount}</span>
            </Chip>
            <Chip color="info" type="contained-2">
              <span className="bold">Skilled: {pod.tocCount}</span>
            </Chip>
            <Chip color="info" type="contained-2">
              <span className="bold">PERS: {pod.persCount}</span>
            </Chip>
          </div>
        </div>
      </div>
    </div>
  );
};
