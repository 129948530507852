import { SelectableInfo } from '../enums/Enum';
import { make } from './Model';
import { ReferralMappingOperation } from './ReferralMappingOperation';

export class ReferralIngestionMapperColumn {
  default = '';

  name = '';

  required = '';

  transformation: ReferralMappingOperation;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.transformation = make(obj.transformation, ReferralMappingOperation);
  }

  static toSelectable(
    items: ReferralIngestionMapperColumn[],
  ): SelectableInfo<ReferralIngestionMapperColumn>[] {
    return items.map((item) => ({
      value: item.name,
      label: item.name,
      info: item,
    }));
  }

  get value() {
    return this.name;
  }
}

export default ReferralIngestionMapperColumn;
