import moment from 'moment';
import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { ClassNameMap, makeStyles } from '@mui/styles';

import {
  AsyncTaskLeadStatus,
  AsyncTaskType,
  CareTeamRosterTaskStatus,
} from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  AsyncTask,
  CareTeamRosterTask,
  PaginationType,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  Colors,
  Spinner,
  StatusIndicator,
  Table,
  UpdatedBy,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import {
  downloadAsyncComparisonReport,
  downloadAsyncResult,
  downloadCTAsyncResult,
} from 'dash/src/services/AsyncServices';

const COMPARISON_REPORT_RELEASE_DATE = moment('2022-06-21');

function getCTStatus(status: CareTeamRosterTaskStatus) {
  if (status === CareTeamRosterTaskStatus.COMPLETED) {
    return <StatusIndicator status={status.toString()} color="success" />;
  }
  if (status === CareTeamRosterTaskStatus.IN_PROGRESS) {
    return <StatusIndicator status={status.toString()} color="loading" />;
  }
  if (status === CareTeamRosterTaskStatus.ERROR) {
    return <StatusIndicator status={status.toString()} color="error" />;
  }
  return status;
}

function getUploadStatus(upload: AsyncTask) {
  if (upload instanceof CareTeamRosterTask) return <></>;
  if (upload.error) {
    return <StatusIndicator status="Failed" color="error" />;
  }

  return upload.result ? (
    <StatusIndicator status="Completed" color="success" />
  ) : (
    <div className="flex gap">
      <Spinner width={15} color="primary" />
      <p style={{ minWidth: '5rem' }}>{upload.percentage.toFixed(0)}%</p>
    </div>
  );
}

function getTbaStatus(status: string) {
  if (status === 'COMPLETE') {
    return <StatusIndicator status="Completed" color="success" />;
  }
  if (status === 'ERROR') {
    return <StatusIndicator status="Error" color="error" />;
  }
  if (status === 'N_A') {
    return translate('global.na');
  }
  return status;
}

function getLeadStatus(
  upload: AsyncTask,
  onReleaseToSF?: (upload: AsyncTask) => void,
) {
  if (upload.futureLeadsStatus === AsyncTaskLeadStatus.DEFAULT) {
    return (
      <StatusIndicator
        status={upload.futureLeadsStatus.toString()}
        color="closed"
      />
    );
  }
  if (upload.futureLeadsStatus === AsyncTaskLeadStatus.ON_HOLD) {
    return (
      <StatusIndicator
        status={upload.futureLeadsStatus.toString()}
        color="warning"
        subtitle={
          onReleaseToSF ? (
            <a
              style={{
                color: Colors.iconGreen,
                textDecoration: 'underline',
              }}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                onReleaseToSF(upload);
              }}
            >
              Release to Outreach
            </a>
          ) : undefined
        }
      />
    );
  }
  if (upload.futureLeadsStatus === AsyncTaskLeadStatus.RELEASED) {
    return (
      <StatusIndicator
        status={upload.futureLeadsStatus.toString()}
        color="success"
      />
    );
  }
  return upload.futureLeadsStatus;
}

type Props = {
  className?: string;
  onChangePage: (page: number) => void;
  onChangePageSize: (page: number) => void;
  onReleaseToSF: (task: AsyncTask) => void;
  pagination?: PaginationType;
  pastUploads: (AsyncTask | CareTeamRosterTask)[];
  rosterType: AsyncTaskType;
};

const HISTORY_TABLE_COLUMNS = (
  { onReleaseToSF, rosterType }: Props,
  styles: ClassNameMap<'downloadButton'>,
) => {
  let columns: Column[] = [
    {
      headerName: translate('rosterIngestion.table.upload'),
      component: (upload: AsyncTask | CareTeamRosterTask) => (
        <UpdatedBy
          date={upload.createdAt}
          user={upload.createdBy.fullName || EMPTY}
        />
      ),
      width: 175,
    },
    {
      headerName: translate('rosterIngestion.table.rosterId'),
      component: (upload: AsyncTask | CareTeamRosterTask) =>
        upload instanceof AsyncTask ? upload.rosterId : upload.id,
      width: 125,
    },
  ];

  if (rosterType === AsyncTaskType.MEMBER_ROSTER) {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('rosterIngestion.table.referral'),
          field: 'referralOrgName',
        },
        {
          headerName: translate('rosterIngestion.table.records'),
          component: ({ total }: AsyncTask) => total - 1,
          width: 125,
        },
        {
          headerName: translate('rosterIngestion.table.ingestionStatus'),
          component: getUploadStatus,
          width: 175,
        },
      ],
    ];
  } else {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('rosterIngestion.table.description'),
          field: 'description',
        },
        {
          headerName: translate('rosterIngestion.table.records'),
          component: ({ numRows }: CareTeamRosterTask) => numRows,
          width: 125,
        },
        {
          headerName: translate('rosterIngestion.table.ingestionStatus'),
          component: ({ status }: CareTeamRosterTask) => getCTStatus(status),
          width: 175,
        },
      ],
    ];
  }

  if (rosterType === AsyncTaskType.MEMBER_ROSTER) {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('rosterIngestion.table.tbaStatus'),
          component: ({ tbaStatus }: AsyncTask) =>
            getTbaStatus(tbaStatus || ''),
          width: 175,
        },
        {
          headerName: translate('rosterIngestion.table.tbaStatusCount'),
          component: ({ tbaCount, tbaStatus }: AsyncTask) =>
            tbaStatus && tbaStatus !== 'N_A'
              ? tbaCount
              : translate('global.na'),
          width: 175,
        },
        {
          headerName: translate('rosterIngestion.table.leadStatus'),
          component: (upload: AsyncTask) =>
            getLeadStatus(upload, upload.rosterId ? onReleaseToSF : undefined),
          width: 125,
        },
      ],
    ];
  }

  columns = [
    ...columns,
    ...[
      {
        headerName: translate('rosterIngestion.table.download'),
        component: (upload: AsyncTask | CareTeamRosterTask) => {
          const showButton =
            upload instanceof AsyncTask
              ? upload.result
              : upload.status === CareTeamRosterTaskStatus.COMPLETED;
          return showButton ? (
            <Button
              className={styles.downloadButton}
              color="secondary"
              icon={<DownloadIcon />}
              type="contained"
              onClick={() =>
                upload instanceof AsyncTask
                  ? downloadAsyncResult(
                      upload.uuid,
                      `${upload.uuid}-results.csv`,
                    )
                  : downloadCTAsyncResult(upload.id, `${upload.id}-results.csv`)
              }
            />
          ) : (
            <></>
          );
        },
        width: 150,
      },
    ],
  ];

  if (rosterType === AsyncTaskType.MEMBER_ROSTER) {
    columns = [
      ...columns,
      ...[
        {
          headerName: translate('rosterIngestion.table.comparisonReport'),
          component: (upload: AsyncTask) =>
            upload?.archiveMetadataId ? (
              <Button
                className={styles.downloadButton}
                color="secondary"
                icon={<DownloadIcon />}
                type="contained"
                onClick={() =>
                  downloadAsyncComparisonReport(
                    upload.archiveMetadataId || '',
                    `${upload.uuid}-comparison-report.csv`,
                  )
                }
              />
            ) : (
              <span>
                {moment
                  .unix(upload.createdAt)
                  .isAfter(COMPARISON_REPORT_RELEASE_DATE)
                  ? translate('rosterIngestion.inProgress')
                  : translate('rosterIngestion.notAvailable')}
              </span>
            ),
          width: 150,
        },
      ],
    ];
  }

  return columns;
};

const useStyles = makeStyles({
  downloadButton: {
    minWidth: '10rem',
  },
});

export const UploadHistoryTable = (props: Props) => {
  const styles = useStyles();
  const {
    className,
    onChangePage,
    onChangePageSize,
    pagination,
    pastUploads,
  } = props;

  return (
    <Table
      className={className}
      config={{
        columns: HISTORY_TABLE_COLUMNS(props, styles),
        data: pastUploads,
        pagination,
      }}
      empty
      fontSize="small"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePageSize}
      whiteBackground
    />
  );
};
