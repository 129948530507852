import React, { useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import {
  Button,
  CollapsableSidebar,
  Colors,
  Label,
  TextInput,
  Tooltip,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import {
  UpdateReferralIngestionConfigParams,
  fetchReferralIngestionConfig,
} from 'dash/src/services/FileHistoryServices';

type Props = {
  onClose: () => void;
  onSubmit: (params: UpdateReferralIngestionConfigParams) => Promise<void>;
  open: boolean;
};

const useStyles = makeStyles({
  input: {
    maxWidth: '3.5rem',
  },
});

export const ReferralIngestionMapperConfigModal = ({
  onClose,
  onSubmit,
  open,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorRate, setErrorRate] = useState<number>();

  const getReferralIngestionConfig = async () => {
    setLoading(true);
    const { errorRate } = await fetchReferralIngestionConfig();
    setErrorRate(errorRate);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      getReferralIngestionConfig();
    }
    setSubmitted(false);
  }, [open]);

  const doSubmit = async () => {
    setSubmitted(true);
    if (!errorRate) return;

    setLoadingButton(true);
    try {
      await onSubmit({
        errorRate,
      });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingButton(false);
    onClose();
  };
  return (
    <CollapsableSidebar
      onClose={onClose}
      open={open}
      title={
        <h2>{translate('referrals.ingestionMapper.modal.titleConfig')}</h2>
      }
      size={500}
    >
      <CollapsableSidebar.Body>
        <div>
          <Label>
            {translate('referrals.ingestionMapper.modal.rosterRejectionStatus')}
          </Label>
          <div className="flex gap wrap">
            <span>
              {translate('referrals.ingestionMapper.modal.errorRate.1')}
            </span>
            <TextInput
              className={styles.input}
              data-cy="referra-ingestion-mapping-error-rate"
              error={submitted && !errorRate}
              loading={loading}
              onChange={(value) => {
                const number = parseInt(value?.substring(0, 2) || '', 10);
                setErrorRate(undefined);
                setTimeout(
                  () => setErrorRate(isNaN(number) ? undefined : number),
                  0,
                );
              }}
              type="number"
              size="xs"
              value={errorRate?.toString() || ''}
            />
            {translate('referrals.ingestionMapper.modal.errorRate.2')}
            <Tooltip
              text={translate(
                'referrals.ingestionMapper.modal.errorRateTooltip',
              )}
            >
              <InfoIcon fontSize="small" htmlColor={Colors.blue} />
            </Tooltip>
          </div>
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          className="grid-span-6"
          data-cy="biometrics-settings-cancel"
          color="tertiary"
          onClick={onClose}
        >
          {translate('global.cancel')}
        </Button>
        <Button
          className="grid-span-6"
          color="secondary"
          data-cy="biometrics-settings-save"
          loading={loadingButton}
          onClick={doSubmit}
        >
          {translate('global.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
