import { EmployeeRole, Language, States } from '../enums';
import { Employee } from './Employee';
import { make, makeArray } from './Model';
import { PodMetricsSummaryAllocation } from './PodMetricsSummaryAllocation';
import { PodMetricsSummaryData } from './PodMetricsSummaryData';

const getTopThree = (items: PodMetricsSummaryData[]) => {
  if (items.length > 3) {
    const topThree = [...items].slice(0, 3);
    const other = [...items].slice(4);
    return [
      ...topThree,
      other?.reduce(
        (acc, item) => {
          acc.count += item.count;
          return acc;
        },
        { item: undefined, count: 0 } as PodMetricsSummaryData,
      ),
    ];
  }
  return items;
};
export class PodMetricsSummaryCommon {
  id = 0;

  name = '';

  manager: Employee;

  /** MEMBERS */

  memberCount = 0;

  maxMembers = 0;

  unreachableCount = 0;

  languageCounts: PodMetricsSummaryData[];

  stateCounts: PodMetricsSummaryData[];

  /** PROGRAMS */

  ccmCount = 0;

  rpmCount = 0;

  tocCount = 0;

  skilledCount = 0;

  persCount = 0;

  /** UNREACHABLE */

  unreachable30Count = 0;

  unreachable3060Count = 0;

  unreachable6090Count = 0;

  unreachable90Count = 0;

  /** TASKS */
  tasksCount = 0;

  /** BIANNUAL */
  npBiannualDueCount = 0;

  npBiannualOverdueCount = 0;

  npBiannualPendingCount = 0;

  npBiannualCompletedCount = 0;

  npBiannualRescheduleDueCount = 0;

  npBiannualRescheduleOverdueCount = 0;

  npBiannualNotYetDueUnreachableCount = 0;

  npBiannualNotYetDueReachableCount = 0;

  npBiannualNotYetOverdueUnreachableCount = 0;

  npBiannualNotYetOverdueReachableCount = 0;

  /** ALLOCATION */

  npAllocation: PodMetricsSummaryAllocation;

  rnAllocation: PodMetricsSummaryAllocation;

  hcAllocation: PodMetricsSummaryAllocation;

  cmaAllocation: PodMetricsSummaryAllocation;

  engagementAllocation: PodMetricsSummaryAllocation;

  /** WARNINGS */
  warningCounts: PodMetricsSummaryData[];

  constructor(obj: any) {
    Object.assign(this, obj);
    this.cmaAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.MA.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.engagementAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.ENGAGEMENT_COORDINATOR.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.hcAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.HEALTH_COACH.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.languageCounts = makeArray(
      obj.languageCounts.map((language: any) => ({
        item: Language.byKey[language.item as string],
        count: language.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.manager = make(obj.manager, Employee);
    this.npAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.NP.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.rnAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.RN.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.stateCounts = makeArray(
      obj.stateCounts.map((state: any) => ({
        item: States.byKey[state.item as string],
        count: state.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.warningCounts = makeArray(
      obj.warningCounts,
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
  }

  get topThreeLanguages() {
    return getTopThree(this.languageCounts);
  }

  get topThreeStates() {
    return getTopThree(this.stateCounts);
  }

  get warningTotalCount() {
    return this.warningCounts?.reduce((acc, { count }) => acc + count, 0) || 0;
  }
}

export default PodMetricsSummaryCommon;
