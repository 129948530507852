import classNames from 'classnames';
import { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { States } from '@vestahealthcare/common/enums';
import { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Brand,
  Organization,
  OrganizationStatuses,
  OrganizationType,
} from '@vestahealthcare/common/models';
import moment from '@vestahealthcare/common/moment';
import { isValidMail } from '@vestahealthcare/common/utils/format';

import {
  Button,
  CheckboxWithLabel,
  CollapsableSidebar,
  DateTimePicker,
  IconButton,
  Select,
  TextInput,
} from 'styleguide-v2';

import { CacheServices } from 'dash/src/services';
import {
  OrganizationParams,
  addOrganization,
  editOrganization,
  fetchOrganizations,
} from 'dash/src/services/OrganizationServices';
import { capitalize } from 'lodash';

import Session from '../../../services/SessionServices';

interface Props {
  grandChild?: boolean;
  referral?: Organization;
  onHide: () => void;
  onSubmit: () => void;
  open: boolean;
}

const useStyles = makeStyles({
  checkbox: {
    height: '5rem',
  },
  firstIcon: {
    '&&': {
      marginTop: '3.5rem',
    },
  },
  icon: {
    marginTop: '1.25rem',
  },
});

export const AddEditReferralModal = (props: Props) => {
  const styles = useStyles();
  const { referral, onHide, onSubmit } = props;
  const { actingUser } = Session;

  const [referralsList, setReferralsList] = useState<Organization[]>([]);
  const [typesList, setTypesList] = useState<OrganizationType[]>([]);
  const [statusesList, setStatusesList] = useState<OrganizationStatuses[]>([]);
  const [brandList, setBrandList] = useState<Brand[]>([]);

  const [name, setName] = useState('');
  const [abbr, setAbbr] = useState('');
  const [brand, setBrand] = useState<string>();
  const [affiliation, setAffiliation] = useState<Organization>();
  const [type, setType] = useState<OrganizationType>();
  const [status, setStatus] = useState<OrganizationStatuses>();
  const [startDate, setStartDate] = useState<Moment | undefined>();
  const [endDate, setEndDate] = useState<Moment | undefined>();
  const [medicareDiscovery, setMedicareDiscovery] = useState(false);
  const [automatedIncidentReporting, setAutomatedIncidentReporting] = useState(
    false,
  );
  const [mails, setMails] = useState<string[]>(['']);
  const [sendCareManagersMail, setSendCareManagersMail] = useState<boolean>(
    false,
  );
  const [rpmOutsourcing, setRPMOutsourcing] = useState<boolean>(false);
  const [sftpDirectoryPath, setSftpDirectoryPath] = useState<string>();
  const [sftpFileName, setSftpFileName] = useState<string>();
  const [termByAbsence, setTermByAbsence] = useState<boolean>(false);
  const [state, setState] = useState<States>();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showError, setShowError] = useState(false);

  const showStateField =
    affiliation ||
    !referral?.children?.filter(
      ({ status }) => status?.id !== OrganizationStatuses.INACTIVE,
    )?.length;

  const getParentState = () =>
    affiliation?.parent && affiliation?.state ? affiliation.state : undefined;

  const getParentStateSelectable = () => {
    const state = getParentState();
    return state ? States.toSelectable([state])[0] : undefined;
  };

  useEffect(() => {
    setName(referral?.name || '');
    setAbbr(referral?.abbr || '');
    setBrand(referral?.brandId);
    setAffiliation(referral?.parent);
    setType(referral?.type);
    setStatus(referral?.status);
    setStartDate(moment(referral?.startDate) || moment());
    setEndDate(referral?.endDate ? moment(referral?.endDate) : undefined);
    setMedicareDiscovery(!!referral?.medicareDiscovery);
    setAutomatedIncidentReporting(!!referral?.incidentAuto);
    setMails(referral?.emails?.length ? referral?.emails : ['']);
    setSendCareManagersMail(!!referral?.incidentCareManagers);
    setRPMOutsourcing(!!referral?.rpmOutsourcing);
    setSftpDirectoryPath(referral?.autoIngestionConfig?.sftpPath);
    setSftpFileName(referral?.autoIngestionConfig?.sftpRawFilenamePattern);
    setTermByAbsence(!!referral?.autoIngestionConfig?.autoTba);
    setState(referral?.state);
    setShowError(false);
  }, [props.open]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      const [
        organizations,
        organizationTypes,
        organizationStatuses,
        brands,
      ] = await Promise.all([
        fetchOrganizations(),
        CacheServices.getOrganizationTypes(),
        CacheServices.getOrganizationStatuses(),
        CacheServices.getOrganizationBrands(),
      ]);
      setReferralsList(organizations);
      setTypesList(organizationTypes);
      setStatusesList(organizationStatuses);
      setBrandList(brands);
      setLoadingData(false);
    };

    if (props.open) {
      fetchData();
    }
  }, [referral, props.open]);

  const submit = async () => {
    if (
      !name ||
      !type ||
      !status ||
      !startDate ||
      !brand ||
      !sftpDirectoryPath !== !sftpFileName ||
      (automatedIncidentReporting &&
        !sendCareManagersMail &&
        !!mails.filter((mail) => {
          return !isValidMail(mail);
        })?.length)
    ) {
      setShowError(true);
      return;
    }

    const params: OrganizationParams = {
      abbr,
      autoIngestionConfig: {
        autoTba: termByAbsence,
        sftpPath: sftpDirectoryPath,
        sftpRawFilenamePattern: sftpFileName,
      },
      affiliation,
      brand,
      emails: automatedIncidentReporting
        ? mails.filter((mail) => {
            return isValidMail(mail);
          })
        : [],
      endDate,
      incidentAuto: automatedIncidentReporting,
      incidentCareManagers: automatedIncidentReporting && sendCareManagersMail,
      medicareDiscovery,
      name,
      rpmOutsourcing,
      startDate,
      state: state || getParentState(),
      status,
      type,
    };

    setLoading(true);
    try {
      if (referral) {
        await editOrganization(referral.id, params);
      } else {
        await addOrganization(params);
      }
      await onSubmit();
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapsableSidebar
      onClose={onHide}
      open={props.open}
      title={translate(`referrals.modal.${referral ? 'edit' : 'add'}`)}
      size={550}
    >
      <CollapsableSidebar.Body>
        <div className="grid-span-12 grid-wrapper fit">
          <TextInput
            className="grid-span-6"
            data-cy="referral-name"
            disabled={!!referral && !actingUser.canEditReferralName}
            error={showError && !name}
            label={translate('referrals.name')}
            onChange={(value: string | undefined) => setName(value || '')}
            value={name}
            required
          />
          <TextInput
            className="grid-span-6"
            data-cy="referral-abbr"
            disabled={!!referral && !actingUser.canEditReferralName}
            label={translate('referrals.abbr')}
            onChange={(value: string | undefined) => setAbbr(value || '')}
            value={abbr}
          />
          <Select
            className="grid-span-6"
            data-cy="referral-brand"
            error={showError && !brand}
            getItemLabel={(b: Brand) => b.name}
            label={translate('referrals.brand')}
            loading={loadingData}
            onChange={(value?: Brand) => setBrand(value?.id)}
            items={brandList}
            value={brandList?.find((b) => b.id === brand)}
            required
          />
          <Select
            className="grid-span-6"
            data-cy="referral-affiliation"
            getItemLabel={(o: Organization) => o.name}
            label={translate('referrals.affiliation')}
            loading={loadingData}
            onChange={setAffiliation}
            items={referralsList}
            value={affiliation}
          />
          <Select
            className="grid-span-12"
            data-cy="referral-type"
            error={showError && !type}
            getItemLabel={(s: OrganizationType) => capitalize(s?.name)}
            label={translate('referrals.type')}
            loading={loadingData}
            onChange={setType}
            items={typesList}
            value={type}
            required
          />
          <Select
            className="grid-span-12"
            data-cy="referral-status"
            error={showError && !status}
            label={translate('referrals.status')}
            loading={loadingData}
            getItemLabel={(s: OrganizationStatuses) => s.name}
            onChange={setStatus}
            items={statusesList}
            value={status}
            required
          />

          {showStateField && (
            <Select
              className="grid-span-12"
              data-cy="referral-state"
              disabled={props.grandChild || !!affiliation?.parent}
              label={translate('referrals.state')}
              onChange={(value?: Selectable) =>
                setState(
                  value ? States.byKey[value?.value as string] : undefined,
                )
              }
              items={States.toSelectable(States.asArray)}
              value={
                state
                  ? States.toSelectable([state])[0]
                  : getParentStateSelectable()
              }
            />
          )}

          <CheckboxWithLabel
            className="grid-span-12"
            data-cy="referral-medicare"
            checked={medicareDiscovery}
            label={translate('referrals.medicare')}
            onChange={(value) => setMedicareDiscovery(!!value)}
            disabled={referral && referral.hasChildren}
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="referral-startdate"
            error={showError && !startDate}
            label={translate('referrals.startDate')}
            onChange={(item: Date) => setStartDate(moment(item?.getTime()))}
            value={startDate?.toDate()}
            required
          />
          <DateTimePicker
            className="grid-span-6"
            data-cy="referral-enddate"
            label={translate('referrals.endDate')}
            onChange={(item: Date) => setEndDate(moment(item?.getTime()))}
            value={endDate?.toDate()}
          />
          <CheckboxWithLabel
            className="grid-span-12"
            data-cy="referral-automated-incident-reporting"
            checked={automatedIncidentReporting}
            label={translate('referrals.automatedIncidentReporting')}
            onChange={(value) => setAutomatedIncidentReporting(!!value)}
          />

          {automatedIncidentReporting && (
            <>
              <CheckboxWithLabel
                className={classNames('grid-span-12', styles.checkbox)}
                data-cy="referral-mail-care-manangers"
                checked={sendCareManagersMail}
                label={translate('referrals.careManagersMail')}
                onChange={(value) => setSendCareManagersMail(!!value)}
                tooltip={translate('referrals.careManagersMailTooltip')}
              />
              {mails.map((mail, index) => (
                <Fragment key={`referral-mail-${index}`}>
                  <TextInput
                    className="grid-span-10"
                    data-cy={`referral-mail-${index}`}
                    error={
                      showError &&
                      !sendCareManagersMail &&
                      !isValidMail(mail) &&
                      translate('common.errors.invalidEmail')
                    }
                    label={index === 0 ? translate('referrals.mail') : ''}
                    onChange={(value: string | undefined) => {
                      mails[index] = value || '';
                      setMails([...mails]);
                    }}
                    required={!sendCareManagersMail}
                    value={mail}
                  />
                  <IconButton
                    className={classNames(
                      'grid-span-2',
                      index === 0 ? styles.firstIcon : styles.icon,
                    )}
                    size="small"
                    onClick={() => {
                      if (mails?.length === 1) {
                        setMails(['']);
                      } else {
                        mails.splice(index, 1);
                        setMails([...mails]);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Fragment>
              ))}
              <Button
                className="grid-span-12"
                color="quaternary"
                onClick={() => setMails([...mails, ''])}
              >
                {translate('referrals.addMail')}
              </Button>
            </>
          )}
          <CheckboxWithLabel
            className="grid-span-12"
            data-cy="referral-rpm-outsourcing"
            checked={rpmOutsourcing}
            label={translate('referrals.rpmOutsourcing')}
            onChange={(value) => setRPMOutsourcing(!!value)}
            tooltip={translate('referrals.rpmOutsourcingTooltip')}
          />
          <TextInput
            className="grid-span-6"
            data-cy="referral-stfp-directory-path"
            error={
              showError &&
              !!(sftpDirectoryPath || sftpFileName) &&
              !sftpDirectoryPath &&
              !!sftpFileName
            }
            label={translate('referrals.sftpDirectoryPath')}
            onChange={setSftpDirectoryPath}
            value={sftpDirectoryPath}
            required={!!(sftpDirectoryPath || sftpFileName)}
          />
          <TextInput
            className="grid-span-6"
            data-cy="referral-stfp-filename"
            error={
              showError &&
              !!(sftpDirectoryPath || sftpFileName) &&
              !!sftpDirectoryPath &&
              !sftpFileName
            }
            label={translate('referrals.sftpFileName')}
            onChange={setSftpFileName}
            value={sftpFileName}
            required={!!(sftpDirectoryPath || sftpFileName)}
          />
          <CheckboxWithLabel
            className="grid-span-12"
            data-cy="referral-rpm-tba"
            checked={termByAbsence}
            label={translate('referrals.termByAbsence')}
            onChange={(value) => setTermByAbsence(!!value)}
            tooltip={translate('referrals.termByAbsenceTooltip')}
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button color="tertiary" onClick={onHide}>
          {translate('global.cancel')}
        </Button>
        <Button
          async
          color="secondary"
          loading={loading}
          onClick={submit}
          data-cy="add-referral"
        >
          {translate('referrals.modal.save')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default AddEditReferralModal;
