import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  HouseHoldMember,
  PatientBase,
  PatientSearch,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Button,
  CollapsableSidebar,
  IconButton,
  Label,
  MemberInfo,
  Select,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { SearchMember } from 'dash/src/components/SearchMember';
import { CacheServices } from 'dash/src/services';
import {
  CreateHouseHoldParams,
  UpdateHouseHoldParams,
} from 'dash/src/services/HouseHoldServices';

type Props = {
  houseHold?: HouseHoldMember;
  member?: PatientBase;
  open: boolean;
  onClose: () => void;
  onCreate: (params: CreateHouseHoldParams) => Promise<void>;
  onEdit: (id: number, params: UpdateHouseHoldParams) => Promise<void>;
};

export const HouseHoldEditModal = ({
  houseHold,
  member,
  onClose,
  onCreate,
  onEdit,
  open,
}: Props) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [relationships, setRelationships] = useState<BaseEnum[]>([]);

  const [relationship, setRelationship] = useState<BaseEnum>();
  const [selectedMember, setSelectedMember] = useState<PatientSearch>();

  const getInitialData = async () => {
    const relationships = await CacheServices.getHouseHoldRelationships();
    setRelationships(relationships);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setLoadingButton(false);
    }
  }, [open]);

  useEffect(() => {
    setSelectedMember(houseHold?.linkedMember);
    setRelationship(houseHold?.relationship);
  }, [houseHold]);

  const doSubmit = async () => {
    if (!member) return;
    setSubmitted(true);
    if (houseHold?.id) {
      if (relationship) {
        setLoadingButton(true);
        try {
          await onEdit(houseHold.id, {
            relationship,
          });
          onClose();
        } catch (e) {
          showGlobalError(e as string);
        }
        setLoadingButton(false);
      }
    } else if (relationship && selectedMember) {
      setLoadingButton(true);
      try {
        await onCreate({
          memberId: member,
          relatedMemberId: selectedMember,
          relationship,
        });
        onClose();
      } catch (e) {
        showGlobalError(e as string);
      }
      setLoadingButton(false);
    }
  };

  return (
    <CollapsableSidebar
      title={
        <h2>
          {houseHold?.id
            ? `${translate('global.edit')} ${houseHold.linkedMember.fullName}`
            : translate('houseHold.modal.titleAdd')}
        </h2>
      }
      onClose={onClose}
      open={open}
      size={500}
    >
      <CollapsableSidebar.Body>
        <div className="grid-wrapper">
          {!houseHold?.id && (
            <SearchMember
              className="grid-span-12"
              data-cy="house-hold-member"
              disabled={!!selectedMember}
              error={submitted && !selectedMember}
              label={translate('houseHold.modal.member')}
              onChange={setSelectedMember}
              value={selectedMember}
              required
            />
          )}

          {selectedMember ? (
            <div className="grid-span-12">
              {houseHold?.id && (
                <Label className="grid-span-12">
                  {translate('houseHold.modal.member')}
                </Label>
              )}
              <div className="flex">
                <MemberInfo
                  link={`#/patients/${selectedMember?.id}`}
                  member={selectedMember}
                />
                {!houseHold?.id && (
                  <IconButton
                    onClick={() => setSelectedMember(undefined)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ) : (
            <p>{EMPTY}</p>
          )}
          <Select
            className="grid-span-12"
            data-cy="house-hold-relationship"
            error={submitted && !relationship}
            items={relationships}
            label={translate('houseHold.modal.relationship')}
            onChange={setRelationship}
            value={relationship}
            required
          />
        </div>
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button
          color="tertiary"
          data-cy="edit-house-hold-close"
          onClick={onClose}
        >
          {translate('global.close')}
        </Button>
        <Button
          color="secondary"
          data-cy="edit-house-hold-submit"
          loading={loadingButton}
          onClick={doSubmit}
        >
          {houseHold?.id ? translate('global.save') : translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};

export default HouseHoldEditModal;
