import React, { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ConfigIcon from '@mui/icons-material/Settings';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  PaginationType,
  ReferralIngestionMapperColumn,
  ReferralMapping,
  RosterFileHistory,
} from '@vestahealthcare/common/models';

import { IconButton, Panel } from 'styleguide-v2';

import { PreUploadMemberColumns } from 'dash/src/pages/Ops/MemberIngestion/MemberTableColumns';
import { CacheServices } from 'dash/src/services';
import {
  createRosterFileHistoryMapper,
  fetchRosterFileHistoryMappers,
  updateReferralIngestionConfig,
  updateRosterFileHistoryMapper,
} from 'dash/src/services/FileHistoryServices';

import { ReferralIngestionMapperConfigModal } from './ReferralIngestionMapperConfigModal';
import { ReferralIngestionMapperEditModal } from './ReferralIngestionMapperEditModal';
import { ReferralIngestionMapperTable } from './ReferralIngestionMapperTable';

export const ReferralIngestionMapperPage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [mappers, setMappers] = useState<ReferralMapping[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const [openConfigModal, setOpenConfigModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editMapper, setEditMapper] = useState<ReferralMapping>();

  const [organizations, setOrganizations] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [files, setFiles] = useState<Selectable[]>([]);
  const [requiredColumns, setRequiredColumns] = useState<string[]>([]);

  const defaultColumns = useMemo(
    () =>
      [...PreUploadMemberColumns]
        .sort(({ column: a }, { column: b }) => a - b)
        .map(
          (item) =>
            new ReferralIngestionMapperColumn({
              name: item.field,
              required: requiredColumns?.includes(item.field),
            }),
        ),
    [requiredColumns],
  );

  const getInitialData = async () => {
    setLoadingData(true);

    const [organizations, files, requiredColumns] = await Promise.all([
      CacheServices.getOrganizations(),
      CacheServices.getRosterFileHistory(),
      CacheServices.getRosterRequiredColumns(),
    ]);

    setOrganizations(
      Organization.toSelectable(
        organizations.filter(({ hasValidDate }) => hasValidDate),
      ),
    );
    setFiles(RosterFileHistory.toSelectable(files));
    setRequiredColumns(requiredColumns);

    setLoadingData(false);
  };

  const getMappers = async () => {
    const offset = page * pageSize;

    setLoading(true);
    const { items, pagination } = await fetchRosterFileHistoryMappers({
      offset,
      limit: pageSize,
    });
    if (offset) {
      setMappers([...mappers, ...items]);
    } else {
      setMappers(items);
    }
    setPagination(pagination);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
    getMappers();
  }, []);

  return (
    <Panel id="referral-intesgion-mapper-section">
      <Panel.Heading title={translate('referrals.ingestionMapper.title')}>
        <Panel.Actions>
          <IconButton
            onClick={() => {
              setOpenConfigModal(true);
            }}
            tooltip={translate('referrals.ingestionMapper.modal.titleConfig')}
          >
            <ConfigIcon fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditMapper(undefined);
              setOpenEditModal(true);
            }}
            tooltip={translate('referrals.ingestionMapper.add')}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </Panel.Actions>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <ReferralIngestionMapperTable
          data={{
            organizations,
          }}
          items={mappers}
          onChangePage={setPage}
          onChangePageSize={(pageSize) => {
            setPageSize(pageSize);
            setPage(0);
          }}
          onEdit={(mapper: ReferralMapping) => {
            setEditMapper(mapper);
            setOpenEditModal(true);
          }}
          pagination={pagination}
        />
        <ReferralIngestionMapperEditModal
          data={{
            destinationColumns: ReferralIngestionMapperColumn.toSelectable(
              defaultColumns,
            ),
            organizations,
            files,
          }}
          loadingData={loadingData}
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSubmit={async (params, id) => {
            if (id) {
              await updateRosterFileHistoryMapper(id, params);
            } else {
              await createRosterFileHistoryMapper(params);
            }
            getMappers();
          }}
          rule={editMapper}
        />
        <ReferralIngestionMapperConfigModal
          open={openConfigModal}
          onClose={() => setOpenConfigModal(false)}
          onSubmit={updateReferralIngestionConfig}
        />
      </Panel.Body>
    </Panel>
  );
};

export default ReferralIngestionMapperPage;
