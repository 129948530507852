import classnames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { DATE_FORMAT_SHORT_WITH_TIME } from '@vestahealthcare/common/utils/constants';

import { Colors } from 'styleguide-v2/src/styles/Colors';

type Props = {
  'data-cy'?: string;
  className?: string;
  contrast?: boolean;
  date: number | Moment;
  dateFormat?: string;
  extraInfo?: string;
  user?: string;
  size?: 's' | 'm';
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  date: {
    color: Colors.gray,
  },
  sizeS: {
    fontSize: '0.875em',
  },
});

export const UpdatedBy = ({
  className,
  contrast,
  'data-cy': dataCy = 'updated-by',
  date,
  dateFormat = DATE_FORMAT_SHORT_WITH_TIME,
  extraInfo,
  user,
  size,
}: Props) => {
  const styles = useStyles();
  return (
    <div
      className={classnames(
        styles.container,
        className,
        size === 's' && styles.sizeS,
      )}
    >
      {user && <span data-cy={`${dataCy}-user`}>{user}</span>}
      <span className={contrast ? '' : styles.date} data-cy={`${dataCy}-date`}>
        {typeof date !== 'number' && date?.format(dateFormat)}
        {typeof date === 'number' && moment.unix(date).format(dateFormat)}
      </span>
      {extraInfo && <span className={styles.date}>{extraInfo}</span>}
    </div>
  );
};

export default UpdatedBy;
