import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  PaginationType,
  PodConfig,
  PodForecastTask,
  PodRule,
} from '@vestahealthcare/common/models';

import { Modal, Spinner } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { fetchPogramTasks } from 'dash/src/services/PodServices';

import { PodTaskReviewTable } from './PodTaskReviewTable';

interface Props {
  assignee?: Employee;
  filters: {
    month: Moment;
    podConfig?: PodConfig;
    taskDefinition: PodRule;
  };
  open?: boolean;
  selectAll: boolean;
  selectedIds: number[];
  taskType?: PodRule;

  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const useStyles = makeStyles({
  container: {
    minHeight: '55rem',
  },
});

const DEFAULT_PAGE_SIZE = 10;

export const PodTaskReviewModal = (props: Props) => {
  const {
    assignee,
    open,
    onClose,
    onSubmit,
    filters: { month, podConfig, taskDefinition },
    selectAll,
    selectedIds,
    taskType,
  } = props;
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [tasks, setTasks] = useState<PodForecastTask[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const getReviewData = async () => {
    const offset = page * pageSize;
    if (offset !== 0 && offset < tasks?.length) return;

    setLoading(true);
    try {
      const { items, pagination } = await fetchPogramTasks({
        excludedTaskId: selectAll ? selectedIds : undefined,
        taskId: selectAll ? undefined : selectedIds,
        month,
        podId: podConfig ? [podConfig?.id] : [],
        taskDefinitionId: taskDefinition ? [taskDefinition.id] : [],
        offset,
        limit: pageSize,
      });

      if (page > 0) {
        setTasks([...tasks, ...items]);
      } else {
        setTasks(items);
      }
      setPagination(pagination);
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setTasks([]);
      setPagination(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      getReviewData();
    }
  }, [page, pageSize, open]);

  const doSubmit = async () => {
    await onSubmit();
  };

  return (
    <Modal
      maxWidth="lg"
      onClose={onClose}
      onSubmit={doSubmit}
      open={!!open}
      submitText={translate('global.confirm')}
      title={translate('pods.metrics.reviewModal.title')}
    >
      <div className={styles.container}>
        <div className="flex center">
          {!assignee || loading ? (
            <Spinner width={32} />
          ) : (
            <PodTaskReviewTable
              assignee={assignee}
              items={tasks}
              pagination={pagination}
              taskType={taskType}
              onChangePage={setPage}
              onChangePageSize={(ps) => {
                setPage(0);
                setPageSize(ps);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
