import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryCommon } from '@vestahealthcare/common/models';

import { PieChart } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummaryCommon[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
    justifyItems: 'center',
  },
  container: {
    display: 'flex',
  },
  itemContainer: {
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableUnreachable = ({ data }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map((pod, idx) => (
        <div className={styles.itemContainer} key={`pod-program-${idx}`}>
          <PieChart
            className={styles.chart}
            color="status"
            data={[
              {
                id: 'status-30',
                label: `< 30 ${translate('global.days')}`,
                value: pod.unreachable30Count,
              },
              {
                id: 'status-30-60',
                label: `30-60 ${translate('global.days')}`,
                value: pod.unreachable3060Count,
              },
              {
                id: 'status-60-90',
                label: `60-90 ${translate('global.days')}`,
                value: pod.unreachable6090Count,
              },
              {
                id: 'status-90',
                label: `> 90 ${translate('global.days')}`,
                value: pod.unreachable90Count,
              },
            ]}
            size="xs"
            type="outlined"
          />
        </div>
      ))}
    </div>
  );
};
