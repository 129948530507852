import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryCommon } from '@vestahealthcare/common/models';

import { PieChart } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummaryCommon[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
    justifyItems: 'center',
  },
  container: {
    display: 'flex',
  },
  itemContainer: {
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableMember = ({ data }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map(
        (
          {
            id,
            languageCounts,
            stateCounts,
            topThreeLanguages,
            topThreeStates,
          },
          idx,
        ) => (
          <div className={styles.itemContainer} key={`pod-member-info-${idx}`}>
            <PieChart
              className={styles.chart}
              data={topThreeLanguages.map((lang) => ({
                id: `pod-${id}-language-${lang.item?.valueOf()}`,
                label: lang.item?.toString() || translate('global.other'),
                value: lang.count,
              }))}
              showLegend={!!languageCounts?.length}
              title="Languages"
              size="xs"
              type="outlined"
            />
            <PieChart
              className={styles.chart}
              data={topThreeStates.map((lang) => ({
                id: `pod-${id}-states-${lang.item?.valueOf()}`,
                label: lang.item?.toString() || translate('global.other'),
                value: lang.count,
              }))}
              showLegend={!!stateCounts?.length}
              title="States"
              size="xs"
              type="outlined"
            />
          </div>
        ),
      )}
    </div>
  );
};
