import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { makeStyles } from '@mui/styles';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  PaginationType,
  ReferralMapping,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Fonts, IconButton, Table, Tooltip, UpdatedBy } from 'styleguide-v2';

type Props = {
  data: {
    organizations: SelectableInfo<Organization>[];
  };
  items: ReferralMapping[];
  onChangePage: (pageSize: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onEdit: (mapping: ReferralMapping) => void;
  pagination?: PaginationType;
};

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

const ROSTER_FILE_HISTORY_COLUMNS = ({ data, onEdit }: Props) => [
  {
    headerName: translate('referrals.ingestionMapper.table.name'),
    field: 'name',
  },
  {
    headerName: translate('referrals.ingestionMapper.table.referral'),
    component: ({ organizations }: ReferralMapping) =>
      organizations?.map((item) => item?.name)?.join(', '),
  },
  {
    headerName: translate('referrals.ingestionMapper.table.fileName'),
    field: 'file.name',
  },
  {
    headerName: translate('referrals.ingestionMapper.table.mixedFile'),
    component: ({ id, branchColumn }: ReferralMapping) =>
      branchColumn?.operation?.dataDictionary?.length ? (
        <Tooltip
          text={
            <>
              {branchColumn.operation?.dataDictionary?.map(
                ({ sourceValue, destinationValue }, index) => (
                  <p
                    className="white no-margin flex spaced gap"
                    key={`referral-mapping-${id}-mixed-${index}`}
                  >
                    <span>{sourceValue}:</span>
                    <span> </span>
                    <span>
                      {data?.organizations?.find(
                        ({ value }) => value?.toString() === destinationValue,
                      )?.label || EMPTY}
                    </span>
                  </p>
                ),
              )}
            </>
          }
        >
          <WarningIcon color="warning" />
        </Tooltip>
      ) : (
        ''
      ),
    width: 175,
  },
  {
    headerName: translate('referrals.ingestionMapper.table.created'),
    component: ({ createdBy, createdAt }: ReferralMapping) => (
      <UpdatedBy date={createdAt} user={createdBy?.fullName} />
    ),
    width: 175,
  },
  {
    headerName: translate('referrals.ingestionMapper.table.updated'),
    component: ({ updatedBy, updatedAt }: ReferralMapping) => (
      <UpdatedBy date={updatedAt} user={updatedBy?.fullName} />
    ),
    width: 175,
  },
  {
    headerName: translate('referrals.ingestionMapper.table.status'),
    component: ({ active }: ReferralMapping) => (
      <Tooltip text={translate(`common.${active ? 'active' : 'inactive'}`)}>
        {active ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <CancelIcon color="error" />
        )}
      </Tooltip>
    ),
    width: 100,
  },
  {
    headerName: ' ',
    component: (mapping: ReferralMapping) => (
      <IconButton
        size="small"
        onClick={() => {
          onEdit(mapping);
        }}
      >
        <EditIcon />
      </IconButton>
    ),
    width: 50,
  },
];

export const ReferralIngestionMapperTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={styles.table}
      config={{
        columns: ROSTER_FILE_HISTORY_COLUMNS(props),
        compact: true,
        data: props.items,
        noItemsLabel: translate('rosterFileHistory.noFiles'),
        pagination: props.pagination,
        paginationOptions: [25, 50, 100],
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default ReferralIngestionMapperTable;
