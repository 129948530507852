import classnames from 'classnames';
import React, { ReactElement, ReactNode, cloneElement } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';

import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

interface Props {
  children?: ReactNode;
  className?: string;
  icon?: ReactElement<any>;
  title?: ReactNode | string;
  type: 'error' | 'warning' | 'info';
}

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    borderRadius: '0.5rem',
    display: 'flex',
    gap: '1rem',
    height: 'fit-content',
    padding: '2rem',
    margin: 0,
  },

  containerError: {
    background: `${Colors.textRed}33`,
    color: Colors.textRed,
  },

  containerWargning: {
    background: BackgroundColors.lightCoral,
    color: Colors.lightCoral,
  },

  containerInfo: {
    background: BackgroundColors.lightBlue,
    color: Colors.blue,
  },

  icon: {
    height: `calc(${Fonts.fontSize} * 2)`,
    width: `calc(${Fonts.fontSize} * 2)`,
  },
});

export const PanelInfo = ({
  children,
  className,
  icon,
  title,
  type = 'warning',
}: Props) => {
  const styles = useStyles();

  const classes = classnames(
    styles.container,
    type === 'error' && styles.containerError,
    type === 'warning' && styles.containerWargning,
    type === 'info' && styles.containerInfo,
    className,
  );

  let iconWithClasses: ReactElement<any> | undefined;
  if (icon) {
    iconWithClasses = cloneElement(icon as ReactElement<any>, {
      className: styles.icon,
    });
  }

  return (
    <div className={classes}>
      {iconWithClasses}
      {!icon && type === 'error' && (
        <ErrorIcon className={classnames(styles.icon)} fontSize="large" />
      )}
      {!icon && type === 'warning' && (
        <WarningIcon className={classnames(styles.icon)} fontSize="large" />
      )}
      {!icon && type === 'info' && (
        <InfoIcon className={classnames(styles.icon)} fontSize="large" />
      )}
      {title && typeof title === 'string' && <span>{title}</span>}
      {title && typeof title !== 'string' && title}
      {children}
    </div>
  );
};

export default PanelInfo;
