import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
} from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Employee,
  PaginationType,
  PodConfig,
  PodForecastTask,
  PodRule,
  ProgramTaskBase,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  FilterItem,
  Panel,
  ToggleDateRange,
  ToggleDateRangeHandle,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { PodTaskReassignModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskReassignModal';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  GetProgramTaskParam,
  attemptCCMTask,
  fetchPogramTasks,
  forceOverrideProgramTask,
  overrideProgramTask,
  updateCCMTaskAssignee,
} from 'dash/src/services/PodServices';
import { getServerFilters } from 'dash/src/utils/filterUtils';

import { PodTaskOverrideModal } from '../../Admin/PODs/Metrics/ForecastDetail/PodTaskOverrideModal';
import { AddEncounterModal } from '../Encounters/AddEncounterModal';
import { MemberCCMTaskFiltersBar } from './MemberCCMTaskFiltersBar';
import { MemberCCMTasksTable, TaskTableModel } from './MemberCCMTaskTable';
import MemberCCMTaskAction from './types/MemberCCMTaskAction';

const INITIAL_PAGE_SIZE = 25;

const useStyles = makeStyles({
  date: {
    width: '15rem',
  },
  filtersContainer: {
    display: 'flex',
    gap: '0.5rem 2rem',
    '&&': {
      marginTop: '1.5rem',
    },
  },
});

type KeyGetCCMTasksParams = keyof GetProgramTaskParam;

export const MemberCCMTasks = () => {
  const styles = useStyles();

  const member = useSelector((state) => state.memberInfoSlice.patient);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [pods, setPods] = useState<SelectableInfo<PodConfig>[]>([]);
  const [types, setTypes] = useState<SelectableInfo<PodRule>[]>([]);
  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);

  const [pagination, setPagination] = useState<PaginationType>();
  const [filters, setFilters] = useState<
    {
      [x in KeyGetCCMTasksParams]?: FilterItem;
    }
  >({});

  const [tasks, setTasks] = useState<PodForecastTask[]>([]);

  const [openLogCallModal, setOpenLogCallModal] = useState<boolean>();
  const [modalDataTask, setModalDataTask] = useState<ProgramTaskBase>();

  const [openReassignModal, setOpenReassignModal] = useState<boolean>();
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>();

  const [actionTaskIdx, setActionTaskIdx] = useState<number>();
  const [actionTaskDefinition, setActionTaskDefinition] = useState<PodRule>();

  const [taskDateFrom, setTaskDateFrom] = useState<Date>();
  const [taskDateTo, setTaskDateTo] = useState<Date>();
  const refDate = useRef<ToggleDateRangeHandle>(null);

  const getInitialData = async () => {
    setLoadingData(true);
    const [configs, types, employees] = await Promise.all([
      CacheServices.getPodConfigs(),
      CacheServices.getPodRules(),
      CacheServices.getEmployees(),
    ]);
    setPods(PodConfig.toSelectable(configs) || []);
    setTypes(PodRule.toSelectable(types) || []);
    setEmployees(Employee.toSelectable(employees));
    setLoadingData(false);
  };

  const getTasks = async () => {
    setLoading(true);
    const { items, pagination } = await fetchPogramTasks({
      ...(getServerFilters(filters) as GetProgramTaskParam),
      memberId: member?.id,
      taskDateFrom: taskDateFrom
        ? moment(taskDateFrom.getTime()).format(DATE_FORMAT)
        : undefined,
      taskDateTo: taskDateTo
        ? moment(taskDateTo.getTime()).format(DATE_FORMAT)
        : undefined,

      limit: pageSize,
      offset: page * pageSize,
      sort: 'taskDate desc',
    });
    setPagination(pagination);
    if (page === 0) {
      setTasks(items);
    } else {
      setTasks([...tasks, ...items]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getTasks();
  }, [filters, taskDateFrom, taskDateTo, page, pageSize]);

  const setActionLoading = (index: number, value: boolean) => {
    if (tasks[index]) {
      (tasks[index] as TaskTableModel).actionLoading = value;
      setTasks([...tasks]);
    }
  };

  const updateTask = (index: number, task: PodForecastTask) => {
    if (tasks[index]) {
      tasks[index] = task;
      setTasks([...tasks]);
    }
  };

  const updateTaskStatusAttempted = async (
    task: PodForecastTask,
    index: number,
  ) => {
    try {
      setActionLoading(index, true);
      const newTask = await attemptCCMTask(task.id);
      updateTask(index, newTask);
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setActionLoading(index, false);
    }
  };

  const updateTaskAssignee = async (employee: Employee) => {
    if (actionTaskIdx !== undefined) {
      const task = tasks[actionTaskIdx];
      if (task) {
        try {
          const detail = await updateCCMTaskAssignee(task.id, employee.id);
          updateTask(actionTaskIdx, detail);
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
          setOpenReassignModal(false);
        } catch (e) {
          showGlobalError(e as string);
        }
      }
    }
  };

  const forceOverrideTask = async (index: number) => {
    if (index !== undefined) {
      const task = tasks[index];
      if (task) {
        try {
          setActionLoading(index, true);
          await forceOverrideProgramTask(task.id);
          getTasks();
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
        } catch (e) {
          showGlobalError(e as string);
        } finally {
          setActionLoading(index, false);
        }
      }
    }
  };

  const overrideTask = async (taskType: PodRule, employee: Employee) => {
    if (actionTaskIdx !== undefined) {
      const task = tasks[actionTaskIdx];
      if (task) {
        try {
          await overrideProgramTask(task.id, taskType.id, employee.id);
          getTasks();
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
          setOpenOverrideModal(false);
        } catch (e) {
          showGlobalError(e as string);
        }
      }
    }
  };

  const onTaskAction = async (
    { value }: Selectable,
    task: PodForecastTask,
    index: number,
  ) => {
    if (value === MemberCCMTaskAction.CHANGE_ASSIGNEE.value) {
      setActionTaskIdx(index);
      setActionTaskDefinition(
        types?.find(({ info }) => info?.name === task.name)?.info,
      );
      setOpenReassignModal(true);
    } else if (value === MemberCCMTaskAction.FORCE_OVERRIDE.value) {
      forceOverrideTask(index);
    } else if (value === MemberCCMTaskAction.OVERRIDE_TASK_TYPE.value) {
      setActionTaskIdx(index);
      setActionTaskDefinition(
        types?.find(({ info }) => info?.name === task.name)?.info,
      );
      setOpenOverrideModal(true);
    } else if (value === MemberCCMTaskAction.UPDATE_TASK_STATUS_ATTEMPTED.value)
      updateTaskStatusAttempted(task, index);
  };

  return (
    <Panel>
      <Panel.Heading title={translate(`memberCCMTasks.title`)} filtersV2>
        <Panel.Filters className={styles.filtersContainer}>
          <ToggleDateRange
            items={[-1, 7, 30, 90, 'custom']}
            label=" "
            onChange={(from, to) => {
              if (from && to) {
                setTaskDateFrom(from);
                setTaskDateTo(to);
              } else {
                setTaskDateFrom(undefined);
                setTaskDateTo(undefined);
              }
              setPage(0);
            }}
            ref={refDate}
          />
        </Panel.Filters>
        <Panel.FilterBar
          inputs={
            <MemberCCMTaskFiltersBar
              data={{
                employees,
                taskDefinitions: types,
              }}
              filters={filters}
              loading={loadingData}
              member={member}
              onChange={setFilters}
            />
          }
        />
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <MemberCCMTasksTable
          data={{
            pods: pods?.map(({ info }) => info) as PodConfig[],
            taskDefinitions: types?.map(({ info }) => info) as PodRule[],
          }}
          tasks={tasks}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onLogCall={(task) => {
            setModalDataTask(task);
            setOpenLogCallModal(true);
          }}
          onTaskAction={onTaskAction}
        />
        <AddEncounterModal
          autofillTime
          avoidRequiredMinutes
          channel={EncounterChannel.CCM_VISIT}
          direction={EncounterDirection.OUTBOUND}
          method={EncounterMethod.CALL}
          type={EncounterType.CCM}
          onClose={() => setOpenLogCallModal(false)}
          onSubmit={async () => {
            await getTasks();
            setOpenLogCallModal(false);
          }}
          open={!!openLogCallModal}
          patient={member}
          programTask={modalDataTask}
        />
        {actionTaskDefinition && (
          <PodTaskReassignModal
            employees={employees?.map(({ info }) => info) as Employee[]}
            open={openReassignModal}
            podConfig={member?.pod}
            taskType={actionTaskDefinition}
            onSubmit={updateTaskAssignee}
            onClose={() => setOpenReassignModal(false)}
          />
        )}
        {actionTaskDefinition && (
          <PodTaskOverrideModal
            employees={employees?.map(({ info }) => info) as Employee[]}
            open={openOverrideModal}
            podConfig={member?.pod}
            taskTypes={
              types
                ?.filter(
                  ({ info }) =>
                    info?.id !== actionTaskDefinition?.id && info?.flexible,
                )
                ?.map(({ info }) => info) as PodRule[]
            }
            onSubmit={overrideTask}
            onClose={() => setOpenOverrideModal(false)}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default MemberCCMTasks;
