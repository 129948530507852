import { useFlags } from 'launchdarkly-react-client-sdk';
import { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Enum,
  Language,
  PatientGender,
  States,
} from '@vestahealthcare/common/enums';
import { useAsyncData } from '@vestahealthcare/common/hooks';
import { translate } from '@vestahealthcare/common/i18n';
import { Organization } from '@vestahealthcare/common/models';

import {
  AddIterationIcon,
  Button,
  Checkbox,
  DateTimePicker,
  Modal,
  Panel,
  Select,
  Text,
  TextInput,
} from 'styleguide';

import {
  broadcastError,
  broadcastMessage,
  closeGlobalMessage,
} from 'dash/src/redux/actions/GlobalActions';
import store from 'dash/src/redux/store';
import { CacheServices, fetchSupportedLanguages } from 'dash/src/services';
import {
  CreateParam,
  ValidationResponse,
  createPatient,
  searchPatients,
  validatePatient,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';

import { PhoneItem, PhoneRow } from './PhoneRow';

const MAX_POLL_ATTEMPTS = 120;

const isValidCity = (city?: string): boolean =>
  !city || /^[a-zA-Z ]+$/.test(city);

const isValidZipCode = (code?: string): boolean =>
  !code || /^\d{5}(-\d{4})?$/.test(code);

export const Members = withRouter((props) => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [birthDate, setBirthDate] = useState<Moment>();
  const [gender, setGender] = useState<PatientGender>();
  const [language, setLanguage] = useState<Language>();
  const [phones, setPhones] = useState<PhoneItem[]>([
    { primary: true } as PhoneItem,
  ]);
  const [address, setAddress] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<States>();
  const [zipCode, setZipCode] = useState<string>();
  const [referral, setReferral] = useState<number>();
  const [externalId, setExternalId] = useState<string>();
  const [availableReferrals, setAvailableReferrals] = useState<Organization[]>(
    [],
  );
  const [isTestPatient, setTestPatient] = useState(false);
  const [isCreatingMember, setIsCreatingMember] = useState(false);
  const { showHoneycombAddMember, disableAddMembers } = useFlags();

  const isIncomplete = (data: Partial<CreateParam>) =>
    !data.firstName ||
    !data.lastName ||
    !!phones.find(({ number, type }) => !number || !type) ||
    !data.referral ||
    !data.externalId ||
    !isValidCity(data.city) ||
    !isValidZipCode(data.zipCode);

  const fetchAvailableReferrals = async () => {
    setLoading(true);
    CacheServices.getOrganizations()
      .then((orgs) => {
        setAvailableReferrals(orgs.filter((item) => item.isActiveReferral));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setShowError(false);
  }, [firstName, lastName, phones, referral, gender, state, birthDate]);

  useEffect(() => {
    if (!availableReferrals.length && !loading) {
      fetchAvailableReferrals();
    }
  });

  const [supportedLanguages] = useAsyncData(fetchSupportedLanguages, [], []);

  const addMember = async () => {
    if (disableAddMembers) return;

    setShowError(false);

    const primaryPhone = phones.find(({ primary }) => primary);
    const otherPhones = phones.filter(({ primary }) => !primary);

    const patientData: Partial<CreateParam> = {
      address,
      address2,
      birthDate,
      city,
      referral,
      externalId,
      firstName,
      gender,
      language,
      lastName,
      phoneNumber: primaryPhone?.number,
      phoneType: primaryPhone?.type?.value,
      phoneNumber2: otherPhones[0]?.number,
      phoneType2: otherPhones[0]?.type?.value,
      phoneNumber3: otherPhones[1]?.number,
      phoneType3: otherPhones[1]?.type?.value,
      phoneNumber4: otherPhones[2]?.number,
      phoneType4: otherPhones[2]?.type?.value,
      state,
      zipCode,
      test: isTestPatient,
    };

    if (isIncomplete(patientData)) {
      setShowError(true);
      return false;
    }

    setLoading(true);

    if (showHoneycombAddMember) {
      try {
        const [v, errs] = await validate(patientData as CreateParam);
        const duplicatedId = errs?.warnings[0]?.duplicateIdentity;
        if (!v) {
          await createPatient(patientData as CreateParam);
          pollForMember(patientData as CreateParam);
        } else if (duplicatedId) {
          store.dispatch(
            broadcastMessage({
              messages: [
                {
                  message: (
                    <Fragment key={duplicatedId}>
                      {translate('vesta.addMember.warningDuplicate')}{' '}
                      <a
                        onClick={() => store.dispatch(closeGlobalMessage())}
                        href={`#/patients/${duplicatedId}`}
                      >
                        ({translate('vesta.addMember.warningDuplicateLink')})
                      </a>
                    </Fragment>
                  ),
                },
              ],
            }),
          );
          setLoading(false);
        } else {
          store.dispatch(
            broadcastMessage({
              message: translate('vesta.addMember.warningUnknown'),
              detail: v,
            }),
          );
          setLoading(false);
        }
      } catch (error) {
        store.dispatch(broadcastError(error));
        setLoading(false);
      }
    } else {
      try {
        const newPatient = await createPatient(
          patientData as CreateParam,
          true,
        );
        if (!newPatient) {
          throw new Error('No patient was returned');
        }
        props.history.push(`/patients/${newPatient.id}/personal-details`);
      } catch (error) {
        store.dispatch(broadcastError(error));
      }
      setLoading(false);
    }
  };

  const validate = async (
    patientData: CreateParam,
  ): Promise<[string, ValidationResponse?]> => {
    try {
      const validation = await validatePatient(patientData);
      const errors = validation.errors.map((e) => e.messages?.join(' ,'));
      const warnings = validation.warnings.map((e) =>
        e.duplicateIdentity ? 'Duplicate member' : e.messages?.join(' ,'),
      );
      return [errors.concat(warnings).join(' ,'), validation];
    } catch (error) {
      store.dispatch(broadcastError(error));
    }
    return [''];
  };

  const pollForMember = async (patientData: CreateParam, recurrences = 0) => {
    setIsCreatingMember(true);

    try {
      const patients = await searchPatients({
        limit: 1,
        fullName: `${patientData.firstName} ${patientData.lastName}`,
        dateOfBirth: patientData.birthDate,
      });

      if (patients && patients.items.length > 0) {
        const patient = patients.items[0];
        props.history.push(`/patients/${patient.id}/personal-details`);
        setLoading(false);
      } else {
        if (recurrences >= MAX_POLL_ATTEMPTS) {
          store.dispatch(broadcastError(new Error(`Poll timed out`)));
          setIsCreatingMember(false);
          return;
        }
        setTimeout(() => pollForMember(patientData, recurrences + 1), 500);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div id="page-wrapper">
      <div id="page">
        <Panel>
          <Panel.Heading data-cy="add-member-page" title="Add New Member" />
          <Panel.Body loading={loading} className="grid-wrapper">
            <div className="grid-span-12 grid-wrapper">
              <Select
                errorText={translate('global.missingRequiredField')}
                className="grid-span-3"
                label="Referral Source"
                options={availableReferrals.map((referral) => ({
                  label: `${referral.name}${
                    referral.abbr ? ` (${referral.abbr})` : ''
                  }`,
                  value: referral.name,
                }))}
                onChange={setReferral}
                required
                submitted={!referral && showError}
                value={referral}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <TextInput
                data-cy="member-first-name"
                autoFocus
                className="grid-span-3"
                errorText={translate('global.missingRequiredField')}
                label="First Name"
                onChange={setFirstName}
                required
                showError={!firstName && showError}
                value={firstName}
              />
              <TextInput
                data-cy="member-last-name"
                className="grid-span-3"
                errorText={translate('global.missingRequiredField')}
                label="Last Name"
                onChange={setLastName}
                required
                showError={!lastName && showError}
                value={lastName}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <DateTimePicker
                data-cy="member-birth-date"
                className="grid-span-3"
                errorText={translate('global.missingRequiredField')}
                label="Birth Date"
                onChange={setBirthDate}
                value={birthDate}
                required
                showError={!birthDate && showError}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <Select
                data-cy="member-gender"
                className="grid-span-3"
                errorText={translate('global.missingRequiredField')}
                label={translate('personalDetails.gender')}
                options={Enum.toSelectable(PatientGender.asArray)}
                onChange={setGender}
                required
                submitted={!gender && showError}
                value={gender}
              />
            </div>
            <div className="grid-span-6">
              <div className="grid-wrapper">
                <label className="grid-span-1">Primary</label>
                <label className="grid-span-5">Phone Number</label>
                <label className="grid-span-5">Type</label>
              </div>
              {phones?.map((phoneNumber, index) => (
                <PhoneRow
                  key={phoneNumber.id || `new-${index}`}
                  phone={phoneNumber}
                  onChange={(oldPhone, newPhone) => {
                    phones[index] = { ...oldPhone, ...newPhone };
                    if (newPhone.primary) {
                      phones.forEach((item, idx) => {
                        if (idx !== index) {
                          item.primary = false;
                        }
                      });
                    }
                    setPhones([...phones]);
                  }}
                  onRemove={
                    phones.length <= 1
                      ? undefined
                      : () => {
                          if (phones[index]?.primary) {
                            phones.splice(index, 1);
                            phones[0].primary = true;
                          } else {
                            phones.splice(index, 1);
                          }
                          setPhones([...phones]);
                        }
                  }
                  showError={showError}
                  showPrimaryError={
                    showError && phones.every((item) => !item.primary)
                  }
                />
              ))}
            </div>
            <div className="grid-span-6" />
            {phones.length < 4 && (
              <>
                <div className="grid-span-2" />
                <Button
                  data-cy="btn-add"
                  className="btn-add grid-span-2"
                  color="tertiary"
                  onClick={() => {
                    setPhones([...phones, {} as PhoneItem]);
                  }}
                >
                  <AddIterationIcon className="margin-right" />
                  {translate('vesta.addMember.addPhone')}
                </Button>
              </>
            )}
            <div className="grid-span-12 grid-wrapper">
              <Select
                data-cy="member-language"
                className="grid-span-4"
                label="Language"
                options={Language.toSelectable(supportedLanguages)}
                onChange={setLanguage}
                value={language}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <TextInput
                data-cy="member-address"
                className="grid-span-4"
                label="Address"
                onChange={setAddress}
                value={address}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <TextInput
                data-cy="member-address-two"
                className="grid-span-4"
                label="Address 2"
                onChange={setAddress2}
                value={address2}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <TextInput
                className="grid-span-3"
                data-cy="member-city"
                errorText={translate('global.cityFormatError')}
                label="City"
                onChange={setCity}
                showError={showError && !isValidCity(city)}
                value={city}
              />
              <Select
                data-cy="member-state"
                className="grid-span-3"
                label="State"
                options={Enum.toSelectable(States.asArray)}
                errorText={translate('global.missingRequiredField')}
                onChange={setState}
                required
                submitted={!state && showError}
                value={state}
              />
              <TextInput
                className="grid-span-3"
                data-cy="member-zip-code"
                errorText={translate('global.zipFormatError')}
                label="Zip Code"
                onChange={setZipCode}
                showError={showError && !isValidZipCode(zipCode)}
                value={zipCode}
              />
            </div>
            <div className="grid-span-12 grid-wrapper">
              <TextInput
                data-cy="member-external-id"
                className="grid-span-4"
                label="External ID"
                required
                errorText={translate('global.missingRequiredField')}
                showError={!externalId && showError}
                onChange={setExternalId}
                value={externalId}
              />
            </div>
            {Session.actingUser.canEditMemberTestFlag &&
              !showHoneycombAddMember && (
                <div className="grid-span-12">
                  <label>Test Patient</label>
                  <Checkbox
                    checked={isTestPatient}
                    className="slide-toggle"
                    icon={isTestPatient ? 'toggle-on' : 'toggle-off'}
                    label={isTestPatient ? 'TRUE' : 'FALSE'}
                    onChange={setTestPatient}
                    showError={showError}
                  />
                </div>
              )}
            <div className="grid-span-12 text-right">
              <Button
                data-cy="add-member-button"
                async
                color="primary"
                disabled={disableAddMembers}
                onClick={addMember}
              >
                Add Member
                {disableAddMembers &&
                  ` (${translate('global.disabledDueToMaintenance')})`}
              </Button>
            </div>
          </Panel.Body>
        </Panel>
        <Modal show={isCreatingMember} onHide={() => {}}>
          <Modal.Header modalTitle="Please wait" />
          <Modal.Body>
            <Text>Please wait while the member is being created.</Text>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      </div>
    </div>
  );
});

export default Members;
