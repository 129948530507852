import React, { ReactNode } from 'react';
import { MenuItem, NavDropdown } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Employee } from '@vestahealthcare/common/models';
import { TEST_RN_ID } from '@vestahealthcare/common/utils/constants';

import { Toast } from 'styleguide';

import config from 'dash/environments';
import Avatar from 'dash/src/components/Header/Avatar';
import { onEmployeeUpdate } from 'dash/src/redux/slices/EmployeeSlice';
import store from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import { updateEmployee } from 'dash/src/services/EmployeeServices';
import LoginServices from 'dash/src/services/LoginServices';
import Session from 'dash/src/services/SessionServices';

interface Props {
  actingUser: Employee;
  isImpersonating: boolean;
}

const AvatarMenu = (props: Props & RouteComponentProps) => {
  const { actingUser, isImpersonating } = props;
  const LINKS = {
    LOGOUT: (
      <MenuItem
        onClick={() => LoginServices.logout()}
        key="logout"
        id="user-menu-logout"
        data-cy="user-menu-logout"
      >
        Logout
      </MenuItem>
    ),
    LOGOUT_IMPERSONATED: (
      <MenuItem
        onClick={() => LoginServices.logoutImpersonated()}
        key="logout-impersonated"
        id="user-menu-logout-impersonated"
        data-cy="user-menu-logout-impersonated"
      >
        Become you again
      </MenuItem>
    ),
    ADMIN: (
      <MenuItem
        href="/#/admin/dashboard"
        key="admin"
        id="user-menu-admin-dashboard"
        data-cy="user-menu-admin-dashboard"
      >
        Admin Dashboard
      </MenuItem>
    ),
    DEV: (
      <MenuItem
        href="/#/dev/dashboard"
        key="dev"
        id="user-menu-dev-dashboard"
        data-cy="user-menu-dev-dashboard"
      >
        Dev Dashboard
      </MenuItem>
    ),
    SUPER_USER: (
      <MenuItem
        onClick={async () => {
          const groups = await CacheServices.getEmployeeGroupsAssignee();
          await updateEmployee(Session.actingUser.id, {
            employeeGroupIds: groups,
          });

          const actingUser = await LoginServices.authCheck();
          store.dispatch(onEmployeeUpdate(actingUser));
          new Toast({
            title: 'Enrollment complete',
            body: `${Session.actingUser.firstName} has been enrolled in all active groups.`,
            position: 'bottom-right',
          });
        }}
        key="dev"
        id="user-menu-enroll-groups"
        data-cy="user-menu-enroll-groups"
      >
        Become Super User
      </MenuItem>
    ),
    BECOME_TEST_RN: (
      <MenuItem
        onClick={() => LoginServices.impersonate(TEST_RN_ID)}
        key="test-rn"
        id="user-menu-impersonate-test-rn"
        data-cy="user-menu-impersonate-test-rn"
      >
        Become Test RN
      </MenuItem>
    ),
    DIVIDER: <hr key="divider" className="divider" />,
    MESSAGING: (
      <MenuItem
        href="/#/ops/messaging"
        key="messaging"
        id="user-menu-messaging"
        data-cy="user-menu-messaging"
      >
        Messaging
      </MenuItem>
    ),
    OPEN_STAGE: (
      <MenuItem
        href={`https://dash.stage.hometeamcare.com/#${props.location.pathname}`}
        target="_blank"
        key="stage"
        id="user-menu-open-stage"
        data-cy="user-menu-open-stage"
      >
        Open In Stage
      </MenuItem>
    ),
    OPEN_PROD: (
      <MenuItem
        href={`https://dash.hometeamcare.com/#${props.location.pathname}`}
        target="_blank"
        key="prod"
        id="user-menu-open-prod"
        data-cy="user-menu-open-prod"
      >
        Open In Production
      </MenuItem>
    ),
  };

  const getUserLinks = (): ReactNode[] => {
    let links: ReactNode[] = [];

    if (
      (actingUser.isAdmin && actingUser.isAdminRole) ||
      (!config.isProd && isImpersonating)
    ) {
      const environments = !config.isProd
        ? [!config.isStage && LINKS.OPEN_STAGE, LINKS.OPEN_PROD]
        : [];

      links = [
        LINKS.ADMIN,
        !config.isProd && LINKS.DEV,
        !config.isProd && LINKS.SUPER_USER,
        !isImpersonating && LINKS.BECOME_TEST_RN,
        ...environments,
      ];
    }

    return [
      ...links,
      LINKS.MESSAGING,
      !!links.length && LINKS.DIVIDER,
      isImpersonating ? LINKS.LOGOUT_IMPERSONATED : LINKS.LOGOUT,
    ].filter((item) => !!item);
  };

  return (
    <NavDropdown
      title={<Avatar user={actingUser} />}
      noCaret
      className="pull-right"
      id="ht-nav-user-menu"
    >
      {getUserLinks()}
    </NavDropdown>
  );
};

export default withRouter(AvatarMenu);
