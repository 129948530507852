import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { RosterFileMetadata } from '@vestahealthcare/common/models';

import { CheckboxWithLabel, Modal, Select } from 'styleguide-v2';

import { fetchAllArchiveFiles } from 'dash/src/services/FileHistoryServices';

type Props = {
  defaultFile?: RosterFileMetadata;
  defaultTermByAbsence?: boolean;
  disabledFile?: boolean;
  onHide: () => void;
  onSubmit: (
    applyFutureLead: boolean,
    applyTermByAbsence: boolean,
    rawArchiveId?: number,
  ) => Promise<void>;
};

export const AddLinkModal = (props: Props) => {
  const { showSalesforceIntegration } = useFlags();
  const {
    defaultFile,
    defaultTermByAbsence,
    disabledFile,
    onHide,
    onSubmit,
  } = props;

  const [filterName, setFilterName] = useState('');
  const [selectedFile, setSelectedFile] = useState<
    RosterFileMetadata | undefined
  >(defaultFile);
  const [rawFiles, setRawFiles] = useState<RosterFileMetadata[]>([]);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [applyTermByAbsence, setApplyTermByAbsence] = useState<boolean>(
    !!defaultTermByAbsence,
  );
  const [applyFutureLead, setApplyFutureLead] = useState<boolean>(false);

  const fetchFiles = async () => {
    setLoading(true);
    const { items } = await fetchAllArchiveFiles({
      filename: filterName || undefined,
      sort: 'createdAt desc',
    });
    setRawFiles(items);
    setLoading(false);
  };

  useEffect(() => {
    if (filterName.length > 1) {
      fetchFiles();
    }
  }, [filterName]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const submit = async () => {
    if (!selectedFile) {
      setShowError(true);
      return;
    }

    return await onSubmit(applyFutureLead, applyTermByAbsence, selectedFile.id);
  };

  const doHide = () => {
    setApplyFutureLead(false);
    setApplyTermByAbsence(false);
    onHide();
  };

  return (
    <Modal
      onClose={doHide}
      open
      title={translate('rosterIngestion.linkToRawFile')}
      submitDisabled={!selectedFile}
      submitText={translate('rosterIngestion.linkFilesAndUpload')}
      onSubmit={submit}
    >
      <>
        <Select
          data-cy="roster-ingestion-link"
          disabled={disabledFile}
          error={showError && !selectedFile}
          getItemLabel={(file: RosterFileMetadata) => file.processedFilename}
          items={rawFiles}
          label={translate('rosterIngestion.linkFileDescription')}
          loading={loading}
          onChange={setSelectedFile}
          onInputChange={setFilterName}
          required
          value={selectedFile}
        />
        <CheckboxWithLabel
          label={translate('rosterIngestion.termByAbsence')}
          checked={applyTermByAbsence}
          onChange={() => {
            setApplyTermByAbsence(!applyTermByAbsence);
          }}
        />
        {showSalesforceIntegration && (
          <CheckboxWithLabel
            label={translate('rosterIngestion.futureLead')}
            checked={false}
            onChange={() => {
              setApplyFutureLead(!applyFutureLead);
            }}
            tooltip={translate('rosterIngestion.futureLeadTooltip')}
          />
        )}
      </>
    </Modal>
  );
};

export default AddLinkModal;
