import React, { useEffect, useState } from 'react';

import { SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  RosterFileMetadata,
} from '@vestahealthcare/common/models';

import { Button, CollapsableSidebar, Select } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CacheServices } from 'dash/src/services';
import { fetchAllArchiveFiles } from 'dash/src/services/FileHistoryServices';
import { useDebounce } from 'dash/src/utils/debounceUtils';

interface Props {
  'data-cy'?: string;
  open?: boolean;
  onClose: () => void;
  onSubmit: (referral: Organization, fileId: number) => void;
}

export const RosterFileHistoryLookupModal = (props: Props) => {
  const { open, onClose, onSubmit } = props;

  const [submitted, setSubmitted] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<Organization[]>([]);
  const [files, setFiles] = useState<SelectableInfo<RosterFileMetadata>[]>([]);

  const [file, setFile] = useState<SelectableInfo<RosterFileMetadata>>();
  const [inputSearch, setInputSearch] = useState<string>();
  const [referral, setReferral] = useState<Organization>();

  const inputSearchDebounced = useDebounce(inputSearch, 300);

  const fetchData = async () => {
    const organizations = await CacheServices.getOrganizations();
    setReferrals(organizations.filter(({ hasValidDate }) => hasValidDate));
  };

  const fetchFilesData = async () => {
    setLoadingFiles(true);
    const { items } = await fetchAllArchiveFiles({
      filename: inputSearchDebounced,
      limit: 25,
      sort: 'createdAt desc',
    });
    setFiles(RosterFileMetadata.toSelectable(items) || []);
    setLoadingFiles(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (inputSearchDebounced && inputSearchDebounced?.length > 2) {
      fetchFilesData();
    } else {
      setFiles([]);
    }
  }, [inputSearchDebounced]);

  const reset = () => {
    setFile(undefined);
    setInputSearch(undefined);
    setReferral(undefined);
    setSubmitted(false);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const submit = async () => {
    setSubmitted(true);
    if (!referral || !file?.value) return;
    setLoadingSubmit(true);
    try {
      await onSubmit(referral, file.value as number);
      onClose();
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoadingSubmit(false);
  };

  return (
    <CollapsableSidebar
      onClose={onClose}
      open={!!open}
      title={<h2>{translate('rosterFileHistory.titleLookup')}</h2>}
      size={450}
    >
      <CollapsableSidebar.Body data-cy="lookup-file-referral">
        <Select
          data-cy="upload-roster-file-history-referral"
          error={submitted && !referral}
          getItemLabel={({ name }: Organization) => name}
          items={referrals}
          label={translate('rosterFileHistory.referralSource')}
          onChange={setReferral}
          required
          value={referral}
        />
        <Select
          data-cy="lookup-file-file"
          error={submitted && !file}
          items={files}
          label={translate('rosterFileHistory.file')}
          loading={loadingFiles}
          noOptionsText={
            inputSearchDebounced && inputSearchDebounced.length > 2
              ? translate('components.searchMembers.noItemsFound')
              : translate('components.searchMembers.typeToSearch')
          }
          onChange={setFile}
          onInputChange={setInputSearch}
          value={file}
          required
        />
      </CollapsableSidebar.Body>
      <CollapsableSidebar.Buttons>
        <Button data-cy="cancel-button" color="tertiary" onClick={onClose}>
          {translate('global.cancel')}
        </Button>
        <Button
          data-cy="save-button"
          color="secondary"
          loading={loadingSubmit}
          onClick={submit}
        >
          {translate('global.add')}
        </Button>
      </CollapsableSidebar.Buttons>
    </CollapsableSidebar>
  );
};
