import { Moment } from 'moment';

import {
  CareTeamMemberAide,
  CareTeamMemberFamily,
  CareTeamMemberOther,
  CareTeamMemberProvider,
  CareTrack,
  MemberCommunityResource,
  MemberWarning,
  MemberWarningDetail,
  Patient,
  PatientMedicalEquipment,
} from '@vestahealthcare/common/models';

import { fetchMemberCareTeamMembers } from 'dash/src/services/CareTeamServices';
import {
  MemberAllergiesResponse,
  MemberDiagnosticsResponse,
  MemberMedicationsResponse,
  fetchMemberAllergies,
  fetchMemberDiagnostics,
  fetchMemberMedications,
} from 'dash/src/services/ClinicalSummarySevices';
import { fetchMemberCommunityResources } from 'dash/src/services/CommunityResourcesServices';
import { fetchEquipment } from 'dash/src/services/EquipmentServices';
import { fetchCareTracks } from 'dash/src/services/PHIPServices';
import {
  fetchById,
  fetchMemberWarnings,
} from 'dash/src/services/PatientServices';

import { fetchEventsCount } from './EventsSlice';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchPatientById = createAsyncThunk(
  'memberInfoSlice/fetchPatientById',
  async (patientId: number, { dispatch }) => {
    const patient = await fetchById(patientId);
    return dispatch(onPatientUpdated(patient));
  },
);

export const fetchPatientEquipment = createAsyncThunk(
  'memberInfoSlice/fetchPatientEquipment',
  async (patientId: number) => {
    return fetchEquipment(patientId);
  },
);

export const fetchPatientCareTeamMembers = createAsyncThunk(
  'memberInfoSlice/fetchPatientCareTeamMembers',
  async (patientId: number, { dispatch }) => {
    const { items } = await fetchMemberCareTeamMembers(patientId);
    return dispatch(onCareTeamMembersUpdated(items));
  },
);

export const fetchPatientCommunityResources = createAsyncThunk(
  'memberInfoSlice/fetchPatientCareTeamMembers',
  async (patientId: number, { dispatch }) => {
    const { items } = await fetchMemberCommunityResources(patientId);
    return dispatch(onCommunityResourcesUpdated(items));
  },
);

export const fetchPatientWarnings = createAsyncThunk(
  'memberInfoSlice/fetchPatientWarnings',
  async (patientId: number, { dispatch }) => {
    const items = await fetchMemberWarnings(patientId);
    return dispatch(onWarningsUpdated(items));
  },
);

export const fetchPatientAllergies = createAsyncThunk(
  'memberInfoSlice/fetchPatientAllergies',
  async (patientId: number, { dispatch }) => {
    const response = await fetchMemberAllergies(patientId);
    return dispatch(onAllergiesUpdated(response));
  },
);

export const fetchPatientDiagnostics = createAsyncThunk(
  'memberInfoSlice/fetchPatientDiagnostics',
  async (patientId: number, { dispatch }) => {
    const response = await fetchMemberDiagnostics(patientId);
    return dispatch(onDiagnosticsUpdated(response));
  },
);

export const fetchPatientMedications = createAsyncThunk(
  'memberInfoSlice/fetchPatientMedications',
  async (patientId: number, { dispatch }) => {
    const response = await fetchMemberMedications(patientId);
    return dispatch(onMedicationsUpdated(response));
  },
);

export const fetchPatientInfo = createAsyncThunk(
  'memberInfoSlice/fetchPatientInfo',
  async (patientId: number, { dispatch }) => {
    const [careTracks] = await Promise.all([
      fetchCareTracks(patientId),
      dispatch(fetchEventsCount(patientId)),
      dispatch(fetchPatientById(patientId)),
      dispatch(fetchPatientCareTeamMembers(patientId)),
      dispatch(fetchPatientCommunityResources(patientId)),
      dispatch(fetchPatientWarnings(patientId)),
    ] as Promise<any>[]);

    return {
      careTracks,
    };
  },
);
type CareTeamMemberGeneric =
  | CareTeamMemberAide
  | CareTeamMemberFamily
  | CareTeamMemberOther
  | CareTeamMemberProvider;
interface MemberInfoPayload {
  allergies?: Omit<MemberAllergiesResponse, 'updatedAt'>;
  careTeamMembers: CareTeamMemberGeneric[];
  communityResources: MemberCommunityResource[];
  careTracks: CareTrack[];
  diagnostics?: Omit<MemberDiagnosticsResponse, 'updatedAt'>;
  equipment?: PatientMedicalEquipment[];
  medications?: Omit<MemberMedicationsResponse, 'updatedAt'>;
  patient?: Patient;
  updatedAtECW?: Moment;
  warnings?: MemberWarning[];
  warningsDetail?: MemberWarningDetail[];
}

interface MemberInfoSlice extends MemberInfoPayload {
  loading: boolean;
}

const initState: MemberInfoSlice = {
  careTeamMembers: [],
  communityResources: [],
  careTracks: [],
  loading: true,
  warnings: [],
};

const memberInfoSlice = createSlice({
  name: 'memberInfoSlice',
  initialState: initState,
  reducers: {
    onAllergiesUpdated: (
      state,
      action: PayloadAction<MemberAllergiesResponse>,
    ) => {
      const { updatedAt, ...data } = action.payload;
      state.allergies = data;
      state.updatedAtECW = action.payload.updatedAt;
    },
    onCareTeamMembersUpdated: (
      state,
      action: PayloadAction<CareTeamMemberGeneric[]>,
    ) => {
      state.careTeamMembers = action.payload;
    },
    onCommunityResourcesUpdated: (
      state,
      action: PayloadAction<MemberCommunityResource[]>,
    ) => {
      state.communityResources = [...action.payload];
    },
    onDiagnosticsUpdated: (
      state,
      action: PayloadAction<MemberDiagnosticsResponse>,
    ) => {
      const { updatedAt, ...data } = action.payload;
      state.diagnostics = data;
      state.updatedAtECW = updatedAt;
    },
    onMedicationsUpdated: (
      state,
      action: PayloadAction<MemberMedicationsResponse>,
    ) => {
      const { updatedAt, ...data } = action.payload;
      state.medications = data;
      state.updatedAtECW = updatedAt;
    },
    onPatientUpdated: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },
    onWarningsUpdated: (state, action: PayloadAction<MemberWarning[]>) => {
      state.warnings = action.payload;
      state.warningsDetail = undefined;
    },
    onWarningsDetailUpdated: (
      state,
      action: PayloadAction<MemberWarningDetail[]>,
    ) => {
      state.warningsDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientEquipment.fulfilled, (state, action) => {
      state.equipment = action.payload;
    });
    builder.addCase(fetchPatientInfo.fulfilled, (state, action) => {
      state.careTracks = action.payload.careTracks as CareTrack[];
      delete state.allergies;
      delete state.diagnostics;
      delete state.equipment;
      delete state.medications;
      state.loading = false;
    });
    builder.addCase(fetchPatientInfo.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  onAllergiesUpdated,
  onCareTeamMembersUpdated,
  onCommunityResourcesUpdated,
  onDiagnosticsUpdated,
  onMedicationsUpdated,
  onPatientUpdated,
  onWarningsUpdated,
  onWarningsDetailUpdated,
} = memberInfoSlice.actions;

export default memberInfoSlice.reducer;
