import { Enum } from './Enum';

export class ReferralMappingOperationType extends Enum {
  translationKey = 'referralMappingOperationType';

  static asArray: ReferralMappingOperationType[] = [];

  static byKey: { [name: string]: ReferralMappingOperationType } = {};

  static readonly BRANCH = new ReferralMappingOperationType('BRANCH');

  static readonly DEFAULT = new ReferralMappingOperationType('DEFAULT');

  static readonly DATE = new ReferralMappingOperationType('DATE');

  static readonly GENDER = new ReferralMappingOperationType('GENDER');

  static readonly LANGUAGE = new ReferralMappingOperationType('LANGUAGE');

  static readonly PHONE_NUMBER = new ReferralMappingOperationType(
    'PHONE_NUMBER',
  );

  static readonly SPLIT = new ReferralMappingOperationType('SPLIT');

  constructor(public readonly value: string) {
    super();
    ReferralMappingOperationType.asArray.push(this);
    ReferralMappingOperationType.byKey[value] = this;
  }
}

export default ReferralMappingOperationType;
