import classNames from 'classnames';
import React, { useMemo } from 'react';

import { ClassNameMap, makeStyles } from '@mui/styles';

import { ProgramTaskStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PaginationType,
  PodForecastTask,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  BrandMemberIcon,
  CheckboxWithLabel,
  Chip,
  StatusIndicator,
  Table,
  Tooltip,
} from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

import { HouseHoldMembersChip } from 'dash/src/components/HouseHoldMembersChip';

interface Props {
  allChecked: boolean;
  blacklist: boolean;
  defaultPageSize?: number;
  disabledChecks?: boolean;
  defaultSort?: string;
  // TODO: Fix type
  items: PodForecastTask[];
  pagination?: PaginationType;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onSelectTask: (member: PodForecastTask) => void;
  onSelectAll: (allChecked: boolean) => void;
  selectedIds: number[];
  showChecks?: boolean;
}

const TABLE_HEADERS = {
  assignee: translate('programTasksManagement.table.assignee'),
  member: translate('programTasksManagement.table.member'),
  pod: translate('programTasksManagement.table.pod'),
  status: translate('programTasksManagement.table.status'),
  taskDefinition: translate('programTasksManagement.table.taskDefinition'),
};

const FORECAST_TASK_COLUMNS = (
  styles: ClassNameMap<'small' | 'extraSmall'>,
  {
    allChecked,
    blacklist,
    disabledChecks,
    items,
    onSelectAll,
    onSelectTask,
    selectedIds,
    showChecks,
  }: Props,
) => {
  const colums: Column[] = [
    {
      headerName: TABLE_HEADERS.status,
      component: ({ status }: PodForecastTask) =>
        status ? (
          <StatusIndicator
            status={status.toString()}
            color={ProgramTaskStatus.getColor(status)}
          />
        ) : (
          EMPTY
        ),
      width: 220,
    },
    {
      headerName: TABLE_HEADERS.taskDefinition,
      field: 'name',
    },
    {
      headerName: TABLE_HEADERS.pod,
      field: 'member.pod.name',
    },
    {
      headerName: TABLE_HEADERS.member,
      component: ({ member }: PodForecastTask) => (
        <>
          <p
            className={classNames(
              'bold no-margin flex middle wrap',
              styles.small,
            )}
          >
            {member.fullName}
            &nbsp;
            <HouseHoldMembersChip householdMembers={member?.householdMembers} />
            {member.isBrandCareAtHome() && (
              <BrandMemberIcon brand="careAtHome" size="s" />
            )}
          </p>
          (
          <a
            className={styles.small}
            href={`#/patients/${member?.id}`}
            rel="noreferrer"
            target="_blank"
            onClick={(evt) => evt.stopPropagation()}
          >
            {member?.id}
          </a>
          )
          {member?.language && (
            <>
              {' '}
              <Chip color="info" size="small" type="outlined">
                {member.language?.toString()}
              </Chip>
            </>
          )}
          {member?.state && (
            <span className={styles.small}> {member.state?.value}</span>
          )}
        </>
      ),
      width: 200,
    },
    {
      headerName: TABLE_HEADERS.assignee,
      field: 'assignee.fullName',
    },
  ];

  const headerCheck = (
    <CheckboxWithLabel
      checked={allChecked}
      disabled={!items.length || disabledChecks}
      onChange={() => onSelectAll(allChecked)}
    />
  );
  const rowCheck = (task: PodForecastTask) => (
    <CheckboxWithLabel
      disabled={disabledChecks}
      checked={blacklist !== selectedIds.includes(task.id)}
      onChange={() => onSelectTask(task)}
    />
  );

  return showChecks
    ? [
        {
          headerName: disabledChecks ? (
            <Tooltip
              text={translate(
                'programTasksManagement.table.disableCheckWarning',
              )}
            >
              <div>{headerCheck}</div>
            </Tooltip>
          ) : (
            headerCheck
          ),
          component: (task: PodForecastTask) =>
            disabledChecks ? (
              <Tooltip
                text={translate(
                  'programTasksManagement.table.disableCheckWarning',
                )}
              >
                <div>{rowCheck(task)}</div>
              </Tooltip>
            ) : (
              rowCheck(task)
            ),
          width: 50,
        },
        ...colums,
      ]
    : colums;
};

const useStyles = makeStyles({
  small: { fontSize: '0.875em', lineHeight: '16px' },
  extraSmall: { fontSize: '0.75em', lineHeight: '16px' },
});

export const ProgramTaskManagementTable = (props: Props) => {
  const styles = useStyles();

  const columns = useMemo(() => FORECAST_TASK_COLUMNS(styles, props), [
    props.onSelectTask,
    styles,
  ]);

  return (
    <Table
      config={{
        columns,
        compact: true,
        data: props.items,
        defaultPageSize: props.defaultPageSize,
        getRowBrand: ({ member }: PodForecastTask) => {
          if (member?.isVPC() || member?.isVPCLite()) return 'VPC';
          return member?.brand;
        },
        pagination: props.pagination,
        paginationOptions: [25, 50, 100],
      }}
      empty
      fixedHeader
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangePageSize}
    />
  );
};

export default ProgramTaskManagementTable;
