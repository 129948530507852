import { Language, MemberTag, States } from '../enums';
import {
  DaysOfServiceHelper,
  getDaysOfService,
} from './Helpers/DaysOfServiceHelper';
import {
  VestaCoreLiteHelper,
  isVPC,
  isVPCLite,
  isVestaCoreLite,
} from './Helpers/VestaCoreLiteHelper';
import { HouseHoldMember } from './HouseHoldMember';
import { MemberContactInfoTimes } from './MemberContactInfoTimes';
import { MemberProgramExtension } from './MemberProgramExtension';
import { make, makeArray, makeEnums } from './Model';
import { PatientBase } from './PatientBase';
import { PodConfigMinimal } from './PodConfigMinimal';

export class ProgramTaskMember
  extends PatientBase
  implements DaysOfServiceHelper, VestaCoreLiteHelper {
  householdMembers?: HouseHoldMember[];

  language?: Language;

  memberContactTimes?: MemberContactInfoTimes[];

  pod: PodConfigMinimal;

  programExtensions?: MemberProgramExtension[];

  state: States;

  tags: MemberTag[];

  constructor(obj: any) {
    super(obj);
    this.language = obj.language ? Language.byKey[obj.language] : undefined;
    this.householdMembers = makeArray(obj.householdMembers, HouseHoldMember);
    if (obj.firstName && obj.lastName && !this.fullName) {
      this.fullName = `${obj.firstName} ${obj.lastName}`;
    }
    this.memberContactTimes = makeArray(
      obj.memberContactTimes,
      MemberContactInfoTimes,
    );
    this.pod = make(obj.pod, PodConfigMinimal);
    this.state = States.byKey[obj.state];
    this.tags = makeEnums(obj.tags, MemberTag);
  }

  isVPC() {
    return isVPC(this);
  }

  isVPCLite() {
    return isVPCLite(this);
  }

  isVestaCoreLite() {
    return isVestaCoreLite(this);
  }

  getDaysOfService() {
    return getDaysOfService(this.memberContactTimes);
  }

  get unreachable() {
    return this.tags.includes(MemberTag.UNREACHABLE);
  }

  get liteToCcm() {
    return this.tags.includes(MemberTag.LITE_TO_CCM);
  }
}

export default ProgramTaskMember;
