import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { PodMetricsSummaryForecast } from '@vestahealthcare/common/models';
import PodMetricsSummaryTaskForecast from '@vestahealthcare/common/models/PodMetricsSummaryTaskForecast';

import { Chip, Fonts, Tooltip } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummaryForecast[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    gap: '0.5rem',
    padding: '2rem 4rem',
    width: `${COLUMN_SIZE}px`,
  },
  text: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
    maxWidth: `${COLUMN_SIZE - 110}px`,
  },
});

export const PODSummaryTableTaskForecast = ({ data }: Props) => {
  const styles = useStyles();

  const renderChip = (data: PodMetricsSummaryTaskForecast) => (
    <Chip className="semi-bold" color="info" type="contained-2">
      {data.count}
    </Chip>
  );

  return (
    <div className={styles.container}>
      {data.map((pod, idx) => (
        <div
          className={styles.itemContainer}
          key={`pod-member-allocation-${idx}`}
        >
          {pod.taskStatusCounts.map((task, taskIdx) => (
            <div
              className="flex space-between gap"
              key={`pod-${idx}-task-${taskIdx}`}
            >
              <Tooltip text={task.item}>
                <div className={classNames(styles.text, 'ellipsis')}>
                  {task.item}
                </div>
              </Tooltip>
              {renderChip(task)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
