import moment from 'moment';

import {
  AppointmentStatus,
  ProgramTaskStatus,
} from '@vestahealthcare/common/enums';
import {
  PodReportingAherenceData,
  PodReportingData,
  PodReportingTask,
  ProgramExtension,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import { RecursiveTableData } from '../../../components/RecursiveTable';
import {
  TOC_TAB_DISCHARGED_INITIAL,
  TOC_TAB_DISCHARGED_POST,
  TOC_TAB_INPATIENT,
} from '../TOC/types';

export const onClickAdherence = (filtersString: string) => (
  data: PodReportingAherenceData,
) => {
  const adherence = data.rpmAdherence?.value;
  const commonPath = `#/dashboard/ccm?clearFilters=true&programExtensionFilter=${ProgramExtension.RPM}:${ProgramExtensionStatus.ACTIVE}`;
  if (adherence) {
    window.open(
      `${commonPath}&rpmAdherenceId=${data.rpmAdherence?.value}&${filtersString}`,
      '_blank',
      'noreferrer',
    );
  } else {
    window.open(
      `${commonPath}&hasRpmAdherence=false&${filtersString}`,
      '_blank',
      'noreferrer',
    );
  }
};

export const onClickApptList = (filtersString: string) => (
  item: PodReportingData,
  parentItem?: PodReportingData,
) => {
  const activePE = `programExtensionId=${ProgramExtension.TCM}&programExtensionStatus=${ProgramExtensionStatus.ACTIVE}`;
  const eligibleFilter =
    `&selectedTab=${TOC_TAB_DISCHARGED_POST}` +
    `&${activePE}` +
    '&test=false' +
    '&touchPointsCompleted=true' +
    `&dischargeDateFrom=${moment().subtract(14, 'days')?.format(DATE_FORMAT)}` +
    `&${filtersString}`;

  const followUpFilter =
    `&selectedTab=${TOC_TAB_DISCHARGED_POST}` +
    `&${activePE}` +
    '&test=false' +
    `&dischargeDateFrom=${moment().subtract(30, 'days')?.format(DATE_FORMAT)}` +
    `&dischargeDateTo=${moment().subtract(14, 'days')?.format(DATE_FORMAT)}` +
    `&${filtersString}`;

  if (item.name.includes('Eligible'))
    return () =>
      window.open(
        `#/dashboard/toc-list?clearFilters=true&${eligibleFilter}`,
        '_blank',
        'noreferrer',
      );
  if (parentItem?.name.includes('Eligible')) {
    if (item.name.includes('Pending'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${eligibleFilter}` +
            `&${AppointmentStatus.PENDING_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Completed'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${eligibleFilter}` +
            `&${AppointmentStatus.COMPLETED_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Reschedule'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${eligibleFilter}` +
            `&${AppointmentStatus.RESCHEDULE_NEEDED_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Not Yet Scheduled'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${eligibleFilter}` +
            `&npVisitStatus=NO_APPOINTMENT`,
          '_blank',
          'noreferrer',
        );
  }
  if (item.name.includes('Follow-up'))
    return () =>
      window.open(
        `#/dashboard/toc-list?clearFilters=true&${followUpFilter}`,
        '_blank',
        'noreferrer',
      );
  if (parentItem?.name?.includes('Follow-up')) {
    if (item.name.includes('Pending'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${followUpFilter}` +
            `&${AppointmentStatus.PENDING_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Completed'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${followUpFilter}` +
            `&${AppointmentStatus.COMPLETED_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Reschedule'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${followUpFilter}` +
            `&${AppointmentStatus.RESCHEDULE_NEEDED_STATUSES.map(
              (s) => `npVisitStatus=${encodeURIComponent(s.value)}`,
            ).join('&')}`,
          '_blank',
          'noreferrer',
        );
    if (item.name.includes('Not Yet Scheduled'))
      return () =>
        window.open(
          '#/dashboard/toc-list?clearFilters=true' +
            `&${followUpFilter}` +
            `&npVisitStatus=NO_APPOINTMENT`,
          '_blank',
          'noreferrer',
        );
  }
};

export const onClickChaseList48 = (filtersString: string) => (
  item: PodReportingData,
) => {
  const activePE = `programExtensionId=${ProgramExtension.TCM}&programExtensionStatus=${ProgramExtensionStatus.ACTIVE}&test=false`;
  if (item.name === 'Inpatient')
    return () =>
      window.open(
        '#/dashboard/toc-list?clearFilters=true' +
          `&selectedTab=${TOC_TAB_INPATIENT}` +
          `&${activePE}` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );

  if (item.name.includes('2 business'))
    return () =>
      window.open(
        '#/dashboard/toc-list?clearFilters=true' +
          `&selectedTab=${TOC_TAB_DISCHARGED_INITIAL}` +
          `&touchPointsCompleted=false` +
          `&${activePE}` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );
  if (item.name.includes('touchpoint'))
    return () =>
      window.open(
        '#/dashboard/toc-list?clearFilters=true' +
          `&selectedTab=${TOC_TAB_DISCHARGED_INITIAL}` +
          `&${activePE}` +
          `&${
            item.name.includes('2')
              ? 'twoTouchPointsNeeded=true'
              : 'oneTouchPointNeeded=true'
          }` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );
  if (item.name.includes('Complete'))
    return () =>
      window.open(
        '#/dashboard/toc-list?clearFilters=true' +
          `&selectedTab=${TOC_TAB_DISCHARGED_INITIAL}` +
          `&${activePE}` +
          `&touchPointsCompleted=true` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );

  const missedFilter =
    `selectedTab=${TOC_TAB_DISCHARGED_POST}` +
    `&${activePE}` +
    `&status=DISCHARGED` +
    `&dischargedInitial2BusinessDays=false` +
    `&touchPointsCompleted=false` +
    `&${filtersString}`;

  if (item.name.includes('Missed'))
    return () =>
      window.open(
        `#/dashboard/toc-list?clearFilters=true&${missedFilter}`,
        '_blank',
        'noreferrer',
      );
  if (item.name.includes('deadline'))
    return () =>
      window.open(
        '#/dashboard/toc-list?clearFilters=true' +
          `&${missedFilter}` +
          `&eventCreatedWithinContactDeadline=${item.name.includes('before')}` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );
};

export const onClickCCMTaskEscalation = (
  filtersString: string,
  month: string,
) => (item: RecursiveTableData, parentItem?: RecursiveTableData) => {
  if (parentItem)
    return () =>
      window.open(
        '#/dashboard/program-tasks-management?clearFilters=true' +
          `&assigneeId=${parentItem.name}` +
          `&month=${month}` +
          `&statusId=${ProgramTaskStatus.ESCALATED.value}` +
          `&statusAge=${item.name}` +
          `&${filtersString}`,
        '_blank',
        'noreferrer',
      );
  return () =>
    window.open(
      '#/dashboard/program-tasks-management?clearFilters=true' +
        `&assigneeId=${item.id}` +
        `&month=${month}` +
        `&statusId=${ProgramTaskStatus.ESCALATED.value}` +
        `&${filtersString}`,
      '_blank',
      'noreferrer',
    );
};

export const onClickProgramTaskStatus = (
  filtersString: string,
  month: string,
) => (item: PodReportingTask, statuses?: ProgramTaskStatus[]) =>
  window.open(
    '#/dashboard/program-tasks-management?clearFilters=true' +
      `${filtersString}` +
      `&month=${month}` +
      `&taskDefinitionId=${item.id}` +
      `&${
        statuses
          ?.map((s) => `statusId=${encodeURIComponent(s.value)}`)
          .join('&') || ''
      }`,
    '_blank',
    'noreferrer',
  );
