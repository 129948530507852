import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';

import { EmployeeRole } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PodMetricsSummaryCommon,
  PodMetricsSummaryEmployee,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  Collapse,
  CollapseIcon,
  Colors,
  IconButton,
  Spinner,
} from 'styleguide-v2';

import RecursiveTable from 'dash/src/components/RecursiveTable';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryCommon;
  employees?: PodMetricsSummaryEmployee[];
  employeesLoading?: boolean;

  onOpenEmployees: () => void;
};

type RoleOptions =
  | 'np'
  | 'rn'
  | 'health_coach'
  | 'care_manager'
  | 'engagement_coordinator';

export const PodSummaryCardMemberAllocation = ({
  className,
  pod,
  employees,
  onOpenEmployees,
}: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const employeeAllocation = useMemo(
    () =>
      Object.entries(
        (employees || []).reduce((acc, item) => {
          const role = item.role as RoleOptions;
          if (acc[role]) {
            acc[role].allocatedMembers += item.allocatedMembers;
            acc[role].employees.push(item);
            acc[role].maxMembers += item.maxMembers;
          } else {
            acc[role] = {
              allocatedMembers: item.allocatedMembers,
              employees: [item],
              maxMembers: item.maxMembers,
            };
          }
          return acc;
        }, {} as { [role in RoleOptions]: Omit<PodMetricsSummaryEmployee, 'id' | 'employee' | 'role'> & { employees: PodMetricsSummaryEmployee[] } }),
      ).map(([key, item]) => ({
        role: key as RoleOptions,
        ...item,
      })),
    [employees],
  );

  const handleOpenDetail = () => {
    if (!employees?.length && !openDetail) {
      setLoading(true);
      onOpenEmployees();
    } else {
      setLoading(false);
      setOpenDetail(!openDetail);
    }
  };

  useEffect(() => {
    if (employees?.length) {
      setOpenDetail(true);
      setLoading(false);
    }
  }, [employees]);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => handleOpenDetail()}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.memberAllocation')}
        </span>
        <div className={styles.titleInfo}>
          <PeopleIcon
            htmlColor={
              pod.memberCount > pod.maxMembers ? Colors.error : Colors.iconGreen
            }
            fontSize="large"
          />
          <span
            className={classNames(
              styles.textWithIcon,
              pod.memberCount > pod.maxMembers && 'red',
            )}
          >
            {pod.memberCount}/{pod.maxMembers}
          </span>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => handleOpenDetail()}
            size="small"
          >
            {loading ? (
              <div className="flex center" style={{ width: 25 }}>
                <Spinner width={20} />
              </div>
            ) : (
              <CollapseIcon open={openDetail} />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <RecursiveTable
            data={employeeAllocation.map((type) => ({
              name: EmployeeRole.byKey[type.role]?.toString() || EMPTY,
              column1: type.allocatedMembers,
              column2: type.maxMembers,
              subtotals: type.employees.map((e) => ({
                name: e.employee.fullName,
                column1: e.allocatedMembers,
                column2: e.maxMembers,
              })),
            }))}
            header="Employee"
            header1="Assigned"
            header2="Capacity"
          />
        </div>
      </Collapse>
    </div>
  );
};
