import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { Paper, darken, lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PieChart as MPieChart } from '@mui/x-charts/PieChart';
import { PieValueType } from '@mui/x-charts/models';

import { BackgroundColors, Colors } from '../../styles/Colors';
import { PieChartLegend } from '../PieChart/PieChartLegend';

type Data = Omit<PieValueType, 'label'> & {
  label: string;
  subdata: Omit<Data, 'subdata'>[];
};

type InternalSubData = Omit<Data, 'subdata'> & { originalValue: number };

type SizeType = 's' | 'm' | 'l';

const SIZES_A: { [size in SizeType]: number } = {
  s: 100,
  m: 150,
  l: 200,
};

const SIZES_B: { [size in SizeType]: number } = {
  s: 140,
  m: 210,
  l: 280,
};

type Props = {
  className?: string;
  'data-cy'?: string;
  data: Data[];
  getTooltipValue?: (value: number, idx: number, percentage: number) => string;
  showLegend?: boolean;
  size?: SizeType;
  title?: string;
};

const COLORS_STATUS = [
  Colors.iconGreen,
  Colors.gold,
  Colors.error,
  Colors.textRed2,
  Colors.purple,
  '#60009b',
];

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    margin: '0.5rem',
    width: 'fit-content',
  },
  noDataContainer: {
    height: 0,
    fontSize: 14,
    textAlign: 'center',
  },
});

export const PieChartMultiLevel = ({
  className,
  data,
  'data-cy': dataCy,
  getTooltipValue,
  showLegend,
  size = 'm',
  title,
}: Props) => {
  const styles = useStyles();

  const total = useMemo(() => data.reduce((acc, { value }) => acc + value, 0), [
    data,
  ]);
  const innerData = useMemo(
    () =>
      data.map(({ subdata, ...rest }, idx) => ({
        color: COLORS_STATUS[idx % COLORS_STATUS.length],
        ...rest,
      })),
    [data],
  );

  const outterData = useMemo(() => {
    const result = [] as InternalSubData[];

    data.forEach((item, idx) => {
      if (!item.subdata?.length) {
        result.push({
          color: 'transparent',
          id: `sublabel-${idx}`,
          label: '',
          originalValue: 0,
          value: (item.value / total) * 1000,
        });
      } else {
        const subcolor = COLORS_STATUS[idx % COLORS_STATUS.length];
        if (item.subdata.length === 1) {
          result.push({
            color: darken(subcolor, 0.9),
            id: `sublabel-${idx}-1`,
            label: item.subdata[0].label,
            originalValue: item.subdata[0].value,
            value: (item.subdata[0].value / total) * 1000,
          });
        } else {
          item.subdata.forEach((subitem, subIdx) =>
            result.push({
              color:
                subIdx < item.subdata.length / 2
                  ? darken(subcolor, (item.subdata.length - subIdx) / 5)
                  : lighten(subcolor, 0.5 + (subIdx - item.subdata.length) / 5),
              id: `sublabel-${idx}-${subIdx}`,
              label: subitem.label,
              originalValue: subitem.value,
              value: (subitem.value / total) * 1000,
            }),
          );
        }
      }
    });
    return result;
  }, [data]);

  const CustomItemTooltipContent = (props: any) => {
    const { itemData, series } = props;
    const serie = series.data[itemData.dataIndex];

    if (!serie.label) return <></>;

    return (
      <Paper
        sx={{ alignItems: 'center', display: 'flex', padding: '1rem 1.5rem' }}
      >
        <div
          style={{
            backgroundColor: serie.color,
            borderRadius: '10px',
            height: '1.5rem',
            marginRight: '0.75rem',
            width: '1.5rem',
          }}
        />
        <span
          style={{
            marginRight: '3rem',
            marginTop: '0.125rem',
            color: Colors.textGray,
          }}
        >
          {serie.label}
        </span>
        <span style={{ marginTop: '0.125rem' }}>{}</span>
        <span style={{ marginTop: '0.125rem' }}>
          {getTooltipValue
            ? getTooltipValue(
                serie.originalValue || serie.value,
                itemData.dataIndex,
                Math.round(
                  (1000 * (serie.originalValue || serie.value)) / total,
                ) / 10,
              )
            : serie.value}
        </span>
      </Paper>
    );
  };

  return (
    <div className={className}>
      <div className={classNames(styles.container)}>
        <MPieChart
          data-cy={dataCy}
          series={[
            {
              cornerRadius: 5,
              cx: SIZES_B[size] / 2 - 5,
              cy: SIZES_B[size] / 2 - 5,
              innerRadius: SIZES_A[size] / 3,
              outerRadius: SIZES_A[size] / 2,
              paddingAngle: 1,
              data: total
                ? innerData
                : [
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                  ],
            },
            {
              cornerRadius: 5,
              cx: SIZES_B[size] / 2 - 5,
              cy: SIZES_B[size] / 2 - 5,
              innerRadius: SIZES_B[size] / 2.66,
              outerRadius: SIZES_B[size] / 2,
              paddingAngle: 1,
              data: total
                ? outterData
                : [
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                    {
                      value: Math.random(),
                      color: BackgroundColors.lightGray,
                    },
                  ],
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          tooltip={{ trigger: 'item', itemContent: CustomItemTooltipContent }}
          width={SIZES_B[size]}
          height={SIZES_B[size]}
        />
        {showLegend && (
          <PieChartLegend
            series={innerData.map((item) => ({
              label: item.label as string,
              color: item.color as string,
            }))}
            title={title}
          />
        )}
      </div>
      {(!data?.length || !total) && (
        <div
          className={styles.noDataContainer}
          style={{
            transform: `translateY(-${SIZES_B[size] / 2 + 15}px)`,
            width: SIZES_B[size] + 10,
          }}
        >
          No Data
        </div>
      )}
    </div>
  );
};
