import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import LookupIcon from '@mui/icons-material/FindInPageOutlined';
import UploadIcon from '@mui/icons-material/UploadFile';

import { RosterFileHistoryStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  Organization,
  PaginationType,
  RosterFileHistory,
  RosterFileMetadata,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';
import { getServerFilters } from '@vestahealthcare/common/utils/filter';

import { FilterItem, IconButton, Panel } from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { AddLinkModal } from 'dash/src/pages/Ops/MemberIngestion/AddLinkModal';
import { CacheServices } from 'dash/src/services';
import { getTaskMemberHistory } from 'dash/src/services/AsyncServices';
import {
  RosterFileHistoryParams,
  downloadFileHistory,
  fetchRosterFileHistory,
  lookupRosterFileHistory,
  processRawRosterReadyFile,
  uploadRosterFileHistory,
} from 'dash/src/services/FileHistoryServices';

import { RosterFileHistoryFilterBar } from './RosterFileHistoryFilterBar';
import { RosterFileHistoryLookupModal } from './RosterFileHistoryLookupModal';
import { RosterFileHistoryTable } from './RosterFileHistoryTable';
import { UploadRosterFileModal } from './UploadRosterFileModal';

const ROWS_PER_PAGE = 25;
const UPDATE_TIME = 5000;

type KeyGetRosterParams = keyof RosterFileHistoryParams;

export const RosterFileHistoryPage = () => {
  const { showRosterFileHistoryUpload } = useFlags();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [lookupOpen, setLookupOpen] = useState<boolean>(false);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(-1);
  const [organizations, setOrganization] = useState<Organization[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [rosterTimer, setRosterTimer] = useState<NodeJS.Timeout>();

  const [filters, setFilters] = useState<
    {
      [x in KeyGetRosterParams]?: FilterItem;
    }
  >({});

  const [fileList, setFileList] = useState<RosterFileHistory[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(ROWS_PER_PAGE);

  const [rosterInProgress, setRosterInProgress] = useState<boolean>(true);
  const someFilesHaveExecuteAction = useMemo(
    () => fileList?.some(({ canBeProcessed }) => canBeProcessed),
    [fileList],
  );

  const [linkFile, setLinkFile] = useState<RosterFileHistory>();
  const [linkOpen, setLinkOpen] = useState<boolean>(false);

  const getInitialData = async () => {
    setLoadingFilters(true);
    const organizations = await CacheServices.getOrganizations();
    setOrganization(organizations);
    setLoadingFilters(false);
  };

  const getData = async () => {
    try {
      const currentFilters = getServerFilters(filters);
      if (currentFilters.createdAtFrom)
        currentFilters.createdAtTo = moment().format(DATE_FORMAT);
      const { items, pagination } = await fetchRosterFileHistory({
        ...currentFilters,
        offset: page * pageSize,
        limit: pageSize,
        sort: 'id desc',
      });
      if (page === 0) {
        setFileList(items);
        if (
          items.find(
            ({ status }) => status === RosterFileHistoryStatus.IN_PROGRESS,
          )
        ) {
          setTimer(setTimeout(() => refreshUI(), UPDATE_TIME));
        }
      } else {
        setFileList([...fileList, ...items]);
      }
      setPagination({ ...pagination, offset: page * pageSize });
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  const checkRosterInProgress = async () => {
    if (rosterTimer) {
      clearTimeout(rosterTimer);
    }

    const { pagination } = await getTaskMemberHistory({
      inProgress: true,
      count: true,
    });
    setRosterInProgress(!!pagination.total);

    setRosterTimer(setTimeout(checkRosterInProgress, UPDATE_TIME));
  };

  const refreshUI = async () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (loading) return;

    let idle = false;
    setTimeout(() => !idle && setLoading(true), 500);
    getData().then(() => (idle = true));
  }, [refresh]);

  useEffect(() => {
    setLoading(true);
    getData();
    () => timer && clearTimeout(timer);
  }, [page, pageSize, filters]);

  useEffect(() => {
    if (someFilesHaveExecuteAction) {
      checkRosterInProgress();
    }
    () => rosterTimer && clearTimeout(rosterTimer);
  }, [someFilesHaveExecuteAction]);

  return (
    <Panel id="file-history-section" data-cy="file-history-section">
      <Panel.Heading title={translate('rosterFileHistory.title')} filtersV2>
        <Panel.FilterBar
          chips={filters}
          onClearFilters={() => {
            setFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setFilters({
              ...filters,
              [key]: undefined,
            });
          }}
          inputs={
            <RosterFileHistoryFilterBar
              data={{
                organizations: Organization.toSelectable(organizations),
              }}
              loading={loadingFilters}
              filters={filters}
              onChange={(filters) => {
                setPage(0);
                setFilters(filters);
              }}
            />
          }
        />
        {showRosterFileHistoryUpload && (
          <Panel.Actions>
            <IconButton
              onClick={() => setLookupOpen(true)}
              tooltip={translate('rosterFileHistory.lookupRawRosterFile')}
            >
              <LookupIcon />
            </IconButton>
            <IconButton
              onClick={() => setUploadOpen(true)}
              tooltip={translate('rosterFileHistory.uploadRawRosterFile')}
            >
              <UploadIcon />
            </IconButton>
          </Panel.Actions>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <RosterFileHistoryTable
          disableProcessRoster={rosterInProgress}
          files={fileList}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onDowndload={(file, fileName) => {
            downloadFileHistory(file, fileName);
          }}
          onProcess={(file) => {
            setLinkFile(file);
            setLinkOpen(true);
          }}
          pagination={pagination}
        />
        <UploadRosterFileModal
          open={uploadOpen}
          onSubmit={async (referral, file) => {
            try {
              await uploadRosterFileHistory(referral.id, file);
              CacheServices.invalidateRosterFileHistory();
              refreshUI();
              setUploadOpen(false);
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          onCancel={() => setUploadOpen(false)}
        />
        <RosterFileHistoryLookupModal
          open={lookupOpen}
          onSubmit={async (referral, fileId) => {
            try {
              await lookupRosterFileHistory(referral.id, fileId);
              CacheServices.invalidateRosterFileHistory();
              refreshUI();
              setUploadOpen(false);
            } catch (e) {
              showGlobalError(e as string);
            }
          }}
          onClose={() => setLookupOpen(false)}
        />
        {linkOpen && linkFile && (
          <AddLinkModal
            defaultFile={
              new RosterFileMetadata({
                id: linkFile.id,
                originalFilename: linkFile.name,
              })
            }
            disabledFile
            defaultTermByAbsence={
              organizations?.find(({ id }) => id === linkFile.organizationId)
                ?.autoIngestionConfig?.autoTba
            }
            onHide={() => setLinkOpen(false)}
            onSubmit={async (futureLead, tba) => {
              try {
                await processRawRosterReadyFile(
                  linkFile.readyToUploadFileId,
                  futureLead,
                  tba,
                );
                history.push('/ops/roster-ingestion');
              } catch (e) {
                showGlobalError(e as string);
              }
            }}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default RosterFileHistoryPage;
