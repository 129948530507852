import React from 'react';

import { EmployeeRole, ProgramTaskStatus } from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Patient } from '@vestahealthcare/common/models';
import { PodConfigEmployeeSlot } from '@vestahealthcare/common/models/PodConfigEmployeeSlot';

import { FilterItem, Select, UNASSIGNED } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { GetProgramTaskParam } from 'dash/src/services/PodServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetCCMTasksParams = keyof GetProgramTaskParam;

type Props = {
  data: {
    employees: SelectableInfo<Employee>[];
    taskDefinitions: Selectable[];
  };
  filters?: {
    [x in KeyGetCCMTasksParams]?: FilterItem;
  };
  loading?: boolean;
  member?: Patient;
  onChange: (
    filters: {
      [x in KeyGetCCMTasksParams]?: FilterItem;
    },
  ) => void;
};

export const MemberCCMTaskFiltersBar = ({
  data,
  filters,
  loading,
  member,
  onChange,
}: Props) => (
  <>
    <Select
      data-cy="member-ccm-task-filter-bar-status"
      items={Enum.toSelectable(ProgramTaskStatus.asArray).sort(Enum.sort)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          statusId: getFilterItem(
            translate('memberCCMTasks.filter.status'),
            values,
          ),
        })
      }
      placeholder={translate('memberCCMTasks.filter.status')}
      placeholderV2
      size="xs"
      value={filters?.statusId?.value || []}
    />
    <Select
      data-cy="member-ccm-task-filter-bar-type"
      items={data.taskDefinitions}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          taskDefinitionId: getFilterItem(
            translate('memberCCMTasks.filter.type'),
            values,
          ),
        })
      }
      placeholder={translate('memberCCMTasks.filter.type')}
      placeholderV2
      size="xs"
      value={filters?.taskDefinitionId?.value || []}
    />
    <Select
      data-cy="member-ccm-task-filter-bar-assignee"
      filterOptions={FilterEmployeeOptions}
      grouped={
        member?.pod
          ? (item: SelectableInfo<Employee | PodConfigEmployeeSlot>) => {
              if (item.value) {
                return item.info instanceof Employee ? 'Other' : `POD`;
              }
              return '';
            }
          : undefined
      }
      items={[
        ...Employee.toSelectable([UNASSIGNED]),
        ...PodConfigEmployeeSlot.toSelectable(
          [
            member?.podManager
              ? new PodConfigEmployeeSlot({
                  id: -1,
                  employee: member?.podManager,
                  role: 'Pod Manager',
                })
              : undefined,
            member?.npOwner
              ? new PodConfigEmployeeSlot({
                  id: -2,
                  employee: member?.npOwner,
                  role: EmployeeRole.NP.value,
                })
              : undefined,
            member?.owner
              ? new PodConfigEmployeeSlot({
                  id: -3,
                  employee: member?.owner,
                  role: EmployeeRole.RN.value,
                })
              : undefined,
            ...(member?.careCoordinators?.map(
              (e) =>
                new PodConfigEmployeeSlot({
                  id: -e.id,
                  employee: e,
                  role: EmployeeRole.MA.value,
                }),
            ) || []),
            ...(member?.engagementOwners?.map(
              (e) =>
                new PodConfigEmployeeSlot({
                  id: -e.id,
                  employee: e,
                  role: EmployeeRole.ENGAGEMENT_COORDINATOR.value,
                }),
            ) || []),
            ...(member?.healthCoaches?.map(
              (e) =>
                new PodConfigEmployeeSlot({
                  id: -e.id,
                  employee: e,
                  role: EmployeeRole.HEALTH_COACH.value,
                }),
            ) || []),
          ].filter(
            (e?: PodConfigEmployeeSlot) => e?.employee?.id,
          ) as PodConfigEmployeeSlot[],
        ),
        ...data.employees,
      ]}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          assigneeId: getFilterItem(
            translate('memberCCMTasks.filter.assignee'),
            values,
          ),
        })
      }
      placeholder={translate('memberCCMTasks.filter.assignee')}
      placeholderV2
      size="xs"
      value={filters?.assigneeId?.value || []}
    />
  </>
);
