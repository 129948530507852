// @ts-ignore
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import {
  EncounterChannel,
  EncounterDirection,
  EncounterMethod,
  EncounterType,
  Language,
} from '@vestahealthcare/common/enums';
import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CCMMemberDetail,
  CCMTask,
  ClinicalMember,
  Employee,
  MemberDashboardEvent,
  MemberDashboardTask,
  PaginationType,
  PodConfig,
  PodRule,
  ProgramTaskBase,
} from '@vestahealthcare/common/models';
import ProgramTaskMember from '@vestahealthcare/common/models/ProgramTaskMember';
import { LS_DASHBOARD_CCM_QUEUE_V2 } from '@vestahealthcare/common/utils/constants';

import { FilterItem, LastUpdated, Panel, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { PodTaskOverrideModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskOverrideModal';
import { PodTaskReassignModal } from 'dash/src/pages/Admin/PODs/Metrics/ForecastDetail/PodTaskReassignModal';
import { InfoCCMModal } from 'dash/src/pages/Clinical/CCM/modals/InfoModal';
import { MemberCCMTaskAction } from 'dash/src/pages/MemberProfile/CCMTasks/types/MemberCCMTaskAction';
import { MAX_DASHBOARD_TABLE_ITEMS } from 'dash/src/pages/MemberProfile/Dashboard/contansts';
import { AddEncounterModal } from 'dash/src/pages/MemberProfile/Encounters/AddEncounterModal';
import { CacheServices, PaginatedResponse } from 'dash/src/services';
import { getMemberInfoData } from 'dash/src/services/CCMServices';
import {
  fecthMemberDashboardEvents,
  fecthMemberDashboardTasks,
} from 'dash/src/services/MemberDashboardServices';
import {
  GetCCMTaskParams,
  attemptCCMTask,
  fetchProgramTasksQueue,
  forceOverrideProgramTask,
  overrideProgramTask,
  updateCCMTaskAssignee,
} from 'dash/src/services/PodServices';
import Session from 'dash/src/services/SessionServices';
import {
  LoadFilterOption,
  getFilterItem,
  getServerFilters,
  loadNewFiltersFromStorage,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';

import { CCMTasksTable, TaskTableModel } from './CCMTaskTable';

type KeyCCMTaskParams = keyof GetCCMTaskParams;

export const CCMTaskDashboard = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [filters, setFilters] = useState<
    Partial<{ [x in KeyCCMTaskParams]: FilterItem }>
  >();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Moment>();
  const [tasks, setTasks] = useState<CCMTask[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [types, setTypes] = useState<SelectableInfo<PodRule>[]>([]);
  const [pods, setPodConfigs] = useState<SelectableInfo<PodConfig>[]>([]);

  const [openLogCallModal, setOpenLogCallModal] = useState<boolean>();
  const [modalData, setModalData] = useState<number>();
  const [modalDataTask, setModalDataTask] = useState<ProgramTaskBase>();

  const [infoMember, setInfoMember] = useState<ClinicalMember>();
  const [infoDetail, setInfoDetail] = useState<CCMMemberDetail>();
  const [infoDetailEvents, setInfoDetailEvents] = useState<
    PaginatedResponse<MemberDashboardEvent>
  >();
  const [infoDetailTasks, setInfoDetailTasks] = useState<
    PaginatedResponse<MemberDashboardTask>
  >();

  const [actionTaskIdx, setActionTaskIdx] = useState<number>();
  const [actionTaskDefinition, setActionTaskDefinition] = useState<PodRule>();

  const [openReassignModal, setOpenReassignModal] = useState<boolean>();
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>();

  const showEmployeesFilter = Session.actingUser.isDialer;

  const getInitialData = async () => {
    setLoadingData(true);
    const [e, l, c, t] = await Promise.all([
      CacheServices.getEmployees(),
      CacheServices.getLanguages(),
      CacheServices.getPodConfigs(),
      CacheServices.getPodRules(),
    ]);
    setEmployees(Employee.toSelectable(e));
    setLanguages(Language.toSelectable(l).filter(({ disabled }) => !disabled));
    setPodConfigs(PodConfig.toSelectable(c) || []);
    setTypes(PodRule.toSelectable(t) || []);
    setLoadingData(false);
  };

  const getTasks = async () => {
    if (!filters) return;

    setLoading(true);
    const { items, pagination, lastUpdatedAt } = await fetchProgramTasksQueue({
      ...getServerFilters(filters),
      offset: page * pageSize,
      limit: pageSize,
      sort: Session.actingUser.isDialer
        ? 'transferred asc, callBackDatetime asc, taskDate asc, contactPreferences asc, attempts asc, id asc'
        : 'transferred desc, callBackDatetime asc, taskDate asc, contactPreferences asc, attempts asc, id asc',
    });
    setLastUpdatedAt(lastUpdatedAt);
    setPagination(pagination);
    if (page !== 0) {
      setTasks([...tasks, ...items]);
    } else {
      setTasks(items);
    }
    setLoading(false);
  };

  const openCCMDetailModal = async (member: ProgramTaskMember) => {
    setLoading(true);

    const detail = await getMemberInfoData(member.id);
    /** @ts-ignore */
    detail.id = member.id;
    /** @ts-ignore */
    detail.fullName = member.fullName;
    /** @ts-ignore */
    detail.language = member.language;
    setInfoMember((detail as unknown) as ClinicalMember);
    setInfoDetail(detail);
    setInfoDetailEvents(detail.recentEvents);
    setInfoDetailTasks(detail.recentTasks);
    setLoading(false);
  };

  const getMoreEvents = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardEvents(
          infoMember.memberId,
          {
            offset: MAX_DASHBOARD_TABLE_ITEMS,
            limit: infoDetailEvents?.pagination?.total || 100,
          },
        );
        setInfoDetailEvents({
          items: [...(infoDetailEvents?.items || []), ...items],
          pagination: infoDetailEvents?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  const getMoreTasks = async () => {
    if (infoMember) {
      try {
        const { items } = await fecthMemberDashboardTasks(infoMember.memberId, {
          offset: MAX_DASHBOARD_TABLE_ITEMS,
          limit: infoDetailTasks?.pagination?.total || 100,
        });
        setInfoDetailTasks({
          items: [...(infoDetailTasks?.items || []), ...items],
          pagination: infoDetailTasks?.pagination || ({} as PaginationType),
        });
      } catch (e) {
        showGlobalError(e as string);
      }
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getTasks();
  }, [filters, page, pageSize]);

  useEffect(() => {
    if (!employees?.length || !languages?.length) return;

    const storedFiltersConfiguration: {
      [x in KeyCCMTaskParams]?: LoadFilterOption;
    } = {
      language: {
        data: languages,
        label: translate('toc.common.handoffDisposition'),
        multiple: true,
      },
    };

    if (showEmployeesFilter) {
      storedFiltersConfiguration.assigneeId = {
        data: employees,
        label: translate('toc.common.assignee'),
        multiple: true,
      };
    }

    const storedFilters = loadNewFiltersFromStorage(
      LS_DASHBOARD_CCM_QUEUE_V2,
      storedFiltersConfiguration,
    );
    setFilters(storedFilters);
  }, [employees, languages]);

  useEffect(() => {
    if (!filters) return;
    saveNewFiltersToStorage(LS_DASHBOARD_CCM_QUEUE_V2, filters);
  }, [filters]);

  /** ACTIONS */

  const setActionLoading = (index: number, value: boolean) => {
    if (tasks[index]) {
      (tasks[index] as TaskTableModel).actionLoading = value;
      setTasks([...tasks]);
    }
  };

  const updateTaskStatusAttempted = async (task: CCMTask, index: number) => {
    try {
      setActionLoading(index, true);
      await attemptCCMTask(task.id);
      getTasks();
    } catch (e) {
      showGlobalError(e as string);
    } finally {
      setActionLoading(index, false);
    }
  };

  const updateTaskAssignee = async (employee: Employee) => {
    if (actionTaskIdx !== undefined) {
      const task = tasks[actionTaskIdx];
      if (task) {
        try {
          await updateCCMTaskAssignee(task.id, employee.id);
          getTasks();
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
          setOpenReassignModal(false);
        } catch (e) {
          showGlobalError(e as string);
        }
      }
    }
  };

  const forceOverrideTask = async (index: number) => {
    if (index !== undefined) {
      const task = tasks[index];
      if (task) {
        try {
          setActionLoading(index, true);
          await forceOverrideProgramTask(task.id);
          getTasks();
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
        } catch (e) {
          showGlobalError(e as string);
        } finally {
          setActionLoading(index, true);
        }
      }
    }
  };

  const overrideTask = async (taskType: PodRule, employee: Employee) => {
    if (actionTaskIdx !== undefined) {
      const task = tasks[actionTaskIdx];
      if (task) {
        try {
          await overrideProgramTask(task.id, taskType.id, employee.id);
          getTasks();
          setActionTaskDefinition(undefined);
          setActionTaskIdx(undefined);
          setOpenOverrideModal(false);
        } catch (e) {
          showGlobalError(e as string);
        }
      }
    }
  };

  const onTaskAction = async (
    { value }: Selectable,
    task: CCMTask,
    index: number,
  ) => {
    if (value === MemberCCMTaskAction.CHANGE_ASSIGNEE.value) {
      setActionTaskIdx(index);
      setActionTaskDefinition(
        types?.find(({ info }) => info?.name === task.name)?.info,
      );
      setOpenReassignModal(true);
    } else if (value === MemberCCMTaskAction.FORCE_OVERRIDE.value) {
      forceOverrideTask(index);
    } else if (value === MemberCCMTaskAction.OVERRIDE_TASK_TYPE.value) {
      setActionTaskIdx(index);
      setActionTaskDefinition(
        types?.find(({ info }) => info?.name === task.name)?.info,
      );
      setOpenOverrideModal(true);
    } else if (value === MemberCCMTaskAction.UPDATE_TASK_STATUS_ATTEMPTED.value)
      updateTaskStatusAttempted(task, index);
  };

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('ccmTasks.title')} filtersV2>
        <Panel.Actions>
          {lastUpdatedAt && <LastUpdated lastUpdatedAt={lastUpdatedAt} />}
        </Panel.Actions>
        <Panel.FilterBar
          inputs={
            <>
              {showEmployeesFilter && (
                <Select
                  data-cy="ccm-task-filter-bar-assignee"
                  items={employees}
                  loading={loadingData}
                  filterOptions={FilterEmployeeOptions}
                  multiple
                  onChange={(assignees: SelectableInfo<Employee>[]) => {
                    setPage(0);
                    setFilters({
                      ...filters,
                      assigneeId: getFilterItem(
                        translate('ccmTasks.filters.assignee'),
                        assignees,
                      ),
                    });
                  }}
                  placeholder={translate('ccmTasks.filters.assignee')}
                  placeholderV2
                  value={filters?.assigneeId?.value}
                  size="xs"
                />
              )}
              <Select
                data-cy="ccm-task-filter-bar-language"
                grouped={({ preferred }) =>
                  preferred
                    ? translate('global.preferred')
                    : translate('global.other')
                }
                items={languages}
                loading={loadingData}
                multiple
                onChange={(languages: Selectable[]) => {
                  setPage(0);
                  setFilters({
                    ...filters,
                    language: getFilterItem(
                      translate('ccmTasks.filters.language'),
                      languages,
                    ),
                  });
                }}
                placeholder={translate('ccmTasks.filters.language')}
                placeholderV2
                value={filters?.language?.value}
                size="xs"
              />
            </>
          }
        />
      </Panel.Heading>
      <Panel.Body loading={loading} fixedTableHeader>
        <CCMTasksTable
          data={{
            pods: pods?.map(({ info }) => info) as PodConfig[],
            taskDefinitions: types?.map(({ info }) => info) as PodRule[],
          }}
          tasks={tasks}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          onClickRow={async (task: CCMTask) => {
            setLoading(true);
            await openCCMDetailModal(task.member);
            setLoading(false);
          }}
          onLogCall={(task) => {
            setModalDataTask(task);
            setModalData(task.member.id);
            setOpenLogCallModal(true);
          }}
          onTaskAction={onTaskAction}
        />
        <AddEncounterModal
          autofillTime
          avoidRequiredMinutes
          channel={EncounterChannel.CCM_VISIT}
          direction={EncounterDirection.OUTBOUND}
          method={EncounterMethod.CALL}
          type={EncounterType.CCM}
          onClose={() => setOpenLogCallModal(false)}
          onSubmit={async () => {
            await getTasks();
            setOpenLogCallModal(false);
          }}
          open={!!openLogCallModal}
          patient={modalData}
          programTask={modalDataTask}
        />
        <InfoCCMModal
          detail={infoDetail}
          events={infoDetailEvents}
          getMoreEvents={getMoreEvents}
          getMoreTasks={getMoreTasks}
          member={infoMember}
          open={!!infoMember}
          onClose={() => setInfoMember(undefined)}
          onLogCall={(member, encounterType) => {
            setModalData(member.id);
            setOpenLogCallModal(!!encounterType);
          }}
          tasks={infoDetailTasks}
        />
        {actionTaskIdx !== undefined && actionTaskDefinition && (
          <PodTaskReassignModal
            employees={employees?.map(({ info }) => info) as Employee[]}
            open={openReassignModal}
            podConfig={
              pods?.find(
                ({ info }) =>
                  info?.id === tasks[actionTaskIdx]?.member?.pod?.id,
              )?.info
            }
            taskType={actionTaskDefinition}
            onSubmit={updateTaskAssignee}
            onClose={() => setOpenReassignModal(false)}
          />
        )}
        {actionTaskIdx !== undefined && actionTaskDefinition && (
          <PodTaskOverrideModal
            employees={employees?.map(({ info }) => info) as Employee[]}
            open={openOverrideModal}
            podConfig={
              pods?.find(
                ({ info }) =>
                  info?.id === tasks[actionTaskIdx]?.member?.pod?.id,
              )?.info
            }
            taskTypes={
              types
                ?.filter(
                  ({ info }) =>
                    info?.id !== actionTaskDefinition?.id && info?.flexible,
                )
                ?.map(({ info }) => info) as PodRule[]
            }
            onSubmit={overrideTask}
            onClose={() => setOpenOverrideModal(false)}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default CCMTaskDashboard;
