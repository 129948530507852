import React from 'react';

import { MemberStatus, States } from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee, Organization } from '@vestahealthcare/common/models';

import { FilterItem, FilterSelectable, Select } from 'styleguide-v2';
import { FilterEmployeeOptions } from 'styleguide-v2/src/components/SelectAssignee';

import { MemberViewParams } from 'dash/src/services/PatientServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

import { ReferralDropdown } from '../ReferralDropdown';

type KeyGetMemberParams = keyof MemberViewParams;

type Props = {
  data: {
    employees: SelectableInfo<Employee>[];
    languages: Selectable[];
    programExtensions: Selectable[];
    programExtensionStatuses: Selectable[];
    referralSources: SelectableInfo<Organization>[];
  };
  filters?: {
    [x in KeyGetMemberParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetMemberParams]?: FilterItem;
    },
  ) => void;
};

export const MemberViewFiltersBar = ({
  data,
  filters,
  loading,
  onChange,
}: Props) => (
  <>
    <Select
      data-cy="member-filter-bar-status"
      items={MemberStatus.toFilterSelectable()}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          status: getFilterItem(translate('members.status'), values),
        })
      }
      placeholder={translate('members.status')}
      placeholderV2
      size="xs"
      value={filters?.status?.value || []}
    />
    <Select
      data-cy="member-filter-bar-pe"
      items={data.programExtensions}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          programExtensionId: getFilterItem(
            translate('members.programExtensions'),
            values,
          ),
        })
      }
      placeholder={translate('members.programExtensions')}
      placeholderV2
      size="xs"
      value={filters?.programExtensionId?.value || []}
    />
    <Select
      data-cy="member-filter-bar-pe-status"
      items={data.programExtensionStatuses}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          programExtensionStatus: getFilterItem(
            translate('members.programExtensionStatus'),
            values,
          ),
        })
      }
      placeholder={translate('members.programExtensionStatus')}
      placeholderV2
      size="xs"
      value={filters?.programExtensionStatus?.value || []}
    />
    <ReferralDropdown
      data-cy="member-filter-modal-referral"
      getItemDisabled={(referral: Selectable) =>
        !!(filters?.excludedReferralSourceId
          ?.value as FilterSelectable[])?.find(
          ({ value }) => value === referral.value,
        ) ||
        (filters?.excludedReferralSourceId
          ?.value as FilterSelectable[])?.reduce(
          (acc, item) =>
            acc || item.info.hasDescendant(referral.value as number),
          false,
        )
      }
      items={data.referralSources}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) => {
        onChange({
          ...filters,
          referralSourceId: getFilterItem(
            translate('members.referralSources'),
            values,
          ),
        });
      }}
      placeholder={translate('members.referralSources')}
      placeholderV2
      size="xs"
      value={filters?.referralSourceId?.value || []}
    />
    <Select
      data-cy="member-filter-bar-state"
      items={Enum.toSelectable(States.asArray)}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          state: getFilterItem(translate('members.state'), values),
        })
      }
      placeholder={translate('members.state')}
      placeholderV2
      size="xs"
      value={filters?.state?.value || []}
    />
    <Select
      data-cy="member-filter-bar-language"
      items={data.languages.filter(({ disabled }) => !disabled)}
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(translate('members.language'), values),
        })
      }
      placeholder={translate('members.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <Select
      data-cy="member-filter-bar-np-owner"
      filterOptions={FilterEmployeeOptions}
      items={data.employees.filter(({ info }) => info?.isNP || !info?.id)}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          npOwnerId: getFilterItem(translate('members.npOwner'), values),
        })
      }
      placeholder={translate('members.npOwner')}
      placeholderV2
      size="xs"
      value={filters?.npOwnerId?.value || []}
    />
  </>
);
