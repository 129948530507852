import classNames from 'classnames';
import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import { Chip, Collapse, CollapseIcon, IconButton } from 'styleguide-v2';

import {
  RecursiveTable,
  RecursiveTableData,
} from 'dash/src/components/RecursiveTable';

import { getTaskColor } from '../utils';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;

  getTaskRowOnClick?: (
    item: RecursiveTableData,
  ) => ((item: RecursiveTableData) => void) | undefined;
};

export const PodSummaryCardTask = ({
  className,
  pod,
  getTaskRowOnClick,
}: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.taskSummary')}
        </span>
        <div className={styles.titleInfo}>
          <Chip
            color={getTaskColor(pod.taskCompletedPercetage)}
            type="contained-2"
          >
            <span className="bold">{pod.taskCompletedPercetage}%</span>
          </Chip>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <RecursiveTable
            data={pod.taskStatusCounts.map((task) => ({
              name: task.name,
              column1: task.total,
              column2: task.completed,
              column3: task.attempted,
              column4: task.notStarted,
            }))}
            header="Task"
            header2="Completed"
            header3="Attempted"
            header4="Not Started"
            getRowOnClick={getTaskRowOnClick}
          />
        </div>
      </Collapse>
    </div>
  );
};
