import classNames from 'classnames';
import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryCommon } from '@vestahealthcare/common/models';

import {
  Chip,
  Collapse,
  CollapseIcon,
  IconButton,
  PieChartMultiLevel,
} from 'styleguide-v2';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryCommon;
};

export const PodSummaryCardBiannual = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.biannualSummary')}
        </span>
        <div className={styles.titleInfo}>
          <div className={styles.chipsContainer}>
            {pod.npBiannualOverdueCount !== undefined && (
              <Chip color="error" type="contained-2">
                <span className="bold">
                  Overdue: {pod.npBiannualOverdueCount}
                </span>
              </Chip>
            )}
            {pod.npBiannualDueCount !== undefined && (
              <Chip color="warning" type="contained-2">
                <span className="bold">Due: {pod.npBiannualDueCount}</span>
              </Chip>
            )}
          </div>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex center')}>
          <PieChartMultiLevel
            data={[
              {
                id: `pod-${pod.id}-biannual-status-completed`,
                label: translate('common.status.completed'),
                value: pod.npBiannualCompletedCount,
                subdata: [],
              },
              {
                id: `pod-${pod.id}-biannual-status-pending`,
                label: translate('common.status.pending'),
                value: pod.npBiannualPendingCount,
                subdata: [],
              },
              {
                id: 'status-scheduled',
                label: 'Reschedule Needed',
                value:
                  pod.npBiannualRescheduleDueCount +
                  pod.npBiannualRescheduleOverdueCount,
                subdata: [
                  {
                    id: 'status-scheduled-1',
                    label: 'Due this month',
                    value: pod.npBiannualRescheduleDueCount,
                  },
                  {
                    id: 'status-scheduled-2',
                    label: 'Overdue',
                    value: pod.npBiannualRescheduleOverdueCount,
                  },
                ],
              },
              {
                id: 'status-not',
                label: 'Not yet scheduled',
                value:
                  pod.npBiannualNotYetDueUnreachableCount +
                  pod.npBiannualNotYetDueReachableCount +
                  pod.npBiannualNotYetOverdueUnreachableCount +
                  pod.npBiannualNotYetOverdueReachableCount,
                subdata: [
                  {
                    id: 'status-scheduled-1',
                    label: 'Not yet scheduled this month',
                    value:
                      pod.npBiannualNotYetDueUnreachableCount +
                      pod.npBiannualNotYetDueReachableCount,
                  },
                  {
                    id: 'status-scheduled-2',
                    label: 'Not yet scheduled overdue',
                    value:
                      pod.npBiannualNotYetOverdueUnreachableCount +
                      pod.npBiannualNotYetOverdueReachableCount,
                  },
                ],
              },
            ]}
            getTooltipValue={(value, _, percentage) =>
              `${value} (${percentage}%)`
            }
            showLegend
            size="s"
          />
        </div>
      </Collapse>
    </div>
  );
};
