import React, { ReactNode, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';

import { Button } from 'styleguide-v2/src/components/Button';
import { Tabs } from 'styleguide-v2/src/components/Tabs';
import { BackgroundColors, Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

export interface DialogTitleProps {
  color?: 'default' | 'warning' | 'error';
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const dialogStyles = {
  '& .MuiDialog-container > [role="dialog"]': {
    alignSelf: 'baseline',
  },
};

const titleStyles = {
  backgroundColor: Colors.lightMint,
  fontSize: `calc(${Fonts.fontSize} * 1.125)`,
  fontWeight: 500,
  padding: '1.5rem 2rem ',
};

const titleWarningStyles = {
  backgroundColor: BackgroundColors.lightYellow,
};

const titleErrorStyles = {
  backgroundColor: Colors.textRed,
  color: Colors.white,
};

const closeStyles = {
  '&&': {
    position: 'absolute',
    right: '2rem',
    top: '1.75rem',
  },
};

const bodyStyles = {
  '&&': {
    padding: '2rem 2rem',
    marginBottom: '2rem',
  },
};

const buttonStyles = {
  '&': {
    padding: '0 2rem 2rem 2rem',
  },
};

const BootstrapDialogTitle = ({
  color,
  children,
  onClose,
  ...other
}: DialogTitleProps) => (
  <DialogTitle
    sx={{
      ...titleStyles,
      ...(color === 'error' ? titleErrorStyles : {}),
      ...(color === 'warning' ? titleWarningStyles : {}),
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
  >
    {children}
    {onClose && (
      <IconButton onClick={onClose} sx={closeStyles}>
        <CloseIcon
          fontSize="large"
          htmlColor={color === 'error' ? Colors.white : Colors.iconGray}
        />
      </IconButton>
    )}
  </DialogTitle>
);

const useStyles = makeStyles({
  loadingButton: {
    minWidth: '7.5rem',
  },
  stepIcon: {
    background: Colors.iconGray,
    borderRadius: '0.5rem',
    color: Colors.white,
    height: '2rem',
    marginRight: '0.5rem',
    width: '2rem',

    '&[data-selected="true"]': {
      background: Colors.iconGreen,
    },

    '&[data-disabled="true"]': {
      opacity: 0.5,
    },

    '&& span': {
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      position: 'relative',
      top: '0.125rem',
    },
  },
  steps: {
    borderBottom: `1px solid ${Colors.lighterGray}`,
    padding: '0 2rem',
    width: '100%',

    '&& .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root': {
      paddingLeft: '1rem',
      paddingRigth: '1rem',
    },
  },
});

export type ModalStep = {
  detail: ReactNode;
  disabled?: boolean;
  icon?: boolean | ReactNode;
  label: string;
};

type Props = {
  body?: string;
  cancelText?: string;
  children?: ReactNode;
  className?: string;
  color?: 'default' | 'warning' | 'error';
  maxWidth?: Breakpoint;
  open: boolean;
  onSubmit?: (() => Promise<any>) | ((step?: number) => Promise<boolean>);
  onSubmitSecondary?: () => Promise<any>;
  onCancel?: () => void;
  onClose?: (reason?: string) => void;
  preventCloseOnBackdropClick?: boolean;
  preventCloseOnSubmit?: boolean;
  stepSelected?: number;
  steps?: ModalStep[];
  submitDisabled?: boolean;
  submitSecondaryDisabled?: boolean;
  submitSecondaryText?: string;
  submitText?: string;
  title: string | ReactNode;
};

export const Modal = ({
  body,
  cancelText,
  children,
  className,
  color = 'default',
  maxWidth,
  open,
  onSubmit,
  onSubmitSecondary,
  onCancel,
  onClose,
  preventCloseOnBackdropClick,
  preventCloseOnSubmit,
  steps,
  submitDisabled,
  submitSecondaryDisabled,
  submitText,
  submitSecondaryText,
  title,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    setSelectedTab(0);
    setLoading(false);
    setLoadingSecondary(false);
  }, [open]);

  const handleSubmit = async () => {
    if (onSubmit) {
      setLoading(true);
      const result = await onSubmit(selectedTab);
      if (result !== false) {
        if (
          !preventCloseOnSubmit &&
          onClose &&
          (!steps || selectedTab + 1 === steps.length)
        ) {
          onClose();
        } else if (steps && selectedTab + 1 !== steps.length) {
          setSelectedTab(selectedTab + 1);
        }
      }
      setLoading(false);
    }
  };

  const handleSubmitSecondary = async () => {
    if (onSubmitSecondary) {
      setLoadingSecondary(true);
      await onSubmitSecondary();
      setLoadingSecondary(false);
    }
  };

  const getIconNumber = (
    index: number,
    selected: boolean,
    disabled: boolean,
  ) => (
    <div
      className={styles.stepIcon}
      data-selected={selected}
      data-disabled={disabled}
    >
      <span>{index + 1}</span>
    </div>
  );

  return (
    <Dialog
      className={className}
      fullWidth
      onClose={(_, reason) =>
        onClose &&
        (!preventCloseOnBackdropClick || reason !== 'backdropClick') &&
        onClose(reason)
      }
      open={open}
      sx={dialogStyles}
      maxWidth={maxWidth}
    >
      <BootstrapDialogTitle id="vesta-modal" onClose={onClose} color={color}>
        {title}
      </BootstrapDialogTitle>
      {steps && (
        <Tabs
          className={styles.steps}
          items={steps.map(({ disabled, icon, label }, index) => ({
            disabled: !!disabled,
            value: index + 1,
            label: icon ? (
              <div key={`modal-step-${index}`} className="flex">
                {typeof icon === 'boolean'
                  ? getIconNumber(index, selectedTab === index, !!disabled)
                  : icon}
                {label}
              </div>
            ) : (
              label
            ),
          }))}
          onChange={(value: number) => setSelectedTab(value - 1)}
          value={selectedTab + 1}
        />
      )}
      <DialogContent sx={bodyStyles}>
        {body || children}
        {steps && steps[selectedTab].detail}
      </DialogContent>
      {onSubmit && (
        <DialogActions sx={buttonStyles}>
          {(onCancel || onClose) && (
            <Button
              className={styles.loadingButton}
              color="tertiary"
              onClick={onCancel || (() => onClose && onClose())}
            >
              {cancelText || translate('components.common.cancel')}
            </Button>
          )}
          {onSubmitSecondary && submitSecondaryText && (
            <Button
              className={styles.loadingButton}
              color="secondary"
              disabled={
                submitSecondaryDisabled !== undefined
                  ? submitSecondaryDisabled
                  : submitDisabled
              }
              loading={loadingSecondary}
              type="outlined"
              onClick={handleSubmitSecondary}
            >
              {submitSecondaryText}
            </Button>
          )}
          <Button
            className={styles.loadingButton}
            color="secondary"
            disabled={submitDisabled}
            loading={loading}
            onClick={handleSubmit}
          >
            {submitText ||
              (steps && selectedTab + 1 !== steps.length
                ? translate('components.common.next')
                : translate('components.common.confirm'))}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
