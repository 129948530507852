import { Moment } from 'moment';

import { RosterFileHistorySource, RosterFileHistoryStatus } from '../enums';
import { SelectableInfo } from '../enums/Enum';
import { Employee } from './Employee';
import { UpdateInfo } from './Helpers/UpdateInfo';
import { make, makeUnix } from './Model';

export class RosterFileHistory implements UpdateInfo {
  id = 0;

  createdAt: Moment;

  createdBy: Employee;

  errorFileId = 0;

  errorMessage = '';

  name = '';

  numErrorRows = 0;

  numFormattedRows = 0;

  organizationId = 0;

  rawRosterFileId = 0;

  readyToUploadFileId = 0;

  rows = 0;

  source: RosterFileHistorySource;

  status: RosterFileHistoryStatus;

  updatedAt: Moment;

  updatedBy: Employee;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.createdAt = makeUnix(obj.createdAt) as Moment;
    this.createdBy = make(obj.createdBy, Employee);
    this.source = RosterFileHistorySource.byKey[obj.source];
    this.status = RosterFileHistoryStatus.byKey[obj.status];
    this.updatedAt = makeUnix(obj.updatedAt) as Moment;
    this.updatedBy = make(obj.updatedBy, Employee);
  }

  get canBeProcessed() {
    return (
      !!this.readyToUploadFileId &&
      this.status === RosterFileHistoryStatus.COMPLETED
    );
  }

  get numErrorRowsPerc() {
    return Math.round((10000 * this.numErrorRows) / (this.rows || 1)) / 100;
  }

  get numFormattedRowsPerc() {
    return Math.round((10000 * this.numFormattedRows) / (this.rows || 1)) / 100;
  }

  static toSelectable(items: RosterFileHistory[]) {
    return items?.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          info: item,
        } as SelectableInfo<RosterFileHistory>),
    );
  }
}
