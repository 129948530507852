import { Enum } from './Enum';

export class RosterFileHistoryStatus extends Enum {
  translationKey = 'rosterFileHistoryStatus';

  static asArray: RosterFileHistoryStatus[] = [];

  static byKey: { [name: string]: RosterFileHistoryStatus } = {};

  static readonly NEW = new RosterFileHistoryStatus('NEW');

  static readonly IN_PROGRESS = new RosterFileHistoryStatus('IN_PROGRESS');

  static readonly ERROR = new RosterFileHistoryStatus('ERROR');

  static readonly COMPLETED = new RosterFileHistoryStatus('COMPLETED');

  constructor(public readonly value: string, public readonly filter?: boolean) {
    super();
    RosterFileHistoryStatus.asArray.push(this);
    RosterFileHistoryStatus.byKey[value] = this;
  }

  static getColor(status: RosterFileHistoryStatus | string) {
    const value = typeof status === 'string' ? status : status?.value;
    switch (value) {
      case RosterFileHistoryStatus.NEW.value:
        return 'info';
      case RosterFileHistoryStatus.ERROR.value:
        return 'error';
      case RosterFileHistoryStatus.COMPLETED.value:
        return 'success';
      case RosterFileHistoryStatus.IN_PROGRESS.value:
        return 'loading';
      default:
        return 'warning';
    }
  }
}

export default RosterFileHistoryStatus;
