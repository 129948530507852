import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  EmployeeRole,
  EncounterChannel,
  EncounterDirection,
  EncounterEscalatedReason,
  EncounterMethod,
  EncounterMethodOutcome,
  EncounterType,
  EventReporterType,
} from '@vestahealthcare/common/enums';
import Enum, { Selectable } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  CareTeamMember,
  Employee,
  EmployeeGroup,
  Encounter,
  MemberCommunityResource,
  Patient,
  PodRule,
  ProgramTaskBase,
  Task,
} from '@vestahealthcare/common/models';
import EmployeePermissions from '@vestahealthcare/common/models/EmployeePermissions';
import PodConfigEmployeeSlot from '@vestahealthcare/common/models/PodConfigEmployeeSlot';
import moment, { Moment } from '@vestahealthcare/common/moment';

import {
  Button,
  CollapsableSidebar,
  DateTimePicker,
  Select,
  TextArea,
  TextInput,
} from 'styleguide-v2';
import {
  FilterEmployeeOption,
  FilterEmployeeOptions,
} from 'styleguide-v2/src/components/SelectAssignee';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { CareTeamQuickAddModal } from 'dash/src/pages/CareTeam/modals/CareTeamQuickAdd';
import {
  EventReporter,
  ReporterHandle,
} from 'dash/src/pages/MemberProfile/Events/components/EventReporter';
import { onCareTeamMembersUpdated } from 'dash/src/redux/slices/MemberInfoSlice';
import { CacheServices, fetchPatient } from 'dash/src/services';
import {
  fetchMemberCareTeamMembers,
  quickAddCareTeam,
} from 'dash/src/services/CareTeamServices';
import {
  EncounterParams,
  addEncounter,
  editEncounter,
} from 'dash/src/services/EncounterServices';
import Session from 'dash/src/services/SessionServices';
import { isNumber } from 'lodash';

interface Props {
  autofillTime?: boolean;
  avoidRequiredMinutes?: boolean;
  channel?: EncounterChannel;
  className?: string;
  direction?: EncounterDirection;
  encounter?: Encounter;
  hideTypes?: EncounterType[];
  method?: EncounterMethod;
  onClose: () => void;
  onSubmit: (encounter?: Encounter) => void;
  open: boolean;
  patient?: Patient | number;
  programTask?: ProgramTaskBase;
  readonly?: boolean;
  task?: Task;
  type?: EncounterType;
}

const getReporterType = ({ encounter }: { encounter?: Encounter }) => {
  if (encounter?.careTeamMemberId) {
    return EventReporterType.CARE_TEAM;
  }
  if (encounter?.communityResourceId) {
    return EventReporterType.COMMUNITY_RESOURCE;
  }
  return EventReporterType.MEMBER;
};

export const AddEncounterModal = ({
  autofillTime,
  avoidRequiredMinutes,
  channel: channelProp,
  className,
  direction: directionProp,
  encounter,
  hideTypes,
  method: methodProp = EncounterMethod.CALL,
  onClose,
  onSubmit,
  open,
  patient,
  programTask,
  readonly,
  task,
  type: typeProp,
}: Props) => {
  const { showBhi } = useFlags();
  const dispatch = useDispatch();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [programTaskDefinitions, setProgramTaskDefinitions] = useState<
    PodRule[]
  >();

  const [method, setMethod] = useState<EncounterMethod | undefined>(methodProp);
  const [direction, setDirection] = useState<EncounterDirection | undefined>(
    directionProp,
  );
  const [methodOutcome, setMethodOutcome] = useState<EncounterMethodOutcome>();
  const [callbackDate, setCallbackDate] = useState<Date>();
  const [callbackTime, setCallbackTime] = useState<Date>();
  const [reporterType, setReporterType] = useState<EventReporterType>(
    getReporterType({ encounter }),
  );
  const [reporterCTM, setReporterCTM] = useState<CareTeamMember>();
  const [reporterCR, setReporterCR] = useState<MemberCommunityResource>();

  const [type, setType] = useState<EncounterType | undefined>(typeProp);
  const [encounterDate, setEncounterDate] = useState<Date | undefined>(
    moment()?.toDate(),
  );
  const [encounterTime, setEncounterTime] = useState<Date | undefined>(
    autofillTime ? new Date() : undefined,
  );
  const [channel, setChannel] = useState<EncounterChannel | undefined>(
    channelProp,
  );
  const [otherChannel, setOtherChannel] = useState<string>();
  const [duration, setDuration] = useState<number>();
  const [note, setNote] = useState<string>();

  const [escalatedTo, setEscalatedTo] = useState<
    Employee | PodConfigEmployeeSlot
  >();
  const [escalationReason, setescalationReason] = useState<
    EncounterEscalatedReason
  >();
  const [escalationReasonOther, setescalationReasonOther] = useState<string>();
  const [loadingEscalatedTo, setLoadingEscalatedTo] = useState<boolean>(false);

  const [transferredTo, setTransferredTo] = useState<
    Employee | PodConfigEmployeeSlot
  >();
  const [loadingTransferredTo, setLoadingTransferredTo] = useState<boolean>(
    false,
  );

  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddCTM, setOpenAddCTM] = useState<boolean>(false);
  const [patientModal, setPatientModal] = useState<Patient>();

  const reporterRef = useRef<ReporterHandle>(null);

  const programTaskDefinition = useMemo(
    () =>
      programTaskDefinitions?.find(({ name }) => name === programTask?.name),
    [programTask, programTaskDefinitions],
  );

  const allowedTransferredEmployees = useMemo(
    () =>
      EncounterMethodOutcome.TRANSFERRED === methodOutcome
        ? [
            ...[
              patientModal?.podManager
                ? new PodConfigEmployeeSlot({
                    id: -1,
                    employee: patientModal?.podManager,
                    role: 'Pod Manager',
                  })
                : undefined,
              patientModal?.npOwner
                ? new PodConfigEmployeeSlot({
                    id: -2,
                    employee: patientModal?.npOwner,
                    role: EmployeeRole.NP.value,
                  })
                : undefined,
              patientModal?.owner
                ? new PodConfigEmployeeSlot({
                    id: -3,
                    employee: patientModal?.owner,
                    role: EmployeeRole.RN.value,
                  })
                : undefined,
              ...(patientModal?.careCoordinators?.map(
                (e) =>
                  new PodConfigEmployeeSlot({
                    id: -e.id,
                    employee: e,
                    role: EmployeeRole.MA.value,
                  }),
              ) || []),
              ...(patientModal?.engagementOwners?.map(
                (e) =>
                  new PodConfigEmployeeSlot({
                    id: -e.id,
                    employee: e,
                    role: EmployeeRole.ENGAGEMENT_COORDINATOR.value,
                  }),
              ) || []),
              ...(patientModal?.healthCoaches?.map(
                (e) =>
                  new PodConfigEmployeeSlot({
                    id: -e.id,
                    employee: e,
                    role: EmployeeRole.HEALTH_COACH.value,
                  }),
              ) || []),
            ].filter((e?: PodConfigEmployeeSlot) => e?.employee?.id),
            ...employees,
          ].filter((item) => {
            if (!programTaskDefinition) return true;
            if (item instanceof Employee)
              return programTaskDefinition.roles.includes(item.role);
            if (item instanceof PodConfigEmployeeSlot)
              return programTaskDefinition.roles.includes(
                EmployeeRole.byKey[item.role || ''],
              );
            return false;
          })
        : [],
    [employees, methodOutcome, patientModal, programTaskDefinition],
  );

  const allowedEscalatedEmployees = useMemo(() => {
    if (EncounterMethodOutcome.ESCALATED !== methodOutcome || !escalationReason)
      return [];
    if (!programTask?.id) return employees;

    const isDeceasedOrInpatient = [
      EncounterEscalatedReason.DECEASED,
      EncounterEscalatedReason.HOSPITALIZATION_INPATIENT,
    ].includes(escalationReason);
    return employees.filter(
      ({
        isEligibleForEncounterEscalationCCM,
        isEligibleForEncounterEscalationDeceasedCCM,
      }) =>
        isDeceasedOrInpatient
          ? isEligibleForEncounterEscalationCCM ||
            isEligibleForEncounterEscalationDeceasedCCM
          : isEligibleForEncounterEscalationCCM,
    );
  }, [methodOutcome, escalationReason, employees, programTask]);

  useEffect(() => {
    setPatientModal(undefined);
    setChannel(encounter?.channel || channelProp);
    setDirection(encounter?.direction || directionProp);
    setDuration(encounter?.duration);
    setEncounterDate(moment(encounter?.encounterDate)?.toDate());
    const defaultTime = autofillTime
      ? moment()
          .minutes(Math.floor(moment().minutes() / 15) * 15)
          .toDate()
      : undefined;
    setEncounterTime(
      encounter?.encounterDateTime
        ? moment.unix(encounter?.encounterDateTime)?.toDate()
        : defaultTime,
    );
    setescalationReason(encounter?.escalationReason);
    setescalationReasonOther(encounter?.escalationReasonOther);
    setEscalatedTo(encounter?.escalatedTo);
    setMethod(encounter?.method || methodProp);
    setMethodOutcome(encounter?.methodOutcome);
    setCallbackDate(
      encounter?.callbackDateTime
        ? moment.unix(encounter?.callbackDateTime).toDate()
        : undefined,
    );
    setCallbackTime(
      encounter?.callbackDateTime
        ? moment.unix(encounter?.callbackDateTime).toDate()
        : undefined,
    );
    setNote(encounter?.note);
    setOtherChannel(encounter?.otherChannel);
    setTransferredTo(encounter?.transferredTo);
    setType(encounter?.type || typeProp);

    setReporterType(getReporterType({ encounter }));

    setShowError(false);
    setLoading(false);
    setLoadingEscalatedTo(false);
    setLoadingTransferredTo(false);
  }, [encounter, open]);

  useEffect(() => {
    if (
      !method ||
      !EncounterMethod.getEncounterOutcomes(method).find(
        (item) => item === methodOutcome,
      )
    ) {
      setMethodOutcome(undefined);
    }
  }, [method]);

  useEffect(() => {
    if (escalationReason === EncounterEscalatedReason.DECEASED) {
      setEscalatedTo(
        allowedEscalatedEmployees?.find(
          ({ id }) => id === EmployeePermissions.MC_GRAVIS,
        ),
      );
    } else if (
      escalationReason === EncounterEscalatedReason.HOSPITALIZATION_INPATIENT
    ) {
      setEscalatedTo(
        allowedEscalatedEmployees?.find(
          ({ id }) => id === EmployeePermissions.VALENTINA_DUQUE,
        ),
      );
    } else {
      setEscalatedTo(
        allowedEscalatedEmployees?.find(
          ({ id }) => id === patientModal?.podManager?.id,
        ),
      );
    }
  }, [escalationReason, allowedEscalatedEmployees?.length]);

  const getInitialData = async () => {
    const employees = await CacheServices.getEmployees();
    setEmployees(employees);
  };

  const getInitialProgramTaskData = async () => {
    if (programTask && !programTaskDefinitions) {
      const programTaskDefinitions = await CacheServices.getPodRules();
      setProgramTaskDefinitions(programTaskDefinitions);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    getInitialProgramTaskData();
  }, [programTask]);

  const isOutbound = () => {
    return direction === EncounterDirection.OUTBOUND;
  };

  const isCallback = () => {
    return methodOutcome === EncounterMethodOutcome.UNABLE_TO_LEAVE_MESSAGE;
  };

  const validate = () =>
    (channel || !hasChannels) &&
    (!channel?.isOther || otherChannel) &&
    encounterDate &&
    encounterTime &&
    direction &&
    (!isOutbound() || methodOutcome) &&
    (!isOutbound() ||
      EncounterMethodOutcome.ESCALATED !== methodOutcome ||
      (escalatedTo &&
        escalationReason &&
        (escalationReason !== EncounterEscalatedReason.OTHER ||
          escalationReasonOther))) &&
    (!isOutbound() ||
      EncounterMethodOutcome.TRANSFERRED !== methodOutcome ||
      transferredTo) &&
    (!isCallback() || (callbackDate && callbackTime)) &&
    (reporterType !== EventReporterType.CARE_TEAM || reporterCTM) &&
    (reporterType !== EventReporterType.COMMUNITY_RESOURCE || reporterCR) &&
    (avoidRequiredMinutes || isNumber(duration)) &&
    type;

  const submit = async () => {
    let error;
    if (!validate()) {
      setShowError(true);
      return;
    }

    const datetime = moment(encounterDate?.getTime());
    datetime
      .hours(encounterTime?.getHours() || 0)
      .minutes(encounterTime?.getMinutes() || 0)
      .seconds(0);

    let callbackDateTime: Moment | undefined;

    if (isCallback() && callbackDate && callbackTime) {
      callbackDateTime = moment(callbackDate.getTime());
      callbackDateTime
        .hours(callbackTime?.getHours() || 0)
        .minutes(callbackTime?.getMinutes() || 0)
        .seconds(0);
    }

    const params = {
      callbackDateTime,
      careTeamMemberId:
        reporterType === EventReporterType.CARE_TEAM
          ? reporterCTM?.id
          : undefined,
      communityResourceId:
        reporterType === EventReporterType.COMMUNITY_RESOURCE
          ? reporterCR?.id
          : undefined,
      direction,
      method,
      methodOutcome: isOutbound() ? methodOutcome : undefined,
      channel,
      duration,
      escalatedToId:
        escalatedTo instanceof Employee
          ? escalatedTo?.id
          : escalatedTo?.employee?.id,
      escalationReason,
      escalationReasonOther,
      encounterDate: datetime,
      note,
      otherChannel,
      programTaskId: programTask?.id || encounter?.programTaskId,
      transferredToId:
        transferredTo instanceof Employee
          ? transferredTo?.id
          : transferredTo?.employee?.id,
      type,
      task,
    } as EncounterParams;

    let newEncounter: Encounter | undefined;

    try {
      setLoading(true);
      const patientId = patient instanceof Patient ? patient.id : patient;
      if (patientId) {
        if (encounter) {
          newEncounter = await editEncounter(patientId, encounter.id, params);
        } else {
          newEncounter = await addEncounter(patientId, params);
        }
      }
    } catch (e) {
      error = e;
      showGlobalError(e as string);
    }

    if (error) {
      setLoading(false);
      return;
    }

    await onSubmit(newEncounter);
    setLoading(false);
  };

  const typeChannels = EncounterChannel.fromEncounterType(type);
  const hasChannels = typeChannels.length > 0;

  const getTitle = ({
    encounter,
    readonly,
  }: {
    encounter?: Encounter;
    readonly?: boolean;
  }) => {
    let tag = 'encounters.modal.';
    if (readonly) {
      tag += 'show';
    } else if (encounter) {
      tag += 'edit';
    } else {
      tag += 'add';
    }
    return translate(tag);
  };

  const checkPatientModel = async () => {
    if (!patient) return;
    if (patient instanceof Patient) {
      setPatientModal(patient);
      return;
    }
    const p = await fetchPatient(patient);
    setPatientModal(p);
  };

  useEffect(() => {
    if (open) {
      checkPatientModel();
    }
  }, [open]);

  const FilterOptionsEmployeeSlots = (
    data: (Employee | EmployeeGroup | Selectable)[],
    { inputValue }: { inputValue: string },
  ) =>
    data.filter((item) => {
      if (item instanceof Employee)
        return FilterEmployeeOption(inputValue)(item);
      if (item instanceof PodConfigEmployeeSlot && item.employee)
        return FilterEmployeeOption(inputValue)(item.employee);
      return false;
    });

  return (
    <>
      <CollapsableSidebar
        className={className}
        title={<h2>{getTitle({ readonly, encounter })}</h2>}
        open={open}
        onClose={onClose}
        size={550}
      >
        <CollapsableSidebar.Body>
          <div className="grid-wrapper fit">
            <DateTimePicker
              className="grid-span-6"
              disabled={readonly}
              error={showError && !encounterDate}
              label={translate('memberEvents.encounterDate')}
              maxDate={new Date()}
              onChange={setEncounterDate}
              required
              type="date"
              value={encounterDate}
            />
            <DateTimePicker
              className="grid-span-6"
              disabled={readonly}
              error={showError && !encounterTime}
              label={translate('memberEvents.encounterTime')}
              maxTime={
                encounterDate &&
                moment(encounterDate?.getTime()).isSame(moment(), 'day')
                  ? new Date()
                  : undefined
              }
              onChange={setEncounterTime}
              required
              type="time"
              value={encounterTime}
            />
            <Select
              className="grid-span-6"
              data-cy="encounter-method"
              disabled={readonly}
              error={showError && !method}
              getItemLabel={(item: EncounterMethod) => item.toString()}
              items={EncounterMethod.asArray}
              label={translate('encounters.method')}
              onChange={setMethod}
              required
              value={method}
            />
            <Select
              className="grid-span-6"
              data-cy="encounter-direction"
              disabled={readonly}
              error={showError && !direction}
              getItemLabel={(item: EncounterDirection) => item.toString()}
              items={EncounterDirection.asArray}
              label={translate('encounters.direction')}
              onChange={setDirection}
              required
              value={direction}
            />

            {method && isOutbound() && (
              <>
                <Select
                  className="grid-span-12"
                  data-cy="encounter-method-outcome"
                  disabled={readonly}
                  error={showError && !methodOutcome}
                  getItemLabel={(item: EncounterMethodOutcome) =>
                    item.toString()
                  }
                  items={EncounterMethod.getEncounterOutcomes(method)}
                  label={translate('encounters.methodOutcome')}
                  onChange={setMethodOutcome}
                  value={methodOutcome}
                  required
                />
                {EncounterMethodOutcome.ESCALATED === methodOutcome && (
                  <>
                    <Select
                      className={`grid-span-${
                        EncounterEscalatedReason.OTHER === escalationReason
                          ? 6
                          : 12
                      }`}
                      data-cy="encounter-method-escalationReason"
                      disabled={readonly}
                      error={showError && !escalationReason}
                      getItemLabel={(i: EncounterEscalatedReason) =>
                        i?.toString()
                      }
                      items={EncounterEscalatedReason.asArray}
                      label={translate('encounters.escalationReason')}
                      onChange={setescalationReason}
                      value={escalationReason}
                      required
                    />
                    {EncounterEscalatedReason.OTHER.value ===
                      escalationReason?.value && (
                      <TextInput
                        className="grid-span-6"
                        disabled={readonly}
                        error={showError && !escalationReasonOther}
                        label={translate('encounters.otherReason')}
                        onChange={setescalationReasonOther}
                        required
                        value={escalationReasonOther}
                      />
                    )}
                    <Select
                      className="grid-span-12"
                      data-cy="encounter-method-escalatedTo"
                      disabled={readonly || !escalationReason}
                      error={showError && !escalatedTo}
                      filterOptions={FilterEmployeeOptions}
                      getItemLabel={(item: Employee) => item?.fullName}
                      items={allowedEscalatedEmployees}
                      label={translate('encounters.escalatedTo')}
                      loading={loadingEscalatedTo}
                      onChange={setEscalatedTo}
                      value={escalatedTo}
                      required
                    />
                  </>
                )}
                {EncounterMethodOutcome.TRANSFERRED === methodOutcome && (
                  <>
                    <Select
                      className="grid-span-12"
                      data-cy="encounter-method-transferredTo"
                      disabled={readonly}
                      error={showError && !transferredTo}
                      getItemLabel={(item: Employee | PodConfigEmployeeSlot) =>
                        item instanceof Employee
                          ? item?.fullName
                          : `${item?.employee?.fullName} - ${
                              EmployeeRole.byKey[item.role || '']?.toString() ||
                              item.role
                            }`
                      }
                      filterOptions={FilterOptionsEmployeeSlots}
                      grouped={
                        allowedTransferredEmployees?.length &&
                        allowedTransferredEmployees[0] instanceof
                          PodConfigEmployeeSlot
                          ? (item: Employee | PodConfigEmployeeSlot) =>
                              item instanceof Employee ? 'Other' : 'POD'
                          : undefined
                      }
                      items={allowedTransferredEmployees}
                      label={translate('encounters.transferredTo')}
                      loading={loadingTransferredTo}
                      onChange={setTransferredTo}
                      value={transferredTo}
                      required
                    />
                  </>
                )}
              </>
            )}

            {methodOutcome && isCallback() && (
              <>
                <DateTimePicker
                  className="grid-span-6"
                  error={showError && !callbackDate}
                  label={translate('encounters.callbackDate')}
                  minDate={new Date()}
                  onChange={setCallbackDate}
                  required
                  type="date"
                  value={callbackDate}
                />
                <DateTimePicker
                  className="grid-span-6"
                  error={showError && !callbackTime}
                  label={translate('encounters.callbackTime')}
                  minTime={
                    callbackDate &&
                    moment(callbackDate.getTime()).isSame(moment(), 'day')
                      ? new Date()
                      : undefined
                  }
                  onChange={setCallbackTime}
                  required
                  type="time"
                  value={callbackTime}
                />
              </>
            )}

            <EventReporter
              className="grid-span-12"
              addCareTeamMember={!Session.actingUser.isExternal}
              defaultType={reporterType}
              defaultCareTeam={encounter?.careTeamMemberId}
              defaultCommunityResource={encounter?.communityResourceId}
              disabled={readonly}
              onAddCareTeamMember={() => setOpenAddCTM(true)}
              onChange={(type, reporter) => {
                setReporterType(type);
                if (type === EventReporterType.CARE_TEAM) {
                  setReporterCTM(reporter as CareTeamMember);
                  setReporterCR(undefined);
                } else if (type === EventReporterType.COMMUNITY_RESOURCE) {
                  setReporterCR(reporter as MemberCommunityResource);
                  setReporterCTM(undefined);
                } else {
                  setReporterCTM(undefined);
                  setReporterCR(undefined);
                }
              }}
              patient={patient}
              ref={reporterRef}
              showCommunityResources
              submitted={showError}
            />

            <Select
              data-cy="encounter-type"
              className="grid-span-12"
              disabled={readonly}
              error={showError && !type}
              getItemLabel={(item) => item.toString()}
              items={EncounterType.asArray.filter(
                (item) =>
                  (showBhi || item !== EncounterType.BHI) &&
                  (!hideTypes?.length || !hideTypes.includes(item)),
              )}
              label={translate('encounters.type')}
              onChange={(val: EncounterType) => {
                setType(val);
                setChannel(undefined);
              }}
              required
              value={type}
            />

            {type && hasChannels && (
              <Select
                className={`grid-span-${channel?.isOther ? 6 : 12}`}
                data-cy="encounter-channel"
                disabled={readonly || !type}
                error={showError && !channel}
                items={typeChannels}
                label={translate('encounters.reason')}
                onChange={(item: Selectable) =>
                  setChannel(
                    item ? EncounterChannel.byKey[item.value] : undefined,
                  )
                }
                required
                value={channel ? Enum.toSelectable([channel])[0] : undefined}
              />
            )}

            {type && channel?.isOther && (
              <TextInput
                className="grid-span-6"
                disabled={readonly}
                error={showError && !otherChannel}
                label={translate('encounters.otherReason')}
                onChange={setOtherChannel}
                required
                value={otherChannel}
              />
            )}

            <TextInput
              className="grid-span-12"
              disabled={readonly}
              error={
                showError &&
                !avoidRequiredMinutes &&
                !duration &&
                duration !== Number.NaN
              }
              label={translate('encounters.totalMinutes')}
              onChange={(val) => setDuration(Number(val))}
              required={!avoidRequiredMinutes}
              value={duration ? String(duration) : undefined}
            />

            <TextArea
              className="grid-span-12"
              disabled={readonly}
              label={translate('encounters.note')}
              onChange={setNote}
              rows={3}
              value={note}
            />
          </div>
        </CollapsableSidebar.Body>
        <CollapsableSidebar.Buttons>
          <Button color="tertiary" data-cy="edit-event-close" onClick={onClose}>
            {translate('global.cancel')}
          </Button>
          <Button
            color="secondary"
            data-cy="edit-event-note-submit"
            loading={loading}
            onClick={submit}
          >
            {translate('encounters.modal.save')}
          </Button>
        </CollapsableSidebar.Buttons>
      </CollapsableSidebar>
      <CareTeamQuickAddModal
        hideExtraButtons
        open={openAddCTM}
        onClose={() => setOpenAddCTM(false)}
        onSubmit={async (params) => {
          let ctm: CareTeamMember | undefined;
          const patientId = patient instanceof Patient ? patient.id : patient;
          try {
            if (patientId) {
              ctm = await quickAddCareTeam(params);
              const { items } = await fetchMemberCareTeamMembers(patientId);
              dispatch(onCareTeamMembersUpdated(items));
              setOpenAddCTM(false);
              reporterRef.current?.refresh();
            }
          } catch (e) {
            showGlobalError(e as string);
          }
          return ctm?.careTeamPerson || false;
        }}
        selectedMember={patient instanceof Patient ? patient : patientModal}
      />
    </>
  );
};

export default AddEncounterModal;
