import moment from 'moment';
import React, { useEffect, useRef } from 'react';

import {
  RosterFileHistorySource,
  RosterFileHistoryStatus,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Organization } from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import {
  FilterItem,
  FilterSelectable,
  Select,
  SelectHandle,
} from 'styleguide-v2';

import { ReferralDropdown } from 'dash/src/components/ReferralDropdown';
import { RosterFileHistoryParams } from 'dash/src/services/FileHistoryServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

export const UPLOAD_OPTIONS = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 90 days', value: 90 },
];

type KeyGetRosterParams = keyof RosterFileHistoryParams;

type Props = {
  data: {
    organizations: SelectableInfo<Organization>[];
  };
  filters?: {
    [x in KeyGetRosterParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetRosterParams]?: FilterItem;
    },
  ) => void;
};

export const RosterFileHistoryFilterBar = ({
  data,
  filters,
  loading,
  onChange,
}: Props) => {
  const uploadRef = useRef<SelectHandle>(null);
  useEffect(() => {
    if (!filters?.createdAtFrom) {
      uploadRef?.current?.clear();
    }
  }, [filters?.createdAtFrom]);
  return (
    <>
      <ReferralDropdown
        data-cy="member-filter-modal-referral"
        items={data.organizations}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) => {
          onChange({
            ...filters,
            organizationId: getFilterItem(
              translate('rosterFileHistory.referralSources'),
              values,
            ),
          });
        }}
        placeholder={translate('rosterFileHistory.referralSources')}
        placeholderV2
        size="xs"
        value={filters?.organizationId?.value || []}
      />
      <Select
        data-cy="member-filter-modal-referral-upload-date"
        getItemLabel={({ label }: Selectable) =>
          `${translate('rosterFileHistory.uploadDate')}: ${label}`
        }
        items={UPLOAD_OPTIONS}
        onChange={(value?: Selectable) =>
          onChange({
            ...filters,
            createdAtFrom: value
              ? {
                  label: translate('rosterFileHistory.uploadDate'),
                  value: {
                    label: value.label,
                    value: moment()
                      .subtract(value.value, 'days')
                      .format(DATE_FORMAT),
                  },
                }
              : undefined,
            createdAtTo: undefined,
          })
        }
        placeholder={translate('rosterFileHistory.uploadDate')}
        placeholderV2
        ref={uploadRef}
        renderOption={({ label }: Selectable) => label}
        size="xs"
        value={
          filters?.createdAtFrom
            ? UPLOAD_OPTIONS.find(
                ({ value }) =>
                  // eslint-disable-next-line eqeqeq
                  value ==
                  (filters?.createdAtFrom?.value as FilterSelectable)?.value,
              )
            : undefined
        }
      />
      <Select
        data-cy="roster-file-history-filter-bar-source"
        items={Enum.toSelectable(RosterFileHistorySource.asArray)}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            source: getFilterItem(
              translate('rosterFileHistory.source'),
              values,
            ),
          })
        }
        placeholder={translate('rosterFileHistory.source')}
        placeholderV2
        size="xs"
        value={filters?.source?.value || []}
      />
      <Select
        data-cy="roster-file-history-filter-bar-status"
        items={Enum.toSelectable(RosterFileHistoryStatus.asArray)}
        limitTags={1}
        loading={loading}
        multiple
        onChange={(values: Selectable[]) =>
          onChange({
            ...filters,
            status: getFilterItem(
              translate('rosterFileHistory.status'),
              values,
            ),
          })
        }
        placeholder={translate('rosterFileHistory.status')}
        placeholderV2
        size="xs"
        value={filters?.status?.value || []}
      />
    </>
  );
};
