import classNames from 'classnames';
import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryCommon } from '@vestahealthcare/common/models';

import { Chip, Collapse, CollapseIcon, IconButton } from 'styleguide-v2';

import { RecursiveTable } from 'dash/src/components/RecursiveTable';

import { getWarningColor } from '../utils';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryCommon;
};

export const PodSummaryCardWarnings = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.warnings')}
        </span>
        <div className={styles.titleInfo}>
          <div className={styles.titleInfo}>
            <Chip
              color={getWarningColor(pod.warningTotalCount)}
              type="contained-2"
            >
              <span className="bold">{pod.warningTotalCount}</span>
            </Chip>
            <IconButton
              className={styles.titleInfoCollapse}
              onClick={() => setOpenDetail(!openDetail)}
              size="small"
            >
              <CollapseIcon open={openDetail} />
            </IconButton>
          </div>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <RecursiveTable
            data={pod.warningCounts.map((warning) => ({
              name: warning.item as string,
              column1: warning.count,
            }))}
            header="Warning"
          />
        </div>
      </Collapse>
    </div>
  );
};
