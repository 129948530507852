export class OrganizationAutoIngestionConfig {
  autoTba = false;

  sftpPath = '';

  sftpRawFilenamePattern = '';

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default OrganizationAutoIngestionConfig;
