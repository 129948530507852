import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { MemberStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { Patient, PatientSearch } from '@vestahealthcare/common/models';

import { Colors } from '../../styles/Colors';
import { Fonts } from '../../styles/Variables';

type Props = {
  className?: string;
  'data-cy'?: string;
  filterString?: string;
  member: Patient | PatientSearch;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  name: {
    fontSize: `calc(${Fonts.fontSize} * 0.875)`,
    fontWeight: 500,
  },
  extraInfo: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 400,
  },
});

const getColor = (
  color?: 'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'action',
) => {
  if (color === 'primary' || color === 'secondary') return Colors.textGreen;
  if (color === 'action') return Colors.textGray;
  if (color === 'error') return Colors.textRed2;
  if (color === 'warning') return Colors.textOrange;
  if (color === 'info') return Colors.textBlue;
  return Colors.textBlack;
};

export const SelectMemberOption = ({
  className,
  'data-cy': dataCy,
  filterString = '',
  member,
}: Props) => {
  const styles = useStyles();
  const classes = classNames(className, styles.container);

  const renderMemberDetails = (patient: Patient | PatientSearch) => {
    const referralsInfo = [
      ...(patient.activeReferrals?.map((ref) =>
        ref.organization.abbr?.toUpperCase(),
      ) || []),
    ]
      .filter(Boolean)
      .join(' - ');

    const externalID = member.activeReferrals?.find(
      ({ externalId }) =>
        filterString &&
        filterString?.length > 2 &&
        externalId.includes(filterString?.trim()),
    )?.externalId;

    const idString = externalID
      ? translate('search.externalID', { id: externalID })
      : undefined;
    const medicareId =
      member instanceof PatientSearch &&
      member.medicareId?.includes(filterString)
        ? translate('search.medicareId', { id: member.medicareId })
        : undefined;
    const medicaidId =
      member instanceof PatientSearch &&
      member.medicaidId?.includes(filterString)
        ? translate('search.medicaidId', { id: member.medicaidId })
        : undefined;
    const mltcId =
      member instanceof PatientSearch && member.mltcId?.includes(filterString)
        ? translate('search.mltcId', { id: member.mltcId })
        : undefined;

    const ellipsisId = (id: string) =>
      id.length > 30 ? `${id.substring(0, 27)}...` : id;

    return (
      <>
        <div className="flex gap no-vertical wrap">
          {patient.status && (
            <span
              className={styles.extraInfo}
              style={{
                color: getColor(MemberStatus.COLOR_MAP.get(patient.status)),
              }}
            >
              {patient.status?.toString().toUpperCase()}
              {' - '}
            </span>
          )}

          {idString && (
            <span className={styles.extraInfo}>{ellipsisId(idString)}</span>
          )}
          {idString && <span> - </span>}

          <span className={styles.extraInfo}>
            {patient.birthDateFormatted}
            {' - '}
          </span>
          {referralsInfo && (
            <p className={classNames(styles.extraInfo, 'no-margin')}>
              {referralsInfo.length > 25
                ? `${referralsInfo.substring(0, 22)}...`
                : referralsInfo}
            </p>
          )}
        </div>
        <div className="flex gap no-vertical wrap">
          <span className={styles.extraInfo}>
            {[
              medicareId ? ellipsisId(medicareId) : undefined,
              medicaidId ? ellipsisId(medicaidId) : undefined,
              mltcId ? ellipsisId(mltcId) : undefined,
            ]
              .filter(Boolean)
              .join(' - ')}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className={classes} data-cy={dataCy}>
      <span className={styles.name}>
        {member.fullName} ({translate('search.dashID', { id: member.id })})
      </span>
      {renderMemberDetails(member)}
    </div>
  );
};

export default SelectMemberOption;
