import classNames from 'classnames';
import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummaryForecast } from '@vestahealthcare/common/models';

import { Chip, Collapse, CollapseIcon, IconButton } from 'styleguide-v2';

import RecursiveTable, {
  RecursiveTableData,
} from 'dash/src/components/RecursiveTable';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummaryForecast;

  getForecastTaskRowOnClick?: (
    item: RecursiveTableData,
  ) => ((item: RecursiveTableData) => void) | undefined;
};

export const PodSummaryCardTaskForecast = ({
  className,
  getForecastTaskRowOnClick,
  pod,
}: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.taskSummary')}
        </span>
        <div className={styles.titleInfo}>
          <Chip color="info" type="contained-2">
            <span className="bold">{pod.tasksCount}</span>
          </Chip>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <RecursiveTable
            data={pod.taskStatusCounts.map((task) => ({
              name: task.item,
              column1: task.count,
              subtotals: task.employees?.map((e) => ({
                name: e.item,
                column1: e.count,
              })),
            }))}
            getRowOnClick={getForecastTaskRowOnClick}
            header="Task"
          />
        </div>
      </Collapse>
    </div>
  );
};
