import { Moment } from 'moment';

import { translate } from '../../i18n';
import { make, makeArray, makeUnix } from '../Model';
import { PodReportingData } from '../PodReportingData';
import { PodReportingDataExtended } from '../PodReportingDataExtended';
import { PodReportingGroup } from '../PodReportingGroup';
import { PodReportingSummary } from '../PodReportingSummary';
import { PodReportingTask } from '../PodReportingTask';

export class PodReportingCCM {
  ccmTasks: PodReportingTask[];

  ccmEscalations: PodReportingDataExtended[];

  minutes: PodReportingData[];

  minutesToNextCpt: PodReportingData[];

  providerTime: PodReportingData[];

  providerTimeComplex: PodReportingData[];

  status: PodReportingData[];

  summary: PodReportingSummary;

  worklistGroups: PodReportingGroup[];

  lastUpdatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.ccmTasks = makeArray(obj.ccmTasks, PodReportingTask) || [];
    this.ccmEscalations =
      makeArray(obj.ccmEscalations, PodReportingDataExtended).sort(
        PodReportingDataExtended.sort,
      ) || [];
    this.lastUpdatedAt = makeUnix(obj.lastUpdatedAt);
    this.minutes = makeArray(obj.minutes, PodReportingData);
    this.minutesToNextCpt = (makeArray(
      obj.minutesToNextCpt,
      PodReportingData,
    ) as PodReportingData[]).sort(({ name: a }, { name: b }) => {
      if (!a.includes('-')) return 1;
      if (!b.includes('-')) return -1;

      const [first]: number[] = a
        .split('-')
        .map((i: string) => i.trim())
        .map(Number.parseInt);
      const [second]: number[] = b
        .split('-')
        .map((i: string) => i.trim())
        .map(Number.parseInt);
      if (Number.isNaN(first)) return 1;
      if (Number.isNaN(second)) return -1;

      return first - second;
    });
    this.providerTime = makeArray(obj.providerTime, PodReportingData);
    this.providerTimeComplex = makeArray(
      obj.providerTimeComplex,
      PodReportingData,
    );
    this.status = makeArray(obj.status, PodReportingData);
    this.summary = make(obj.summary, PodReportingSummary);
    this.worklistGroups = makeArray(
      obj.worklistGroups,
      PodReportingGroup,
    )?.sort(PodReportingGroup.sort);

    if (this.worklistGroups.length > 1) {
      this.worklistGroups = [
        new PodReportingGroup({
          worklistName: translate('global.total'),
          summary: this.summary,
        }),
        ...this.worklistGroups,
      ];
    }
  }
}

export default PodReportingCCM;
