import classNames from 'classnames';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  PodMetricsSummary,
  PodMetricsSummaryCommon,
  PodMetricsSummaryForecast,
} from '@vestahealthcare/common/models';

import { Chip, CollapseIcon, Fonts, IconButton, Table } from 'styleguide-v2';
import {
  COLORS_DEFAULT,
  COLORS_STATUS,
} from 'styleguide-v2/src/components/PieChart';
import { PieChartLegend } from 'styleguide-v2/src/components/PieChart/PieChartLegend';
import { Column, TableItem } from 'styleguide-v2/src/components/Table';

import { getProgramColor } from '../utils';
import { PODSummaryTableAllocation } from './PODSummaryTableAllocation';
import { PODSummaryTableBiannual } from './PODSummaryTableBiannual';
import { PODSummaryTableMember } from './PODSummaryTableMember';
import { PODSummaryTableProgram } from './PODSummaryTableProgram';
import { PODSummaryTableTask } from './PODSummaryTableTask';
import { PODSummaryTableTaskForecast } from './PODSummaryTableTaskForecast';
import { PODSummaryTableUnreachable } from './PODSummaryTableUnreachable';
import { COLUMN_SIZE, COLUMN_SIZE_HEADER } from './constants';

type Props = {
  pods: PodMetricsSummary[] | PodMetricsSummaryForecast[];
};

type KeyOfColumns = 'headerName' | 'program';

type InternalData = {
  id: KeyOfColumns;
  headerName: string;
  data: PodMetricsSummaryCommon[];
} & TableItem;

const columns = {
  headerName: (data: PodMetricsSummaryCommon) =>
    data.memberCount !== undefined ? data.memberCount : ' ',
  program: (data: PodMetricsSummaryCommon) =>
    data instanceof PodMetricsSummary ? (
      <>
        <Chip
          className="semi-bold"
          type="contained-2"
          color={getProgramColor(data.ccmCompletedPercetage)}
        >
          CCM: {data.ccmCompletedPercetage}%
        </Chip>
        <Chip
          className="semi-bold"
          type="contained-2"
          color={getProgramColor(data.rpmCompletedPercetage)}
        >
          RPM: {data.rpmCompletedPercetage}%
        </Chip>
        <Chip
          className="semi-bold"
          type="contained-2"
          color={getProgramColor(data.tocCompletedPercetage)}
        >
          TOC: {data.tocCompletedPercetage}%
        </Chip>
        <Chip className="semi-bold" type="contained-2" color="info">
          PERS: {data.persCount}
        </Chip>
      </>
    ) : (
      <>
        <Chip className="semi-bold" type="contained-2" color="info">
          CCM: {data.ccmCount}
        </Chip>
        <Chip className="semi-bold" type="contained-2" color="info">
          RPM: {data.rpmCount}
        </Chip>
        <Chip className="semi-bold" type="contained-2" color="info">
          TOC: {data.tocCount}
        </Chip>
        <Chip className="semi-bold" type="contained-2" color="info">
          PERS: {data.persCount}
        </Chip>
      </>
    ),
  unreachable: (pod: PodMetricsSummaryCommon) => pod.unreachableCount,
  task: (pod: PodMetricsSummaryCommon) =>
    pod instanceof PodMetricsSummary ? (
      <Chip className="semi-bold" type="contained-2" color="success">
        {pod.taskCompletedPercetage}%
      </Chip>
    ) : (
      <Chip className="semi-bold" type="contained-2" color="info">
        {pod.tasksCount}
      </Chip>
    ),
  biannual: (pod: PodMetricsSummaryCommon) => (
    <div className="flex wrap gap">
      <Chip className="semi-bold" type="contained-2" color="warning">
        Due: {pod.npBiannualDueCount}
      </Chip>
      <Chip className="semi-bold" type="contained-2" color="error">
        Overdue: {pod.npBiannualOverdueCount}
      </Chip>
    </div>
  ),
  allocation: (pod: PodMetricsSummaryCommon) =>
    `${pod.memberCount}/${pod.maxMembers}`,
} as {
  [x in KeyOfColumns]: (
    data: PodMetricsSummaryCommon | string,
  ) => string | ReactNode;
};

const DEFAULT_ROWS = [
  {
    id: 'headerName',
    headerName: 'Member Info',
    data: [] as PodMetricsSummary[],
  },
  {
    id: 'program',
    headerName: 'Program Summary',
    data: [] as PodMetricsSummary[],
  },
  {
    id: 'unreachable',
    headerName: 'Unreachable Totals',
    data: [] as PodMetricsSummary[],
  },

  {
    id: 'task',
    headerName: 'Task Summary',
    data: [] as PodMetricsSummary[],
  },

  {
    id: 'biannual',
    headerName: 'Biannual Summary',
    data: [] as PodMetricsSummary[],
  },
  {
    id: 'allocation',
    headerName: 'Member Allocation',
    data: [] as PodMetricsSummary[],
  },
] as InternalData[];

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
  },
  headers: {
    minWidth: `${COLUMN_SIZE_HEADER}px`,
    marginTop: '49.64px',
    '& > div': {
      alignItems: 'baseline',
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 500,
      height: '60px',
      paddingRight: '2rem',
      paddingTop: '1rem',

      '& > span': {
        alignSelf: 'end',
      },
    },
    '& > div:nth-child(2)': {
      height: '86px',
    },

    '& > div.open': {
      transition: 'height 0.3s ease',
    },

    '& > div:nth-child(1).open': {
      height: '290px',
    },
    '& > div:nth-child(2).open': {
      height: '536px',
    },
    '& > div:nth-child(3).open': {
      height: '180px',
    },
    '& > div:nth-child(5).open': {
      height: '210px',
    },
    '& > div:nth-child(6).open': {
      height: '182px',
    },
  },
  headerCollapse: {
    height: '4rem',
  },
  headerText: {},
  legend: {
    height: '101px',
  },
  legendContainer: {
    alignSelf: 'end',
    marginTop: '35px',
    display: 'flex',
    flexFlow: 'column',
    gap: '1rem',
  },
  legendRow: {
    alignItems: 'end',
    overflow: 'hidden',
  },
  table: {
    '&& > thead > tr > th': {
      textAlign: 'center',
    },
    '&& > tbody > tr > td': {
      height: '60px',
    },
    '&& > tbody > tr.detail > td': {
      padding: 0,
    },
  },
  tableContainer: {
    overflowY: 'auto',
    width: '100%',
  },
});

const POD_METRICS_COLUMNS = ({ pods }: Props) => {
  const result = [
    ...pods.map((pod, idx) => ({
      headerName: pod.name,
      component: ({ id, data }: InternalData) => (
        <div className="flex center wrap gap">{columns[id](data[idx])}</div>
      ),
      width: COLUMN_SIZE,
    })),
  ] as Column[];

  return result;
};

export const PODSummaryTable = (props: Props) => {
  const { pods } = props;

  const [openMemberInfo, setOpenMemberInfo] = useState<boolean>(false);
  const [openProgram, setOpenProgram] = useState<boolean>(false);
  const [openUnreachable, setOpenUnreachable] = useState<boolean>(false);
  const [openMemberAllocation, setOpenMemberAllocation] = useState<boolean>(
    false,
  );
  const [openTask, setOpenTask] = useState<boolean>(false);
  const [openBiannual, setOpenBiannual] = useState<boolean>(false);
  const [memberInfoLoading] = useState<boolean>(false);
  const [programLoading] = useState<boolean>(false);
  const [unreachableLoading] = useState<boolean>(false);
  const [memberAllocationLoading] = useState<boolean>(false);
  const [taskLoading] = useState<boolean>(false);
  const [biannualLoading] = useState<boolean>(false);

  const maxTaskLength = useMemo(
    () =>
      props.pods?.reduce((acc, item) => {
        const value = item.taskStatusCounts.length;
        return acc > value ? acc : value;
      }, 0) || 1,
    [props.pods],
  );

  const [internalData, setInternalData] = useState<InternalData[]>([]);

  useEffect(() => {
    if (!internalData.length) return;
    internalData[0].open = openMemberInfo;
    if (openMemberInfo && props.pods) {
      internalData[0].detail = <PODSummaryTableMember data={pods} />;
    } else {
      delete internalData[0].detail;
    }
    setInternalData([...internalData]);
  }, [openMemberInfo]);

  useEffect(() => {
    if (!internalData.length) return;
    internalData[1].open = openProgram;
    if (
      openProgram &&
      props.pods &&
      props.pods[0] instanceof PodMetricsSummary
    ) {
      internalData[1].detail = (
        <PODSummaryTableProgram data={pods as PodMetricsSummary[]} />
      );
    } else {
      delete internalData[1].detail;
    }
    setInternalData([...internalData]);
  }, [openProgram]);

  useEffect(() => {
    if (!internalData.length) return;
    internalData[2].open = openUnreachable;
    if (openUnreachable && props.pods) {
      internalData[2].detail = <PODSummaryTableUnreachable data={pods} />;
    } else {
      delete internalData[2].detail;
    }
    setInternalData([...internalData]);
  }, [openUnreachable]);

  useEffect(() => {
    if (!internalData.length) return;
    internalData[3].open = openTask;
    if (openTask) {
      if (props.pods && props.pods[0] instanceof PodMetricsSummary)
        internalData[3].detail = (
          <PODSummaryTableTask data={pods as PodMetricsSummary[]} />
        );
      else
        internalData[3].detail = (
          <PODSummaryTableTaskForecast
            data={pods as PodMetricsSummaryForecast[]}
          />
        );
    } else {
      delete internalData[3].detail;
    }
    setInternalData([...internalData]);
  }, [openTask]);

  useEffect(() => {
    if (!internalData.length) return;
    internalData[4].open = openBiannual;
    if (openBiannual && props.pods) {
      internalData[4].detail = <PODSummaryTableBiannual data={pods} />;
    } else {
      delete internalData[4].detail;
    }
    setInternalData([...internalData]);
  }, [openBiannual]);

  useEffect(() => {
    if (!internalData.length) return;

    internalData[5].open = openMemberAllocation;
    if (openMemberAllocation && props.pods) {
      internalData[5].detail = <PODSummaryTableAllocation data={pods} />;
    } else {
      delete internalData[5].detail;
    }
    setInternalData([...internalData]);
  }, [openMemberAllocation]);

  const styles = useStyles();

  useEffect(() => {
    setOpenBiannual(false);
    setOpenMemberAllocation(false);
    setOpenMemberInfo(false);
    setOpenProgram(false);
    setOpenTask(false);
    setOpenUnreachable(false);

    const rows = [...DEFAULT_ROWS.map((item) => ({ ...item }))];
    pods.forEach((pod, idx) => {
      rows[0].data[idx] = pod;
      rows[1].data[idx] = pod;
      rows[2].data[idx] = pod;
      rows[3].data[idx] = pod;
      rows[4].data[idx] = pod;
      rows[5].data[idx] = pod;
    });

    setInternalData(rows);
  }, [pods]);

  return (
    <div className={styles.container}>
      <div className={styles.headers}>
        <div className={classNames('flex column', openMemberInfo && 'open')}>
          <span className={styles.headerText}>
            <IconButton
              className={styles.headerCollapse}
              onClick={() => setOpenMemberInfo(!openMemberInfo)}
              loading={memberInfoLoading}
              size="small"
            >
              <CollapseIcon open={openMemberInfo} />
            </IconButton>
            Member Info
          </span>
        </div>
        <div
          className={classNames(
            'flex column',
            styles.legendRow,
            openProgram && 'open',
          )}
        >
          <span>
            {props.pods && props.pods[0] instanceof PodMetricsSummary && (
              <IconButton
                className={styles.headerCollapse}
                onClick={() => setOpenProgram(!openProgram)}
                loading={programLoading}
                size="small"
              >
                <CollapseIcon open={openProgram} />
              </IconButton>
            )}
            Program Summary
          </span>
          {props.pods && props.pods[0] instanceof PodMetricsSummary && (
            <div
              className={styles.legendContainer}
              style={{ marginTop: '55px' }}
            >
              <PieChartLegend
                className={styles.legend}
                title="CCM (Enrolled)"
                reversed
                series={[
                  {
                    color: COLORS_STATUS[0],
                    label: 'Completed',
                  },
                  {
                    color: COLORS_STATUS[1],
                    label: 'Attempted',
                  },
                  {
                    color: COLORS_STATUS[2],
                    label: 'Not Started',
                  },
                ]}
              />
              <PieChartLegend
                className={styles.legend}
                title="CCM (On Hold)"
                reversed
                series={[
                  {
                    color: COLORS_DEFAULT[0],
                    label: 'Disenroll Req.',
                  },
                  {
                    color: COLORS_DEFAULT[1],
                    label: 'Inpatient',
                  },
                  {
                    color: COLORS_DEFAULT[2],
                    label: 'Travelling',
                  },
                ]}
              />
              <PieChartLegend
                className={styles.legend}
                title="RPM"
                reversed
                series={[
                  {
                    color: COLORS_STATUS[0],
                    label: 'Met',
                  },
                  {
                    color: COLORS_STATUS[1],
                    label: 'On Track',
                  },
                  {
                    color: COLORS_STATUS[2],
                    label: 'At Risk',
                  },
                ]}
              />
              <PieChartLegend
                className={styles.legend}
                title="TOC Appt Due"
                reversed
                series={[
                  {
                    color: COLORS_STATUS[0],
                    label: 'Completed',
                  },
                  {
                    color: COLORS_STATUS[1],
                    label: 'Pending',
                  },
                  {
                    color: COLORS_STATUS[2],
                    label: 'Reschedule',
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'flex column',
            styles.legendRow,
            openUnreachable && 'open',
          )}
        >
          <span>
            <IconButton
              className={styles.headerCollapse}
              onClick={() => setOpenUnreachable(!openUnreachable)}
              loading={unreachableLoading}
              size="small"
            >
              <CollapseIcon open={openUnreachable} />
            </IconButton>
            Unreachable Totals
          </span>
          <div className={styles.legendContainer}>
            <PieChartLegend
              className={styles.legend}
              reversed
              series={[
                {
                  color: COLORS_STATUS[0],
                  label: '<30',
                },
                {
                  color: COLORS_STATUS[1],
                  label: '30-60',
                },
                {
                  color: COLORS_STATUS[2],
                  label: '60-90',
                },
                {
                  color: COLORS_STATUS[3],
                  label: '>90',
                },
              ]}
            />
          </div>
        </div>
        <div
          className={classNames('flex column', openTask && 'open')}
          style={{
            height: openTask
              ? `${100 + 24 * maxTaskLength + 5 * maxTaskLength - 5}px`
              : undefined,
          }}
        >
          <span>
            <IconButton
              className={styles.headerCollapse}
              onClick={() => setOpenTask(!openTask)}
              loading={taskLoading}
              size="small"
            >
              <CollapseIcon open={openTask} />
            </IconButton>
            Task Summary
          </span>
        </div>
        <div
          className={classNames(
            'flex column',
            styles.legendRow,
            openBiannual && 'open',
          )}
        >
          <span>
            <IconButton
              className={styles.headerCollapse}
              onClick={() => setOpenBiannual(!openBiannual)}
              loading={biannualLoading}
              size="small"
            >
              <CollapseIcon open={openBiannual} />
            </IconButton>
            Biannual Summary
          </span>
          <div
            className={styles.legendContainer}
            style={{ paddingTop: '2rem' }}
          >
            <PieChartLegend
              className={styles.legend}
              reversed
              series={[
                {
                  color: COLORS_STATUS[0],
                  label: 'Completed',
                },
                {
                  color: COLORS_STATUS[1],
                  label: 'Pending',
                },
                {
                  color: COLORS_STATUS[2],
                  label: 'Reschedule',
                },
                {
                  color: COLORS_STATUS[3],
                  label: 'Not Rescheduled',
                },
              ]}
            />
          </div>
        </div>
        <div
          className={classNames('flex column', openMemberAllocation && 'open')}
        >
          <span>
            <IconButton
              className={styles.headerCollapse}
              onClick={() => setOpenMemberAllocation(!openMemberAllocation)}
              loading={memberAllocationLoading}
              size="small"
            >
              <CollapseIcon open={openMemberAllocation} />
            </IconButton>
            Member Allocation
          </span>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table
          className={styles.table}
          config={{
            columns: POD_METRICS_COLUMNS(props),
            data: internalData,
            detail: true,
            paginationOptions: [-1],
          }}
          style={{
            width: COLUMN_SIZE * pods.length,
          }}
        />
      </div>
    </div>
  );
};

export default PODSummaryTable;
