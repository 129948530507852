import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PieChart as MPieChart,
  pieArcLabelClasses,
} from '@mui/x-charts/PieChart';
import { MakeOptional } from '@mui/x-charts/internals';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';

import { BackgroundColors, Colors } from '../../styles/Colors';
import { Fonts } from '../../styles/Variables';
import { PieChartLegend } from './PieChartLegend';

type Data = Omit<PieValueType, 'label'> & {
  label: string;
};
export type PieChartData = Data;

type ColorType = 'default' | 'status';
type SizeType = 'xs' | 's' | 'm' | 'l' | 'xl';
type TypeOption = 'contained' | 'outlined';

const SIZES: { [size in SizeType]: number } = {
  xs: 100,
  s: 150,
  m: 200,
  l: 250,
  xl: 300,
};

type Props = {
  color?: ColorType;
  className?: string;
  'data-cy'?: string;
  data: Data[];
  getTooltipValue?: (
    data: Partial<PieValueType>,
    idx: number,
    percentage: number,
  ) => string;
  onClick?: (item: Data, idx: number) => void;
  showHighlight?: boolean;
  showLegend?: boolean;
  showPercentage?: boolean;
  size?: SizeType;
  title?: string;
  type?: TypeOption;
};

export const COLORS_DEFAULT = [
  Colors.iconGreen,
  Colors.blue,
  Colors.purple,
  Colors.textGreen,
  Colors.textBlue,
  '#60009b',
];

export const COLORS_STATUS = [
  Colors.iconGreen,
  Colors.gold,
  Colors.error,
  Colors.textRed2,
  Colors.purple,
  '#60009b',
];

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    margin: '0.5rem',
    width: 'fit-content',
  },
  noDataContainer: {
    height: 0,
    fontSize: 14,
    textAlign: 'center',
  },
});

export const PieChart = ({
  className,
  color = 'default',
  data,
  'data-cy': dataCy,
  getTooltipValue,
  onClick,
  showHighlight,
  showLegend,
  showPercentage,
  size = 'm',
  title,
  type = 'contained',
}: Props) => {
  const styles = useStyles();
  const showPercentageCalc = showPercentage && type === 'contained';
  const total = useMemo(
    () => data.reduce((acc, { value }) => acc + (value || 0), 0),
    [data],
  );

  const showPercentageProps: Omit<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type' | 'data'
  > = {
    arcLabel: (item: { value: number }) =>
      `${Math.round((100 * item.value) / total)}%`,
    arcLabelMinAngle: 35,
    arcLabelRadius: '140%',
  };

  const showHighlightProps: Omit<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type' | 'data'
  > = {
    highlightScope: { fade: 'global', highlight: 'item' },
    faded: { additionalRadius: -SIZES[size] / 10, color: 'gray' },
  };

  const getColors = (c: ColorType) => {
    if (c === 'status') return COLORS_STATUS;
    return COLORS_DEFAULT;
  };

  const CustomItemTooltipContent = (props: any) => {
    const { itemData, series } = props;
    const serie = series.data[itemData.dataIndex];

    if (!serie.label) return <></>;

    return (
      <Paper
        sx={{ alignItems: 'center', display: 'flex', padding: '1rem 1.5rem' }}
      >
        <div
          style={{
            backgroundColor: serie.color,
            borderRadius: '10px',
            height: '1.5rem',
            marginRight: '0.75rem',
            width: '1.5rem',
          }}
        />
        <span
          style={{
            marginRight: '3rem',
            marginTop: '0.125rem',
            color: Colors.textGray,
          }}
        >
          {serie.label}
        </span>
        <span style={{ marginTop: '0.125rem' }}>
          {getTooltipValue
            ? getTooltipValue(
                serie,
                itemData.dataIndex,
                Math.round((1000 * serie.value) / total) / 10,
              )
            : serie.value}
        </span>
      </Paper>
    );
  };

  return (
    <div className={className}>
      <div className={classNames(styles.container)}>
        <MPieChart
          colors={getColors(color)}
          data-cy={dataCy}
          onItemClick={(_, pieItemIdentifier) =>
            onClick &&
            onClick(
              data[pieItemIdentifier.dataIndex],
              pieItemIdentifier.dataIndex,
            )
          }
          series={[
            {
              cornerRadius: size === 'xs' ? 5 : 10,
              cx: SIZES[size] / 2 - 5,
              cy: SIZES[size] / 2 - 5,
              innerRadius:
                type === 'contained' ? SIZES[size] / 6 : SIZES[size] / 3,
              outerRadius: SIZES[size] / 2,
              paddingAngle: 1,
              ...(showPercentageCalc ? showPercentageProps : {}),
              ...(showHighlight ? showHighlightProps : {}),
              data:
                data?.length && !!total
                  ? data
                  : [
                      {
                        value: Math.random(),
                        color: BackgroundColors.lightGray,
                      },
                      {
                        value: Math.random(),
                        color: BackgroundColors.lightGray,
                      },
                      {
                        value: Math.random(),
                        color: BackgroundColors.lightGray,
                      },
                    ],
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
            noDataOverlay: data?.length
              ? undefined
              : { message: 'No data to display in this chart' },
          }}
          sx={{
            ...(showPercentageCalc
              ? {
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontSize: size === 'xs' ? 12 : undefined,
                    fill: Colors.textWhite,
                    fontFamily: Fonts.fontFamily,
                    fontWeight: size === 'xs' ? 600 : 500,
                  },
                }
              : {}),
          }}
          tooltip={{ trigger: 'item', itemContent: CustomItemTooltipContent }}
          width={SIZES[size]}
          height={SIZES[size]}
        />

        {showLegend && (
          <PieChartLegend
            series={data.map((item, idx) => ({
              label: item.label as string,
              color:
                item.color || getColors(color)[idx % COLORS_DEFAULT.length],
            }))}
            title={title}
          />
        )}
      </div>
      {(!data?.length || !total) && (
        <div
          className={styles.noDataContainer}
          style={{
            transform: `translateY(-${SIZES[size] / 2 + 15}px)`,
            width: SIZES[size] + 10,
          }}
        >
          No Data
        </div>
      )}
    </div>
  );
};
