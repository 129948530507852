import { Enum } from './Enum';

export class ReferralDeletionReason extends Enum {
  translationKey = 'referralDeletionReason';

  static asArray: ReferralDeletionReason[] = [];

  static byKey: { [name: string]: ReferralDeletionReason } = {};

  static readonly USER_ERROR = new ReferralDeletionReason('USER_ERROR');

  static readonly ROSTER_MAPPING_ERROR = new ReferralDeletionReason(
    'ROSTER_MAPPING_ERROR',
  );

  static readonly OTHER = new ReferralDeletionReason('OTHER');

  constructor(public readonly value: string) {
    super();
    ReferralDeletionReason.asArray.push(this);
    ReferralDeletionReason.byKey[value] = this;
  }
}

export default ReferralDeletionReason;
